import { EPlansChangeValues } from '@/types/plans/plans';

import { FC, useState } from 'react';
import { Select } from '@mantine/core';
import { IconChevronDown, IconChevronUp } from '@tabler/icons-react';

import { useStyles } from '@/assets/styles/selectPlanStatus';

interface PlanStatusProps {
  onChange: (value: EPlansChangeValues) => void;
}

const SelectPlanStatus: FC<PlanStatusProps> = ({ onChange }) => {
  const { classes } = useStyles();

  const [isOpened, setIsOpened] = useState<boolean>(false);

  const selectData = [
    { value: EPlansChangeValues.published, label: 'Готов' },
    { value: EPlansChangeValues.declined, label: 'Отклонен' },
    { value: EPlansChangeValues.processed, label: 'Рассчитан' },
  ];

  const handleChangeState = () => {
    setIsOpened(!isOpened);
  };

  return (
    <Select
      onDropdownOpen={handleChangeState}
      onDropdownClose={handleChangeState}
      className={classes.select}
      w={230}
      value={null}
      transitionProps={{
        transition: 'pop-top-left',
        duration: 80,
        timingFunction: 'ease',
      }}
      data={selectData}
      placeholder="Изменить статус анализа"
      onChange={onChange}
      rightSection={!isOpened ? <IconChevronDown size="1rem" /> : <IconChevronUp size="1rem" />}
      variant="filled"
    />
  );
};

export default SelectPlanStatus;
