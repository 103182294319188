import { ERuleType, IFixPriceRule, IFixPriceRuleFormValues } from '@/types/rules/rules';

import { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ActionIcon,
  Badge,
  Button,
  createStyles,
  Flex,
  Switch,
  Text,
  TextInput,
  Title,
  Tooltip,
} from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { useForm } from '@mantine/form';
import { modals } from '@mantine/modals';
import { IconCalendar, IconPlus, IconTrash, IconX } from '@tabler/icons-react';
import moment from 'moment';

import {
  emptyValuesValidation,
  getFixPriceRuleInitialValues,
  modifiedFixPriceRule,
  positiveNumbersValidation,
} from '@/utils/forms';
import { dateParse, modifiedNodeParams } from '@/utils/rules';

import { rulesInitialValues } from '@/constants/rules';

import 'dayjs/locale/ru';

import PriceRuleBlock from '../PriceRuleBlock/PriceRuleBlock';

import { AppDispatch } from '@/store';
import { selectNodesParams } from '@/store/slices/nodes/nodes';
import {
  fetchDeleteRuleAction,
  fetchRuleEditAction,
  fetchRulesListAction,
  selectRuleBlocks,
  setCurrentRule,
  setFixPriceRule,
  setFormsCommentModalOpened,
  setRuleBlocks,
} from '@/store/slices/rules/rules';

export const openDeleteModal = (onDelete: () => void) =>
  modals.openConfirmModal({
    title: (
      <Text fz={22} weight="bold">
        Удаление правила
      </Text>
    ),
    children: (
      <>
        <Text size="md" color="dark.2">
          Вы действительно хотите удалить правило без возможности восстановления?
        </Text>
      </>
    ),
    labels: { confirm: 'Удалить', cancel: 'Отменить' },
    confirmProps: { color: 'orange.9', sx: { width: '195px' } },
    cancelProps: { variant: 'filled', color: 'gray.2', sx: { width: '195px' } },

    onConfirm: () => {
      onDelete();
    },
  });

export const useStyles = createStyles((theme) => ({
  label: {
    fontWeight: 'bold',
  },
  rightSection: {
    color: 'gray',
  },
  actionIcon: {
    backgroundColor: theme.colors.gray[2],
    height: '36px',
  },
  track: {
    cursor: 'pointer',
  },
}));

interface FixPriceRuleProps {
  fixPriceRule: IFixPriceRule;
  alsoHasInheritedRule?: boolean;
}

const FixPriceRule: FC<FixPriceRuleProps> = ({ alsoHasInheritedRule, fixPriceRule }) => {
  const { classes } = useStyles();

  const dispatch: AppDispatch = useDispatch();

  const ruleBlocks = useSelector(selectRuleBlocks);
  const nodesParams = useSelector(selectNodesParams);

  const [disabled, setDisabled] = useState<boolean>(fixPriceRule?.inherited ?? false);

  const form = useForm({
    initialValues: {
      fields: getFixPriceRuleInitialValues(fixPriceRule?.values ?? []),
    },
    validate: {
      fields: {
        dateStart: (value) => emptyValuesValidation(value),
        dateEnd: (value) => emptyValuesValidation(value),
        value: (value) => positiveNumbersValidation(value),
      },
    },
    validateInputOnBlur: true,
    validateInputOnChange: true,
  });

  const handleSubmit = (formData: { fields: IFixPriceRuleFormValues[] }) => {
    if (fixPriceRule) {
      const newValues = formData.fields.map((item) => ({
        dateStart: moment(item.dateStart).format('DD.MM.YYYY'),
        dateEnd: moment(item.dateEnd).format('DD.MM.YYYY'),
        value: Number(item.value),
      }));

      const updatedFixPriceRule = {
        ...fixPriceRule,
        values: newValues,
      };

      dispatch(setFixPriceRule(updatedFixPriceRule));
      dispatch(setCurrentRule(updatedFixPriceRule));
      dispatch(setFormsCommentModalOpened(true));
    }
  };

  const changeActiveStatus = async (status: boolean) => {
    if (fixPriceRule) {
      const updatedFixPriceRule = {
        ...fixPriceRule,
        active: status,
      };

      dispatch(setCurrentRule(updatedFixPriceRule));
      dispatch(setFixPriceRule(updatedFixPriceRule));

      await dispatch(
        fetchRuleEditAction({
          nodes: modifiedNodeParams(nodesParams),
          rule: modifiedFixPriceRule(status, fixPriceRule),
        }),
      );

      dispatch(fetchRulesListAction(modifiedNodeParams(nodesParams)));
    }
  };

  const addValue = () => {
    if (fixPriceRule)
      dispatch(
        setFixPriceRule({
          ...fixPriceRule,
          values: [...fixPriceRule?.values, { ...rulesInitialValues.fixPriceRule }],
        }),
      );

    form.insertListItem('fields', { ...rulesInitialValues.fixPriceRuleFormValue });
  };

  const removeValue = (valueIndex: number) => {
    if (fixPriceRule) {
      if (fixPriceRule?.values.length !== 1) {
        const newValues = fixPriceRule?.values.filter((item, index) => index !== valueIndex);

        dispatch(
          setFixPriceRule({
            ...fixPriceRule,
            values: newValues,
          }),
        );

        form.removeListItem('fields', valueIndex);
      } else {
        form.setFieldValue('fields.0.dateEnd', dateParse(moment().format('DD.MM.YYYY')));
        form.setFieldValue('fields.0.dateStart', dateParse(moment().format('DD.MM.YYYY')));
        form.setFieldValue('fields.0.value', '');
      }
    }
  };

  const deleteRule = async () => {
    if (fixPriceRule?.id) {
      await dispatch(fetchDeleteRuleAction([fixPriceRule.id]));
      dispatch(fetchRulesListAction(modifiedNodeParams(nodesParams)));
    }

    dispatch(setFixPriceRule(null));
    const updatedRuleBlocks = ruleBlocks.map((item) => ({
      ...item,
      checked: item.type === ERuleType.fixPrice ? false : item.checked,
      shown: item.type === ERuleType.fixPrice ? false : item.shown,
    }));

    dispatch(setRuleBlocks(updatedRuleBlocks));
  };

  return (
    <PriceRuleBlock>
      <Flex direction="row" justify="space-between">
        {fixPriceRule?.inherited || alsoHasInheritedRule ? (
          <Badge color="green" variant="filled">
            Наследуется
          </Badge>
        ) : (
          <Switch
            classNames={{ track: classes.track }}
            checked={fixPriceRule?.active ?? false}
            color="dark"
            size="md"
            onChange={(event) => {
              changeActiveStatus(event.currentTarget.checked);
            }}
          />
        )}
        {!(alsoHasInheritedRule || fixPriceRule?.inherited) && (
          <ActionIcon
            color="dark"
            size="lg"
            variant="transparent"
            className={classes.actionIcon}
            onClick={() => openDeleteModal(deleteRule)}
          >
            <IconX />
          </ActionIcon>
        )}
      </Flex>
      <Title mt={12} mb={24} order={3}>
        {fixPriceRule?.name}
      </Title>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        {fixPriceRule?.values.map((item, index) => (
          <Flex direction="row" gap={24} align="flex-end" key={index}>
            <DatePickerInput
              classNames={{ label: classes.label }}
              w={182}
              locale="ru"
              valueFormat="DD.MM.YYYY"
              label="Дата начала"
              description="Включительно"
              disabled={!fixPriceRule.active || disabled}
              icon={<IconCalendar size="1.1rem" stroke={1.5} />}
              maxDate={form.getInputProps(`fields.${index}.dateEnd`).value}
              clearable
              required
              {...form.getInputProps(`fields.${index}.dateStart`)}
            />
            <DatePickerInput
              classNames={{ label: classes.label }}
              w={182}
              locale="ru"
              valueFormat="DD.MM.YYYY"
              label="Дата завершения"
              description="Не включительно"
              disabled={!fixPriceRule.active || disabled}
              icon={<IconCalendar size="1.1rem" stroke={1.5} />}
              minDate={form.getInputProps(`fields.${index}.dateStart`).value}
              clearable
              required
              {...form.getInputProps(`fields.${index}.dateEnd`)}
            />
            <TextInput
              classNames={{ label: classes.label, rightSection: classes.rightSection }}
              w={182}
              label="Значение"
              rightSection="₽"
              disabled={!fixPriceRule.active || disabled}
              required
              {...form.getInputProps(`fields.${index}.value`)}
            />
            <ActionIcon
              className={classes.actionIcon}
              color="dark"
              size="lg"
              variant="transparent"
              disabled={!fixPriceRule.active || disabled}
              onClick={() => removeValue(index)}
            >
              <IconTrash />
            </ActionIcon>
          </Flex>
        ))}

        <Flex direction="row" justify="space-between" mt={24}>
          <Button
            sx={(theme) => ({
              color: fixPriceRule?.active || !disabled ? theme.colors.blue[8] : 'gray',
              paddingLeft: '0px',
              ['&[data-disabled]']: {
                backgroundColor: 'white',
              },
            })}
            variant="subtle"
            disabled={!fixPriceRule?.active || disabled}
            leftIcon={<IconPlus size="1.3rem" />}
            onClick={addValue}
          >
            Добавить ещё
          </Button>
          {alsoHasInheritedRule && (
            <Tooltip
              label="Вы не можете редактировать правило, так как уже создано деактивированное правило. Перейдите во вкладку деактивированных правил."
              withArrow
              multiline
              w={350}
              openDelay={500}
              position="left"
            >
              <Flex>
                <Button color="dark.9" disabled={alsoHasInheritedRule}>
                  Редактировать
                </Button>
              </Flex>
            </Tooltip>
          )}
          {fixPriceRule?.active && disabled && !alsoHasInheritedRule && (
            <Button color="dark.9" onClick={() => setDisabled(false)}>
              Редактировать
            </Button>
          )}
          {fixPriceRule?.active && !disabled && !alsoHasInheritedRule && (
            <Button color="dark.9" type="submit" disabled={!form.isValid()}>
              Сохранить
            </Button>
          )}
        </Flex>
      </form>
    </PriceRuleBlock>
  );
};

export default FixPriceRule;
