import { FC } from 'react';
import { useSelector } from 'react-redux';
import { Badge, createStyles, Flex, Grid, Spoiler, Text } from '@mantine/core';
import { IconChevronDown, IconChevronUp } from '@tabler/icons-react';

import { setStatusColor, setValidationStatus } from '@/utils/plans';

import { selectPlanDetails } from '@/store/slices/plans/plans';

const useStyles = createStyles((theme) => ({
  icon: {
    color: theme.colors.mariner[6],
  },
  badge: {
    padding: '1px 8px',
    height: '24px',
  },
}));

export const EditPlanPageInfoTitle: FC = () => {
  const { classes } = useStyles();

  const planDetails = useSelector(selectPlanDetails);

  if (!planDetails) return null;

  return (
    <Spoiler
      styles={{
        control: {
          ':hover': { textDecoration: 'none' },
          paddingBottom: '16px',
        },
      }}
      maxHeight={0}
      showLabel={
        <Flex gap={4} align="center">
          <Text color="mariner.6" size={14}>
            Показать информацию об анализе
          </Text>
          <IconChevronDown className={classes.icon} />
        </Flex>
      }
      hideLabel={
        <Flex gap={4} align="center">
          <Text color="mariner.6" size={14}>
            Скрыть
          </Text>
          <IconChevronUp className={classes.icon} />
        </Flex>
      }
    >
      {!!planDetails?.description.length && (
        <Text color="gray.7" pb={16}>
          {planDetails.description}
        </Text>
      )}

      <Grid>
        <Grid.Col span={2}>
          <Text color="gray.6" size={14}>
            Статус согласования
          </Text>
          <Badge
            color={setStatusColor(planDetails.validationStatus)}
            variant="filled"
            size="lg"
            radius="md"
            fw={500}
            className={classes.badge}
          >
            {planDetails.validationStatus}
          </Badge>
        </Grid.Col>
        <Grid.Col span={2}>
          <Text color="gray.6" size={14}>
            Статус расчета
          </Text>
          <Badge
            color={setValidationStatus(planDetails.status)}
            variant="filled"
            size="lg"
            radius="md"
            fw={500}
            className={classes.badge}
          >
            {planDetails.status}
          </Badge>
        </Grid.Col>
        <Grid.Col span={2}>
          <Text color="gray.6" size={14}>
            Кол-во товаров
          </Text>
          <Text size={14}>{planDetails.productsCount}</Text>
        </Grid.Col>
        <Grid.Col span={2}>
          <Text color="gray.6" size={14}>
            Кол-во регионов
          </Text>
          <Text size={14}>{planDetails.regionsCount}</Text>
        </Grid.Col>
        <Grid.Col span={2}>
          <Text color="gray.6" size={14}>
            Кол-во пар товар-регион
          </Text>
          <Text size={14}>{planDetails.pairsCount}</Text>
        </Grid.Col>
        <Grid.Col span={2}>
          <Text color="gray.6" size={14}>
            Дата создания
          </Text>
          <Text size={14}>{planDetails.createdAt}</Text>
        </Grid.Col>
        <Grid.Col span={2}>
          <Text color="gray.6" size={14}>
            Дата расчета
          </Text>
          <Text size={14}>{planDetails.processedAt || '\u2014'}</Text>
        </Grid.Col>
        <Grid.Col span={2}>
          <Text color="gray.6" size={14}>
            Дата согласования
          </Text>
          <Text size={14}>{planDetails.approved_at || '\u2014'}</Text>
        </Grid.Col>
        <Grid.Col span={2}>
          <Text color="gray.6" size={14}>
            Кол-во цен (Поднято/Опущено)
          </Text>
          <Text
            size={14}
          >{`${planDetails.changedPrices} (${planDetails.increasedPrices} / ${planDetails.decreasedPrices})`}</Text>
        </Grid.Col>
        <Grid.Col span={2}>
          <Text color="gray.6" size={14}>
            Кол-во пар с нарушением
          </Text>
          <Text size={14}>{planDetails.pairsWithErrors}</Text>
        </Grid.Col>
        <Grid.Col span={2}>
          <Text color="gray.6" size={14}>
            Общий оборот за 30 дней
          </Text>
          <Text size={14}>{planDetails.turnover}</Text>
        </Grid.Col>
        <Grid.Col span={2}>
          <Text color="gray.6" size={14}>
            Общий расчетный оборот
          </Text>
          <Text size={14}>{planDetails.estimatedTurnover}</Text>
        </Grid.Col>
        <Grid.Col span={2}>
          <Text color="gray.6" size={14}>
            Общая расчетная рентабельность
          </Text>
          <Text size={14}>{planDetails.estimatedProfit}</Text>
        </Grid.Col>
      </Grid>
    </Spoiler>
  );
};
