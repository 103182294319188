import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';

import { getCookie } from '@/utils/cookie';

import { paths } from '@/constants/paths';

import { selectUserInfo } from '@/store/slices/user/user';

export function RequireAuth({ children }: { children: JSX.Element }) {
  const isAuth = useSelector(selectUserInfo);

  const token = getCookie('token');

  let location = useLocation();

  if (!isAuth && !token) {
    return <Navigate to={paths.PATH_AUTH} state={{ from: location }} replace />;
  }

  return children;
}
