import { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Flex, Input, Modal, Text } from '@mantine/core';
import { useForm } from '@mantine/form';

import { AppDispatch } from '@/store';
import {
  fetchPlanItemLockPriceAction,
  selectDetailPlanInfo,
  selectPlanChangeLockPriceModalOpened,
  selectPlanDetailRefetch,
  setPlanChangeLockPriceModalOpened,
  setPlanDetailRefetch,
} from '@/store/slices/plans/plans';

const EditPlanTableChangeLockPriceModal: FC = () => {
  const dispatch: AppDispatch = useDispatch();

  const planChangeLockPriceModalOpened = useSelector(selectPlanChangeLockPriceModalOpened);
  const planEditInfo = useSelector(selectDetailPlanInfo);
  const planDetailRefetch = useSelector(selectPlanDetailRefetch);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const form = useForm({
    initialValues: {
      value: planEditInfo?.lockPrice || '',
    },
  });

  const handleModalClose = () => {
    dispatch(setPlanChangeLockPriceModalOpened(false));
    form.setFieldValue('value', '');
  };

  const handleSubmit = async (formData: { value: string | number }) => {
    if (planEditInfo?.id) {
      setIsLoading(true);
      await dispatch(
        fetchPlanItemLockPriceAction({ id: planEditInfo.id, value: Number(formData.value) }),
      );
      dispatch(setPlanDetailRefetch(!planDetailRefetch));
      setIsLoading(false);
      handleModalClose();
    }
  };

  return (
    <Modal
      size={'md'}
      opened={planChangeLockPriceModalOpened}
      onClose={handleModalClose}
      title={
        <Text fz={22} weight="bold">
          Изменить цену
        </Text>
      }
    >
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Input
          size="sm"
          color="dark.2"
          placeholder="Введите lock цену"
          {...form.getInputProps('value')}
          type="number"
        />
        <Flex direction="row" justify="space-between" gap={16} mt={24}>
          <Button fullWidth onClick={handleModalClose} variant="filled" color="gray.2">
            Отменить
          </Button>
          <Button fullWidth type="submit" color="dark.9" loading={isLoading}>
            Подтвердить
          </Button>
        </Flex>
      </form>
    </Modal>
  );
};

export default EditPlanTableChangeLockPriceModal;
