import {
  EAggregationType,
  ERuleType,
  IMinMaxMarginRule,
  IMinMaxMarginRuleValues,
} from '@/types/rules/rules';

import { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ActionIcon,
  Badge,
  Button,
  Flex,
  Select,
  Switch,
  Text,
  TextInput,
  Title,
  Tooltip,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconChevronDown, IconPlus, IconTrash, IconX } from '@tabler/icons-react';

import {
  emptyValuesValidation,
  getMinMaxMarginRuleInitialValues,
  modifiedMinMaxMarginSoftRule,
  positiveNumbersValidation,
} from '@/utils/forms';
import { modifiedNodeParams } from '@/utils/rules';

import { rulesInitialValues } from '@/constants/rules';

import { openDeleteModal, useStyles } from '../FixPriceRule/FixPriceRule';
import PriceRuleBlock from '../PriceRuleBlock/PriceRuleBlock';

import { AppDispatch } from '@/store';
import { selectNodesParams } from '@/store/slices/nodes/nodes';
import {
  fetchDeleteRuleAction,
  fetchRuleEditAction,
  fetchRulesListAction,
  selectRuleBlocks,
  setCurrentRule,
  setFormsCommentModalOpened,
  setMinMaxMarginSoftRule,
  setRuleBlocks,
} from '@/store/slices/rules/rules';

interface MinMaxMarginSoftRuleProps {
  minMaxMarginSoftRule: IMinMaxMarginRule;
  alsoHasInheritedRule?: boolean;
}

const MinMaxMarginSoftRule: FC<MinMaxMarginSoftRuleProps> = ({
  alsoHasInheritedRule,
  minMaxMarginSoftRule,
}) => {
  const { classes } = useStyles();

  const dispatch: AppDispatch = useDispatch();

  const ruleBlocks = useSelector(selectRuleBlocks);
  const nodesParams = useSelector(selectNodesParams);

  const [disabled, setDisabled] = useState<boolean>(minMaxMarginSoftRule?.inherited ?? false);

  const form = useForm({
    initialValues: {
      fields: getMinMaxMarginRuleInitialValues(minMaxMarginSoftRule?.values ?? []),
      aggregationType: minMaxMarginSoftRule?.aggregationType ?? EAggregationType.intersection,
    },
    validate: {
      fields: {
        priceFrom: (value) => positiveNumbersValidation(value, true),
        priceTo: (value) => positiveNumbersValidation(value, true),
      },
      aggregationType: (value) => emptyValuesValidation(value),
    },
    validateInputOnBlur: true,
    validateInputOnChange: true,
  });

  const handleSubmit = (formData: {
    fields: IMinMaxMarginRuleValues[];
    aggregationType: EAggregationType | undefined;
  }) => {
    if (minMaxMarginSoftRule) {
      const newValues = formData.fields.map((item) => ({
        priceFrom: Number(item.priceFrom),
        priceTo: Number(item.priceTo),
        marginFrom: item.marginFrom ? Number(item.marginFrom) : null,
        marginTo: item.marginTo ? Number(item.marginTo) : null,
        marginWithBonusFrom: item.marginWithBonusFrom ? Number(item.marginWithBonusFrom) : null,
        marginWithBonusTo: item.marginWithBonusTo ? Number(item.marginWithBonusTo) : null,
      }));

      const updatedMinMaxMarginSoftRule = {
        ...minMaxMarginSoftRule,
        aggregationType: formData.aggregationType,
        values: newValues,
      };

      dispatch(setMinMaxMarginSoftRule(updatedMinMaxMarginSoftRule));
      dispatch(setCurrentRule(updatedMinMaxMarginSoftRule));
      dispatch(setFormsCommentModalOpened(true));
    }
  };

  const changeActiveStatus = async (status: boolean) => {
    if (minMaxMarginSoftRule) {
      const updatedMinMaxMarginSoftRule = {
        ...minMaxMarginSoftRule,
        active: status,
      };

      dispatch(setCurrentRule(updatedMinMaxMarginSoftRule));
      dispatch(setMinMaxMarginSoftRule(updatedMinMaxMarginSoftRule));

      await dispatch(
        fetchRuleEditAction({
          nodes: modifiedNodeParams(nodesParams),
          rule: modifiedMinMaxMarginSoftRule(status, minMaxMarginSoftRule),
        }),
      );

      dispatch(fetchRulesListAction(modifiedNodeParams(nodesParams)));
    }
  };

  const addValue = () => {
    if (minMaxMarginSoftRule)
      dispatch(
        setMinMaxMarginSoftRule({
          ...minMaxMarginSoftRule,
          values: [...minMaxMarginSoftRule?.values, { ...rulesInitialValues.minMaxMarginRule }],
        }),
      );

    form.insertListItem('fields', { ...rulesInitialValues.minMaxMarginRule });
  };

  const removeValue = (valueIndex: number) => {
    if (minMaxMarginSoftRule) {
      if (minMaxMarginSoftRule?.values.length !== 1) {
        const newValues = minMaxMarginSoftRule?.values.filter(
          (item, index) => index !== valueIndex,
        );

        dispatch(
          setMinMaxMarginSoftRule({
            ...minMaxMarginSoftRule,
            values: newValues,
          }),
        );

        form.removeListItem('fields', valueIndex);
      } else {
        form.setFieldValue('fields.0.priceFrom', 0);
        form.setFieldValue('fields.0.priceTo', 999999);
        form.setFieldValue('fields.0.marginFrom', '');
        form.setFieldValue('fields.0.marginTo', '');
        form.setFieldValue('fields.0.marginWithBonusFrom', '');
        form.setFieldValue('fields.0.marginWithBonusTo', '');
      }
    }
  };

  const deleteRule = async () => {
    if (minMaxMarginSoftRule?.id) {
      await dispatch(fetchDeleteRuleAction([minMaxMarginSoftRule.id]));
      dispatch(fetchRulesListAction(modifiedNodeParams(nodesParams)));
    }

    dispatch(setMinMaxMarginSoftRule(null));
    const updatedRuleBlocks = ruleBlocks.map((item) => ({
      ...item,
      checked: item.type === ERuleType.minMaxMarginSoft ? false : item.checked,
      shown: item.type === ERuleType.minMaxMarginSoft ? false : item.shown,
    }));

    dispatch(setRuleBlocks(updatedRuleBlocks));
  };

  return (
    <PriceRuleBlock>
      <Flex direction="row" justify="space-between">
        {minMaxMarginSoftRule?.inherited || alsoHasInheritedRule ? (
          <Badge color="green" variant="filled">
            Наследуется
          </Badge>
        ) : (
          <Switch
            classNames={{ track: classes.track }}
            checked={minMaxMarginSoftRule?.active ?? false}
            color="dark"
            size="md"
            onChange={(event) => {
              changeActiveStatus(event.currentTarget.checked);
            }}
          />
        )}
        {!(alsoHasInheritedRule || minMaxMarginSoftRule?.inherited) && (
          <ActionIcon
            color="dark"
            size="lg"
            variant="transparent"
            className={classes.actionIcon}
            onClick={() => openDeleteModal(deleteRule)}
          >
            <IconX />
          </ActionIcon>
        )}
      </Flex>
      <Title mt={12} mb={24} order={3}>
        {minMaxMarginSoftRule?.name}
      </Title>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Select
          w={380}
          mb={24}
          label="Контроль наценок"
          rightSection={<IconChevronDown size="1rem" />}
          data={[
            { value: EAggregationType.intersection, label: 'макс/мин' },
            { value: EAggregationType.union, label: 'мин/макс' },
          ]}
          disabled={!minMaxMarginSoftRule?.active || disabled}
          required
          {...form.getInputProps('aggregationType')}
        />
        {minMaxMarginSoftRule?.values.map((item, index) => (
          <Flex direction="row" gap={24} align="flex-end" key={index}>
            <TextInput
              classNames={{ label: classes.label, rightSection: classes.rightSection }}
              label="Диапазон ЗЦ"
              rightSection="₽"
              disabled={!minMaxMarginSoftRule.active || disabled}
              icon={<Text>от</Text>}
              required
              {...form.getInputProps(`fields.${index}.priceFrom`)}
            />
            <TextInput
              classNames={{ label: classes.label, rightSection: classes.rightSection }}
              rightSection="₽"
              disabled={!minMaxMarginSoftRule.active || disabled}
              icon={<Text>до</Text>}
              required
              {...form.getInputProps(`fields.${index}.priceTo`)}
            />
            <TextInput
              classNames={{ label: classes.label, rightSection: classes.rightSection }}
              label="Наценка от ЗЦ"
              rightSection="%"
              disabled={!minMaxMarginSoftRule.active || disabled}
              {...form.getInputProps(`fields.${index}.marginFrom`)}
            />
            <TextInput
              classNames={{ label: classes.label, rightSection: classes.rightSection }}
              rightSection="%"
              disabled={!minMaxMarginSoftRule.active || disabled}
              {...form.getInputProps(`fields.${index}.marginTo`)}
            />
            <TextInput
              classNames={{ label: classes.label, rightSection: classes.rightSection }}
              label="Наценка от ЗЦ с бонусом"
              rightSection="%"
              disabled={!minMaxMarginSoftRule.active || disabled}
              {...form.getInputProps(`fields.${index}.marginWithBonusFrom`)}
            />
            <TextInput
              classNames={{ label: classes.label, rightSection: classes.rightSection }}
              rightSection="%"
              disabled={!minMaxMarginSoftRule.active || disabled}
              {...form.getInputProps(`fields.${index}.marginWithBonusTo`)}
            />
            <ActionIcon
              color="dark"
              size="lg"
              variant="transparent"
              className={classes.actionIcon}
              disabled={!minMaxMarginSoftRule.active || disabled}
              onClick={() => removeValue(index)}
              title="Очистить"
            >
              <IconTrash />
            </ActionIcon>
          </Flex>
        ))}

        <Flex direction="row" justify="space-between" mt={24}>
          <Button
            sx={(theme) => ({
              color: minMaxMarginSoftRule?.active || !disabled ? theme.colors.blue[8] : 'gray',
              paddingLeft: '0px',
              ['&[data-disabled]']: {
                backgroundColor: 'white',
              },
            })}
            variant="subtle"
            disabled={!minMaxMarginSoftRule?.active || disabled}
            leftIcon={<IconPlus size="1.3rem" />}
            onClick={addValue}
          >
            Добавить ещё
          </Button>
          {alsoHasInheritedRule && (
            <Tooltip
              label="Вы не можете редактировать правило, так как уже создано деактивированное правило. Перейдите во вкладку деактивированных правил."
              withArrow
              multiline
              w={350}
              openDelay={500}
              position="left"
            >
              <Flex>
                <Button color="dark.9" disabled={alsoHasInheritedRule}>
                  Редактировать
                </Button>
              </Flex>
            </Tooltip>
          )}
          {minMaxMarginSoftRule?.active && disabled && !alsoHasInheritedRule && (
            <Button color="dark.9" ml={40} onClick={() => setDisabled(false)}>
              Редактировать
            </Button>
          )}
          {minMaxMarginSoftRule?.active && !disabled && !alsoHasInheritedRule && (
            <Button color="dark.9" ml={40} type="submit" disabled={!form.isValid()}>
              Сохранить
            </Button>
          )}
        </Flex>
      </form>
    </PriceRuleBlock>
  );
};

export default MinMaxMarginSoftRule;
