export const plansSelectData = [
  {
    value: 'old_price',
    label: 'Минимизировать изменение текущей цены',
  },
  {
    value: 'min',
    label: 'Минимальное значение доступного диапазона цены',
  },
  {
    value: 'max',
    label: 'Максимальное значение доступного диапазона цены',
  },
  {
    value: 'next_range',
    label: 'Ближайшая цена к следующему диапазону цен после доступного',
  },
];

export const chartsEmptyDataTitles = {
  BARS: 'Сводка по правилу ещё не готова',
  PRICES: 'Динамика цен ещё не готова',
};
