import { EFilterKey } from '@/types/filters/filters';

import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Flex, Group, Skeleton } from '@mantine/core';
import { IconTrash } from '@tabler/icons-react';

import Filters from '@/ui/organisms/Filters/Filters';
import FilterTreeMultiSelect from '@/ui/organisms/FilterTreeMultiSelect/FilterTreeMultiSelect';

import { AppDispatch } from '@/store';
import {
  fetchPlanDetailFiltersAction,
  resetFilters,
  selectFetchingFilters,
} from '@/store/slices/filters/filters';
import {
  fetchFiltersTreeAction,
  fetchFiltersTreeFilteredAction,
  selectFiltersTree,
  selectFiltersTreeFiltered,
  selectTreeFilter,
  setActiveRulesFilters,
  setCheckedTreeFilters,
  setTreeFilter,
} from '@/store/slices/rules/rules';

const EditPlanPageFilters: FC = () => {
  const dispatch: AppDispatch = useDispatch();

  const filtersTree = useSelector(selectFiltersTree);
  const filtersTreeFiltered = useSelector(selectFiltersTreeFiltered);
  const treeFilter = useSelector(selectTreeFilter);
  const fetchingFilters = useSelector(selectFetchingFilters);

  const handleResetFilters = () => {
    dispatch(resetFilters(EFilterKey.planDetail));
    dispatch(setTreeFilter(''));
    dispatch(setCheckedTreeFilters([]));
    dispatch(setActiveRulesFilters([]));
  };

  useEffect(() => {
    if (treeFilter.length > 0) {
      dispatch(fetchFiltersTreeFilteredAction(treeFilter));
    } else {
      dispatch(fetchFiltersTreeAction());
    }
  }, [treeFilter]);

  useEffect(() => {
    dispatch(fetchPlanDetailFiltersAction());
  }, []);

  return (
    <Flex justify="space-between" mb={24}>
      {fetchingFilters.planDetail ? (
        <Skeleton height={36} radius="8px" width="50%" />
      ) : (
        <Group spacing="xs">
          {filtersTree && (
            <FilterTreeMultiSelect
              title={filtersTree.name}
              placeholder="Поиск по названию или ID"
              data={filtersTree.items.slice(1)}
              dataFiltered={filtersTreeFiltered?.items.slice(1)}
            />
          )}
          <Filters filterKey={EFilterKey.planDetail} />
          <Button
            variant="subtle"
            sx={{ color: 'black' }}
            leftIcon={<IconTrash size="1.3rem" />}
            onClick={handleResetFilters}
          >
            Сбросить все
          </Button>
        </Group>
      )}
    </Flex>
  );
};

export default EditPlanPageFilters;
