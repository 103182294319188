import { IFilterTreeValue } from '@/types/filters/filters';

import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox, createStyles, Flex, Text, Tooltip } from '@mantine/core';

import { AppDispatch } from '@/store';
import { selectCheckedTreeFilter, setCheckedTreeFilters } from '@/store/slices/rules/rules';

interface FilterTreeMultiSelectTitleProps {
  item: IFilterTreeValue;
  isProductsFilter?: boolean;
}

const useStyles = createStyles(() => ({
  title: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}));

const FilterTreeMultiSelectTitle: FC<FilterTreeMultiSelectTitleProps> = ({
  item,
  isProductsFilter,
}) => {
  const { classes } = useStyles();

  const dispatch: AppDispatch = useDispatch();

  const checkedTreeFilter = useSelector(selectCheckedTreeFilter);

  return (
    <Flex direction="row" justify="space-between" align="center" w="100%">
      {isProductsFilter ? (
        item.name.length > 60 ? (
          <Tooltip.Floating multiline w={620} position="bottom" label={item.name}>
            <Text ml={8} w={620} className={classes.title}>
              {item.name}
            </Text>
          </Tooltip.Floating>
        ) : (
          <Text ml={8} w={620} className={classes.title}>
            {item.name}
          </Text>
        )
      ) : (
        <Checkbox
          m={8}
          color="dark"
          radius="sm"
          size="sm"
          label={
            item.name.length > 30 ? (
              <Tooltip.Floating multiline w={290} position="bottom" label={item.name}>
                <Flex>
                  <Text w={290} className={classes.title}>
                    {item.name}
                  </Text>
                </Flex>
              </Tooltip.Floating>
            ) : (
              <Text w={290} className={classes.title}>
                {item.name}
              </Text>
            )
          }
          checked={
            !!checkedTreeFilter.filter((data) => data.id === item.id && data.level === item.level)
              .length
          }
          onChange={(event) => {
            if (event.target.checked) {
              dispatch(setCheckedTreeFilters([...checkedTreeFilter, { ...item }]));
            } else {
              dispatch(
                setCheckedTreeFilters(
                  checkedTreeFilter.filter((treeItem) => treeItem.id !== item.id),
                ),
              );
            }
          }}
        ></Checkbox>
      )}
    </Flex>
  );
};

export default FilterTreeMultiSelectTitle;
