import { IAdditionalNodeModified } from '@/types/nodes/nodes';

import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Divider, Flex } from '@mantine/core';
import {
  IconBrandDrops,
  IconCake,
  IconChartBar,
  IconLayoutGrid,
  IconPlus,
  IconSquareRoundedLetterB,
  IconTag,
} from '@tabler/icons-react';

import {
  getCheckedAdditionalNodes,
  getShownAdditionalNodes,
  resetCheckedAdditionalNodes,
} from '@/utils/nodes';

import PageDrawer from '@/ui/organisms/DrawerExt/DrawerExt';
import PageDrawerButton from '@/ui/organisms/DrawerExt/organisms/DrawerExtButton/DrawerExtButton';

import { useStyles } from '@/assets/styles/priceRulesSteppers';
import { AppDispatch } from '@/store';
import {
  selectAdditionalNodeOpened,
  selectAdditionalNodes,
  setAdditionalNodeOpened,
  setAdditionalNodes,
} from '@/store/slices/nodes/nodes';

const PriceRulesSteppersAdditionalNodesPageDrawer: FC = () => {
  const { classes } = useStyles();

  const dispatch: AppDispatch = useDispatch();

  const additionalNodes = useSelector(selectAdditionalNodes);
  const additionalNodeOpened = useSelector(selectAdditionalNodeOpened);

  const getPageDrawerButtonIcon = (node: IAdditionalNodeModified) => {
    switch (node.code) {
      case 'Torgovaia_marka':
        return <IconTag size={40} />;
      case 'Klass':
        return <IconChartBar size={40} />;
      case 'Brendoobladatel':
        return <IconSquareRoundedLetterB size={40} />;
      case 'Gruppa_tovarov':
        return <IconLayoutGrid size={40} />;
      case 'Vkus':
        return <IconCake size={40} />;
      default:
        return <IconBrandDrops size={40} />;
    }
  };

  return (
    <PageDrawer
      opened={additionalNodeOpened}
      onClose={() => dispatch(setAdditionalNodeOpened(false))}
      title="Добавить узел"
      description="Выберите узлы для добавления в правило"
      size={560}
      classNames={{ body: classes.drawerBody }}
      trigger={
        <Button
          color="gray.2"
          variant="filled"
          leftIcon={<IconPlus size="1.1rem" />}
          mt={24}
          onClick={() => dispatch(setAdditionalNodeOpened(true))}
        >
          Добавить узел
        </Button>
      }
    >
      <Flex direction="column" gap={16} sx={{ flex: 1 }}>
        {additionalNodes &&
          additionalNodes.map((node) => (
            <PageDrawerButton
              isRule={false}
              key={node.code}
              checked={node.checked}
              onClick={() => {
                dispatch(setAdditionalNodes(getCheckedAdditionalNodes(node, additionalNodes)));
              }}
              title={node.name}
              icon={getPageDrawerButtonIcon(node)}
            />
          ))}
      </Flex>

      <Box
        sx={{
          position: 'sticky',
          bottom: '0px',
          paddingBottom: '16px',
          backgroundColor: 'white',
          zIndex: 100,
        }}
      >
        <Divider
          sx={(theme) => ({
            margin: '16px -16px',
            borderTopColor: theme.colors.gray[2],
          })}
        />
        <Flex direction="row" gap="8px">
          <Button
            fullWidth
            color="gray.2"
            onClick={() => {
              dispatch(setAdditionalNodeOpened(false));
              dispatch(setAdditionalNodes(resetCheckedAdditionalNodes(additionalNodes)));
            }}
          >
            Отменить
          </Button>
          <Button
            fullWidth
            color="dark.9"
            onClick={() => {
              dispatch(setAdditionalNodes(getShownAdditionalNodes(additionalNodes)));
              dispatch(setAdditionalNodeOpened(false));
            }}
          >
            Добавить
          </Button>
        </Flex>
      </Box>
    </PageDrawer>
  );
};

export default PriceRulesSteppersAdditionalNodesPageDrawer;
