import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Menu } from '@mantine/core';
import { IconLogout } from '@tabler/icons-react';

import { getCookie } from '@/utils/cookie';

import LayoutNavbarUserMenuButton from '../LayoutNavbarUserMenuButton/LayoutNavbarUser';

import { AppDispatch } from '@/store';
import { logoutAction } from '@/store/slices/auth/auth';
import { fetchUserInfoAction, selectUserInfo } from '@/store/slices/user/user';

const LayoutNavbarUserMenu: FC = () => {
  const dispatch: AppDispatch = useDispatch();

  const userInfo = useSelector(selectUserInfo);

  const token = getCookie('token');

  const handleLogOut = () => {
    dispatch(logoutAction(null));
  };

  useEffect(() => {
    if (token) dispatch(fetchUserInfoAction());
  }, []);

  return (
    <Menu width={250} trigger="hover" openDelay={100} closeDelay={400}>
      {userInfo && (
        <Menu.Target>
          <LayoutNavbarUserMenuButton name={userInfo.name} email={userInfo.email} />
        </Menu.Target>
      )}

      <Menu.Dropdown>
        <Menu.Item color="burnt-sienna" icon={<IconLogout size={24} />} onClick={handleLogOut}>
          Выход
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};

export default LayoutNavbarUserMenu;
