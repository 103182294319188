import {
  IFilterValue,
  IModifiedFilterItem,
  IModifiedFilterTreeItem,
} from '@/types/filters/filters';
import { IGetFilteredRulesParams } from '@/types/rules/rules';

import { EventDataNode } from 'rc-tree/lib/interface';

export const modifiedData = (data: IFilterValue[] | null): IModifiedFilterItem[] => {
  const result = data?.map((item: IFilterValue, index: number) => ({
    label: item.name,
    key: index,
    id: item.id,
    checked: false,
  }));
  if (result) {
    return result;
  } else return [];
};

export const modifiedTreeFilter = (filters: IGetFilteredRulesParams[]) => {
  return filters.map((treeItem) => {
    return {
      type: 'checkbox',
      code: treeItem.paramName,
      value: {
        values: treeItem.paramValues,
      },
    };
  });
};

export const generateTreeNodes = (
  treeNode: {
    node: EventDataNode<IModifiedFilterTreeItem>;
    expanded: boolean;
    nativeEvent?: MouseEvent;
  },
  newData: IModifiedFilterTreeItem[],
) => {
  const key = treeNode.node.key;

  return newData.map((item: IModifiedFilterTreeItem, index: number) => ({
    title: item.title,
    key: `${key}-${index}`,
    id: item.id,
    isLeaf: true,
  }));
};

export const getNewTreeData = (
  newTreeData: IModifiedFilterTreeItem[],
  curKey: string,
  child: any,
) => {
  const loop = (newData: IModifiedFilterTreeItem[]) => {
    newData.forEach((item: IModifiedFilterTreeItem) => {
      if (curKey.indexOf(item.key) === 0) {
        if (item.children) {
          loop(item.children as any);
        } else {
          item.children = child;
        }
      }
    });
  };
  loop(newTreeData);
};

export const motion = {
  motionName: 'node-motion',
  motionAppear: false,
  onAppearStart: () => ({ height: 0 }),
  onAppearActive: (node: any) => ({ height: node.scrollHeight }),
  onLeaveStart: (node: any) => ({ height: node.offsetHeight }),
  onLeaveActive: () => ({ height: 0 }),
};
