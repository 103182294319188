import {
  ERuleType,
  ICompetitorRule,
  IFixMarginRule,
  IFixPriceRule,
  IMinMaxMarginRule,
  IMinMaxPriceRule,
  IMinPriceChangeRule,
  ISamePriceRule,
} from '@/types/rules/rules';

import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Flex, Modal, Text, Textarea } from '@mantine/core';
import { useForm } from '@mantine/form';

import {
  modifiedCompetitorRule,
  modifiedFixMarginRule,
  modifiedFixPriceRule,
  modifiedMaxPriceChangeRule,
  modifiedMinMaxMarginHardRule,
  modifiedMinMaxMarginSoftRule,
  modifiedMinMaxPriceRule,
  modifiedMinPriceChangeRule,
  modifiedSamePriceRule,
} from '@/utils/forms';
import { modifiedNodeParams } from '@/utils/rules';

import { AppDispatch } from '@/store';
import { selectNodesParams } from '@/store/slices/nodes/nodes';
import {
  fetchRuleEditAction,
  selectCurrentRule,
  selectFormsCommentModalOpened,
  setCompetitorRule,
  setFixMarginRule,
  setFixPriceRule,
  setFormsCommentModalOpened,
  setMaxPriceChangeRule,
  setMinMaxMarginHardRule,
  setMinMaxMarginSoftRule,
  setMinMaxPriceRule,
  setMinPriceChangeRule,
  setSamePriceRule,
} from '@/store/slices/rules/rules';

const PriceRulesSteppersCommentModal: FC = () => {
  const dispatch: AppDispatch = useDispatch();

  const formsCommentModalOpened = useSelector(selectFormsCommentModalOpened);
  const nodesParams = useSelector(selectNodesParams);
  const currentRule = useSelector(selectCurrentRule);

  const fetchNodesParams = modifiedNodeParams(nodesParams);

  const form = useForm({
    initialValues: {
      comment: currentRule?.comment || '',
    },
  });

  const closeModal = () => {
    dispatch(setFormsCommentModalOpened(false));
    form.setFieldValue('comment', '');
  };

  const handleSubmit = async (formData: { comment: string }) => {
    switch (currentRule?.type) {
      case ERuleType.fixPrice:
        const fixPriceId = await dispatch(
          fetchRuleEditAction({
            nodes: fetchNodesParams,
            rule: modifiedFixPriceRule(
              currentRule?.active,
              currentRule as IFixPriceRule,
              formData.comment,
            ),
          }),
        );

        dispatch(
          setFixPriceRule({
            ...(currentRule as IFixPriceRule),
            id: fixPriceId.payload,
            inherited: currentRule.inherited ? false : currentRule.inherited,
            comment: formData.comment,
          }),
        );
        break;

      case ERuleType.fixMargin:
        const fixMarginId = await dispatch(
          fetchRuleEditAction({
            nodes: fetchNodesParams,
            rule: modifiedFixMarginRule(
              currentRule?.active,
              currentRule as IFixMarginRule,
              formData.comment,
            ),
          }),
        );

        dispatch(
          setFixMarginRule({
            ...(currentRule as IFixMarginRule),
            id: fixMarginId.payload,
            inherited: currentRule.inherited ? false : currentRule.inherited,
            comment: formData.comment,
          }),
        );
        break;

      case ERuleType.minPriceChange:
        const minPriceChangeId = await dispatch(
          fetchRuleEditAction({
            nodes: fetchNodesParams,
            rule: modifiedMinPriceChangeRule(
              currentRule?.active,
              currentRule as IMinPriceChangeRule,
              formData.comment,
            ),
          }),
        );

        dispatch(
          setMinPriceChangeRule({
            ...(currentRule as IMinPriceChangeRule),
            id: minPriceChangeId.payload,
            inherited: currentRule.inherited ? false : currentRule.inherited,
            comment: formData.comment,
          }),
        );
        break;

      case ERuleType.maxPriceChange:
        const maxPriceChangeId = await dispatch(
          fetchRuleEditAction({
            nodes: fetchNodesParams,
            rule: modifiedMaxPriceChangeRule(
              currentRule?.active,
              currentRule as IMinPriceChangeRule,
              formData.comment,
            ),
          }),
        );

        dispatch(
          setMaxPriceChangeRule({
            ...(currentRule as IMinPriceChangeRule),
            id: maxPriceChangeId.payload,
            inherited: currentRule.inherited ? false : currentRule.inherited,
            comment: formData.comment,
          }),
        );
        break;

      case ERuleType.minMaxPrice:
        const minMaxPriceId = await dispatch(
          fetchRuleEditAction({
            nodes: fetchNodesParams,
            rule: modifiedMinMaxPriceRule(
              currentRule?.active,
              currentRule as IMinMaxPriceRule,
              formData.comment,
            ),
          }),
        );

        dispatch(
          setMinMaxPriceRule({
            ...(currentRule as IMinMaxPriceRule),
            id: minMaxPriceId.payload,
            inherited: currentRule.inherited ? false : currentRule.inherited,
            comment: formData.comment,
          }),
        );
        break;

      case ERuleType.minMaxMarginSoft:
        const minMaxMarginSoftId = await dispatch(
          fetchRuleEditAction({
            nodes: fetchNodesParams,
            rule: modifiedMinMaxMarginSoftRule(
              currentRule?.active,
              currentRule as IMinMaxMarginRule,
              formData.comment,
            ),
          }),
        );

        dispatch(
          setMinMaxMarginSoftRule({
            ...(currentRule as IMinMaxMarginRule),
            id: minMaxMarginSoftId.payload,
            inherited: currentRule.inherited ? false : currentRule.inherited,
            comment: formData.comment,
          }),
        );
        break;

      case ERuleType.minMaxMarginHard:
        const minMaxMarginHardId = await dispatch(
          fetchRuleEditAction({
            nodes: fetchNodesParams,
            rule: modifiedMinMaxMarginHardRule(
              currentRule?.active,
              currentRule as IMinMaxMarginRule,
              formData.comment,
            ),
          }),
        );

        dispatch(
          setMinMaxMarginHardRule({
            ...(currentRule as IMinMaxMarginRule),
            id: minMaxMarginHardId.payload,
            inherited: currentRule.inherited ? false : currentRule.inherited,
            comment: formData.comment,
          }),
        );
        break;

      case ERuleType.samePrice:
        const samePriceId = await dispatch(
          fetchRuleEditAction({
            nodes: fetchNodesParams,
            rule: modifiedSamePriceRule(
              currentRule?.active,
              currentRule as ISamePriceRule,
              formData.comment,
            ),
          }),
        );

        dispatch(
          setSamePriceRule({
            ...(currentRule as ISamePriceRule),
            id: samePriceId.payload,
            inherited: currentRule.inherited ? false : currentRule.inherited,
            comment: formData.comment,
          }),
        );
        break;

      case ERuleType.competitor:
        const competitorId = await dispatch(
          fetchRuleEditAction({
            nodes: fetchNodesParams,
            rule: modifiedCompetitorRule(
              currentRule?.active,
              currentRule as ICompetitorRule,
              formData.comment,
            ),
          }),
        );

        dispatch(
          setCompetitorRule({
            ...(currentRule as ICompetitorRule),
            id: competitorId.payload,
            inherited: currentRule.inherited ? false : currentRule.inherited,
            comment: formData.comment,
          }),
        );
        break;
    }

    closeModal();
  };

  useEffect(() => {
    if (currentRule) {
      form.setFieldValue('comment', currentRule.comment as string);
    }
  }, [currentRule]);

  return (
    <Modal
      size={'md'}
      opened={formsCommentModalOpened}
      onClose={closeModal}
      title={
        <Text fz={22} weight="bold">
          Комментировать
        </Text>
      }
    >
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Textarea
          size="sm"
          color="dark.2"
          placeholder="Введите комментарий для правила"
          minRows={4}
          {...form.getInputProps('comment')}
        />
        <Flex direction="row" justify="space-between" gap={16} mt={24}>
          <Button fullWidth onClick={closeModal} variant="filled" color="gray.2">
            Отменить
          </Button>
          <Button fullWidth type="submit" color="dark.9" disabled={!form.values.comment}>
            Комментировать
          </Button>
        </Flex>
      </form>
    </Modal>
  );
};

export default PriceRulesSteppersCommentModal;
