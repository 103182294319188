import { EFilterKey, IFilterValue } from '@/types/filters/filters';
import { IMonitoringFilterValue } from '@/types/products/products';

import { FC, SyntheticEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Button,
  Checkbox,
  createStyles,
  Divider,
  Flex,
  Input,
  Popover,
  ScrollArea,
  Text,
  Tooltip,
} from '@mantine/core';
import { IconChevronDown, IconChevronUp, IconSearch, IconX } from '@tabler/icons-react';

import { useDebounce } from '@/hooks/useDebounce';

import { AppDispatch } from '@/store';
import {
  clearFilter,
  fetchMonitoringFiltersValuesAction,
  fetchPlanDetailFilerValuesAction,
  fetchPricesFiltersValuesAction,
  selectActiveFilters,
  setActiveFilters,
} from '@/store/slices/filters/filters';

interface IProps {
  filterKey: EFilterKey;
  name: string;
  code: string;
  type: string;
  values?: IFilterValue[];
}

const useStyles = createStyles((theme) => ({
  select: {
    flexDirection: 'row',
    alignItems: 'center',
    height: '40px',
    borderRadius: '4px',

    '&:hover': {
      backgroundColor: theme.colors.gray[1],
    },
  },
  checkboxBody: {
    display: 'flex',
    alignItems: 'center',
  },
  text: {
    color: theme.colors.gray[5],
  },
  title: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}));

export const getIcon = (opened: boolean) => {
  if (opened) {
    return <IconChevronUp size="1.1rem" />;
  } else return <IconChevronDown size="1.1rem" />;
};

const FilterCheckbox: FC<IProps> = ({ name, code, type, values, filterKey }) => {
  const dispatch: AppDispatch = useDispatch();

  const { classes } = useStyles();

  const activeFilters = useSelector(selectActiveFilters)[filterKey];

  const currentActiveFilter = activeFilters.find((item) => item.code === code);
  const currentCheckedItems = currentActiveFilter?.value?.values;

  const [items, setItems] = useState<IFilterValue[]>(values ?? []);
  const [checkedItems, setCheckedItems] = useState<string[]>(currentCheckedItems ?? []);
  const [opened, setOpened] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');

  const debouncedSearchValue = useDebounce<string>(searchValue);

  const allChecked = items.length === checkedItems.length;
  const indeterminate = !!checkedItems.length && !allChecked;

  const activeFiltersCount = currentActiveFilter?.value.values?.length;

  const isActiveFilter = !!currentActiveFilter && !!activeFilters.length;

  const resetCheckedItems = () => {
    if (activeFiltersCount) return;
    if (allChecked) {
      setCheckedItems([]);
    } else {
      setCheckedItems(currentActiveFilter?.value.values ?? []);
    }
  };

  const handleClose = () => {
    resetCheckedItems();
    setSearchValue('');
    setItems(values ?? []);
  };

  const resetFilters = (event: SyntheticEvent) => {
    if (!opened) event.stopPropagation();

    handleClose();

    const filterItem = {
      filterKey: filterKey,
      item: {
        code: code,
        type: type,
        value: {},
      },
    };
    setCheckedItems([]);
    dispatch(clearFilter(filterItem));
  };

  const setFilters = () => {
    setOpened(false);

    const filterValues = {
      values: checkedItems,
    };

    const filterItem = {
      filterKey: filterKey,
      item: {
        code: code,
        type: type,
        value: filterValues,
      },
    };
    dispatch(setActiveFilters(filterItem));
  };

  const getFilterItems = async () => {
    if (!values) {
      if (debouncedSearchValue.length > 2) {
        if (filterKey === EFilterKey.monitorings) {
          const monitoringsFilterData = await dispatch(
            fetchMonitoringFiltersValuesAction({
              query: debouncedSearchValue,
              field: code,
            }),
          );
          if (monitoringsFilterData.meta.requestStatus === 'fulfilled') {
            const monitoringsFilterValues = monitoringsFilterData.payload as IMonitoringFilterValue;
            setItems(monitoringsFilterValues.values ?? []);
          }
        }
        if (filterKey === EFilterKey.prices) {
          const pricesFilterData = await dispatch(
            fetchPricesFiltersValuesAction({
              query: debouncedSearchValue,
              field: code,
            }),
          );
          if (pricesFilterData.meta.requestStatus === 'fulfilled') {
            const pricesFilterValues = pricesFilterData.payload as IMonitoringFilterValue;
            setItems(pricesFilterValues.values ?? []);
          }
        }
        if (filterKey === EFilterKey.planDetail) {
          const planDetailFilterValues = await dispatch(
            fetchPlanDetailFilerValuesAction({
              query: debouncedSearchValue,
              field: code,
            }),
          );
          if (planDetailFilterValues.meta.requestStatus === 'fulfilled') {
            setItems((planDetailFilterValues.payload as IFilterValue[]) ?? []);
          }
        }
      }
      if (!debouncedSearchValue.length) {
        setItems([]);
      }
    }
  };

  useEffect(() => {
    getFilterItems();
  }, [debouncedSearchValue]);

  useEffect(() => {
    if (currentCheckedItems?.length !== checkedItems.length) {
      setCheckedItems(currentCheckedItems ?? []);
    }
  }, [currentCheckedItems]);

  return (
    <Popover
      opened={opened}
      onChange={setOpened}
      onClose={handleClose}
      width={380}
      trapFocus
      position="bottom"
      shadow="md"
    >
      <Popover.Target>
        <Button
          variant={isActiveFilter ? 'filled' : 'default'}
          sx={{ color: isActiveFilter ? 'white' : 'black' }}
          color="dark"
          onClick={() => setOpened((state) => !state)}
        >
          <Flex gap={8} align="center">
            <Text>{name}</Text>

            <Flex w={14} className={classes.text}>
              {isActiveFilter && activeFiltersCount}
            </Flex>
            {isActiveFilter ? (
              <Flex align="center" onClick={resetFilters}>
                <IconX size="1.1rem" />
              </Flex>
            ) : (
              getIcon(opened)
            )}
          </Flex>
        </Button>
      </Popover.Target>

      <Popover.Dropdown>
        <Flex direction="column">
          <Input
            mb={8}
            radius="sm"
            icon={<IconSearch />}
            placeholder={name}
            value={searchValue}
            onChange={({ target: { value } }) => {
              setSearchValue(value);
            }}
          />

          <ScrollArea h={216}>
            {items.length > 0 && (
              <Flex className={classes.select}>
                <Checkbox
                  classNames={{ body: classes.checkboxBody }}
                  m={8}
                  color="dark"
                  radius="sm"
                  size="sm"
                  label="Выбрать все"
                  checked={allChecked}
                  indeterminate={indeterminate}
                  onChange={() =>
                    allChecked ? setCheckedItems([]) : setCheckedItems(items.map((item) => item.id))
                  }
                />
              </Flex>
            )}
            {items
              .filter((item) => item?.name?.toLowerCase().includes(searchValue?.toLowerCase()))
              .sort((a, b) => {
                if (a.name === 'Все регионы') {
                  return -1;
                } else if (b.name === 'Все регионы') {
                  return 1;
                } else {
                  return a.name.localeCompare(b.name);
                }
              })
              .map((item) => (
                <Flex className={classes.select} key={item.id}>
                  <Checkbox
                    classNames={{ body: classes.checkboxBody }}
                    m={8}
                    color="dark"
                    radius="sm"
                    size="sm"
                    label={
                      item.name.length > 40 ? (
                        <Tooltip.Floating multiline w={290} position="bottom" label={item.name}>
                          <Flex>
                            <Text w={290} className={classes.title}>
                              {item.name}
                            </Text>
                          </Flex>
                        </Tooltip.Floating>
                      ) : (
                        <Text w={290} className={classes.title}>
                          {item.name}
                        </Text>
                      )
                    }
                    key={item.id}
                    checked={checkedItems.includes(item.id)}
                    onChange={(event) => {
                      return event.currentTarget.checked
                        ? setCheckedItems((prevState) => [...prevState, item.id])
                        : setCheckedItems((prevState) =>
                            prevState.filter((value) => value !== item.id),
                          );
                    }}
                    aria-label={item.id}
                  />
                </Flex>
              ))}
          </ScrollArea>
        </Flex>

        <Box>
          <Divider
            sx={(theme) => ({ margin: '16px -16px', borderTopColor: theme.colors.gray[2] })}
          />
          <Flex direction="row" gap="8px">
            <Button fullWidth color="gray.2" onClick={resetFilters}>
              Очистить
            </Button>
            <Button fullWidth color="dark.9" onClick={setFilters} disabled={!checkedItems.length}>
              Применить
            </Button>
          </Flex>
        </Box>
      </Popover.Dropdown>
    </Popover>
  );
};

export default FilterCheckbox;
