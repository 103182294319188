import { IAdditionalNodeModified, IAdditionalNodeValue, INodeParams } from '@/types/nodes/nodes';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { apiGetAdditionalNodes, apiGetAdditionalNodeValues } from '@/api/nodes/nodes';

import { createAxiosThunk } from '@/utils/asyncRequest';
import { modifiedNodesData } from '@/utils/nodes';

interface INodesState {
  additionalNodes: IAdditionalNodeModified[] | null;
  additionalNodeOpened: boolean;
  additionalNodeFilter: string;
  additionalNodeCode: string | null;
  additionalNodeCount: number | null;
  additionalNodeValues: IAdditionalNodeValue[] | null;
  nodesParams: INodeParams[];
}

const initialState: INodesState = {
  additionalNodes: null,
  additionalNodeOpened: false,
  additionalNodeFilter: '',
  additionalNodeCode: null,
  additionalNodeCount: null,
  additionalNodeValues: null,
  nodesParams: [],
};

export const fetchAdditionalNodesAction = createAxiosThunk(
  '/getAdditionalNodes',
  apiGetAdditionalNodes,
);
export const fetchAdditionalNodeValuesAction = createAxiosThunk(
  '/getAdditionalNodeValues',
  apiGetAdditionalNodeValues,
);

export const nodesSlice = createSlice({
  name: 'nodes',
  initialState,
  reducers: {
    setAdditionalNodes: (state, action: PayloadAction<IAdditionalNodeModified[] | null>) => {
      state.additionalNodes = action.payload;
    },

    setAdditionalNodeOpened: (state, action: PayloadAction<boolean>) => {
      state.additionalNodeOpened = action.payload;
    },

    setAdditionalNodeValues: (state, action: PayloadAction<IAdditionalNodeValue[] | null>) => {
      state.additionalNodeValues = action.payload;
    },

    setAdditionalNodeFilter: (state, action: PayloadAction<string>) => {
      state.additionalNodeFilter = action.payload;
    },

    setAdditionalNodeCode: (state, action: PayloadAction<string | null>) => {
      state.additionalNodeCode = action.payload;
    },

    setAdditionalNodeCount: (state, action: PayloadAction<number | null>) => {
      state.additionalNodeCount = action.payload;
    },

    setNodesParams: (state, action: PayloadAction<INodeParams[]>) => {
      state.nodesParams = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAdditionalNodesAction.fulfilled, (state, action) => {
      state.additionalNodes = modifiedNodesData(action.payload, state.nodesParams);
    });

    builder.addCase(fetchAdditionalNodeValuesAction.fulfilled, (state, action) => {
      state.additionalNodeValues = action.payload;
    });
  },
});

type TSelectorState = { nodes: INodesState };

export const selectAdditionalNodes = (state: TSelectorState) => state.nodes.additionalNodes;
export const selectAdditionalNodeOpened = (state: TSelectorState) =>
  state.nodes.additionalNodeOpened;
export const selectAdditionalNodeFilter = (state: TSelectorState) =>
  state.nodes.additionalNodeFilter;
export const selectAdditionalNodeCode = (state: TSelectorState) => state.nodes.additionalNodeCode;
export const selectAdditionalNodeCount = (state: TSelectorState) => state.nodes.additionalNodeCount;
export const selectAdditionalNodeValues = (state: TSelectorState) =>
  state.nodes.additionalNodeValues;
export const selectNodesParams = (state: TSelectorState) => state.nodes.nodesParams;

export const {
  setAdditionalNodes,
  setAdditionalNodeOpened,
  setAdditionalNodeFilter,
  setAdditionalNodeCode,
  setAdditionalNodeValues,
  setAdditionalNodeCount,
  setNodesParams,
} = nodesSlice.actions;

export default nodesSlice.reducer;
