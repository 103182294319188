import { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { Box, createStyles, Group, Text } from '@mantine/core';

interface LayoutNavbarItemProps {
  icon: React.ReactNode;
  label: string;
  path: string;
}

const useStyles = createStyles((theme) => ({
  link: {
    display: 'block',
    width: '100%',
    maxWidth: '100%',
    height: 40,
    padding: 8,
    marginBottom: 1,
    borderRadius: 8,
    color: theme.black,
    backgroundColor: theme.white,
    overflow: 'hidden',
    textDecoration: 'none',

    '&:hover': {
      color: theme.colors.gray[0],
      backgroundColor: theme.colors.dark[9],
    },
  },
  active: {
    color: theme.colors.gray[0],
    backgroundColor: theme.colors.dark[9],
  },
}));

const LayoutNavbarItem: FC<LayoutNavbarItemProps> = ({ icon, label, path }) => {
  const { classes } = useStyles();

  return (
    <NavLink
      to={path}
      className={({ isActive }) =>
        isActive ? `${classes.link} ${classes.active}` : `${classes.link}`
      }
    >
      <Group spacing={12} noWrap sx={{ height: 24, alignItems: 'center' }}>
        <Box title={label} sx={{ display: 'flex', alignItems: 'center' }}>
          {icon}
        </Box>
        <Text size="sm" truncate>
          {label}
        </Text>
      </Group>
    </NavLink>
  );
};

export default LayoutNavbarItem;
