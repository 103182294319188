import { forwardRef } from 'react';
import { Box, Group, Text, UnstyledButton } from '@mantine/core';
import { IconUserCircle } from '@tabler/icons-react';

interface LayoutNavbarUserMenuButtonProps extends React.ComponentPropsWithoutRef<'button'> {
  name: string;
  email: string;
  icon?: React.ReactNode;
}

const LayoutNavbarUserMenuButton = forwardRef<HTMLButtonElement, LayoutNavbarUserMenuButtonProps>(
  ({ name, email, ...others }: LayoutNavbarUserMenuButtonProps, ref) => {
    return (
      <UnstyledButton
        ref={ref}
        sx={{
          display: 'flex',
          flexWrap: 'nowrap',
          width: '100%',
          maxWidth: '100%',
          padding: 8,
          justifyContent: 'space-between',
          overflow: 'hidden',
        }}
        {...others}
      >
        <Group spacing={12} noWrap align="flex-start">
          <IconUserCircle />

          <Box sx={{ flex: 1 }}>
            <Text truncate size="sm">
              {name}
            </Text>
            <Text truncate color="dimmed" size="xs">
              {email}
            </Text>
          </Box>
        </Group>
      </UnstyledButton>
    );
  },
);

export default LayoutNavbarUserMenuButton;
