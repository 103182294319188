import React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import { Flex, Title } from '@mantine/core';

import { RequireAuth } from '@/ui/organisms/RequireAuth/RequireAuth';
import CreatePricePlansPage from '@/ui/pages/CreatePricePlansPage/CreatePricePlansPage';
import { EditPlanPage } from '@/ui/pages/EditPlanPage/EditPlanPage';
import { MonitoringsPage } from '@/ui/pages/MonitoringPage/MonitoringsPage';
import PricePlansPage from '@/ui/pages/PricePlansPage/PricePlansPage';
import { PricesPage } from '@/ui/pages/PricesPage/PricesPage';

import { paths } from './constants/paths';
import AuthPage from './ui/pages/AuthPage/AuthPage';
import CreatePriceRulesPage from './ui/pages/CreatePriceRulesPage/CreatePriceRulesPage';
import MainPage from './ui/pages/MainPage/MainPage';
import NotificationsPage from './ui/pages/NotificationsPage/NotificationsPage';
import PriceRulesPage from './ui/pages/PriceRulesPage/PriceRulesPage';
import App from './App';

class ErrorBoundary extends React.Component<any, { error?: boolean }> {
  constructor(props: any) {
    super(props);
    this.state = {
      error: false,
    };
  }

  componentDidCatch() {
    this.setState({ error: true });
  }

  render() {
    return this.state.error ? (
      <Flex mt={64} justify="center">
        <Title order={2}>Что-то пошло не так, но мы уже в курсе и занимаемся проблемой.</Title>
      </Flex>
    ) : (
      this.props.children
    );
  }
}

export const router = createBrowserRouter([
  {
    path: paths.PATH_MAIN,
    element: (
      <ErrorBoundary>
        <App />
      </ErrorBoundary>
    ),
    children: [
      {
        path: paths.PATH_MAIN,
        element: (
          <RequireAuth>
            <MainPage />
          </RequireAuth>
        ),
      },
      {
        path: paths.PATH_AUTH,
        element: <AuthPage />,
      },
      {
        path: paths.PATH_PRICE_RULES,
        element: (
          <RequireAuth>
            <PriceRulesPage />
          </RequireAuth>
        ),
      },
      {
        path: paths.PATH_CREATE_PRICE_RULES,
        element: (
          <RequireAuth>
            <CreatePriceRulesPage />
          </RequireAuth>
        ),
      },
      {
        path: paths.PATH_PRICE_PLANS,
        element: (
          <RequireAuth>
            <PricePlansPage />
          </RequireAuth>
        ),
      },
      {
        path: paths.PATH_CREATE_PRICE_PLAN,
        element: (
          <RequireAuth>
            <CreatePricePlansPage />
          </RequireAuth>
        ),
      },
      {
        path: paths.PATH_EDIT_PRICE_PLAN,
        element: (
          <RequireAuth>
            <EditPlanPage />
          </RequireAuth>
        ),
      },
      {
        path: paths.PATH_MONITORING,
        element: (
          <RequireAuth>
            <MonitoringsPage />
          </RequireAuth>
        ),
      },
      {
        path: paths.PATH_PRICE_QUEUE,
        element: (
          <RequireAuth>
            <PricesPage />
          </RequireAuth>
        ),
      },
      {
        path: paths.PATH_NOTIFICATIONS,
        element: (
          <RequireAuth>
            <NotificationsPage />
          </RequireAuth>
        ),
      },
    ],
  },
]);
