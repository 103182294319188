import { EPlansChangeValues, EPlanStatus, EPlanValidationStatus } from '@/types/plans/plans';

import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Button, Checkbox, Flex, Loader, Text } from '@mantine/core';
import { IconFileExport, IconMessage } from '@tabler/icons-react';

import notify from '@/utils/notify';
import { getPlansSort } from '@/utils/plans';
import { getOrder } from '@/utils/rules';

import { paths, titles } from '@/constants/paths';

import PageFooter from '@/ui/organisms/PageFooter/PageFooter';
import PageHeader from '@/ui/organisms/PageHeader/PageHeader';
import SelectPlanStatus from '@/ui/organisms/SelectPlanStatus/SelectPlanStatus';
import { PricePlansTable } from '@/ui/pages/PricePlansPage/organisms/PricePlansTable/PricePlansTable';
import PricePlansTableFilters from '@/ui/pages/PricePlansPage/organisms/PricePlansTableFilters/PricePlansTableFilters';
import PageLayoutBody from '@/ui/templates/PageLayout/organisms/PageLayoutBody/PageLayoutBody';
import PageLayoutFooter from '@/ui/templates/PageLayout/organisms/PageLayoutFooter/PageLayoutFooter';
import PageLayoutHeader from '@/ui/templates/PageLayout/organisms/PageLayoutHeader/PageLayoutHeader';
import PageLayout from '@/ui/templates/PageLayout/PageLayout';

import { AppDispatch } from '@/store';
import { selectActiveFilters } from '@/store/slices/filters/filters';
import {
  fetchChangePlansStatusAction,
  fetchExportPlansAction,
  fetchProcessPlansStatusAction,
  selectCheckedPlans,
  selectFetchingPlansExport,
  selectInputQuery,
  selectPlansRefetch,
  selectPlansSorting,
  selectTotalPlansCount,
  setPlansCommentModalOpened,
  setPlansRefetch,
} from '@/store/slices/plans/plans';

const PricePlansPage: FC = () => {
  const dispatch: AppDispatch = useDispatch();

  const totalPlansCount = useSelector(selectTotalPlansCount);
  const checkedPlans = useSelector(selectCheckedPlans);
  const plansRefetch = useSelector(selectPlansRefetch);
  const fetchingPlansExport = useSelector(selectFetchingPlansExport);
  const activeFilters = useSelector(selectActiveFilters).plans;
  const plansSorting = useSelector(selectPlansSorting);
  const queryValue = useSelector(selectInputQuery);

  const canChangeStatus = checkedPlans.every((item, index, values) => {
    return values.some((plan) => plan.status !== item.status);
  });
  const isProcessedPlans = checkedPlans.every((item) => item.status === EPlanStatus.ready);
  const isReadyPlans = checkedPlans.some(
    (item) => item.validationStatus === EPlanValidationStatus.readyPlan,
  );
  const isCanceledPlans = checkedPlans.every(
    (item) => item.validationStatus === EPlanValidationStatus.canceled,
  );
  const canProcessPlans = checkedPlans.some(
    (plan) =>
      plan.validationStatus === EPlanValidationStatus.readyPlan ||
      plan.validationStatus === EPlanValidationStatus.canceled,
  );
  const checkedPlansIDs = checkedPlans.map((item) => item.id);
  const canSetReadyPlans = checkedPlans.every(
    (item) =>
      item.validationStatus === EPlanValidationStatus.processed ||
      item.validationStatus === EPlanValidationStatus.canceled,
  );
  const canSetCancelPlans = checkedPlans.every(
    (item) => item.validationStatus === EPlanValidationStatus.processed,
  );

  const handleCommentPlans = () => {
    dispatch(setPlansCommentModalOpened(true));
  };

  const handlePlansStatus = async (value: EPlansChangeValues) => {
    if (canChangeStatus) {
      notify({ message: 'Вы выбрали ЦП с разными статусами расчета', type: 'warning' });
      return;
    }

    if (!isProcessedPlans) {
      notify({
        message: 'Изменить статус анализа можно только у ЦП, у которых статус расчета = Рассчитан',
        type: 'warning',
      });
      return;
    }

    if (isReadyPlans) {
      notify({
        message: 'Нельзя изменить статус анализа со статусом согласования "Готов"',
        type: 'warning',
      });
      return;
    }

    if (!canSetReadyPlans && value === EPlansChangeValues.published) {
      notify({
        message:
          'Перевести в статус "Готов" можно только анализы со статусами согласования "Рассчитан" и "Отклонен"',
        type: 'warning',
      });
      return;
    }

    if (!canSetCancelPlans && value === EPlansChangeValues.declined) {
      notify({
        message:
          'Перевести в статус "Отклонен" можно только анализы со статусом согласования "Рассчитан"',
        type: 'warning',
      });
      return;
    }

    if (isCanceledPlans && value !== EPlansChangeValues.processed) {
      notify({
        message: 'Изменить статус "Отклонен" можно только на статус "Рассчитан"',
        type: 'warning',
      });
      return;
    }

    await dispatch(fetchChangePlansStatusAction({ id: checkedPlansIDs, status: value }));
    dispatch(setPlansRefetch(!plansRefetch));
  };

  const handleProcessPlans = async () => {
    await dispatch(fetchProcessPlansStatusAction(checkedPlansIDs));
    dispatch(setPlansRefetch(!plansRefetch));
  };

  const downloadFile = (file: Blob) => {
    const url = window.URL.createObjectURL(new Blob([file]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'plans.csv');
    document.body.appendChild(link);
    link.click();
  };

  const handleExportFile = async () => {
    const result = await dispatch(
      fetchExportPlansAction({
        sort: getPlansSort(plansSorting),
        order: getOrder(plansSorting),
        filter: [...activeFilters],
        query: queryValue,
      }),
    );
    downloadFile(result.payload);
  };

  return (
    <PageLayout>
      <PageLayoutHeader>
        <PageHeader title={titles.TITLE_PRICE_PLANS}>
          <NavLink to={paths.PATH_CREATE_PRICE_PLAN}>
            <Button color="dark.9">Создать анализ</Button>
          </NavLink>
          <Button
            color="gray.2"
            leftIcon={fetchingPlansExport ? <Loader size="xs" /> : <IconFileExport size="1.1rem" />}
            onClick={handleExportFile}
            disabled={!totalPlansCount}
          >
            Экспортировать
          </Button>
        </PageHeader>
      </PageLayoutHeader>

      <PageLayoutBody>
        <PricePlansTableFilters />
        <PricePlansTable />
      </PageLayoutBody>
      <PageLayoutFooter>
        {!!checkedPlans.length && (
          <PageFooter>
            <Flex justify="space-between" align="center">
              <Flex align="center">
                <Checkbox m={8} color="dark" radius="sm" size="sm" readOnly checked></Checkbox>
                <Text size="sm">
                  Выбрано {checkedPlans.length} из {totalPlansCount}
                </Text>
              </Flex>
              <Flex align="center" gap={16}>
                <Button leftIcon={<IconMessage />} color="gray.2" onClick={handleCommentPlans}>
                  Комментировать
                </Button>
                <SelectPlanStatus onChange={handlePlansStatus} />
                <Button color="dark.9" onClick={handleProcessPlans} disabled={canProcessPlans}>
                  Рассчитать
                </Button>
              </Flex>
            </Flex>
          </PageFooter>
        )}
      </PageLayoutFooter>
    </PageLayout>
  );
};

export default PricePlansPage;
