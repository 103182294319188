import { EFilterKey } from '@/types/filters/filters';
import { IGetPlanDetail } from '@/types/plans/plans';

import { FC, UIEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Flex, Loader } from '@mantine/core';
import { useInfiniteQuery } from '@tanstack/react-query';
import {
  MRT_SortingState,
  MRT_TableContainer,
  MRT_Virtualizer,
  useMantineReactTable,
} from 'mantine-react-table';
import { MRT_Localization_RU } from 'mantine-react-table/locales/ru';

import { modifiedTreeFilter } from '@/utils/filters';

import { fetchOptions } from '@/constants/fetch';
import { useTableState } from '@/hooks/useTableState';

import EditPlanPageTableCommentModal from '@/ui/pages/EditPlanPage/organisms/EditPlanPageTable/organisms/EditPlanPageTableCommentModal/EditPlanPageTableCommentModal';
import EditPlanPageTableDrawer from '@/ui/pages/EditPlanPage/organisms/EditPlanPageTable/organisms/EditPlanPageTableDrawer/EditPlanPageTableDrawer';
import EditPlanPageTableRowActions from '@/ui/pages/EditPlanPage/organisms/EditPlanPageTable/organisms/EditPlanPageTableRowActions/EditPlanPageTableRowActions';
import EditPlanTableChangeLockPriceModal from '@/ui/pages/EditPlanPage/organisms/EditPlanPageTable/organisms/EditPlanTableChangeLockPriceModal/EditPlanTableChangeLockPriceModal';

import { AppDispatch } from '@/store';
import { resetFilters, selectActiveFilters } from '@/store/slices/filters/filters';
import {
  fetchPlanDetailDataAction,
  selectPlanDetailRefetch,
  selectPlansCommentModalOpened,
  setCheckedPlan,
  setDetailCheckedPlans,
  setPlanDetailSorting,
} from '@/store/slices/plans/plans';
import {
  selectActiveFilters as selectActiveTreeFilters,
  setActiveRulesFilters,
} from '@/store/slices/rules/rules';

const EditPlanPageTable: FC = () => {
  const dispatch: AppDispatch = useDispatch();

  const { id } = useParams();

  const { editPlanColumns } = useTableState();

  const activeFilters = useSelector(selectActiveFilters).planDetail;
  const planDetailRefetch = useSelector(selectPlanDetailRefetch);
  const commentModalOpened = useSelector(selectPlansCommentModalOpened);
  const activeTreeFilters = useSelector(selectActiveTreeFilters);

  const tableContainerRef = useRef<HTMLDivElement>(null);
  const rowVirtualizerInstanceRef =
    useRef<MRT_Virtualizer<HTMLDivElement, HTMLTableRowElement>>(null);

  const [sorting, setSorting] = useState<MRT_SortingState>([]);

  const getInfiniteData = async ({ pageParam = 0 }) => {
    if (id) {
      const result = await dispatch(
        fetchPlanDetailDataAction({
          id: id,
          ...(sorting[0]?.id && { sort: sorting[0].id }),
          ...(typeof sorting[0]?.desc === 'boolean' && {
            order: sorting[0].desc ? 'desc' : 'asc',
          }),
          offset: pageParam * fetchOptions.FETCH_DETAIL_PLAN_LIMIT,
          limit: fetchOptions.FETCH_DETAIL_PLAN_LIMIT,
          filter: [...modifiedTreeFilter(activeTreeFilters), ...activeFilters],
        }),
      );
      return result.payload;
    }
  };

  const { data, fetchNextPage, isLoading, isFetching } = useInfiniteQuery<IGetPlanDetail>({
    queryKey: [
      'getPlanDetail',
      sorting,
      modifiedTreeFilter(activeTreeFilters),
      planDetailRefetch,
      activeFilters,
      id,
    ],
    queryFn: getInfiniteData,
    getNextPageParam: (_lastGroup, groups) => groups.length,
  });

  const flatData = useMemo(
    () => (data?.pages[0]?.items ? data?.pages.flatMap((page) => page?.items) : []),
    [data],
  );

  const totalRowsCount = data?.pages?.[0]?.total ?? 0;
  const totalFetched = flatData.length;

  const fetchMoreOnBottomReached = useCallback(
    (containerRefElement?: HTMLDivElement | null) => {
      if (containerRefElement) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
        if (
          scrollHeight - scrollTop - clientHeight < 646 &&
          !isFetching &&
          totalFetched < totalRowsCount
        ) {
          fetchNextPage();
        }
      }
    },
    [fetchNextPage, isFetching, totalFetched],
  );

  const plansDetailTable = useMantineReactTable({
    columns: editPlanColumns,
    data: flatData,
    enableColumnResizing: true,
    enablePinning: true,
    enableStickyHeader: true,
    enableRowSelection: true,
    enableGrouping: true,
    enableColumnOrdering: true,
    initialState: {
      density: 'xs',
      showColumnFilters: false,
      showGlobalFilter: false,
      columnPinning: {
        right: ['mrt-row-actions'],
      },
    },
    enableColumnFilters: false,
    enablePagination: false,
    enableColumnActions: false,
    enableRowActions: true,
    positionActionsColumn: 'last',
    displayColumnDefOptions: {
      'mrt-row-actions': {
        header: '',
        size: 43,
        mantineTableBodyCellProps: ({ row }) =>
          row.getIsSelected() ? { bg: 'gray.2' } : { bg: 'white' },
      },
      'mrt-row-select': {
        size: 40,
      },
    },
    mantineTableProps: {
      withColumnBorders: true,
      withBorder: true,
      highlightOnHover: false,
      sx: {
        tableLayout: 'fixed',
      },
    },
    mantineTableBodyProps: {
      sx: {
        minHeight: 'auto',
      },
    },
    mantineTableHeadRowProps: {
      sx: (theme) => ({
        backgroundColor: theme.colors.gray[2],
        boxShadow: 'none',
        withBorder: true,
      }),
    },
    mantineTableHeadProps: {
      sx: {
        opacity: 1,
      },
    },
    mantineTableHeadCellProps: {
      sx: {
        ['div[class~="mantine-TableHeadCell-Content-Labels"]']: {
          gap: '5px',
          color: 'black',
        },
        ['div[class~="mantine-TableHeadCell-Content-Actions"]']: {
          marginLeft: 'auto',
          transform: 'rotate(90deg)',
        },
      },
    },
    mantineSelectCheckboxProps: {
      color: 'dark',
      radius: 'sm',
    },
    mantineSelectAllCheckboxProps: {
      color: 'dark',
      radius: 'sm',
    },
    renderRowActionMenuItems: ({ row, table }) => (
      <EditPlanPageTableRowActions planData={row.original} table={table} />
    ),
    enableRowVirtualization: true,
    manualSorting: true,
    mantineTableContainerProps: {
      ref: tableContainerRef,
      sx: { height: 'calc(100vh - 483px)' },
      onScroll: (event: UIEvent<HTMLDivElement>) =>
        fetchMoreOnBottomReached(event.target as HTMLDivElement),
    },
    onSortingChange: setSorting,
    state: {
      isLoading,
      showProgressBars: isFetching,
      sorting,
    },
    rowVirtualizerInstanceRef,
    rowVirtualizerProps: { overscan: 10 },
    localization: MRT_Localization_RU,
  });

  const checkedRows = plansDetailTable.getSelectedRowModel().rows;

  useEffect(() => {
    if (rowVirtualizerInstanceRef.current) {
      try {
        rowVirtualizerInstanceRef.current.scrollToIndex(0);
      } catch (e) {
        console.error(e);
      }
    }
  }, [sorting, activeFilters, activeTreeFilters, planDetailRefetch]);

  useEffect(() => {
    dispatch(setPlanDetailSorting(sorting));
  }, [sorting]);

  useEffect(() => {
    fetchMoreOnBottomReached(tableContainerRef.current);
  }, [fetchMoreOnBottomReached]);

  useEffect(() => {
    dispatch(setDetailCheckedPlans(checkedRows.map((item) => item.original)));
  }, [activeFilters, activeTreeFilters, checkedRows]);

  useEffect(() => {
    plansDetailTable.resetRowSelection();
  }, [activeFilters, activeTreeFilters, planDetailRefetch]);

  useEffect(() => {
    if (checkedRows.length) dispatch(setCheckedPlan(null));
  }, [checkedRows]);

  useEffect(() => {
    if (!commentModalOpened) plansDetailTable.resetRowSelection();
  }, [commentModalOpened]);

  useEffect(() => {
    return () => {
      dispatch(resetFilters(EFilterKey.planDetail));
      dispatch(setActiveRulesFilters([]));
    };
  }, []);

  return (
    <Flex direction="column">
      <MRT_TableContainer table={plansDetailTable} />
      {isFetching && !isLoading && (
        <Flex justify="center">
          <Loader mt={10} color="black" size="lg" />
        </Flex>
      )}
      <EditPlanTableChangeLockPriceModal />
      <EditPlanPageTableCommentModal />
      <EditPlanPageTableDrawer />
    </Flex>
  );
};

export default EditPlanPageTable;
