import { FC } from 'react';
import { createStyles, Group, Text, Title } from '@mantine/core';

import PriceRulesSteppersRulesPageDrawer from '../PriceRulesSteppersRulesPageDrawer/PriceRulesSteppersRulesPageDrawer';

const useStyles = createStyles((theme) => ({
  wrapper: {
    padding: '32px',
    border: `1px solid ${theme.colors.gray[2]}`,
    borderRadius: '8px',
  },
}));

const PriceRulesSteppersEmptyData: FC = () => {
  const { classes } = useStyles();

  return (
    <Group className={classes.wrapper} mt={32}>
      <Title order={3}>Ой! Пока что у этого товарного узла нет добавленных правил.</Title>
      <Text size="md" color="dark.2">
        Начните сейчас — просто нажмите кнопку «добавить правило». Это быстрый и простой процесс,
        который позволит вам максимально использовать возможности вашего товарного узла.
      </Text>
      <PriceRulesSteppersRulesPageDrawer />
    </Group>
  );
};

export default PriceRulesSteppersEmptyData;
