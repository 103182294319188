import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Checkbox, Flex, Text } from '@mantine/core';

import { getPositionsDescription } from '@/utils/monitorings';

import { titles } from '@/constants/paths';

import PageFooter from '@/ui/organisms/PageFooter/PageFooter';
import PageHeader from '@/ui/organisms/PageHeader/PageHeader';
import { MonitoringsPageFilters } from '@/ui/pages/MonitoringPage/organisms/MonitoringsPageFilters/MonitoringsPageFilters';
import { MonitoringsPageTable } from '@/ui/pages/MonitoringPage/organisms/MonitoringsPageTable/MonitoringsPageTable';
import PageLayoutBody from '@/ui/templates/PageLayout/organisms/PageLayoutBody/PageLayoutBody';
import PageLayoutFooter from '@/ui/templates/PageLayout/organisms/PageLayoutFooter/PageLayoutFooter';
import PageLayoutHeader from '@/ui/templates/PageLayout/organisms/PageLayoutHeader/PageLayoutHeader';
import PageLayout from '@/ui/templates/PageLayout/PageLayout';

import { AppDispatch } from '@/store';
import {
  fetchMonitoringAnomalyAction,
  selectCheckedMonitorings,
  selectMonitoringsRefetch,
  setMonitoringsRefetch,
} from '@/store/slices/monitoring/monitoring';

export const MonitoringsPage: FC = () => {
  const dispatch: AppDispatch = useDispatch();

  const checkedMonitorings = useSelector(selectCheckedMonitorings);
  const monitoringsRefetch = useSelector(selectMonitoringsRefetch);

  const handleCheckedMonitorings = async () => {
    await dispatch(fetchMonitoringAnomalyAction(checkedMonitorings.map((item) => item.id)));
    dispatch(setMonitoringsRefetch(!monitoringsRefetch));
  };

  return (
    <PageLayout>
      <PageLayoutHeader>
        <PageHeader title={titles.TITLE_MONITORING}></PageHeader>
      </PageLayoutHeader>

      <PageLayoutBody>
        <MonitoringsPageFilters />
        <MonitoringsPageTable />
      </PageLayoutBody>

      <PageLayoutFooter>
        {!!checkedMonitorings.length && (
          <PageFooter>
            <Flex justify="space-between" align="center">
              <Flex align="center">
                <Checkbox m={8} color="dark" radius="sm" size="sm" readOnly checked></Checkbox>
                <Text size="sm">{getPositionsDescription(checkedMonitorings.length)}</Text>
              </Flex>
              <Button color="dark.9" onClick={handleCheckedMonitorings}>
                Снять признак
              </Button>
            </Flex>
          </PageFooter>
        )}
      </PageLayoutFooter>
    </PageLayout>
  );
};
