import {
  IconBell,
  IconClipboardCheck,
  IconGraph,
  IconHeartRateMonitor,
  IconHome,
  IconHourglassHigh,
  IconListDetails,
  IconPuzzle,
} from '@tabler/icons-react';

import { paths, titles } from '@/constants/paths';

export const navbarItems = [
  {
    icon: <IconHome />,
    label: titles.TITLE_MAIN,
    path: paths.PATH_MAIN,
  },
  {
    icon: <IconListDetails />,
    label: titles.TITLE_PRICE_RULES,
    path: paths.PATH_PRICE_RULES,
  },
  {
    icon: <IconGraph />,
    label: titles.TITLE_PRICE_PLANS,
    path: paths.PATH_PRICE_PLANS,
  },
  {
    icon: <IconHeartRateMonitor />,
    label: titles.TITLE_MONITORING,
    path: paths.PATH_MONITORING,
  },
  {
    icon: <IconHourglassHigh />,
    label: titles.TITLE_PRICE_QUEUE,
    path: paths.PATH_PRICE_QUEUE,
  },
  {
    icon: <IconBell />,
    label: titles.TITLE_NOTIFICATIONS,
    path: paths.PATH_NOTIFICATIONS,
  },
];

export const navbarItemsExternal = [
  {
    icon: <IconPuzzle />,
    label: titles.TITLE_MATCHER,
    path: paths.PATH_MATCHER,
  },
  {
    icon: <IconClipboardCheck />,
    label: titles.TITLE_REPORTS,
    path: paths.PATH_REPORTS,
  },
];
