import { IFilter, IFilterFilters, IFilterValue } from '@/types/filters/filters';
import { IGetNotificationsParams } from '@/types/notifications/notifications';

import { URLS } from '@/api/urls';

import api from '../instance';

export const apiPostNotifications = ({ offset, limit, filter }: IGetNotificationsParams) =>
  api.post(URLS.notifications.notifications, { filter }, { params: { offset, limit } });

export const apiGetNotificationsFilters = () =>
  api.get<IFilterFilters<IFilter<IFilterValue>>>(URLS.notifications.filters);
