import { FC } from 'react';

import { titles } from '@/constants/paths';

import NotificationsBlock from '@/ui/organisms/NotificationsBlock/NotificationsBlock';
import PageHeader from '@/ui/organisms/PageHeader/PageHeader';
import PageLayoutBody from '@/ui/templates/PageLayout/organisms/PageLayoutBody/PageLayoutBody';
import PageLayoutHeader from '@/ui/templates/PageLayout/organisms/PageLayoutHeader/PageLayoutHeader';
import PageLayout from '@/ui/templates/PageLayout/PageLayout';

import NotificationsPageFilters from './organisms/NotificationsPageFilters/NotificationsPageFilters';

const NotificationsPage: FC = () => {
  return (
    <PageLayout>
      <PageLayoutHeader>
        <PageHeader title={titles.TITLE_NOTIFICATIONS}></PageHeader>
      </PageLayoutHeader>

      <PageLayoutBody>
        <NotificationsPageFilters />
        <NotificationsBlock />
      </PageLayoutBody>
    </PageLayout>
  );
};

export default NotificationsPage;
