import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  select: {
    rightSection: {
      pointerEvents: 'none',
    },
    input: {
      ':hover': { backgroundColor: theme.colors.gray[3] },
      '::placeholder': {
        color: 'black',
      },
      backgroundColor: theme.colors.gray[2],
      ':focus': {
        border: 'none',
      },
      border: 'none',
    },
  },
}));
