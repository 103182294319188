import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { paths, titles } from '@/constants/paths';

import PageHeader from '@/ui/organisms/PageHeader/PageHeader';
import EditPlanPageFilters from '@/ui/pages/EditPlanPage/organisms/EditPlanPageFilters/EditPlanPageFilters';
import EditPlanPageFooter from '@/ui/pages/EditPlanPage/organisms/EditPlanPageFooter/EditPlanPageFooter';
import { EditPlanPageHeaderButtons } from '@/ui/pages/EditPlanPage/organisms/EditPlanPageHeaderButtons/EditPlanPageHeaderButtons';
import { EditPlanPageInfo } from '@/ui/pages/EditPlanPage/organisms/EditPlanPageInfo/EditPlanPageInfo';
import EditPlanPageTable from '@/ui/pages/EditPlanPage/organisms/EditPlanPageTable/EditPlanPageTable';
import PageLayoutBody from '@/ui/templates/PageLayout/organisms/PageLayoutBody/PageLayoutBody';
import PageLayoutFooter from '@/ui/templates/PageLayout/organisms/PageLayoutFooter/PageLayoutFooter';
import PageLayoutHeader from '@/ui/templates/PageLayout/organisms/PageLayoutHeader/PageLayoutHeader';
import PageLayout from '@/ui/templates/PageLayout/PageLayout';

import { AppDispatch } from '@/store';
import {
  fetchPlanDetailAction,
  selectPlanDetailCheckedItems,
  selectPlanDetails,
  setDetailPlanInfo,
  setPlanDetail,
} from '@/store/slices/plans/plans';

export const EditPlanPage: FC = () => {
  const dispatch: AppDispatch = useDispatch();

  const { id } = useParams();

  const planDetails = useSelector(selectPlanDetails);
  const planName = planDetails?.name || 'Название ценового анализа';
  const detailCheckedPlans = useSelector(selectPlanDetailCheckedItems);

  const items = [
    { title: titles.TITLE_PRICE_PLANS, path: paths.PATH_PRICE_PLANS },
    { title: planName, path: '#' },
  ];

  useEffect(() => {
    if (id) dispatch(fetchPlanDetailAction(id));
  }, [id]);

  useEffect(() => {
    return () => {
      dispatch(setDetailPlanInfo(null));
      dispatch(setPlanDetail(null));
    };
  }, []);

  return (
    <PageLayout>
      <PageLayoutHeader>
        <PageHeader title={planName} items={items} children={<EditPlanPageHeaderButtons />} />
      </PageLayoutHeader>

      <PageLayoutBody>
        <EditPlanPageInfo />
        <EditPlanPageFilters />
        <EditPlanPageTable />
      </PageLayoutBody>

      <PageLayoutFooter>{!!detailCheckedPlans.length && <EditPlanPageFooter />}</PageLayoutFooter>
    </PageLayout>
  );
};
