import { TAuthParams } from '@/types/auth/auth';

import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, createStyles, Flex, PasswordInput, TextInput, Title } from '@mantine/core';
import { useForm } from '@mantine/form';

import { AppDispatch } from '@/store';
import { fetchAuthAction, fetchingGetConfiguration } from '@/store/slices/auth/auth';

const useStyles = createStyles((theme) => ({
  form: {
    backgroundColor: 'white',
    border: `1px solid ${theme.colors.gray[3]}`,
    borderRadius: '16px',
    label: {
      fontWeight: 'bold',
    },
  },
  input: {
    label: {
      marginBottom: '5px',
    },
  },
}));

const AuthPage: FC = () => {
  const { classes } = useStyles();
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const isLoading = useSelector(fetchingGetConfiguration);

  const fromLocation = location.state?.from?.pathname || '/';

  const form = useForm({
    initialValues: {
      email: '',
      password: '',
    },
    validate: {
      email: (value) => !value.match(/^\S+@\S+\.\S+$/),
      password: (value) => value.length === 0,
    },
    validateInputOnBlur: true,
  });

  const handleSubmit = async (formData: TAuthParams) => {
    const authResponse = await dispatch(fetchAuthAction({ ...formData }));

    if (authResponse.meta.requestStatus === 'fulfilled') {
      navigate(fromLocation, { replace: true });
    }
  };

  return (
    <Flex justify="center">
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Flex w={509} direction="column" p={32} gap={24} className={classes.form}>
          <Title order={3}>Авторизация</Title>
          <TextInput
            label="Электронная почта"
            placeholder="Введите почту"
            className={classes.input}
            {...form.getInputProps('email')}
          />
          <PasswordInput
            label="Пароль"
            placeholder="Введите пароль"
            className={classes.input}
            {...form.getInputProps('password')}
          />
          <Button
            fullWidth
            type="submit"
            color="dark.9"
            disabled={!form.isValid()}
            loading={isLoading}
          >
            Войти
          </Button>
        </Flex>
      </form>
    </Flex>
  );
};

export default AuthPage;
