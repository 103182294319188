import { FC, ReactNode } from 'react';
import { Badge, createStyles, Flex, UnstyledButton } from '@mantine/core';

interface DrawerExtButtonProps extends React.ComponentPropsWithoutRef<'button'> {
  title: string;
  icon: ReactNode;
  checked: boolean;
  isRule: boolean;
  isActive?: boolean;
  isShown?: boolean;
}

const DrawerExtButton: FC<DrawerExtButtonProps> = ({
  title,
  icon,
  checked,
  isRule,
  isActive,
  isShown,
  ...props
}) => {
  const useStyles = createStyles((theme) => ({
    button: {
      padding: '16px',
      width: '100%',
      border: `2px solid ${theme.colors.gray[2]}`,
      borderColor: checked && !isShown ? `${theme.colors.dark[9]}` : `${theme.colors.gray[2]}`,
      borderRadius: '8px',
      color: isShown ? theme.colors.gray[6] : theme.colors.dark[9],
      backgroundColor: isShown ? theme.colors.gray[2] : 'white',
      fontWeight: 'bold',
    },
  }));

  const { classes } = useStyles();

  const getStatus = () => {
    if (!isShown) {
      return (
        <Badge color="yellow" variant="filled">
          Не добавлено
        </Badge>
      );
    } else if (isActive) {
      return (
        <Badge color="green" variant="filled">
          Активно
        </Badge>
      );
    } else
      return (
        <Badge color="gray" variant="filled">
          Неактивно
        </Badge>
      );
  };

  return (
    <UnstyledButton className={classes.button} {...props} disabled={isShown}>
      <Flex direction="column" gap={16}>
        <Flex direction="row" justify="space-between">
          {icon}
          {isRule && getStatus()}
        </Flex>
        {title}
      </Flex>
    </UnstyledButton>
  );
};

export default DrawerExtButton;
