import { EFilterKey, IFilterItemPayload } from '@/types/filters/filters';
import { IFilter, IFilterFilters, IFilterItem, IFilterValue } from '@/types/filters/filters';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { apiGetRulesFilters } from '@/api/filters/filters';
import { apiGetMonitoringFilters, apiGetMonitoringFiltersValue } from '@/api/monitoring/monitoring';
import { apiGetNotificationsFilters } from '@/api/notifications/notifications';
import {
  apiGetPlanDetailFilters,
  apiGetPlanDetailFilterSearch,
  apiGetPlansFilters,
} from '@/api/plans/plans';
import { apiGetPricesFilters, apiGetPricesFiltersValue } from '@/api/prices/prices';

import { createAxiosThunk } from '@/utils/asyncRequest';

interface IFiltersState {
  filters: {
    rules: IFilterFilters<IFilter<IFilterValue>> | null;
    plans: IFilterFilters<IFilter<IFilterValue>> | null;
    planDetail: IFilterFilters<IFilter<IFilterValue>> | null;
    monitorings: IFilterFilters<IFilter<IFilterValue>> | null;
    prices: IFilterFilters<IFilter<IFilterValue>> | null;
    notifications: IFilterFilters<IFilter<IFilterValue>> | null;
  };
  fetchingFilters: {
    rules: boolean;
    plans: boolean;
    planDetail: boolean;
    monitorings: boolean;
    prices: boolean;
    notifications: boolean;
  };
  activeFilters: {
    rules: IFilterItem[];
    plans: IFilterItem[];
    planDetail: IFilterItem[];
    monitorings: IFilterItem[];
    prices: IFilterItem[];
    notifications: IFilterItem[];
  };
}

const initialState: IFiltersState = {
  filters: {
    rules: null,
    plans: null,
    planDetail: null,
    monitorings: null,
    prices: null,
    notifications: null,
  },
  fetchingFilters: {
    rules: false,
    plans: false,
    planDetail: false,
    monitorings: false,
    prices: false,
    notifications: false,
  },
  activeFilters: {
    rules: [],
    plans: [],
    planDetail: [],
    monitorings: [],
    prices: [],
    notifications: [],
  },
};

export const fetchMonitoringFiltersAction = createAxiosThunk(
  '/getMonitoringFilters',
  apiGetMonitoringFilters,
);
export const fetchMonitoringFiltersValuesAction = createAxiosThunk(
  '/getMonitoringFiltersValues',
  apiGetMonitoringFiltersValue,
);
export const fetchPricesFiltersAction = createAxiosThunk('/getPricesFilters', apiGetPricesFilters);
export const fetchPricesFiltersValuesAction = createAxiosThunk(
  '/getPricesFiltersValues',
  apiGetPricesFiltersValue,
);
export const fetchPlanDetailFiltersAction = createAxiosThunk(
  '/getPlanDetailFilters',
  apiGetPlanDetailFilters,
);
export const fetchPlanDetailFilerValuesAction = createAxiosThunk(
  '/getPlanDetailFilterValues',
  apiGetPlanDetailFilterSearch,
);
export const fetchRulesFiltersAction = createAxiosThunk('/getFilters', apiGetRulesFilters);
export const fetchNotificationsFiltersAction = createAxiosThunk(
  '/getNotificationsFilters',
  apiGetNotificationsFilters,
);
export const fetchPlansFiltersAction = createAxiosThunk('/getPlansFilters', apiGetPlansFilters);

export const filtersSlice = createSlice({
  name: 'filters',
  initialState: initialState,
  reducers: {
    setActiveFilters: (state, action: PayloadAction<IFilterItemPayload>) => {
      const key = action.payload.filterKey;
      const filteredFilters = state.activeFilters[key].filter(
        (item) => item.code !== action.payload.item.code,
      );
      state.activeFilters[key] = [...filteredFilters, action.payload.item];
    },
    clearFilter: (state, action: PayloadAction<IFilterItemPayload>) => {
      const key = action.payload.filterKey;
      state.activeFilters[key] = state.activeFilters[key].filter(
        (item) => item.code !== action.payload.item.code,
      );
    },
    resetFilters: (state, action: PayloadAction<EFilterKey>) => {
      const key = action.payload;
      state.activeFilters[key] = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMonitoringFiltersAction.pending, (state) => {
      state.fetchingFilters.monitorings = true;
    });
    builder.addCase(fetchMonitoringFiltersAction.fulfilled, (state, action) => {
      state.filters.monitorings = action.payload ?? [];
      state.fetchingFilters.monitorings = false;
    });
    builder.addCase(fetchMonitoringFiltersAction.rejected, (state) => {
      state.fetchingFilters.monitorings = false;
    });

    builder.addCase(fetchPricesFiltersAction.pending, (state) => {
      state.fetchingFilters.prices = true;
    });
    builder.addCase(fetchPricesFiltersAction.fulfilled, (state, action) => {
      state.filters.prices = action.payload;
      state.fetchingFilters.prices = false;
    });
    builder.addCase(fetchPricesFiltersAction.rejected, (state) => {
      state.fetchingFilters.prices = false;
    });

    builder.addCase(fetchPlanDetailFiltersAction.pending, (state) => {
      state.fetchingFilters.planDetail = true;
    });
    builder.addCase(fetchPlanDetailFiltersAction.fulfilled, (state, action) => {
      state.filters.planDetail = action.payload;
      state.fetchingFilters.planDetail = false;
    });
    builder.addCase(fetchPlanDetailFiltersAction.rejected, (state) => {
      state.fetchingFilters.planDetail = false;
    });

    builder.addCase(fetchRulesFiltersAction.pending, (state) => {
      state.fetchingFilters.rules = true;
    });
    builder.addCase(fetchRulesFiltersAction.fulfilled, (state, action) => {
      state.filters.rules = action.payload;
      state.fetchingFilters.rules = false;
    });
    builder.addCase(fetchRulesFiltersAction.rejected, (state) => {
      state.fetchingFilters.rules = false;
    });

    builder.addCase(fetchNotificationsFiltersAction.pending, (state) => {
      state.fetchingFilters.notifications = true;
    });
    builder.addCase(fetchNotificationsFiltersAction.fulfilled, (state, action) => {
      state.filters.notifications = action.payload;
      state.fetchingFilters.notifications = false;
    });
    builder.addCase(fetchNotificationsFiltersAction.rejected, (state) => {
      state.fetchingFilters.notifications = false;
    });

    builder.addCase(fetchPlansFiltersAction.pending, (state) => {
      state.fetchingFilters.plans = true;
    });
    builder.addCase(fetchPlansFiltersAction.fulfilled, (state, action) => {
      state.filters.plans = action.payload;
      state.fetchingFilters.plans = false;
    });
    builder.addCase(fetchPlansFiltersAction.rejected, (state) => {
      state.fetchingFilters.plans = false;
    });
  },
});

type TSelectorState = {
  filters: IFiltersState;
};

export const selectFilters = (state: TSelectorState) => state.filters.filters;
export const selectActiveFilters = (state: TSelectorState) => state.filters.activeFilters;
export const selectFetchingFilters = (state: TSelectorState) => state.filters.fetchingFilters;

export const { setActiveFilters, clearFilter, resetFilters } = filtersSlice.actions;

export default filtersSlice.reducer;
