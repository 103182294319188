import { IMonitoringItem } from '@/types/monitoring/monitoring';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { apiPostMonitoringAnomaly, apiPostMonitoringList } from '@/api/monitoring/monitoring';

import { createAxiosThunk } from '@/utils/asyncRequest';

interface IMonitoringState {
  checkedMonitorings: IMonitoringItem[];
  checkedMonitoring: IMonitoringItem | null;
  monitoringsRefetch: boolean;
}

const initialState: IMonitoringState = {
  checkedMonitorings: [],
  checkedMonitoring: null,
  monitoringsRefetch: false,
};

export const fetchMonitoringListAction = createAxiosThunk(
  '/postMonitoringList',
  apiPostMonitoringList,
);
export const fetchMonitoringAnomalyAction = createAxiosThunk(
  '/postMonitoringAnomaly',
  apiPostMonitoringAnomaly,
);

export const monitoringSlice = createSlice({
  name: 'monitoring',
  initialState: initialState,
  reducers: {
    setCheckedMonitorings: (state, action: PayloadAction<IMonitoringItem[]>) => {
      state.checkedMonitorings = action.payload;
    },
    setCheckedMonitoring: (state, action: PayloadAction<IMonitoringItem>) => {
      state.checkedMonitoring = action.payload;
    },
    setMonitoringsRefetch: (state, action: PayloadAction<boolean>) => {
      state.monitoringsRefetch = action.payload;
    },
  },
});

type TSelectorState = {
  monitoring: IMonitoringState;
};

export const selectCheckedMonitorings = (state: TSelectorState) =>
  state.monitoring.checkedMonitorings;
export const selectMonitoringsRefetch = (state: TSelectorState) =>
  state.monitoring.monitoringsRefetch;

export const { setCheckedMonitorings, setCheckedMonitoring, setMonitoringsRefetch } =
  monitoringSlice.actions;

export default monitoringSlice.reducer;
