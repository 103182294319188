import { IFilter, IFilterFilters, IFilterValue } from '@/types/filters/filters';
import { IPostMonitoringValues } from '@/types/monitoring/monitoring';
import { IGetProductsFilterValues, IMonitoringFilterValue } from '@/types/products/products';

import api from '@/api/instance';
import { URLS } from '@/api/urls';

export const apiGetMonitoringFilters = () =>
  api.get<IFilterFilters<IFilter<IFilterValue>>>(URLS.monitoring.filters);

export const apiGetMonitoringFiltersValue = (params: IGetProductsFilterValues) =>
  api.get<IMonitoringFilterValue>(URLS.monitoring.filtersSearch, { params: params });

export const apiPostMonitoringList = ({
  sort,
  order,
  offset,
  limit,
  filter,
}: IPostMonitoringValues) =>
  api.post(URLS.monitoring.list, { filter: filter }, { params: { sort, order, offset, limit } });

export const apiPostMonitoringAnomaly = (id: string[]) =>
  api.post(URLS.monitoring.removeAnomaly, { ids: id });
