import { FC } from 'react';
import { Flex, Grid, Title } from '@mantine/core';

import { paths } from '@/constants/paths';

import PageHeader from '@/ui/organisms/PageHeader/PageHeader';
import PageLayoutBody from '@/ui/templates/PageLayout/organisms/PageLayoutBody/PageLayoutBody';
import PageLayoutHeader from '@/ui/templates/PageLayout/organisms/PageLayoutHeader/PageLayoutHeader';
import PageLayout from '@/ui/templates/PageLayout/PageLayout';

import NotificationsBlock from '../../organisms/NotificationsBlock/NotificationsBlock';

import MainPageMenuBlock from './organisms/MainPageMenuBlock/MainPageMenuBlock';

const MainPage: FC = () => {
  return (
    <PageLayout>
      <PageLayoutHeader>
        <PageHeader title="Главная" />
      </PageLayoutHeader>

      <PageLayoutBody>
        <Grid gutter={24} w="100%">
          <Grid.Col span={6}>
            <NotificationsBlock title="Уведомления" path={paths.PATH_NOTIFICATIONS} />
          </Grid.Col>
          <Grid.Col span={6}>
            <NotificationsBlock title="Отчёты" path={paths.PATH_REPORTS} />
          </Grid.Col>
        </Grid>
        <Title order={3} mt={30} mb={20}>
          Разделы
        </Title>
        <Flex gap={24} wrap="wrap">
          <MainPageMenuBlock />
        </Flex>
      </PageLayoutBody>
    </PageLayout>
  );
};

export default MainPage;
