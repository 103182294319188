import { INotification } from '@/types/notifications/notifications';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { apiPostNotifications } from '@/api/notifications/notifications';

import { createAxiosThunk } from '@/utils/asyncRequest';

interface INotificationsState {
  notifications: INotification[] | null;
  filterValue: string;
  filterCode: string | null;
}

const initialState: INotificationsState = {
  notifications: null,
  filterValue: '',
  filterCode: null,
};

export const fetchNotificationsAction = createAxiosThunk(
  '/postNotifications',
  apiPostNotifications,
);

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    setNotificationsFilterValue: (state, action: PayloadAction<string>) => {
      state.filterValue = action.payload;
    },
    setNotificationsFilterCode: (state, action: PayloadAction<string | null>) => {
      state.filterCode = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchNotificationsAction.fulfilled, (state, action) => {
      state.notifications = action.payload.items;
    });
  },
});

type TSelectorState = { notifications: INotificationsState };

export const selectNotifications = (state: TSelectorState) => state.notifications.notifications;
export const selectNotificationsFilterValue = (state: TSelectorState) =>
  state.notifications.filterValue;

export const { setNotificationsFilterValue, setNotificationsFilterCode } =
  notificationsSlice.actions;

export default notificationsSlice.reducer;
