import { IGetFilterTreeParams } from '@/types/products/products';

export interface IModifiedFilterItem {
  label: string;
  checked: boolean;
  id: string;
  key: number;
}
export interface IModifiedFilterTreeItem {
  title: React.JSX.Element;
  key: string;
  id: number;
  children?: {
    title: React.JSX.Element;
    key: string;
    id: number;
  }[];
}

export interface IItemsFilters<T> {
  items: T[];
}
export interface IFilterFilters<T> {
  filters: T[];
}
export interface IFilter<T> {
  type: string;
  name: string;
  code: string;
  values: T[];
}
export interface IFilterValue {
  name: string;
  id: string;
}
export interface IFilterTree<T> {
  name: string;
  code: string;
  items: T[];
}
export interface IFilterTreeValue {
  name: string;
  id: number;
  level: number;
  parent: number;
}
export interface IGetFilteredTreeValue {
  parentId: number;
  query: string;
  filter?: IGetFilterTreeParams[];
}
export interface IFilterItem {
  code: string;
  type: string;
  value: {
    values?: string[];
    from?: string | number;
    to?: string | number;
    isSelected?: boolean;
  };
}

export interface IFilterItemPayload {
  filterKey: EFilterKey;
  item: IFilterItem;
}

export enum EFilterType {
  checkbox = 'checkbox',
  range = 'range',
  dateRange = 'dateRange',
  boolean = 'bool',
}

export enum EFilterKey {
  plans = 'plans',
  rules = 'rules',
  planDetail = 'planDetail',
  monitorings = 'monitorings',
  prices = 'prices',
  notifications = 'notifications',
}
