import { IFilterTreeValue, IModifiedFilterTreeItem } from '@/types/filters/filters';
import { IAdditionalNodeModified } from '@/types/nodes/nodes';

import { ChangeEvent, FC, Key, StrictMode, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ActionIcon,
  Box,
  Button,
  Divider,
  Flex,
  Input,
  Loader,
  Paper,
  ScrollArea,
  Select,
  Stepper,
  Text,
  TextInput,
  Title,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useDisclosure } from '@mantine/hooks';
import {
  IconCheck,
  IconChevronDown,
  IconChevronRight,
  IconSearch,
  IconTrash,
} from '@tabler/icons-react';
import { Provider } from 'rc-motion';
import Tree from 'rc-tree';
import { EventDataNode } from 'rc-tree/lib/interface';

import { generateTreeNodes, getNewTreeData, motion } from '@/utils/filters';
import { validateAdditionalNode } from '@/utils/forms';
import { modifiedNodeParams } from '@/utils/rules';

import { useDebounce } from '@/hooks/useDebounce';

import PageDrawer from '@/ui/organisms/DrawerExt/DrawerExt';
import FilterTreeMultiSelectTitle from '@/ui/organisms/FilterTreeMultiSelect/organisms/FilterTreeMultiSelectTitle/FilterTreeMultiSelectTitle';

import SteppersProductNodeFilters from '../../../../organisms/SteppersProductNodeFilters/SteppersProductNodeFilters';

import PriceRulesSteppersAdditionalNodesPageDrawer from './organisms/PriceRulesSteppersAdditionalNodesPageDrawer/PriceRulesSteppersAdditionalNodesPageDrawer';
import PriceRulesSteppersEmptyData from './organisms/PriceRulesSteppersEmptyData/PriceRulesSteppersEmptyData';
import PriceRulesSteppersHeader from './organisms/PriceRulesSteppersHeader/PriceRulesSteppersHeader';
import PriceRulesSteppersRulesForms from './organisms/PriceRulesSteppersRulesForms/PriceRulesSteppersRulesForms';

import { useStyles } from '@/assets/styles/priceRulesSteppers';
import { childStyle } from '@/assets/styles/treeMultiSelect';
import { AppDispatch } from '@/store';
import {
  fetchAdditionalNodesAction,
  fetchAdditionalNodeValuesAction,
  selectAdditionalNodeCode,
  selectAdditionalNodeCount,
  selectAdditionalNodeFilter,
  selectAdditionalNodes,
  selectAdditionalNodeValues,
  selectNodesParams,
  setAdditionalNodeCode,
  setAdditionalNodeCount,
  setAdditionalNodeFilter,
  setAdditionalNodes,
  setAdditionalNodeValues,
  setNodesParams,
} from '@/store/slices/nodes/nodes';
import {
  fetchTreeProductAction,
  fetchTreeProductFilteredAction,
  selectActiveProductsFilters,
  selectTreeProduct,
  selectTreeProductFilter,
  selectTreeProductFiltered,
  setActiveProductsFilters,
  setTreeProductFilter,
} from '@/store/slices/products/products';
import {
  fetchCompetitorAggregationsAction,
  fetchFiltersTreeNodeAction,
  fetchRulesListAction,
  selectFetchingRulesList,
  selectRuleBlocks,
  selectStepper,
  setCheckedRule,
  setStepper,
} from '@/store/slices/rules/rules';

const CreatePriceRulesSteppers: FC = () => {
  const { classes } = useStyles();

  const dispatch: AppDispatch = useDispatch();

  const treeRef = useRef<Tree<IModifiedFilterTreeItem>>(null);

  const [treeDataExample, setTreeDataExample] = useState<IModifiedFilterTreeItem[]>([]);
  const [treeDataExampleFiltered, setTreeDataExampleFiltered] = useState<IModifiedFilterTreeItem[]>(
    [],
  );
  const [productNodeValue, setProductNodeValue] = useState<IFilterTreeValue | null>(null);
  const [openedProductNode, openedProductNodeHandlers] = useDisclosure(false);

  const [searchValueRegion, setSearchValueRegion] = useState<string>('');
  const [searchValueMark, setSearchValueMark] = useState<string>('');
  const [searchValueClass, setSearchValueClass] = useState<string>('');
  const [searchValueBrand, setSearchValueBrand] = useState<string>('');
  const [searchValueGroup, setSearchValueGroup] = useState<string>('');
  const [searchValueTaste, setSearchValueTaste] = useState<string>('');

  const additionalNodes = useSelector(selectAdditionalNodes);
  const additionalNodeFilter = useSelector(selectAdditionalNodeFilter);
  const additionalNodeCode = useSelector(selectAdditionalNodeCode);
  const additionalNodeCount = useSelector(selectAdditionalNodeCount);
  const additionalNodeValues = useSelector(selectAdditionalNodeValues);
  const nodesParams = useSelector(selectNodesParams);
  const stepper = useSelector(selectStepper);

  const treeProductFilter = useSelector(selectTreeProductFilter);
  const treeProductData = useSelector(selectTreeProduct);
  const treeProductDataFiltered = useSelector(selectTreeProductFiltered);
  const activeProductsFilters = useSelector(selectActiveProductsFilters);

  const ruleBlocks = useSelector(selectRuleBlocks);
  const fetchingRulesList = useSelector(selectFetchingRulesList);

  const debouncedAdditionalNodeFilter = useDebounce<string>(additionalNodeFilter);

  const rulesIsEmpty = ruleBlocks.every((item) => !item.shown);

  const currentNodeParams = (nodeCode: string) =>
    nodesParams.find((item) => item.code === nodeCode);

  const nodesParamsWithoutCurrent = (nodeCode: string) =>
    nodesParams.filter((item) => item !== currentNodeParams(nodeCode));

  const form = useForm({
    initialValues: {
      product: currentNodeParams('product')?.name ?? '',
      region: currentNodeParams('region')?.name ?? '',
      Torgovaia_marka: currentNodeParams('Torgovaia_marka')?.name ?? '',
      Klass: currentNodeParams('Klass')?.name ?? '',
      Brendoobladatel: currentNodeParams('Brendoobladatel')?.name ?? '',
      Gruppa_tovarov: currentNodeParams('Gruppa_tovarov')?.name ?? '',
      Vkus: currentNodeParams('Vkus')?.name ?? '',
    },
    validate: {
      product: (value) => value.length === 0,
      region: (value) => value.length === 0,
      Torgovaia_marka: (value) => validateAdditionalNode('Torgovaia_marka', value, additionalNodes),
      Klass: (value) => validateAdditionalNode('Klass', value, additionalNodes),
      Brendoobladatel: (value) => validateAdditionalNode('Brendoobladatel', value, additionalNodes),
      Gruppa_tovarov: (value) => validateAdditionalNode('Gruppa_tovarov', value, additionalNodes),
      Vkus: (value) => validateAdditionalNode('Vkus', value, additionalNodes),
    },
  });

  const getSearchValue = (code: string) => {
    switch (code) {
      case 'region':
        return searchValueRegion;
      case 'Torgovaia_marka':
        return searchValueMark;
      case 'Klass':
        return searchValueClass;
      case 'Brendoobladatel':
        return searchValueBrand;
      case 'Gruppa_tovarov':
        return searchValueGroup;
      case 'Vkus':
        return searchValueTaste;
      default:
        return searchValueRegion;
    }
  };

  const setSearchValue = (code: string, value: string) => {
    switch (code) {
      case 'region':
        return setSearchValueRegion(value);
      case 'Torgovaia_marka':
        return setSearchValueMark(value);
      case 'Klass':
        return setSearchValueClass(value);
      case 'Brendoobladatel':
        return setSearchValueBrand(value);
      case 'Gruppa_tovarov':
        return setSearchValueGroup(value);
      case 'Vkus':
        return setSearchValueTaste(value);
      default:
        return setSearchValueRegion(value);
    }
  };

  const hideAdditionalNode = (node: IAdditionalNodeModified) => {
    dispatch(setAdditionalNodeFilter(''));
    dispatch(setAdditionalNodeCode(null));
    dispatch(setAdditionalNodeValues(null));
    dispatch(setNodesParams([...nodesParamsWithoutCurrent(node.code)]));

    if (additionalNodes) {
      return additionalNodes.map((item: IAdditionalNodeModified) => ({
        ...item,
        shown: item === node ? false : item.shown,
        checked: item === node ? false : item.checked,
      }));
    } else return null;
  };

  const setNodeCode = (code: string, count: number | null) => {
    dispatch(setAdditionalNodeCode(code));
    if (!!count) {
      dispatch(setAdditionalNodeCount(count));
    } else dispatch(setAdditionalNodeCount(null));
  };

  const modifiedTreeData = (dataFromBackend: IFilterTreeValue[]): IModifiedFilterTreeItem[] => {
    return [
      {
        key: '0-0',
        title: (
          <FilterTreeMultiSelectTitle
            item={{ id: 0, name: 'Все товары', level: 1, parent: 0 }}
            isProductsFilter
          />
        ),
        id: 0,
        children: dataFromBackend.map((item: IFilterTreeValue, index: number) => ({
          title: <FilterTreeMultiSelectTitle item={item} isProductsFilter />,
          key: `0-0-${index}`,
          id: item.id,
          isLeaf: false,
        })),
      },
    ];
  };

  const onClose = () => {
    dispatch(setTreeProductFilter(''));
    openedProductNodeHandlers.close();
    if (!form.values.product) {
      setProductNodeValue(null);
    }
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setTreeProductFilter(e.target.value));
  };

  const onSelectChange = (value: string | null, code: string) => {
    form.setFieldValue(code, value ?? '');
    dispatch(
      setNodesParams([
        ...nodesParamsWithoutCurrent(code),
        {
          code: code,
          value: value ?? '',
          name: additionalNodeValues?.find((item) => item.id === value)?.name ?? '',
        },
      ]),
    );
  };

  const onExpand = async (
    keys: Key[],
    treeNode: {
      node: EventDataNode<IModifiedFilterTreeItem>;
      expanded: boolean;
      nativeEvent: MouseEvent;
    },
  ) => {
    let newTreeData = [...treeDataExample];
    const result = await dispatch(
      fetchFiltersTreeNodeAction({
        parentId: treeNode.node.id,
        query: treeProductFilter,
        ...(activeProductsFilters && { filter: activeProductsFilters }),
      }),
    );

    const modifiedFiltersTreeData = result.payload.items
      ? result.payload.items.map((item: IFilterTreeValue) => ({
          title: <FilterTreeMultiSelectTitle item={item} isProductsFilter />,
          id: item.id,
        }))
      : [];

    getNewTreeData(
      newTreeData,
      treeNode.node.key,
      generateTreeNodes(treeNode, modifiedFiltersTreeData),
    );
    setTreeDataExample(newTreeData);
  };

  const onExpandFiltered = async (
    keys: Key[],
    treeNode: {
      node: EventDataNode<IModifiedFilterTreeItem>;
      expanded: boolean;
      nativeEvent: MouseEvent;
    },
  ) => {
    let newTreeDataFiltered = [...treeDataExampleFiltered];

    const result = await dispatch(
      fetchFiltersTreeNodeAction({
        parentId: treeNode.node.id,
        query: treeProductFilter,
        ...(activeProductsFilters && { filter: activeProductsFilters }),
      }),
    );

    const modifiedFiltersTreeData = result.payload.items
      ? result.payload.items.map((item: IFilterTreeValue) => ({
          title: <FilterTreeMultiSelectTitle item={item} isProductsFilter />,
          id: item.id,
        }))
      : [];

    getNewTreeData(
      newTreeDataFiltered,
      treeNode.node.key,
      generateTreeNodes(treeNode, modifiedFiltersTreeData),
    );
    setTreeDataExampleFiltered(newTreeDataFiltered);
  };

  const onSelect = (
    selectedKeys: Key[],
    info: {
      node: EventDataNode<IModifiedFilterTreeItem>;
    },
  ) => {
    if (!!selectedKeys.length) {
      setProductNodeValue(info.node.title.props.item);
    } else {
      setProductNodeValue(null);
    }
  };

  const resetProductFilters = () => {
    openedProductNodeHandlers.close();
    dispatch(setActiveProductsFilters([]));
    dispatch(setTreeProductFilter(''));
    setProductNodeValue(null);
    dispatch(setNodesParams([...nodesParamsWithoutCurrent('product')]));
    form.setFieldValue('product', '');
  };

  const setProduct = () => {
    openedProductNodeHandlers.close();
    form.setFieldValue('product', productNodeValue?.name ?? '');
    dispatch(
      setNodesParams([
        ...nodesParamsWithoutCurrent('product'),
        {
          code: 'product',
          name: productNodeValue?.name ?? '',
          level: productNodeValue?.level,
          value: productNodeValue?.id.toString() ?? '',
        },
      ]),
    );
  };

  const handleSubmit = () => {
    dispatch(fetchRulesListAction(modifiedNodeParams(nodesParams)));
    dispatch(fetchCompetitorAggregationsAction());
    dispatch(setStepper(1));
  };

  useEffect(() => {
    if (treeProductFilter.length > 0) {
      dispatch(
        fetchTreeProductFilteredAction({
          query: treeProductFilter,
          filter: activeProductsFilters,
        }),
      );
    } else {
      dispatch(fetchTreeProductAction(activeProductsFilters));
    }
  }, [treeProductFilter, activeProductsFilters]);

  useEffect(() => {
    if (openedProductNode && treeProductData) {
      if (treeProductFilter.length === 0) {
        setTreeDataExample(modifiedTreeData(treeProductData.items.slice(1)));
      }
    }
  }, [openedProductNode, treeProductData, treeProductFilter, activeProductsFilters]);

  useEffect(() => {
    if (openedProductNode) {
      if (treeProductFilter.length > 0 && treeProductDataFiltered) {
        setTreeDataExampleFiltered(modifiedTreeData(treeProductDataFiltered.items.slice(1)));
      }
    }
  }, [openedProductNode, treeProductDataFiltered, treeProductFilter, activeProductsFilters]);

  useEffect(() => {
    if (additionalNodeCount && additionalNodeCount > 100 && additionalNodeCode) {
      if (additionalNodeFilter.length > 2) {
        dispatch(
          fetchAdditionalNodeValuesAction({
            field: additionalNodeCode,
            query: debouncedAdditionalNodeFilter,
          }),
        );
      } else {
        dispatch(setAdditionalNodeValues(null));
      }
    }
  }, [debouncedAdditionalNodeFilter, additionalNodeCode, additionalNodeCount]);

  useEffect(() => {
    if ((!additionalNodeCount || additionalNodeCount <= 100) && additionalNodeCode) {
      if (additionalNodeFilter.length > 0) {
        dispatch(
          fetchAdditionalNodeValuesAction({
            field: additionalNodeCode,
            query: debouncedAdditionalNodeFilter,
          }),
        );
      } else {
        dispatch(setAdditionalNodeValues(null));
      }
    }
  }, [additionalNodeCode, additionalNodeCount, debouncedAdditionalNodeFilter]);

  useEffect(() => {
    dispatch(fetchAdditionalNodesAction());

    if (nodesParams && stepper === 1) {
      dispatch(fetchRulesListAction(modifiedNodeParams(nodesParams)));
      dispatch(fetchCompetitorAggregationsAction());
    }
  }, []);

  useEffect(() => {
    return () => {
      dispatch(setTreeProductFilter(''));
      dispatch(setNodesParams([]));
      dispatch(setCheckedRule(null));
      dispatch(setStepper(0));

      form.reset();
    };
  }, []);

  useEffect(() => {
    setSearchValueRegion(form.getInputProps('region').value);
    setSearchValueMark(form.getInputProps('Torgovaia_marka').value);
    setSearchValueClass(form.getInputProps('Klass').value);
    setSearchValueBrand(form.getInputProps('Brendoobladatel').value);
    setSearchValueGroup(form.getInputProps('Gruppa_tovarov').value);
    setSearchValueTaste(form.getInputProps('Vkus').value);
  }, [stepper]);

  return (
    <Flex direction="column" h="100%">
      {stepper === 1 && <PriceRulesSteppersHeader />}

      <Stepper
        active={stepper}
        onStepClick={(stepIndex) => {
          dispatch(setStepper(stepIndex));
          if (stepIndex === 1) handleSubmit();
        }}
        color="dark"
        size="xs"
        iconSize={24}
        classNames={{
          steps: classes.steps,
          stepIcon: classes.stepIcon,
          stepLabel: classes.stepLabel,
        }}
      >
        <Stepper.Step label="Выбор узла" completedIcon={<IconCheck size="1.1rem" />}>
          <form onSubmit={form.onSubmit(handleSubmit)}>
            <Paper withBorder p={32} mt={32} w={856}>
              <ScrollArea h={290}>
                <Title order={3}>Добавьте узлы</Title>
                <Flex className={classes.container}>
                  <PageDrawer
                    opened={openedProductNode}
                    onClose={onClose}
                    title="Выберите товар или категорию"
                    size={720}
                    classNames={{ content: classes.drawerContent, body: classes.drawerBody }}
                    trigger={
                      <TextInput
                        readOnly
                        label="Товарный узел"
                        className={classes.select}
                        placeholder="Товарный узел"
                        rightSection={<IconChevronRight size="1.1rem" color="gray" />}
                        color="dark.9"
                        required
                        onClick={openedProductNodeHandlers.open}
                        {...form.getInputProps('product')}
                      />
                    }
                  >
                    <Flex direction="column">
                      <SteppersProductNodeFilters />
                      <Text c="dimmed" size="sm" mt={-16} mb={16}>
                        *Перед применением фильтров необходимо свернуть все открытые категории, если
                        таковые имеются (при открытии окна «Товарный узел» категории по умолчанию
                        свернуты).
                      </Text>
                      <Input
                        mb={16}
                        radius={8}
                        w={250}
                        icon={<IconSearch />}
                        placeholder="Поиск по названию"
                        onChange={onChange}
                      />
                    </Flex>
                    <Provider motion={true}>
                      <StrictMode>
                        <style dangerouslySetInnerHTML={{ __html: childStyle }} />
                        {treeProductFilter.length === 0 && (
                          <Tree
                            ref={treeRef}
                            motion={motion}
                            height={800}
                            itemHeight={40}
                            className={classes.tree}
                            showLine
                            selectable
                            defaultExpandedKeys={['0-0']}
                            treeData={treeDataExample}
                            onSelect={onSelect}
                            onExpand={onExpand}
                            showIcon={false}
                            switcherIcon={(obj) => {
                              if (!obj.isLeaf) {
                                return obj.expanded ? <IconChevronDown /> : <IconChevronRight />;
                              } else return null;
                            }}
                          />
                        )}
                        {treeProductFilter.length > 0 && (
                          <Tree
                            ref={treeRef}
                            motion={motion}
                            height={800}
                            itemHeight={40}
                            className={classes.tree}
                            showLine
                            selectable
                            treeData={treeDataExampleFiltered}
                            onSelect={onSelect}
                            onExpand={onExpandFiltered}
                            showIcon={false}
                            switcherIcon={(obj) => {
                              if (!obj.isLeaf) {
                                return obj.expanded ? <IconChevronDown /> : <IconChevronRight />;
                              } else return null;
                            }}
                          />
                        )}
                      </StrictMode>
                    </Provider>
                    <Box
                      sx={{
                        position: 'sticky',
                        bottom: '0px',
                        paddingBottom: '16px',
                        backgroundColor: 'white',
                        zIndex: 100,
                      }}
                    >
                      <Divider
                        sx={(theme) => ({
                          margin: '16px -16px',
                          borderTopColor: theme.colors.gray[2],
                        })}
                      />
                      <Flex direction="row" gap="8px">
                        <Button fullWidth color="gray.2" onClick={resetProductFilters}>
                          Отменить
                        </Button>
                        <Button
                          fullWidth
                          color="dark.9"
                          onClick={setProduct}
                          disabled={!productNodeValue}
                        >
                          Применить
                        </Button>
                      </Flex>
                    </Box>
                  </PageDrawer>
                </Flex>
                <Flex className={classes.container}>
                  <Select
                    label="Регион"
                    placeholder="Регион"
                    color="dark.9"
                    className={classes.select}
                    searchable
                    clearable
                    required
                    maxDropdownHeight={160}
                    onDropdownOpen={() => setNodeCode('region', null)}
                    data={
                      additionalNodeValues
                        ? additionalNodeValues.map((item) => ({ label: item.name, value: item.id }))
                        : []
                    }
                    {...form.getInputProps('region')}
                    searchValue={getSearchValue('region')}
                    onChange={(value) => onSelectChange(value, 'region')}
                    onSearchChange={(value) => {
                      dispatch(setAdditionalNodeFilter(value));
                      setSearchValue('region', value);
                    }}
                    onClick={() => {
                      if (form.getInputProps('region').value)
                        dispatch(setAdditionalNodeFilter(form.getInputProps('region').value));
                    }}
                  />
                </Flex>

                {additionalNodes &&
                  additionalNodes.map(
                    (node) =>
                      node.shown && (
                        <Flex className={classes.container} key={node.code}>
                          <Select
                            label={node.name}
                            placeholder={node.name}
                            color="dark.9"
                            className={classes.select}
                            searchable
                            clearable
                            required
                            maxDropdownHeight={160}
                            onDropdownOpen={() => setNodeCode(node.code, node.count)}
                            data={
                              additionalNodeValues
                                ? additionalNodeValues.map((item) => ({
                                    label: item.name,
                                    value: item.id,
                                  }))
                                : []
                            }
                            {...form.getInputProps(node.code)}
                            searchValue={getSearchValue(node.code)}
                            onChange={(value) => onSelectChange(value, node.code)}
                            onSearchChange={(value) => {
                              dispatch(setAdditionalNodeFilter(value));
                              setSearchValue(node.code, value);
                            }}
                            onClick={() => {
                              if (form.getInputProps(node.code).value)
                                dispatch(
                                  setAdditionalNodeFilter(form.getInputProps(node.code).value),
                                );
                            }}
                          />
                          <ActionIcon
                            color="dark"
                            size="lg"
                            variant="transparent"
                            className={classes.actionIcon}
                            onClick={() => {
                              dispatch(setAdditionalNodes(hideAdditionalNode(node)));
                              form.setFieldValue(node.code, '');
                            }}
                          >
                            <IconTrash />
                          </ActionIcon>
                        </Flex>
                      ),
                  )}

                <PriceRulesSteppersAdditionalNodesPageDrawer />
              </ScrollArea>
            </Paper>
            <Button mt={24} color="dark.9" type="submit" disabled={!form.isValid()}>
              Продолжить
            </Button>
          </form>
        </Stepper.Step>

        <Stepper.Step
          label="Работа с правилами"
          completedIcon={<IconCheck size="1.1rem" />}
          allowStepSelect={form.isValid()}
        >
          {fetchingRulesList && (
            <Flex justify="center">
              <Loader mt={10} color="black" size="lg" />
            </Flex>
          )}
          {rulesIsEmpty && !fetchingRulesList && <PriceRulesSteppersEmptyData />}
          {!rulesIsEmpty && !fetchingRulesList && <PriceRulesSteppersRulesForms />}
        </Stepper.Step>
      </Stepper>
    </Flex>
  );
};

export default CreatePriceRulesSteppers;
