import { IRuleBlock } from '@/types/rules/rules';

import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Divider, Flex } from '@mantine/core';
import {
  IconArrowsSort,
  IconBrandDcos,
  IconBriefcase,
  IconEqual,
  IconLock,
  IconLockPlus,
  IconPlus,
  IconTrendingDown,
  IconTrendingUp,
} from '@tabler/icons-react';
import { IconArrowsHorizontal } from '@tabler/icons-react';

import { getCheckedRuleBlocks, getShownRuleBlocks } from '@/utils/rules';

import PageDrawer from '@/ui/organisms/DrawerExt/DrawerExt';
import PageDrawerButton from '@/ui/organisms/DrawerExt/organisms/DrawerExtButton/DrawerExtButton';

import { useStyles } from '@/assets/styles/priceRulesSteppers';
import { AppDispatch } from '@/store';
import {
  selectRuleBlocks,
  selectRulesPageDrawerOpened,
  setRuleBlocks,
  setRulesPageDrawerOpened,
} from '@/store/slices/rules/rules';

const PriceRulesSteppersRulesPageDrawer: FC = () => {
  const { classes } = useStyles();

  const dispatch: AppDispatch = useDispatch();

  const rulesPageDrawerOpened = useSelector(selectRulesPageDrawerOpened);
  const ruleBlocks = useSelector(selectRuleBlocks);

  const addRules = () => {
    dispatch(setRuleBlocks(getShownRuleBlocks(ruleBlocks)));
  };

  const getPageDrawerButtonIcon = (ruleBlock: IRuleBlock) => {
    switch (ruleBlock.type) {
      case 'fixPrice':
        return <IconLock size={40} />;
      case 'minMaxPrice':
        return <IconArrowsHorizontal size={40} />;
      case 'maxPriceChange':
        return <IconTrendingUp size={40} />;
      case 'fixMargin':
        return <IconLockPlus size={40} />;
      case 'minPriceChange':
        return <IconTrendingDown size={40} />;
      case 'minMaxMarginSoft':
        return <IconArrowsSort size={40} />;
      case 'minMaxMarginHard':
        return <IconArrowsSort size={40} />;
      case 'competitor':
        return <IconBriefcase size={40} />;
      case 'samePrice':
        return <IconEqual size={40} />;
      default:
        return <IconBrandDcos size={40} />;
    }
  };

  return (
    <PageDrawer
      opened={rulesPageDrawerOpened}
      onClose={() => dispatch(setRulesPageDrawerOpened(false))}
      title="Добавить правило"
      description="Выберите правила для добавления"
      size={560}
      classNames={{ body: classes.drawerBody }}
      trigger={
        <Button
          color="gray.2"
          variant="filled"
          size="md"
          leftIcon={<IconPlus size="1.1rem" />}
          mt={24}
          mb={24}
          onClick={() => dispatch(setRulesPageDrawerOpened(true))}
        >
          Добавить правило
        </Button>
      }
    >
      <Flex direction="column" gap={16} sx={{ flex: 1 }}>
        {ruleBlocks.map((ruleBlock) => (
          <PageDrawerButton
            isRule
            key={ruleBlock.type}
            checked={ruleBlock.checked}
            onClick={() => {
              dispatch(setRuleBlocks(getCheckedRuleBlocks(ruleBlock, ruleBlocks)));
            }}
            title={ruleBlock.name}
            icon={getPageDrawerButtonIcon(ruleBlock)}
            isActive={ruleBlock.active}
            isShown={ruleBlock.shown}
          />
        ))}
      </Flex>

      <Box
        sx={{
          position: 'sticky',
          bottom: '0px',
          paddingBottom: '16px',
          backgroundColor: 'white',
          zIndex: 100,
        }}
      >
        <Divider
          sx={(theme) => ({
            margin: '16px -16px',
            borderTopColor: theme.colors.gray[2],
          })}
        />
        <Flex direction="row" gap="8px">
          <Button
            fullWidth
            color="gray.2"
            onClick={() => {
              dispatch(setRulesPageDrawerOpened(false));
            }}
          >
            Отменить
          </Button>
          <Button
            fullWidth
            color="dark.9"
            onClick={() => {
              addRules();
              dispatch(setRulesPageDrawerOpened(false));
            }}
          >
            Добавить
          </Button>
        </Flex>
      </Box>
    </PageDrawer>
  );
};

export default PriceRulesSteppersRulesPageDrawer;
