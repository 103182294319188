import { TokenData } from '@/types/auth/auth';

import { apiPostAuthByRefresh } from '@/api/auth/auth';

import { getCookie, setCookie } from '@/utils/cookie';

import { REFRESH_CHECK_TIMEOUT } from '@/constants/token';

export const saveToken = (token: TokenData) => {
  setCookie(
    'token',
    JSON.stringify({
      ...token,
      expiresAt: token.expiresAt * 1000,
      refreshExpiresAt: token.refreshExpiresAt * 1000,
    }),
    { path: '/' },
  );
};

export const tokenGetAndRefresh = async (): Promise<any | null> => {
  if (getCookie('token')) {
    const now = Date.now();
    const parsedToken = getCookie('token') as string;
    const tokenInfo = JSON.parse(parsedToken);

    if (
      tokenInfo.expiresAt &&
      now < tokenInfo.expiresAt &&
      now + REFRESH_CHECK_TIMEOUT < tokenInfo.expiresAt
    ) {
      return JSON.parse(parsedToken);
    }

    if (tokenInfo.refreshExpiresAt && now < tokenInfo.refreshExpiresAt) {
      const dataToSend = {
        refreshToken: tokenInfo.refreshToken,
      };
      const { data: refreshedToken } = await apiPostAuthByRefresh(dataToSend);

      saveToken(refreshedToken);

      return refreshedToken.token;
    }
  }
};
