import { IFilter, IFilterFilters, IFilterValue } from '@/types/filters/filters';
import {
  IGetPlanPairs,
  IPlanAddPairs,
  IPlanChangeActive,
  IPlanData,
  IPlanDeletePairs,
  IPlanEditData,
  IPlansChangeStatus,
  IPlansCommentParams,
  PlanDetailExport,
  PlanDetailStatusChange,
  TGetPlanDetailData,
  TGetPlansData,
  TGetPlansParams,
} from '@/types/plans/plans';
import { IGetProductsFilterValues } from '@/types/products/products';

import qs from 'qs';

import api from '@/api/instance';
import { URLS } from '@/api/urls';

export const apiGetPlansFilters = () =>
  api.get<IFilterFilters<IFilter<IFilterValue>>>(URLS.plans.getPlansFilters);

export const apiPostPlans = ({
  sort,
  order,
  offset,
  limit,
  filter,
  query,
}: TGetPlansParams & TGetPlansData) =>
  api.post(
    URLS.plans.getPlans,
    { filter, query },
    {
      params: { sort, order, offset, limit },
      paramsSerializer: (rawParams) => qs.stringify(rawParams),
    },
  );

export const apiPostProcessPlan = (id: string[]) => api.post(URLS.plans.processPlans, { id });

export const apiPostClonePlan = (id: string) => api.post(URLS.plans.clonePlan(id));

export const apiPostCommentPlan = ({ id, comment, isMultiple }: IPlansCommentParams) =>
  api.post(URLS.plans.commentPlan, { id, comment, isMultiple });

export const apiPutChangePlanStatus = ({ id, value }: IPlanChangeActive) =>
  api.put(URLS.plans.changePlanActive(id), null, { params: { value: value } });

export const apiPostChangePlansStatus = ({ id, status }: IPlansChangeStatus) =>
  api.post(URLS.plans.changePlansStatus, { id: id, status: status });

export const apiPostCreatePlan = (data: IPlanData) => api.post(URLS.plans.createPlan, data);

export const apiPutEditPlan = ({ id, data }: IPlanEditData) =>
  api.put(URLS.plans.editPlan(id), data);

export const apiPostAddPairs = ({ id, geo, prod }: IPlanAddPairs) =>
  api.post(URLS.plans.addPlanPairs(id), { geo, prod });

export const apiGetPairs = ({ id, parentProduct, parentGeo }: IGetPlanPairs) => {
  return api.get(URLS.plans.getPlanPairs(id), {
    params: { parentProduct: parentProduct, parentGeo: parentGeo },
  });
};

export const apiDeletePairs = ({ id, data }: IPlanDeletePairs) =>
  api.delete(URLS.plans.deletePlanPairs(id), { data });

export const apiPutUndraftPlan = (id: string) => api.put(URLS.plans.undraftPlan(id));

export const apiGetPlanDetails = (id: string) => api.get(URLS.plans.getPlanDetail(id));

export const apiGetPlanDetailFilters = () =>
  api.get<IFilterFilters<IFilter<IFilterValue>>>(URLS.plans.getPlanDetailFilters);

export const apiGetPlanDetailFilterSearch = (params: IGetProductsFilterValues) =>
  api.get<IFilterValue[]>(URLS.plans.getPlanDetailFilterSearch, { params: params });

export const apiPostPlanDetailData = ({
  id,
  sort,
  order,
  offset,
  limit,
  filter,
}: TGetPlanDetailData) =>
  api.post(
    URLS.plans.getPlanDetailData(id),
    { filter },
    { params: { sort, order, offset, limit } },
  );

export const apiPostPlanDetailComment = ({ id, comment, isMultiple }: IPlansCommentParams) =>
  api.post(URLS.plans.planDetailComment, { id, comment, isMultiple });

export const apiGetPlanPairProcessInfo = (id: string) =>
  api.get(URLS.plans.planDetailProcessInfo(id));

export const apiGetPlanPriceInfo = (id: string) => api.get(URLS.plans.planDetailPriceInfo(id));

export const apiPutPlanItemLockPrice = ({ id, value }: { id: string; value: number }) =>
  api.put(URLS.plans.planDetailLockPrice(id), null, { params: { price: value } });

export const apiPostPlanDetailStatusChange = ({ id, values, status }: PlanDetailStatusChange) =>
  api.post(URLS.plans.planDetailStatusChange(id), { id: values }, { params: { status: status } });

export const apiPostPlanDetailProcessPairs = ({
  id,
  values,
}: Omit<PlanDetailStatusChange, 'status'>) =>
  api.post(URLS.plans.planDetailProcessPairs(id), { id: values });

export const apiPostExportPlans = ({
  sort,
  order,
  filter,
  query,
}: Partial<TGetPlansParams> & TGetPlansData) =>
  api.post(URLS.plans.export, { filter, query }, { params: { sort, order } });

export const apiPostExportPlanDetail = ({
  id,
  sort,
  order,
  filter,
}: PlanDetailExport & TGetPlansData) =>
  api.post(URLS.plans.planDetailExport(id), { filter }, { params: { sort, order } });
