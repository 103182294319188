import { FC } from 'react';
import { Flex } from '@mantine/core';

import { EditPlanPageInfoTitle } from '@/ui/pages/EditPlanPage/organisms/EditPlanPageInfo/organisms/EditPlanPageInfoTitle/EditPlanPageInfoTitle';

export const EditPlanPageInfo: FC = () => {
  return (
    <Flex gap={8} direction="column">
      <EditPlanPageInfoTitle />
    </Flex>
  );
};
