import { EPlanChangeItemValues, EPlanStatus, EPlanValidationStatus } from '@/types/plans/plans';

import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Button, Checkbox, Flex, Text } from '@mantine/core';
import { IconMessage } from '@tabler/icons-react';

import { getPositionsDescription } from '@/utils/monitorings';
import notify from '@/utils/notify';

import { notifyMessages } from '@/constants/notify';

import PageFooter from '@/ui/organisms/PageFooter/PageFooter';
import SelectPlanItemsStatus from '@/ui/organisms/SelectPlanItemsStatus/SelectPlanItemsStatus';

import { AppDispatch } from '@/store';
import {
  fetchPlanDetailChangeStatusAction,
  fetchPostPlanDetailProcessPairsAction,
  selectPlanDetailCheckedItems,
  selectPlanDetailRefetch,
  selectPlanDetails,
  setEditPlanCommentModalOpened,
  setPlanDetailRefetch,
} from '@/store/slices/plans/plans';

const EditPlanPageFooter: FC = () => {
  const dispatch: AppDispatch = useDispatch();

  const { id } = useParams();

  const planDetails = useSelector(selectPlanDetails);
  const detailCheckedPlans = useSelector(selectPlanDetailCheckedItems);
  const planDetailRefetch = useSelector(selectPlanDetailRefetch);

  const detailCheckedPlansIds = detailCheckedPlans.map((plan) => plan.id);
  const canChangeStatus =
    planDetails?.status === EPlanStatus.ready &&
    planDetails.validationStatus === EPlanValidationStatus.processed;

  const allowProcessPlanItems =
    planDetails?.status === EPlanStatus.ready &&
    planDetails.validationStatus === EPlanValidationStatus.processed &&
    detailCheckedPlans.every((plan) => plan.status === EPlanChangeItemValues.waiting);

  const allowProcessPlanItemsWithError =
    planDetails?.status === EPlanStatus.error &&
    planDetails.validationStatus === EPlanValidationStatus.error &&
    detailCheckedPlans.every((plan) => plan.status === EPlanChangeItemValues.error);

  const handleCommentPlanItems = () => {
    dispatch(setEditPlanCommentModalOpened(true));
  };

  const handlePlanItemStatus = async (value: EPlanChangeItemValues) => {
    if (!canChangeStatus) {
      notify({
        message: notifyMessages.NOT_PROCESSED_PLAN_DETAIL,
        type: 'warning',
      });
      return;
    }

    if (id)
      await dispatch(
        fetchPlanDetailChangeStatusAction({
          id: id,
          values: detailCheckedPlansIds,
          status: value,
        }),
      );
    dispatch(setPlanDetailRefetch(!planDetailRefetch));
  };

  const handleProcessPlansItems = async () => {
    if (id) {
      await dispatch(
        fetchPostPlanDetailProcessPairsAction({
          id: id,
          values: detailCheckedPlansIds,
        }),
      );
      dispatch(setPlanDetailRefetch(!planDetailRefetch));
    }
  };

  return (
    <PageFooter>
      <Flex justify="space-between" align="center">
        <Flex align="center">
          <Checkbox m={8} color="dark" radius="sm" size="sm" readOnly checked></Checkbox>
          <Text size="sm">{getPositionsDescription(detailCheckedPlans.length)}</Text>
        </Flex>
        <Flex align="center" gap={16}>
          <Button leftIcon={<IconMessage />} color="gray.2" onClick={handleCommentPlanItems}>
            Комментировать
          </Button>
          <SelectPlanItemsStatus
            onChange={handlePlanItemStatus}
            placeholder="Изменить статус элементов"
            width="250px"
          />
          <Button
            color="dark.9"
            onClick={handleProcessPlansItems}
            disabled={!allowProcessPlanItems && !allowProcessPlanItemsWithError}
          >
            Рассчитать
          </Button>
        </Flex>
      </Flex>
    </PageFooter>
  );
};

export default EditPlanPageFooter;
