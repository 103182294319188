export const paths = {
  PATH_MAIN: '/',
  PATH_AUTH: '/auth',
  PATH_PRICE_RULES: '/price-rules',
  PATH_CREATE_PRICE_RULES: '/price-rules/create-price-rules',
  PATH_PRICE_PLANS: '/pricing-plans',
  PATH_CREATE_PRICE_PLAN: '/pricing-plans/create-price-plan',
  PATH_EDIT_PRICE_PLAN: '/pricing-plans/:id',
  PATH_MONITORING: '/monitoring',
  PATH_PRICE_QUEUE: '/prices',
  PATH_NOTIFICATIONS: '/notifications',
  PATH_MATCHER: process.env.MATCHER_URL ?? '',
  PATH_PRICE_PLANS_INSTRUCTION: '/pricing-plans/instructions',
  PATH_PRICE_RULES_INSTRUCTION: '/price-rules/instructions',
  PATH_MONITORING_INSTRUCTION: '/monitoring/instructions',
  PATH_PRICE_QUEUE_INSTRUCTION: '/prices/instructions',
  PATH_REPORTS: process.env.REPORTS_URL ?? '',
};

export const titles = {
  TITLE_MAIN: 'Главная',
  TITLE_PRICE_RULES: 'Ценовые правила',
  TITLE_CREATE_PRICE_RULES: 'Работа с правилами',
  TITLE_PRICE_PLANS: 'Ценовые анализы',
  TITLE_MONITORING: 'Проверка мониторингов',
  TITLE_PRICE_QUEUE: 'Oчередь цен',
  TITLE_NOTIFICATIONS: 'Уведомления',
  TITLE_MATCHER: 'Матчер',
  TITLE_REPORTS: 'Отчёты',
  TITLE_CREATE_PRICE_PLANS: 'Работа с ценовым анализом',
};
