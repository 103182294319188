import { IItems } from '@/types/common/common';
import { EFilterKey } from '@/types/filters/filters';
import { IPriceItem } from '@/types/prices/prices';

import { FC, UIEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Flex, Loader } from '@mantine/core';
import { useInfiniteQuery } from '@tanstack/react-query';
import {
  MRT_SortingState,
  MRT_TableContainer,
  MRT_Virtualizer,
  useMantineReactTable,
} from 'mantine-react-table';
import { MRT_Localization_RU } from 'mantine-react-table/locales/ru';

import { getPricesSort } from '@/utils/prices';
import { getOrder } from '@/utils/rules';

import { fetchOptions } from '@/constants/fetch';
import { useTableState } from '@/hooks/useTableState';

import { AppDispatch } from '@/store';
import { resetFilters, selectActiveFilters } from '@/store/slices/filters/filters';
import { fetchPricesListAction } from '@/store/slices/prices/prices';

export const PricePageTable: FC = () => {
  const dispatch: AppDispatch = useDispatch();

  const { pricesColumns } = useTableState();

  const [sorting, setSorting] = useState<MRT_SortingState>([]);

  const activeFilters = useSelector(selectActiveFilters).prices;

  const tableContainerRef = useRef<HTMLDivElement>(null);
  const rowVirtualizerInstanceRef =
    useRef<MRT_Virtualizer<HTMLDivElement, HTMLTableRowElement>>(null);

  const getInfiniteData = async ({ pageParam = 0 }) => {
    const result = await dispatch(
      fetchPricesListAction({
        sort: getPricesSort(sorting),
        order: getOrder(sorting),
        offset: pageParam * fetchOptions.FETCH_PRICES_LIMIT,
        limit: fetchOptions.FETCH_PRICES_LIMIT,
        filter: activeFilters,
      }),
    );
    return result.payload;
  };

  const { data, fetchNextPage, isLoading, isFetching } = useInfiniteQuery<IItems<IPriceItem>>({
    queryKey: ['getPricesList', sorting, activeFilters],
    queryFn: getInfiniteData,
    getNextPageParam: (_lastGroup, groups) => groups.length,
  });

  const flatData = useMemo(
    () => (data?.pages[0]?.items ? data?.pages.flatMap((page) => page?.items) : []),
    [data],
  );

  const totalRowsCount = data?.pages?.[0]?.total ?? 0;
  const totalFetched = flatData.length;

  const fetchMoreOnBottomReached = useCallback(
    (containerRefElement?: HTMLDivElement | null) => {
      if (containerRefElement) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
        if (
          scrollHeight - scrollTop - clientHeight < 646 &&
          !isFetching &&
          totalFetched < totalRowsCount
        ) {
          fetchNextPage();
        }
      }
    },
    [fetchNextPage, isFetching, totalFetched],
  );

  const pricesTable = useMantineReactTable({
    columns: pricesColumns,
    data: flatData,
    enableColumnResizing: true,
    enableStickyHeader: true,
    enableRowSelection: false,
    enableGrouping: true,
    enableColumnOrdering: true,
    initialState: {
      density: 'xs',
      showColumnFilters: false,
      showGlobalFilter: false,
    },
    enableColumnFilters: false,
    enablePagination: false,
    enableColumnActions: false,
    enableRowActions: false,
    mantineTableProps: {
      withColumnBorders: true,
      withBorder: true,
      highlightOnHover: false,
      sx: {
        tableLayout: 'fixed',
      },
    },
    mantineTableBodyProps: {
      sx: {
        minHeight: 'auto',
      },
    },
    mantineTableHeadRowProps: {
      sx: {
        backgroundColor: '#E9ECEF',
        boxShadow: 'none',
        withBorder: true,
      },
    },
    mantineTableHeadProps: {
      sx: {
        opacity: 1,
      },
    },
    mantineTableHeadCellProps: {
      sx: {
        ['div[class~="mantine-TableHeadCell-Content-Labels"]']: {
          gap: '5px',
          color: 'black',
        },
      },
    },
    enableRowVirtualization: true,
    manualSorting: true,
    mantineTableContainerProps: {
      ref: tableContainerRef,
      sx: { height: 'calc(100vh - 300px)' },
      onScroll: (event: UIEvent<HTMLDivElement>) =>
        fetchMoreOnBottomReached(event.target as HTMLDivElement),
    },
    onSortingChange: setSorting,
    state: {
      isLoading,
      showProgressBars: isFetching,
      sorting,
    },
    rowVirtualizerInstanceRef,
    rowVirtualizerProps: { overscan: 10 },
    localization: MRT_Localization_RU,
  });

  useEffect(() => {
    if (rowVirtualizerInstanceRef.current) {
      try {
        rowVirtualizerInstanceRef.current.scrollToIndex(0);
      } catch (e) {
        console.error(e);
      }
    }
  }, [sorting, activeFilters]);

  useEffect(() => {
    fetchMoreOnBottomReached(tableContainerRef.current);
  }, [fetchMoreOnBottomReached]);

  useEffect(() => {
    return () => {
      dispatch(resetFilters(EFilterKey.prices));
    };
  }, []);

  return (
    <Flex direction="column">
      <MRT_TableContainer table={pricesTable} />
      {isFetching && !isLoading && (
        <Flex justify="center">
          <Flex justify="center">
            <Loader mt={10} color="black" size="lg" />
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};
