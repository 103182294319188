import { IPricesInfo } from '@/types/prices/prices';

import { createSlice } from '@reduxjs/toolkit';

import { apiGetPricesInfo, apiPostPricesList } from '@/api/prices/prices';

import { createAxiosThunk } from '@/utils/asyncRequest';

interface IQueueState {
  info: IPricesInfo | null;
}

const initialState: IQueueState = {
  info: null,
};

export const fetchPricesInfoAction = createAxiosThunk('/getPricesInfo', apiGetPricesInfo);
export const fetchPricesListAction = createAxiosThunk('/getPrices', apiPostPricesList);

export const pricesSlice = createSlice({
  name: 'prices',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPricesInfoAction.fulfilled, (state, action) => {
      state.info = action.payload;
    });
  },
});

type TSelectorState = {
  prices: IQueueState;
};

export const selectPricesInfo = (state: TSelectorState) => state.prices.info;

export default pricesSlice.reducer;
