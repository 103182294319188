import axios, { AxiosError } from 'axios';
import qs from 'qs';

import { getCookie, setCookie } from '@/utils/cookie';
import { tokenGetAndRefresh } from '@/utils/token';

export const headers = {
  'Content-Type': 'application/json',
};

const api = axios.create({
  baseURL: process.env.PUBLIC_API_URL,
  paramsSerializer: (params) => {
    return qs.stringify(params, { indices: false });
  },
});

export const objectToJson = (obj: Record<string, unknown>): string => {
  return JSON.stringify(obj, null, 0);
};

const requestMiddleware = async (config: any) => {
  const now = Date.now();
  const cookiesToken = getCookie('token') as string;
  const tokenInfo = cookiesToken ? JSON.parse(cookiesToken) : null;
  const accessToken = tokenInfo ? tokenInfo.accessToken : null;

  if (!(window.location.pathname === '/auth')) {
    if (!cookiesToken) {
      setCookie('token', '');
      window.location.assign('/auth');
    }

    if (tokenInfo && tokenInfo.refreshExpiresAt && now > tokenInfo.refreshExpiresAt) {
      setCookie('token', '');
      window.location.assign('/auth');
    } else {
      await tokenGetAndRefresh();
    }
  }

  const tokenFromHeaders = accessToken ? { Authorization: `Bearer ${accessToken}` } : undefined;

  return {
    ...config,
    ...{
      headers: {
        ...config.headers,
        ...tokenFromHeaders,
      },
    },
  };
};

export const errorMiddleware = async (err: AxiosError) => {
  if (!err.response?.status) {
    location.reload();
  }
  return Promise.reject(err);
};

api.interceptors.request.use(
  (request) => {
    return requestMiddleware(request);
  },
  (error) => {
    return Promise.reject(error);
  },
);

api.interceptors.response.use(
  (response) => {
    // Type response middleware here
    return response;
  },
  (error) => errorMiddleware(error),
);

export default api;
