import { EPlanChangeItemValues } from '@/types/plans/plans';

import { FC, useState } from 'react';
import { Select } from '@mantine/core';
import { IconChevronDown, IconChevronUp } from '@tabler/icons-react';

import { useStyles } from '@/assets/styles/selectPlanStatus';

interface SelectPlanItemsStatusProps {
  placeholder: string;
  onChange: (value: EPlanChangeItemValues) => void;
  width: string;
}

const SelectPlanItemsStatus: FC<SelectPlanItemsStatusProps> = ({
  width,
  placeholder,
  onChange,
}) => {
  const { classes } = useStyles();

  const [isOpened, setIsOpened] = useState<boolean>(false);

  const selectData = [
    { value: EPlanChangeItemValues.approved, label: 'Согласовано' },
    { value: EPlanChangeItemValues.declined, label: 'Отклонен' },
    { value: EPlanChangeItemValues.waiting, label: 'Ожидает согласования' },
  ];

  const handleChangeState = () => {
    setIsOpened(!isOpened);
  };

  return (
    <Select
      onDropdownOpen={handleChangeState}
      onDropdownClose={handleChangeState}
      w={width}
      className={classes.select}
      value={null}
      transitionProps={{
        transition: 'pop-top-left',
        duration: 80,
        timingFunction: 'ease',
      }}
      data={selectData}
      placeholder={placeholder}
      onChange={onChange}
      rightSection={!isOpened ? <IconChevronDown size="1rem" /> : <IconChevronUp size="1rem" />}
      variant="filled"
    />
  );
};

export default SelectPlanItemsStatus;
