import { IFilterTreeValue, IModifiedFilterTreeItem } from '@/types/filters/filters';
import { IGetFilterTreeParams } from '@/types/products/products';

import {
  ChangeEvent,
  FC,
  HTMLAttributes,
  Key,
  StrictMode,
  SyntheticEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Divider, Flex, Input, Popover, Text } from '@mantine/core';
import { IconChevronDown, IconChevronRight, IconSearch, IconX } from '@tabler/icons-react';
import { Provider } from 'rc-motion';
import Tree from 'rc-tree';
import { EventDataNode } from 'rc-tree/lib/interface';

import { generateTreeNodes, getNewTreeData, motion } from '@/utils/filters';

import { getIcon } from '@/ui/organisms/Filters/organisms/FilterCheckbox/FilterCheckbox';

import FilterTreeMultiSelectTitle from './organisms/FilterTreeMultiSelectTitle/FilterTreeMultiSelectTitle';

import 'rc-tree/assets/index.css';

import { childStyle, useStyles } from '@/assets/styles/treeMultiSelect';
import { AppDispatch } from '@/store';
import {
  fetchFiltersTreeNodeAction,
  selectActiveFilters,
  selectCheckedTreeFilter,
  selectTreeFilter,
  setActiveRulesFilters,
  setCheckedTreeFilters,
  setTreeFilter,
} from '@/store/slices/rules/rules';

interface FilterTreeMultiSelectProps extends HTMLAttributes<HTMLElement> {
  title: string;
  placeholder: string;
  data: IFilterTreeValue[];
  dataFiltered?: IFilterTreeValue[];
}

const FilterTreeMultiSelect: FC<FilterTreeMultiSelectProps> = ({
  title,
  placeholder,
  data,
  dataFiltered,
}) => {
  const { classes } = useStyles();
  const dispatch: AppDispatch = useDispatch();

  const treeRef = useRef<Tree<IModifiedFilterTreeItem>>(null);

  const activeFilters = useSelector(selectActiveFilters);
  const treeFilter = useSelector(selectTreeFilter);
  const checkedTreeFilter = useSelector(selectCheckedTreeFilter);

  const [opened, setOpened] = useState<boolean>(false);
  const [treeDataExample, setTreeDataExample] = useState<IModifiedFilterTreeItem[]>([]);
  const [treeDataExampleFiltered, setTreeDataExampleFiltered] = useState<IModifiedFilterTreeItem[]>(
    [],
  );
  const productActiveFilters = activeFilters.find((item) => item.paramName.includes('product'));

  const checkedIdsIsNotEmpty = () => {
    return checkedTreeFilter.length !== 0 && activeFilters.length !== 0;
  };

  const modifiedTreeData = (dataFromBackend: IFilterTreeValue[]): IModifiedFilterTreeItem[] => {
    return [
      {
        key: '0-0',
        title: (
          <FilterTreeMultiSelectTitle item={{ id: 0, name: 'Все товары', level: 1, parent: 0 }} />
        ),
        id: 0,
        children: dataFromBackend.map((item: IFilterTreeValue, index: number) => ({
          title: <FilterTreeMultiSelectTitle item={item} />,
          key: `0-0-${index}`,
          id: item.id,
          isLeaf: false,
        })),
      },
    ];
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setTreeFilter(e.target.value));
  };

  const onExpand = async (
    keys: Key[],
    treeNode: {
      node: EventDataNode<IModifiedFilterTreeItem>;
      expanded: boolean;
      nativeEvent: MouseEvent;
    },
  ) => {
    if (treeNode.expanded) {
      let newTreeData = [...treeDataExample];

      const result = await dispatch(
        fetchFiltersTreeNodeAction({ parentId: treeNode.node.id, query: treeFilter }),
      );

      const modifiedFiltersTreeData = result.payload.items
        ? result.payload.items.map((item: IFilterTreeValue) => ({
            title: <FilterTreeMultiSelectTitle item={item} />,
            id: item.id,
          }))
        : [];

      getNewTreeData(
        newTreeData,
        treeNode.node.key,
        generateTreeNodes(treeNode, modifiedFiltersTreeData),
      );
      setTreeDataExample(newTreeData);
    }
  };

  const onExpandFiltered = async (
    keys: Key[],
    treeNode: {
      node: EventDataNode<IModifiedFilterTreeItem>;
      expanded: boolean;
      nativeEvent: MouseEvent;
    },
  ) => {
    if (treeNode.expanded) {
      let newTreeDataFiltered = [...treeDataExampleFiltered];

      const result = await dispatch(
        fetchFiltersTreeNodeAction({ parentId: treeNode.node.id, query: treeFilter }),
      );

      const modifiedFiltersTreeData = result.payload.items
        ? result.payload.items.map((item: IFilterTreeValue) => ({
            title: <FilterTreeMultiSelectTitle item={item} />,
            id: item.id,
          }))
        : [];

      getNewTreeData(
        newTreeDataFiltered,
        treeNode.node.key,
        generateTreeNodes(treeNode, modifiedFiltersTreeData),
      );
      setTreeDataExampleFiltered(newTreeDataFiltered);
    }
  };

  const getTreeFilterItemsByLevel = (treeItems: IFilterTreeValue[]) => {
    const treeFilterItems: IGetFilterTreeParams[] = [];
    const treeLevels = treeItems.reduce((acc: number[], item) => {
      if (acc.includes(item.level)) {
        return acc;
      }
      return [...acc, item.level];
    }, []);

    treeLevels.forEach((level) => {
      const checkedTreeLevelItems = treeItems.filter((item) => item.level === level);
      if (!!checkedTreeLevelItems.length)
        treeFilterItems.push({
          paramName: `product_${level}`,
          paramValues: checkedTreeLevelItems.map((item) => item.id.toString()),
        });
    });

    return treeFilterItems;
  };

  const setFilters = () => {
    setOpened(false);

    if (!!checkedTreeFilter.length) {
      const filteredActiveFilters = activeFilters.filter(
        (item) => !item.paramName.includes('product'),
      );
      const treeFiltersByLevel = getTreeFilterItemsByLevel(checkedTreeFilter);

      dispatch(
        setActiveRulesFilters(
          productActiveFilters
            ? [...filteredActiveFilters, ...treeFiltersByLevel]
            : [...activeFilters, ...treeFiltersByLevel],
        ),
      );
    }
  };

  const resetFilters = (event: SyntheticEvent) => {
    if (!opened) event.stopPropagation();

    dispatch(setTreeFilter(''));
    dispatch(setCheckedTreeFilters([]));

    const otherActiveFilters = activeFilters.filter((item) => !item.paramName.includes('product'));

    if (otherActiveFilters.length !== 0) {
      dispatch(setActiveRulesFilters(otherActiveFilters));
    } else {
      dispatch(setActiveRulesFilters([]));
    }
  };

  useEffect(() => {
    if (opened) {
      if (treeFilter.length === 0) {
        setTreeDataExample(modifiedTreeData(data));
      }
    }
  }, [opened, treeFilter, data]);

  useEffect(() => {
    if (opened) {
      if (treeFilter.length > 0 && dataFiltered) {
        setTreeDataExampleFiltered(modifiedTreeData(dataFiltered));
      }
    }
  }, [opened, treeFilter, dataFiltered]);

  useEffect(() => {
    if (activeFilters.length === 0) {
      dispatch(setCheckedTreeFilters([]));
    }
  }, [activeFilters]);

  return (
    <Popover
      opened={opened}
      onChange={() => {
        setOpened(!opened);
        dispatch(setTreeFilter(''));
        if (opened) {
          dispatch(setCheckedTreeFilters([]));
        }
      }}
      width={440}
      trapFocus
      position="bottom"
      shadow="md"
    >
      <Popover.Target>
        <Button
          variant={checkedIdsIsNotEmpty() ? 'filled' : 'default'}
          sx={{
            color: checkedIdsIsNotEmpty() ? 'white' : 'black',
          }}
          color="dark"
          onClick={() => setOpened((state) => !state)}
        >
          <Flex gap={8} align="center">
            <Text>{title}</Text>

            <Flex w={14} sx={(theme) => ({ color: theme.colors.gray[5] })}>
              {checkedIdsIsNotEmpty() && checkedTreeFilter.length}
            </Flex>
            {checkedIdsIsNotEmpty() ? (
              <Flex align="center" onClick={resetFilters}>
                <IconX size="1.1rem" />
              </Flex>
            ) : (
              getIcon(opened)
            )}
          </Flex>
        </Button>
      </Popover.Target>

      <Popover.Dropdown>
        <Flex direction="column">
          <Input
            mb={8}
            radius="sm"
            icon={<IconSearch />}
            placeholder={placeholder}
            value={treeFilter}
            onChange={onChange}
          />

          <Provider motion={true}>
            <StrictMode>
              <style dangerouslySetInnerHTML={{ __html: childStyle }} />
              {treeFilter.length === 0 && (
                <Tree
                  ref={treeRef}
                  motion={motion}
                  height={300}
                  itemHeight={40}
                  className={classes.tree}
                  showLine
                  selectable={false}
                  defaultExpandedKeys={['0-0']}
                  treeData={treeDataExample}
                  onExpand={onExpand}
                  showIcon={false}
                  switcherIcon={(obj) => {
                    if (!obj.isLeaf) {
                      return obj.expanded ? <IconChevronDown /> : <IconChevronRight />;
                    } else return null;
                  }}
                />
              )}
              {treeFilter.length > 0 && (
                <Tree
                  ref={treeRef}
                  motion={motion}
                  height={300}
                  itemHeight={40}
                  className={classes.tree}
                  showLine
                  selectable={false}
                  treeData={treeDataExampleFiltered}
                  onExpand={onExpandFiltered}
                  showIcon={false}
                  switcherIcon={(obj) => {
                    if (!obj.isLeaf) {
                      return obj.expanded ? <IconChevronDown /> : <IconChevronRight />;
                    } else return null;
                  }}
                />
              )}
            </StrictMode>
          </Provider>
        </Flex>

        <Box>
          <Divider
            sx={(theme) => ({ margin: '16px -16px', borderTopColor: theme.colors.gray[2] })}
          />
          <Flex direction="row" gap="8px">
            <Button fullWidth color="gray.2" onClick={resetFilters}>
              Очистить
            </Button>
            <Button
              fullWidth
              color="dark.9"
              onClick={setFilters}
              disabled={!checkedTreeFilter.length}
            >
              Применить
            </Button>
          </Flex>
        </Box>
      </Popover.Dropdown>
    </Popover>
  );
};

export default FilterTreeMultiSelect;
