import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Flex, Modal, Text, Textarea } from '@mantine/core';
import { useForm } from '@mantine/form';

import { AppDispatch } from '@/store';
import {
  fetchPlanDetailCommentAction,
  selectEditPlanCommentModalOpened,
  selectPlanDetailCheckedItem,
  selectPlanDetailCheckedItems,
  selectPlanDetailRefetch,
  setDetailCheckedPlan,
  setDetailCheckedPlans,
  setEditPlanCommentModalOpened,
  setPlanDetailRefetch,
} from '@/store/slices/plans/plans';

const EditPlanPageTableCommentModal: FC = () => {
  const dispatch: AppDispatch = useDispatch();

  const editPlanCommentModalOpened = useSelector(selectEditPlanCommentModalOpened);
  const detailCheckedPlan = useSelector(selectPlanDetailCheckedItem);
  const detailCheckedPlans = useSelector(selectPlanDetailCheckedItems);
  const detailRefetchPlans = useSelector(selectPlanDetailRefetch);

  const checkedPlansIDs = detailCheckedPlans.map((item) => item.id);

  const form = useForm({
    initialValues: {
      comment: detailCheckedPlan?.comment || '',
    },
  });

  const resetCheckedPlans = () => {
    dispatch(setEditPlanCommentModalOpened(false));
    dispatch(setDetailCheckedPlan(null));
    dispatch(setDetailCheckedPlans([]));
    form.setFieldValue('comment', '');
  };

  const handleSubmit = async (formData: { comment: string }) => {
    if (detailCheckedPlan) {
      await dispatch(
        fetchPlanDetailCommentAction({ id: [detailCheckedPlan?.id], comment: formData.comment }),
      );
      dispatch(setPlanDetailRefetch(!detailRefetchPlans));
    } else if (detailCheckedPlans) {
      await dispatch(
        fetchPlanDetailCommentAction({
          id: checkedPlansIDs,
          comment: formData.comment,
          isMultiple: detailCheckedPlans.length > 1,
        }),
      );
      dispatch(setPlanDetailRefetch(!detailRefetchPlans));
    }
    resetCheckedPlans();
  };

  useEffect(() => {
    if (detailCheckedPlan) {
      form.setFieldValue('comment', detailCheckedPlan.comment as string);
    } else if (detailCheckedPlans.length === 1) {
      form.setFieldValue('comment', detailCheckedPlans[0].comment as string);
    } else form.setFieldValue('comment', '');

    form.resetDirty();
  }, [detailCheckedPlan, detailCheckedPlans]);

  return (
    <Modal
      size="md"
      opened={editPlanCommentModalOpened}
      onClose={resetCheckedPlans}
      title={
        <Text fz={22} weight="bold">
          Комментировать
        </Text>
      }
    >
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Textarea
          size="sm"
          color="dark.2"
          placeholder="Введите комментарий для пары"
          minRows={4}
          {...form.getInputProps('comment')}
        />
        <Flex direction="row" justify="space-between" gap={16} mt={24}>
          <Button fullWidth onClick={resetCheckedPlans} variant="filled" color="gray.2">
            Отменить
          </Button>
          <Button fullWidth type="submit" color="dark.9">
            Комментировать
          </Button>
        </Flex>
      </form>
    </Modal>
  );
};

export default EditPlanPageTableCommentModal;
