import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { paths, titles } from '@/constants/paths';

import PageHeader from '@/ui/organisms/PageHeader/PageHeader';
import { PricePlansSteppers } from '@/ui/pages/CreatePricePlansPage/organisms/PricePlansSteppers/PricePlansSteppers';
import PageLayoutBody from '@/ui/templates/PageLayout/organisms/PageLayoutBody/PageLayoutBody';
import PageLayoutHeader from '@/ui/templates/PageLayout/organisms/PageLayoutHeader/PageLayoutHeader';
import PageLayout from '@/ui/templates/PageLayout/PageLayout';

import { AppDispatch } from '@/store';
import { setExpandedRows, setPlanId, setPlanPairs, setPlansStep } from '@/store/slices/plans/plans';
import { setCheckedTreeFilters } from '@/store/slices/rules/rules';

const items = [
  { title: titles.TITLE_PRICE_PLANS, path: paths.PATH_PRICE_PLANS },
  { title: titles.TITLE_CREATE_PRICE_PLANS, path: paths.PATH_CREATE_PRICE_PLAN },
];

const CreatePricePlansPage: FC = () => {
  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(setExpandedRows([]));
      dispatch(setPlansStep(0));
      dispatch(setPlanId(null));
      dispatch(setCheckedTreeFilters([]));
      dispatch(setPlanPairs([]));
    };
  }, []);
  return (
    <PageLayout>
      <PageLayoutHeader>
        <PageHeader title={titles.TITLE_CREATE_PRICE_PLANS} items={items} />
      </PageLayoutHeader>

      <PageLayoutBody>
        <PricePlansSteppers />
      </PageLayoutBody>
    </PageLayout>
  );
};

export default CreatePricePlansPage;
