import { FC } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { AppShell, createStyles } from '@mantine/core';

import { getCookie } from '@/utils/cookie';

import LayoutHeader from './organisms/LayoutHeader/LayoutHeader';
import LayoutNavbar from './organisms/LayoutNavbar/LayoutNavbar';

const Layout: FC = () => {
  const location = useLocation();

  const isAuth = location.pathname === '/auth';

  const token = getCookie('token');

  const excludedPaths = [
    '/pricing-plans/instructions',
    '/price-rules/instructions',
    '/monitoring/instructions',
    '/prices/instructions',
  ];

  const isExcluded = excludedPaths.some((path) => location.pathname.startsWith(path));

  const useStyles = createStyles((theme) => ({
    appshell: {
      main: {
        transition: 'padding-left 0.25s ease',
        backgroundColor: isAuth ? theme.colors.gray[1] : 'none',
        display: isAuth ? 'flex' : 'block',
        justifyContent: 'center',
        alignItems: 'center',
      },
    },
  }));

  const { classes } = useStyles();

  return (
    <AppShell
      header={isExcluded ? <></> : <LayoutHeader />}
      navbar={isAuth || !token?.length || isExcluded ? <></> : <LayoutNavbar />}
      className={classes.appshell}
    >
      <Outlet />
    </AppShell>
  );
};

export default Layout;
