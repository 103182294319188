import { FC, useState } from 'react';
import { Divider, Navbar } from '@mantine/core';

import LayoutNavbarItem from './organisms/LayoutNavbarItem/LayoutNavbarItem';
import LayoutNavbarItemExternal from './organisms/LayoutNavbarItemExternal/LayoutNavbarItemExternal';
import LayoutNavbarToggle from './organisms/LayoutNavbarToggle/LayoutNavbarToggle';
import LayoutNavbarUserMenu from './organisms/LayoutNavbarUserMenu/LayoutNavbarUserMenuButton';
import { navbarItems, navbarItemsExternal } from './data';

const LayoutNavbar: FC = () => {
  const [collapse, setCollapse] = useState<boolean>(true);

  return (
    <Navbar p={8} width={{ base: collapse ? 280 : 57 }} sx={{ transition: 'width 0.25s ease' }}>
      <LayoutNavbarToggle collapse={collapse} onClick={() => setCollapse(!collapse)} />

      <Navbar.Section grow>
        {navbarItems.map((item) => (
          <LayoutNavbarItem {...item} key={item.label} />
        ))}
        <Divider sx={(theme) => ({ margin: '8px 0px', borderTopColor: theme.colors.gray[2] })} />
        {navbarItemsExternal.map((item) => (
          <LayoutNavbarItemExternal {...item} key={item.label} />
        ))}
      </Navbar.Section>

      <Navbar.Section>
        <LayoutNavbarUserMenu />
      </Navbar.Section>
    </Navbar>
  );
};

export default LayoutNavbar;
