import { FC } from 'react';
import { UnstyledButton } from '@mantine/core';
import { IconChevronsLeft, IconChevronsRight } from '@tabler/icons-react';

interface LayoutNavbarToggleProps extends React.ComponentPropsWithoutRef<'button'> {
  collapse: boolean;
}

const LayoutNavbarToggle: FC<LayoutNavbarToggleProps> = ({ collapse, ...props }) => {
  return (
    <UnstyledButton
      sx={(theme) => ({
        position: 'absolute',
        top: 8,
        right: '-12px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 24,
        height: 24,
        borderRadius: '100%',
        border: `1px solid ${theme.colors.gray[2]}`,
        color: theme.colors.dark[2],
        backgroundColor: theme.white,
        zIndex: 10,

        '&:hover': {
          backgroundColor: theme.colors.gray[0],
        },
      })}
      {...props}
    >
      {collapse ? <IconChevronsLeft size={16} /> : <IconChevronsRight size={16} />}
    </UnstyledButton>
  );
};

export default LayoutNavbarToggle;
