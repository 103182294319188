export const reportsData = [
  {
    title: 'Отчёт по фиксированным ценам',
    description:
      'В отчете отображена сводная информация по всем установленным фиксированным ценам, включая даты начала и конца.',
    url: `${process.env.REPORT_FIXPRICES_URL ?? ''}`,
  },
  {
    title: 'Отчёт по прибылям и убыткам',
    description:
      'В отчете отображена сводная информация по потенциальным прибылям и убыткам, рассчитанным исходя из мониторинга рынка и истории продаж.',
    url: `${process.env.REPORTS_PROFIT_URL ?? ''}`,
  },
  {
    title: 'Отчет по ценам',
    description:
      'В отчете отображена сводная информация по товарам: цены, цены рынка, суммы продаж.',
    url: `${process.env.REPORTS_PRICES_URL ?? ''}`,
  },
];
