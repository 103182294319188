import { EFilterKey } from '@/types/filters/filters';

import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Flex, Group, Skeleton } from '@mantine/core';
import { IconTrash } from '@tabler/icons-react';

import Filters from '@/ui/organisms/Filters/Filters';

import { AppDispatch } from '@/store';
import {
  fetchPricesFiltersAction,
  resetFilters,
  selectFetchingFilters,
} from '@/store/slices/filters/filters';

export const PricesPageFilters: FC = () => {
  const dispatch: AppDispatch = useDispatch();

  const fetchingFilters = useSelector(selectFetchingFilters);

  const clearAllFilters = () => {
    dispatch(resetFilters(EFilterKey.prices));
  };

  useEffect(() => {
    dispatch(fetchPricesFiltersAction());
  }, []);

  return (
    <Flex justify="space-between" mb={24}>
      {fetchingFilters.prices ? (
        <Skeleton height={36} radius="8px" width="50%" />
      ) : (
        <Group spacing="xs">
          <Filters filterKey={EFilterKey.prices} />
          <Button
            variant="subtle"
            sx={{ color: 'black' }}
            leftIcon={<IconTrash size="1.3rem" />}
            onClick={clearAllFilters}
          >
            Сбросить все
          </Button>
        </Group>
      )}
    </Flex>
  );
};
