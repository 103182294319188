import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Flex, Text, Textarea } from '@mantine/core';
import { useForm } from '@mantine/form';

import { AppDispatch } from '@/store';
import {
  fetchPlanDetailCommentAction,
  selectDetailPlanInfo,
  setDetailPlanInfo,
} from '@/store/slices/plans/plans';

const EditPlanPageTableDrawerCommentTab: FC = () => {
  const dispatch: AppDispatch = useDispatch();

  const editPlanInfo = useSelector(selectDetailPlanInfo);

  const form = useForm({
    initialValues: {
      comment: editPlanInfo?.comment || '',
    },
  });

  const handleSubmit = async (formData: { comment: string }) => {
    if (editPlanInfo) {
      await dispatch(
        fetchPlanDetailCommentAction({ id: [editPlanInfo?.id], comment: formData.comment }),
      );
      dispatch(setDetailPlanInfo({ ...editPlanInfo, comment: formData.comment }));
    }
  };

  return (
    <Flex direction="column" pt={38}>
      <Text fw={600}>Комментарий</Text>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Textarea
          size="sm"
          color="dark.2"
          placeholder="Введите комментарий для пары"
          minRows={4}
          {...form.getInputProps('comment')}
        />
        <Flex pt={16}>
          <Button type="submit" color="dark.9" ml="auto">
            Комментировать
          </Button>
        </Flex>
      </form>
    </Flex>
  );
};

export default EditPlanPageTableDrawerCommentTab;
