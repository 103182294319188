import { IModifiedFilterItem } from '@/types/filters/filters';

import { FC, HTMLAttributes, SyntheticEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Button,
  Checkbox,
  createStyles,
  Divider,
  Flex,
  Input,
  Popover,
  ScrollArea,
  Text,
} from '@mantine/core';
import { useListState } from '@mantine/hooks';
import { IconSearch, IconX } from '@tabler/icons-react';

import { getIcon } from '@/ui/organisms/Filters/organisms/FilterCheckbox/FilterCheckbox';

import { AppDispatch } from '@/store';
import {
  selectActiveProductsFilters,
  selectProductsFilterFilter,
  setActiveProductsFilters,
  setProductsFilterCode,
  setProductsFilterFilter,
} from '@/store/slices/products/products';
import { selectActiveFilters, setActiveRulesFilters } from '@/store/slices/rules/rules';

interface FilterMultiSelectProps extends HTMLAttributes<HTMLElement> {
  title: string;
  placeholder: string;
  name: string;
  data: IModifiedFilterItem[];
  isProductsFilter?: boolean;
}

const useStyles = createStyles((theme) => ({
  select: {
    flexDirection: 'row',
    alignItems: 'center',
    height: '40px',
    borderRadius: '4px',

    '&:hover': {
      backgroundColor: theme.colors.gray[1],
    },
  },
  checkboxBody: {
    display: 'flex',
    alignItems: 'center',
  },
  text: {
    color: theme.colors.gray[5],
  },
}));

const FilterMultiSelect: FC<FilterMultiSelectProps> = ({
  title,
  placeholder,
  data,
  name,
  isProductsFilter,
}) => {
  const dispatch: AppDispatch = useDispatch();

  const activeFilters = useSelector(selectActiveFilters);
  const activeProductsFilters = useSelector(selectActiveProductsFilters);
  const productsFilterFilter = useSelector(selectProductsFilterFilter);

  const { classes } = useStyles();

  const [items, setItems] = useListState<IModifiedFilterItem>(data);
  const [opened, setOpened] = useState<boolean>(false);

  const allChecked = items.every((item) => item.checked);
  const indeterminate = items.some((item) => item.checked) && !allChecked;
  const checkedValues = items.filter((item) => item.checked).map((item) => item.id);

  const currentActiveFilter = activeFilters.find((item) => item.paramName === name);
  const currentProductActiveFilter = activeProductsFilters.find((item) => item.paramName === name);

  const activeFiltersCount = currentActiveFilter?.paramValues.length;
  const activeProductFiltersCount = currentProductActiveFilter?.paramValues.length;

  const isActiveFilter = () => {
    return activeFiltersCount && activeFilters.length !== 0;
  };
  const isActiveProductsFilter = () => {
    return activeProductFiltersCount && activeProductsFilters.length !== 0;
  };

  const resetCheckedItems = () => {
    if (activeFiltersCount) return;
    if (allChecked) {
      setItems.setState((item) => item.map((value) => ({ ...value, checked: !allChecked })));
    } else {
      setItems.setState((item) => item.map((value) => ({ ...value, checked: allChecked })));
    }
  };

  const resetFilters = (event: SyntheticEvent) => {
    if (!opened) event.stopPropagation();

    dispatch(setProductsFilterFilter(''));
    dispatch(setProductsFilterCode(null));
    resetCheckedItems();

    const otherActiveFilters = isProductsFilter
      ? activeProductsFilters.filter((item) => item.paramName !== name)
      : activeFilters.filter((item) => item.paramName !== name);

    if (isProductsFilter) {
      if (otherActiveFilters.length !== 0) {
        dispatch(setActiveProductsFilters(otherActiveFilters));
      } else {
        dispatch(setActiveProductsFilters([]));
      }
    } else {
      if (otherActiveFilters.length !== 0) {
        dispatch(setActiveRulesFilters(otherActiveFilters));
      } else {
        dispatch(setActiveRulesFilters([]));
      }
    }
  };

  const setFilters = () => {
    setOpened(false);

    if (isProductsFilter) {
      if (currentProductActiveFilter) {
        const filteredActiveProductsFilters = activeProductsFilters.filter(
          (item) => item.paramName !== name,
        );

        dispatch(
          setActiveProductsFilters([
            ...filteredActiveProductsFilters,
            { paramName: name, paramValues: checkedValues },
          ]),
        );
      } else
        dispatch(
          setActiveProductsFilters([
            ...activeProductsFilters,
            { paramName: name, paramValues: checkedValues },
          ]),
        );
    } else {
      if (currentActiveFilter) {
        const filteredActiveFilters = activeFilters.filter((item) => item.paramName !== name);

        dispatch(
          setActiveRulesFilters([
            ...filteredActiveFilters,
            { paramName: name, paramValues: checkedValues },
          ]),
        );
      } else
        dispatch(
          setActiveRulesFilters([
            ...activeFilters,
            { paramName: name, paramValues: checkedValues },
          ]),
        );
    }
  };

  useEffect(() => {
    if (activeFilters.length === 0) {
      dispatch(setProductsFilterFilter(''));
      resetCheckedItems();
    }
  }, [activeFilters]);

  useEffect(() => {
    if (activeProductsFilters.length === 0) {
      dispatch(setProductsFilterFilter(''));
      resetCheckedItems();
    }
  }, [activeProductsFilters]);

  useEffect(() => {
    if (isProductsFilter) {
      const chechkedData = data.map((item) => ({
        ...item,
        checked: currentProductActiveFilter
          ? currentProductActiveFilter.paramValues.includes(item.id)
          : item.checked,
      }));
      setItems.setState(chechkedData);
    } else {
      const chechkedData = data.map((item) => ({
        ...item,
        checked: currentActiveFilter
          ? currentActiveFilter.paramValues.includes(item.id)
          : item.checked,
      }));
      setItems.setState(chechkedData);
    }
  }, [data]);

  return (
    <Popover
      opened={opened}
      onChange={setOpened}
      onOpen={() => {
        dispatch(setProductsFilterCode(name));
      }}
      onClose={() => {
        dispatch(setProductsFilterCode(null));
        dispatch(setProductsFilterFilter(''));
        resetCheckedItems();
      }}
      width={300}
      trapFocus
      position="bottom"
      shadow="md"
    >
      <Popover.Target>
        <Button
          variant={isActiveFilter() || isActiveProductsFilter() ? 'filled' : 'default'}
          sx={{ color: isActiveFilter() || isActiveProductsFilter() ? 'white' : 'black' }}
          color="dark"
          onClick={() => setOpened((state) => !state)}
        >
          <Flex gap={8} align="center">
            <Text>{title}</Text>

            <Flex w={14} className={classes.text}>
              {(isActiveFilter() && activeFiltersCount) ||
                (isActiveProductsFilter() && activeProductFiltersCount)}
            </Flex>
            {isActiveFilter() || isActiveProductsFilter() ? (
              <Flex align="center" onClick={resetFilters}>
                <IconX size="1.1rem" />
              </Flex>
            ) : (
              getIcon(opened)
            )}
          </Flex>
        </Button>
      </Popover.Target>

      <Popover.Dropdown>
        <Flex direction="column">
          <Input
            mb={8}
            radius="sm"
            icon={<IconSearch />}
            placeholder={placeholder}
            value={productsFilterFilter ? productsFilterFilter : ''}
            onChange={({ target: { value } }) => {
              dispatch(setProductsFilterFilter(value));
              dispatch(setProductsFilterCode(name));
            }}
          />

          <ScrollArea h={216}>
            {items.length > 0 && (
              <Flex className={classes.select}>
                <Checkbox
                  classNames={{ body: classes.checkboxBody }}
                  m={8}
                  color="dark"
                  radius="sm"
                  size="sm"
                  label="Выбрать все"
                  checked={allChecked}
                  indeterminate={indeterminate}
                  onChange={() => {
                    setItems.setState((current) =>
                      current.map((value) => ({ ...value, checked: !allChecked })),
                    );
                  }}
                />
              </Flex>
            )}
            {items
              .filter(
                (item) => item?.label?.toLowerCase().includes(productsFilterFilter?.toLowerCase()),
              )
              .map((item) => (
                <Flex className={classes.select} key={item.key}>
                  <Checkbox
                    classNames={{ body: classes.checkboxBody }}
                    m={8}
                    color="dark"
                    radius="sm"
                    size="sm"
                    label={item.label}
                    key={item.key}
                    checked={item.checked}
                    onChange={(event) => {
                      setItems.setItemProp(item.key, 'checked', event.currentTarget.checked);
                    }}
                  ></Checkbox>
                </Flex>
              ))}
          </ScrollArea>
        </Flex>

        <Box>
          <Divider
            sx={(theme) => ({ margin: '16px -16px', borderTopColor: theme.colors.gray[2] })}
          />
          <Flex direction="row" gap="8px">
            <Button fullWidth color="gray.2" onClick={resetFilters}>
              Очистить
            </Button>
            <Button
              fullWidth
              color="dark.9"
              onClick={setFilters}
              disabled={checkedValues.length === 0}
            >
              Применить
            </Button>
          </Flex>
        </Box>
      </Popover.Dropdown>
    </Popover>
  );
};

export default FilterMultiSelect;
