import { ERuleType } from '@/types/rules/rules';

import moment from 'moment';

import { dateParse } from '@/utils/rules';

export const ruleBlocksInitialState = [
  {
    type: ERuleType.fixPrice,
    name: 'Фиксированная цена',
    active: true,
    inherited: false,
    checked: false,
    shown: false,
  },
  {
    type: ERuleType.fixMargin,
    name: 'Фиксированная наценка',
    active: true,
    inherited: false,
    checked: false,
    shown: false,
  },
  {
    type: ERuleType.samePrice,
    name: 'Одинаковая цена',
    active: true,
    inherited: false,
    checked: false,
    shown: false,
  },
  {
    type: ERuleType.maxPriceChange,
    name: 'Максимальное изменение цены',
    active: true,
    inherited: false,
    checked: false,
    shown: false,
  },
  {
    type: ERuleType.minPriceChange,
    name: 'Минимальное изменение цены',
    active: true,
    inherited: false,
    checked: false,
    shown: false,
  },
  {
    type: ERuleType.minMaxPrice,
    name: 'Контроль РРЦ',
    active: true,
    inherited: false,
    checked: false,
    shown: false,
  },
  {
    type: ERuleType.minMaxMarginHard,
    name: 'Диапазон наценки (приоритетный)',
    active: true,
    inherited: false,
    checked: false,
    shown: false,
  },
  {
    type: ERuleType.competitor,
    name: 'Цена конкурентов',
    active: true,
    inherited: false,
    checked: false,
    shown: false,
  },
  {
    type: ERuleType.minMaxMarginSoft,
    name: 'Диапазон наценки',
    active: true,
    inherited: false,
    checked: false,
    shown: false,
  },
];

export const rulesInitialValues = {
  fixPriceRule: {
    dateStart: moment().format('DD.MM.YYYY'),
    dateEnd: moment().format('DD.MM.YYYY'),
    value: '',
  },
  fixPriceRuleFormValue: {
    dateStart: dateParse(moment().format('DD.MM.YYYY')),
    dateEnd: dateParse(moment().format('DD.MM.YYYY')),
    value: '',
  },
  minMaxMarginRule: {
    priceFrom: 0,
    priceTo: 999999,
    marginFrom: '',
    marginTo: '',
    marginWithBonusFrom: '',
    marginWithBonusTo: '',
  },
  minPriceChangeRule: {
    priceFrom: 0,
    priceTo: 999999,
    decreaseValue: '',
    decreasePercent: '',
    increaseValue: '',
    increasePercent: '',
  },
  competitorRule: {
    priceFrom: 0,
    priceTo: 999999,
    fromPercent: '',
    fromValue: '',
    toPercent: '',
    toValue: '',
  },
};
