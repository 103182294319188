import { IFilter, IFilterFilters, IFilterValue } from '@/types/filters/filters';
import { IPostMonitoringValues } from '@/types/monitoring/monitoring';
import { IGetProductsFilterValues, IMonitoringFilterValue } from '@/types/products/products';

import api from '@/api/instance';
import { URLS } from '@/api/urls';

export const apiGetPricesFilters = () =>
  api.get<IFilterFilters<IFilter<IFilterValue>>>(URLS.prices.filters);

export const apiGetPricesInfo = () => api.get(URLS.prices.info);

export const apiPostPricesList = ({ sort, order, offset, limit, filter }: IPostMonitoringValues) =>
  api.post(URLS.prices.pricesList, { filter: filter }, { params: { sort, order, offset, limit } });

export const apiGetPricesFiltersValue = (params: IGetProductsFilterValues) =>
  api.get<IMonitoringFilterValue>(URLS.prices.filtersSearch, { params: params });
