import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  select: {
    width: '454px',
    label: {
      fontWeight: 'bold',
    },
  },
  container: {
    maxWidth: '560px',
    marginTop: '24px',
    alignItems: 'flex-end',
    gap: '24px',
  },
  tree: {
    color: 'black',
    flex: 1,
    ['span.rc-tree-switcher.rc-tree-switcher_close']: {
      display: 'flex',
    },
    ['span.rc-tree-switcher.rc-tree-switcher_open']: {
      display: 'flex',
    },
    ['div.rc-tree-treenode']: {
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
      height: '40px',
    },
    ['span.rc-tree-node-content-wrapper']: {
      width: '100%',
      height: 'auto',
    },
    ['span.rc-tree-node-content-wrapper.rc-tree-node-content-wrapper-open']: {
      height: 'auto',
    },
    ['span.rc-tree-node-content-wrapper.rc-tree-node-content-wrapper-close']: {
      height: 'auto',
    },
    ['span.rc-tree-node-content-wrapper.rc-tree-node-content-wrapper-normal']: {
      height: 'auto',
    },
    ['span.rc-tree-title']: {
      width: '100%',
      padding: '8px 0',
    },
    ['span.rc-tree-node-selected']: {
      backgroundColor: theme.colors.gray[2],
      border: 'none',
      boxShadow: 'none',
      borderRadius: '8px',
    },
    ['span.rc-tree-iconEle.rc-tree-icon__open.rc-tree-icon_loading']: {
      display: 'none',
    },
    ['span.rc-tree-switcher']: {
      width: '24px !important',
      height: '24px !important',
      backgroundImage: 'none !important',
    },
    ['span.rc-tree-checkbox']: {
      border: '1px solid gray !important',
      borderRadius: '4px',
      display: 'block',
      height: '20px !important',
      minWidth: '20px !important',
      position: 'relative',
      backgroundImage: 'none !important',
      ['&::after']: {
        content: '""',
        display: 'block',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundPosition: 'center',
        opacity: 0,
        backgroundImage:
          'url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACoSURBVHgB1dTBDcMgDAVQtxMwAiMwApsygtUJPALdgBHIBi60cZRDgzD1pV/6USSSF8kRAHziW6m1tvJCcTcObBU6twqKBpiUbvuNVTZrEO5gnCXQOTdcVw0+pcQ9McarZ/RYrZW99zoQEbmUcrx4xkIIow9/XyCiN9BRBXYNtsFzzpklk9h4hoIqsLmf0uFJjP9jp2xgmA4+wS6PfvFgfMAKij9AJNgLoOAlxeZ4kIQAAAAASUVORK5CYII=") !important',
      },
    },
    ['span.rc-tree-checkbox-checked']: {
      ['&::after']: {
        opacity: 1,
      },
    },
  },
  track: {
    cursor: 'pointer',
  },
  drawerContent: {
    overflowY: 'hidden',
  },
  drawerBody: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  steps: {
    width: '410px',
  },
  stepIcon: {
    ['&[data-progress]']: {
      backgroundColor: 'black',
      color: 'white',
    },
  },
  stepLabel: {
    fontSize: '16px',
  },
  tabsList: {
    width: '427px',
    borderBottom: `0.1rem solid ${theme.colors.gray[2]}`,
  },
  tab: {
    fontSize: '16px',
    color: 'gray',
    '&[data-active]': {
      color: 'black',
      borderColor: 'black',
    },
  },
  wrapper: {
    padding: '24px',
    border: `1px solid ${theme.colors.gray[2]}`,
    borderRadius: '8px',
  },
  label: {
    fontWeight: 'bold',
  },
  actionIcon: {
    backgroundColor: theme.colors.gray[2],
    height: '36px',
  },
}));
