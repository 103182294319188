import { IFilterTreeValue, IModifiedFilterTreeItem } from '@/types/filters/filters';
import { IPlanData } from '@/types/plans/plans';

import { FC, Key, StrictMode, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavigateFunction, useNavigate } from 'react-router-dom';
import {
  Button,
  Checkbox,
  Flex,
  Input,
  Paper,
  ScrollArea,
  Select,
  Stepper,
  Text,
  Textarea,
  TextInput,
  Title,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconCheck, IconChevronDown, IconChevronRight, IconSearch } from '@tabler/icons-react';
import { Provider } from 'rc-motion';
import Tree from 'rc-tree';
import { EventDataNode } from 'rc-tree/lib/interface';

import { generateTreeNodes, getNewTreeData, motion } from '@/utils/filters';
import notify from '@/utils/notify';

import { paths } from '@/constants/paths';
import { plansSelectData } from '@/constants/plans';
import { useDebounce } from '@/hooks/useDebounce';

import FilterTreeMultiSelectTitle from '@/ui/organisms/FilterTreeMultiSelect/organisms/FilterTreeMultiSelectTitle/FilterTreeMultiSelectTitle';
import PageFooter from '@/ui/organisms/PageFooter/PageFooter';
import SteppersProductNodeFilters from '@/ui/organisms/SteppersProductNodeFilters/SteppersProductNodeFilters';
import { PricePlansSteppersTable } from '@/ui/pages/CreatePricePlansPage/organisms/PricePlansSteppers/orgsnisms/PricePlansSteppersTable/PricePlansSteppersTable';

import { useStyles } from '@/assets/styles/pricePlansSteppers';
import { childStyle } from '@/assets/styles/treeMultiSelect';
import { AppDispatch } from '@/store';
import {
  fetchAdditionalNodeValuesAction,
  selectAdditionalNodeValues,
} from '@/store/slices/nodes/nodes';
import {
  fetchAddPairsAction,
  fetchCreateNewPlanAction,
  fetchEditPlanAction,
  fetchPairsAction,
  fetchUndraftPlanAction,
  selectIsLoadingPairs,
  selectPlanDetails,
  selectPlanID,
  selectPlanPairs,
  selectPlansStep,
  setExpandedRows,
  setIsLoadingPairs,
  setPlansStep,
} from '@/store/slices/plans/plans';
import {
  fetchTreeProductAction,
  fetchTreeProductFilteredAction,
  selectActiveProductsFilters,
  selectTreeProduct,
  selectTreeProductFilter,
  selectTreeProductFiltered,
  setTreeProductFilter,
} from '@/store/slices/products/products';
import { fetchFiltersTreeNodeAction, selectCheckedTreeFilter } from '@/store/slices/rules/rules';

export const PricePlansSteppers: FC = () => {
  const { classes } = useStyles();

  const dispatch: AppDispatch = useDispatch();

  const navigate: NavigateFunction = useNavigate();

  const treeRef = useRef<Tree<IModifiedFilterTreeItem>>(null);

  const [query, setQuery] = useState<string>('');
  const [treeQuery, setTreeQuery] = useState<string>('');
  const [selectedNodeIDs, setSelectedNodeIDs] = useState<string[]>([]);
  const [treeDataExample, setTreeDataExample] = useState<IModifiedFilterTreeItem[]>([]);
  const [treeDataExampleFiltered, setTreeDataExampleFiltered] = useState<IModifiedFilterTreeItem[]>(
    [],
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const plansStep = useSelector(selectPlansStep);
  const planId = useSelector(selectPlanID);
  const nodeValues = useSelector(selectAdditionalNodeValues);
  const treeProductData = useSelector(selectTreeProduct);
  const treeProductFilter = useSelector(selectTreeProductFilter);
  const activeProductsFilters = useSelector(selectActiveProductsFilters);
  const treeProductDataFiltered = useSelector(selectTreeProductFiltered);
  const checkedTreeFilters = useSelector(selectCheckedTreeFilter);
  const planPairs = useSelector(selectPlanPairs);
  const isLoadingPairs = useSelector(selectIsLoadingPairs);
  const planDetails = useSelector(selectPlanDetails);

  const debouncedValue = useDebounce<string>(query);
  const debouncedTreeQuery = useDebounce<string>(treeQuery);

  const form = useForm<IPlanData>({
    initialValues: {
      name: planDetails?.name ?? '',
      priceAlg: planDetails?.priceAlg ?? '',
      description: planDetails?.description ?? undefined,
      comment: planDetails?.comment ?? undefined,
    },
    validate: {
      name: (value) => value.length === 0,
      priceAlg: (value) => value.length === 0,
    },
    validateInputOnChange: true,
    validateInputOnBlur: true,
  });

  const handleNodeCheckbox = (checked: boolean, itemID: string) => {
    if (checked) {
      setSelectedNodeIDs((prev) => [...prev, itemID]);
    } else {
      setSelectedNodeIDs((prev) => prev.filter((item) => item !== itemID));
    }
  };

  const modifiedTreeData = (dataFromBackend: IFilterTreeValue[]): IModifiedFilterTreeItem[] => {
    return [
      {
        key: '0-0',
        title: (
          <FilterTreeMultiSelectTitle item={{ id: 0, name: 'Все товары', level: 1, parent: 0 }} />
        ),
        id: 0,
        children: dataFromBackend.map((item: IFilterTreeValue, index: number) => ({
          title: <FilterTreeMultiSelectTitle item={item} />,
          key: `0-0-${index}`,
          id: item.id,
          isLeaf: false,
        })),
      },
    ];
  };

  const onExpand = async (
    keys: Key[],
    treeNode: {
      node: EventDataNode<IModifiedFilterTreeItem>;
      expanded: boolean;
      nativeEvent: MouseEvent;
    },
  ) => {
    let newTreeData = [...treeDataExample];

    const result = await dispatch(
      fetchFiltersTreeNodeAction({
        parentId: treeNode.node.id,
        query: treeProductFilter,
        ...(activeProductsFilters && { filter: activeProductsFilters }),
      }),
    );

    const modifiedFiltersTreeData = result.payload.items
      ? result.payload.items.map((item: IFilterTreeValue) => ({
          title: <FilterTreeMultiSelectTitle item={item} />,
          id: item.id,
        }))
      : [];

    getNewTreeData(
      newTreeData,
      treeNode.node.key,
      generateTreeNodes(treeNode, modifiedFiltersTreeData),
    );
    setTreeDataExample(newTreeData);
  };

  const onExpandFiltered = async (
    keys: Key[],
    treeNode: {
      node: EventDataNode<IModifiedFilterTreeItem>;
      expanded: boolean;
      nativeEvent: MouseEvent;
    },
  ) => {
    let newTreeDataFiltered = [...treeDataExampleFiltered];

    const result = await dispatch(
      fetchFiltersTreeNodeAction({
        parentId: treeNode.node.id,
        query: treeProductFilter,
        ...(activeProductsFilters && { filter: activeProductsFilters }),
      }),
    );

    const modifiedFiltersTreeData = result.payload.items
      ? result.payload.items.map((item: IFilterTreeValue) => ({
          title: <FilterTreeMultiSelectTitle item={item} />,
          id: item.id,
        }))
      : [];

    getNewTreeData(
      newTreeDataFiltered,
      treeNode.node.key,
      generateTreeNodes(treeNode, modifiedFiltersTreeData),
    );
    setTreeDataExampleFiltered(newTreeDataFiltered);
  };

  const handleNextStep = async () => {
    if (plansStep === 0) {
      form.validate();

      if (!form.isValid()) {
        notify({ message: 'Заполните обязательные поля', type: 'error' });
        return;
      }
      setIsLoading(true);

      if (planId) {
        const answer = await dispatch(
          fetchEditPlanAction({
            id: planId,
            data: form.values,
          }),
        );
        setIsLoading(false);

        if (answer.meta.requestStatus === 'rejected') return;

        dispatch(setPlansStep(1));
        return;
      }
      const answer = await dispatch(fetchCreateNewPlanAction(form.values));
      setIsLoading(false);
      if (answer.meta.requestStatus === 'rejected') return;
      dispatch(setPlansStep(1));
    }
  };

  const handleAddPairs = async () => {
    if (planId) {
      dispatch(setIsLoadingPairs(true));
      await dispatch(
        fetchAddPairsAction({
          id: planId,
          geo: selectedNodeIDs,
          prod: checkedTreeFilters.map((item) => ({ id: item.id, level: item.level })),
        }),
      );
      await dispatch(fetchPairsAction({ id: planId }));
      dispatch(setExpandedRows([]));
      dispatch(setIsLoadingPairs(false));
    }
  };

  const undraftPlan = async () => {
    if (planId) {
      setIsLoading(true);
      await dispatch(fetchUndraftPlanAction(planId));
      setIsLoading(false);
      navigate(`${paths.PATH_PRICE_PLANS}/${planId}`);
    }
  };

  useEffect(() => {
    dispatch(fetchAdditionalNodeValuesAction({ field: 'region', query: debouncedValue }));
  }, [debouncedValue]);

  useEffect(() => {
    dispatch(setTreeProductFilter(debouncedTreeQuery));
  }, [debouncedTreeQuery]);

  useEffect(() => {
    if (treeProductFilter.length > 0) {
      dispatch(
        fetchTreeProductFilteredAction({
          query: treeProductFilter,
          filter: activeProductsFilters,
        }),
      );
    } else {
      dispatch(fetchTreeProductAction(activeProductsFilters));
    }
  }, [treeProductFilter, activeProductsFilters]);

  useEffect(() => {
    if (treeProductFilter.length === 0) {
      setTreeDataExample(modifiedTreeData(treeProductData?.items.slice(1) ?? []));
    }
  }, [treeProductData, treeProductFilter, activeProductsFilters]);

  useEffect(() => {
    if (treeProductFilter.length > 0 && treeProductDataFiltered) {
      setTreeDataExampleFiltered(modifiedTreeData(treeProductDataFiltered.items.slice(1)));
    }
  }, [treeProductDataFiltered, treeProductFilter, activeProductsFilters]);

  useEffect(() => {
    if (planId) dispatch(fetchPairsAction({ id: planId }));
  }, []);

  return (
    <>
      <Flex direction="column" h="100%">
        <Stepper
          active={plansStep}
          onStepClick={(stepIndex) => {
            dispatch(setPlansStep(stepIndex));
          }}
          color="dark"
          size="xs"
          iconSize={24}
          classNames={{
            steps: classes.plansSteps,
            stepIcon: classes.stepIcon,
            stepLabel: classes.stepLabel,
          }}
        >
          <Stepper.Step label="Общие данные" completedIcon={<IconCheck size="1.1rem" />}>
            <Paper withBorder p={32} mt={32} w={856}>
              <Title order={3}>Создание ценового анализа</Title>

              <form>
                <Flex className={classes.container}>
                  <TextInput
                    maxLength={18}
                    label="Название ЦП"
                    className={classes.select}
                    placeholder="Введите название ценового анализа"
                    color="dark.9"
                    required
                    {...form.getInputProps('name')}
                  />
                </Flex>
                <Flex className={classes.container}>
                  <Select
                    placeholder="Выберите алгоритм определения цены"
                    data={plansSelectData}
                    className={classes.select}
                    label="Варианты алгоритма определения новой цены"
                    required
                    rightSection={<IconChevronDown size="1rem" />}
                    styles={{
                      item: {
                        wordBreak: 'normal',
                      },
                    }}
                    {...form.getInputProps('priceAlg')}
                  />
                </Flex>
                <Flex className={classes.container}>
                  <Textarea
                    minRows={3}
                    label="Описание ЦП"
                    placeholder="Введите описание ценового анализа"
                    className={classes.select}
                    {...form.getInputProps('description')}
                  />
                </Flex>
                <Flex className={classes.container}>
                  <Textarea
                    minRows={3}
                    label="Комментарий"
                    placeholder="Введите комментарий ценового анализа"
                    className={classes.select}
                    {...form.getInputProps('comment')}
                  />
                </Flex>
              </form>
            </Paper>
          </Stepper.Step>
          <Stepper.Step
            label="Формирование пар"
            completedIcon={<IconCheck size="1.1rem" />}
            allowStepSelect={!!planId}
          >
            <Paper w={1300}>
              <Flex gap={24} h={640}>
                <Paper withBorder p={24} mt={32} w={636}>
                  <Title order={3} pb={16}>
                    Выберите товар или категорию
                  </Title>
                  <Flex direction="column">
                    <SteppersProductNodeFilters />
                    <Text c="dimmed" size="sm" mt={-16} mb={16}>
                      *Перед применением фильтров необходимо свернуть все открытые категории, если
                      таковые имеются (при открытии окна «Товарный узел» категории по умолчанию
                      свернуты).
                    </Text>
                    <Input
                      mb={24}
                      radius={8}
                      icon={<IconSearch />}
                      placeholder="Поиск"
                      onChange={(event) => setTreeQuery(event.target.value)}
                    />
                  </Flex>
                  <Provider motion={true}>
                    <StrictMode>
                      <style dangerouslySetInnerHTML={{ __html: childStyle }} />
                      {treeProductFilter.length === 0 && (
                        <Tree
                          ref={treeRef}
                          motion={motion}
                          height={294}
                          itemHeight={40}
                          className={classes.tree}
                          showLine
                          selectable
                          defaultExpandedKeys={['0-0']}
                          treeData={treeDataExample}
                          onExpand={onExpand}
                          showIcon={false}
                          switcherIcon={(obj) => {
                            if (!obj.isLeaf) {
                              return obj.expanded ? <IconChevronDown /> : <IconChevronRight />;
                            } else return null;
                          }}
                        />
                      )}
                      {treeProductFilter.length > 0 && (
                        <Tree
                          ref={treeRef}
                          motion={motion}
                          height={294}
                          itemHeight={40}
                          className={classes.tree}
                          showLine
                          selectable
                          treeData={treeDataExampleFiltered}
                          onExpand={onExpandFiltered}
                          showIcon={false}
                          switcherIcon={(obj) => {
                            if (!obj.isLeaf) {
                              return obj.expanded ? <IconChevronDown /> : <IconChevronRight />;
                            } else return null;
                          }}
                        />
                      )}
                    </StrictMode>
                  </Provider>
                </Paper>
                <Paper withBorder p={24} mt={32} w={636}>
                  <Title order={3} pb={16}>
                    Выберите регион
                  </Title>
                  <Input
                    mb={24}
                    radius={8}
                    icon={<IconSearch />}
                    placeholder="Поиск"
                    onChange={(event) => setQuery(event.target.value)}
                  />
                  <ScrollArea h={455}>
                    <Flex direction="column" gap={16}>
                      {nodeValues
                        ?.filter(({ name }) => name === 'Все регионы')
                        .map((item, index) => (
                          <Checkbox
                            checked={selectedNodeIDs.includes(item.id)}
                            key={index}
                            label={item.name}
                            id={item.id}
                            radius="sm"
                            size="sm"
                            color="dark"
                            onChange={(event) => handleNodeCheckbox(event.target.checked, item.id)}
                          />
                        ))}
                      {nodeValues
                        ?.filter(({ name }) => name !== 'Все регионы')
                        .sort((a, b) => {
                          if (a.name === 'Все регионы') {
                            return -1;
                          } else if (b.name === 'Все регионы') {
                            return 1;
                          } else {
                            return a.name.localeCompare(b.name);
                          }
                        })
                        .map((item, index) => (
                          <Checkbox
                            checked={selectedNodeIDs.includes(item.id)}
                            key={index}
                            color="dark"
                            label={item.name}
                            id={item.id}
                            radius="sm"
                            size="sm"
                            onChange={(event) => handleNodeCheckbox(event.target.checked, item.id)}
                          />
                        ))}
                    </Flex>
                  </ScrollArea>
                </Paper>
              </Flex>
              <Flex pt={24} pb={40}>
                <Button
                  color="gray.9"
                  onClick={handleAddPairs}
                  disabled={!selectedNodeIDs.length || !checkedTreeFilters.length}
                  loading={isLoadingPairs}
                >
                  Добавить пары
                </Button>
              </Flex>
            </Paper>
            <PricePlansSteppersTable />
          </Stepper.Step>
        </Stepper>
      </Flex>
      <PageFooter>
        <Flex justify="space-between" align="center">
          <Link to={paths.PATH_PRICE_PLANS}>
            <Button color="gray.2">Отменить</Button>
          </Link>
          <Flex gap={16}>
            {plansStep > 0 && (
              <Button color="gray.2" onClick={() => dispatch(setPlansStep(0))}>
                Назад
              </Button>
            )}
            <Button
              loading={isLoading}
              color="gray.9"
              onClick={plansStep === 0 ? handleNextStep : undraftPlan}
              disabled={
                (plansStep === 1 && planPairs.length < 1) || (plansStep === 0 && !form.isValid())
              }
            >
              {plansStep === 0 && 'Далее'}
              {plansStep === 1 && 'Создать / редактировать анализ'}
            </Button>
          </Flex>
        </Flex>
      </PageFooter>
    </>
  );
};
