import { IMonitoringItem } from '@/types/monitoring/monitoring';
import {
  EPlanChangeItemValues,
  EPlanStatus,
  EPlanValidationStatus,
  IPlanEditItem,
  IPlanGeoItem,
  IPlanItem,
  IPlanPairs,
  IPlanProdItem,
} from '@/types/plans/plans';
import { IPriceItem } from '@/types/prices/prices';
import { IRule } from '@/types/rules/rules';

import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { createStyles, Flex, Text, Textarea } from '@mantine/core';
import { modals } from '@mantine/modals';
import { IconChartBar } from '@tabler/icons-react';
import { MRT_ColumnDef, MRT_Row } from 'mantine-react-table';

import notify from '@/utils/notify';
import {
  getDetailPlanStatusName,
  getPossiblyEmptyItemValue,
  setDetailPlanStatus,
  setStatusColor,
  setValidationStatus,
} from '@/utils/plans';

import { notifyMessages } from '@/constants/notify';

import TableBadge from '@/ui/organisms/TableBadge/TableBage';

import { AppDispatch } from '@/store';
import {
  selectPlanDetails,
  setDetailDrawerOpened,
  setDetailPlanInfo,
  setPlanChangeLockPriceModalOpened,
} from '@/store/slices/plans/plans';

const useStyles = createStyles((theme) => ({
  plansBadge: {
    padding: '1px 8px',
    height: '24px',
  },
  textSize: {
    fontSize: '16px',
  },
  pricePlanText: {
    fontSize: '16px',
    color: theme.colors.blue[4],
  },
  cellTextOverflow: {
    fontSize: '16px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  link: {
    textDecoration: 'none',
  },
  editPrice: {
    fontSize: '16px',
    cursor: 'pointer',
    textAlign: 'end',
  },
}));

export const useTableState = () => {
  const dispatch: AppDispatch = useDispatch();

  const planDetails = useSelector(selectPlanDetails);
  const canChangeLockPrice =
    (planDetails?.status === EPlanStatus.ready &&
      planDetails.validationStatus === EPlanValidationStatus.processed) ||
    (planDetails?.status === EPlanStatus.newPlan &&
      planDetails.validationStatus === EPlanValidationStatus.newPlan);

  const handleDetailDrawer = (item: IPlanEditItem) => {
    dispatch(setDetailPlanInfo(item));
    dispatch(setDetailDrawerOpened(true));
  };

  const handlePlanChangeLockPriceModal = (row: MRT_Row<IPlanEditItem>) => {
    if (!canChangeLockPrice) {
      notify({
        message: notifyMessages.LOCK_PRICE_STATUS_ERROR,
        type: 'warning',
      });
      return;
    }

    if (!!row.original.status.length && row.original.status !== EPlanChangeItemValues.waiting) {
      notify({
        message: notifyMessages.LOCK_PRICE_ERROR,
        type: 'warning',
      });
      return;
    }
    dispatch(setDetailPlanInfo(row.original));
    dispatch(setPlanChangeLockPriceModalOpened(true));
  };

  const { classes } = useStyles();

  const openInfoModal = (title: string, info: string) =>
    modals.openContextModal({
      modal: 'demonstration',
      title: (
        <Text fz={22} weight="bold">
          {title}
        </Text>
      ),
      innerProps: {
        modalBody: <Textarea minRows={4} readOnly size="sm" value={info}></Textarea>,
      },
    });

  const plansColumns: MRT_ColumnDef<IPlanItem>[] = [
    {
      accessorKey: 'name',
      header: 'Имя анализа',
      size: 218,
      Cell: ({ cell, row }) => (
        <Link to={`${row.original.id}`} className={classes.link}>
          <Text
            className={classes.cellTextOverflow}
            sx={(theme) => ({
              color: theme.colors.blue[8],
            })}
          >
            {cell.getValue<string>()}
          </Text>
        </Link>
      ),
    },
    {
      accessorKey: 'validationStatus',
      header: 'Статус согласования',
      size: 218,
      Cell: ({ cell }) => (
        <TableBadge
          key={cell.id}
          color={setStatusColor(cell.getValue<EPlanValidationStatus>())}
          text={cell.getValue<EPlanValidationStatus>()}
        />
      ),
    },
    {
      accessorKey: 'description',
      header: 'Описание анализа',
      size: 218,
      Cell: ({ cell, column }) => (
        <Text
          className={classes.cellTextOverflow}
          sx={{
            cursor: cell.getValue<string>().length > 21 ? 'pointer' : '',
          }}
          onClick={() =>
            cell.getValue<string>().length > 21
              ? openInfoModal(column.columnDef.header, cell.getValue<string>())
              : null
          }
        >
          {cell.getValue<string>()}
        </Text>
      ),
    },
    {
      accessorKey: 'createdAt',
      header: 'Дата создания',
      size: 218,
      Cell: ({ cell }) => <Text className={classes.textSize}>{cell.getValue<string>()}</Text>,
    },
    {
      accessorKey: 'processedAt',
      header: 'Дата расчета',
      size: 218,
      Cell: ({ cell }) => <Text className={classes.textSize}>{cell.getValue<string>()}</Text>,
    },
    {
      accessorKey: 'status',
      header: 'Статус расчета',
      size: 218,
      Cell: ({ cell }) => (
        <TableBadge
          key={cell.id}
          color={setValidationStatus(cell.getValue<EPlanStatus>())}
          text={cell.getValue<EPlanStatus>()}
        />
      ),
    },
    {
      accessorKey: 'comment',
      header: 'Комментарий',
      size: 218,
      Cell: ({ cell, column }) => (
        <Text
          className={classes.cellTextOverflow}
          sx={{
            cursor: cell.getValue<string>().length > 21 ? 'pointer' : '',
          }}
          onClick={() =>
            cell.getValue<string>().length > 21
              ? openInfoModal(column.columnDef.header, cell.getValue<string>())
              : null
          }
        >
          {cell.getValue<string>()}
        </Text>
      ),
    },
    {
      accessorKey: 'active',
      header: 'Состояние анализа',
      size: 218,
      Cell: ({ cell }) => (
        <TableBadge
          color={cell.getValue<boolean>() ? 'chateau-green.5' : 'gray.6'}
          text={cell.getValue<boolean>() ? 'Активен' : 'Неактивен'}
        />
      ),
    },
  ];

  const rulesColumns: MRT_ColumnDef<IRule>[] = [
    {
      accessorKey: 'active',
      header: 'Статус правила',
      size: 218,
      Cell: ({ cell }) => (
        <TableBadge
          key={cell.id}
          color={cell.getValue<string>() ? 'green' : 'gray'}
          text={cell.getValue<string>() ? 'Активно' : 'Неактивно'}
        />
      ),
    },
    {
      accessorKey: 'product',
      header: 'Товарный узел',
      size: 218,
      Cell: ({ cell, column }) => (
        <Text
          className={classes.cellTextOverflow}
          sx={{
            cursor: cell.getValue<string>().length > 21 ? 'pointer' : '',
          }}
          onClick={() =>
            cell.getValue<string>().length > 21
              ? openInfoModal(column.columnDef.header, cell.getValue<string>())
              : null
          }
        >
          {cell.getValue<string>()}
        </Text>
      ),
    },
    {
      accessorKey: 'region',
      header: 'Регион',
      size: 218,
      Cell: ({ cell }) => (
        <Text className={classes.cellTextOverflow}>{cell.getValue<string>()}</Text>
      ),
    },
    {
      accessorKey: 'type',
      header: 'Тип правила',
      size: 218,
      Cell: ({ cell }) => (
        <Text className={classes.cellTextOverflow}>{cell.getValue<string>()}</Text>
      ),
    },
    {
      accessorKey: 'createdAt',
      header: 'Дата создания',
      size: 218,
      Cell: ({ cell }) => <Text className={classes.textSize}>{cell.getValue<string>()}</Text>,
    },
    {
      accessorKey: 'comment',
      header: 'Комментарий',
      size: 218,
      Cell: ({ cell, column }) => (
        <Text
          className={classes.cellTextOverflow}
          sx={{
            cursor: cell.getValue<string>().length > 21 ? 'pointer' : '',
          }}
          onClick={() =>
            cell.getValue<string>().length > 21
              ? openInfoModal(column.columnDef.header, cell.getValue<string>())
              : null
          }
        >
          {cell.getValue<string>()}
        </Text>
      ),
    },
    {
      accessorKey: 'tradeMark',
      header: 'Торговая марка',
      size: 218,
      Cell: ({ cell }) => (
        <Text className={classes.cellTextOverflow}>
          {getPossiblyEmptyItemValue(cell.getValue<number | null>())}
        </Text>
      ),
    },
    {
      accessorKey: 'class',
      header: 'Класс',
      size: 218,
      Cell: ({ cell }) => (
        <Text className={classes.cellTextOverflow}>
          {getPossiblyEmptyItemValue(cell.getValue<number | null>())}
        </Text>
      ),
    },
    {
      accessorKey: 'prodGroup',
      header: 'Группа товаров',
      size: 218,
      Cell: ({ cell }) => (
        <Text className={classes.cellTextOverflow}>
          {getPossiblyEmptyItemValue(cell.getValue<number | null>())}
        </Text>
      ),
    },
  ];

  const managePlanColumns: MRT_ColumnDef<IPlanPairs>[] = [
    {
      accessorKey: 'prodItem',
      header: 'Товарный узел',
      size: 600,
      Cell: ({ cell }) => <Flex>{cell.getValue<IPlanProdItem>().name}</Flex>,
    },
    {
      accessorKey: 'geoItem',
      header: 'Регион',
      size: 540,
      Cell: ({ cell }) => <Flex>{cell.getValue<IPlanGeoItem>().name}</Flex>,
    },
  ];

  const monitoringColumns: MRT_ColumnDef<IMonitoringItem>[] = [
    {
      accessorKey: 'date',
      header: 'Дата мониторинга',
      size: 218,
      Cell: ({ cell }) => <Flex className={classes.textSize}>{cell.getValue<string>()}</Flex>,
    },
    {
      accessorKey: 'aggregate',
      header: 'Конкурент',
      size: 218,
      Cell: ({ cell }) => (
        <Text className={classes.cellTextOverflow}>{cell.getValue<string>()}</Text>
      ),
    },
    {
      accessorKey: 'prodId',
      header: 'ID Товара',
      size: 218,
      Cell: ({ cell }) => <Flex className={classes.textSize}>{cell.getValue<number>()}</Flex>,
    },
    {
      accessorKey: 'prodName',
      header: 'Наименование товара',
      size: 224,
      Cell: ({ cell, column }) => (
        <Text
          className={classes.cellTextOverflow}
          sx={{
            cursor: cell.getValue<string>().length > 21 ? 'pointer' : '',
          }}
          onClick={() =>
            cell.getValue<string>().length > 21
              ? openInfoModal(column.columnDef.header, cell.getValue<string>())
              : null
          }
        >
          {cell.getValue<string>()}
        </Text>
      ),
    },
    {
      accessorKey: 'monitoringPrice',
      header: 'Цена мониторинга, \u20BD',
      size: 218,
      Cell: ({ cell, row }) => (
        <Flex className={classes.textSize} justify="space-between" w="100%">
          <Link
            target="_blank"
            to={`${process.env.MATCHER_URL}matcher/products/detail?product=${row.getValue(
              'prodId',
            )}`}
          >
            <IconChartBar className={classes.pricePlanText} />
          </Link>
          {cell.getValue<number>()}
        </Flex>
      ),
    },
    {
      accessorKey: 'recommendedPrice',
      header: 'РЦ, \u20BD (Система)',
      size: 218,
      Cell: ({ cell }) => (
        <Flex ml="auto" className={classes.textSize}>
          {cell.getValue<number>()}
        </Flex>
      ),
    },
    {
      accessorKey: 'costPrice',
      header: 'ЗЦ, \u20BD (Система)',
      size: 218,
      Cell: ({ cell }) => (
        <Flex ml="auto" className={classes.textSize}>
          {cell.getValue<number>()}
        </Flex>
      ),
    },
    {
      accessorKey: 'monitoringPriceMin',
      header: 'История, \u20BD (min)',
      size: 218,
      Cell: ({ cell }) => (
        <Flex ml="auto" className={classes.textSize}>
          {cell.getValue<number>()}
        </Flex>
      ),
    },
    {
      accessorKey: 'monitoringPriceMax',
      header: 'История, \u20BD (max)',
      size: 218,
      Cell: ({ cell }) => (
        <Flex ml="auto" className={classes.textSize}>
          {cell.getValue<number>()}
        </Flex>
      ),
    },
  ];

  const pricesColumns: MRT_ColumnDef<IPriceItem>[] = [
    {
      accessorKey: 'region',
      header: 'Регион',
      size: 218,
      Cell: ({ cell }) => (
        <Text className={classes.cellTextOverflow}>{cell.getValue<string>()}</Text>
      ),
    },
    {
      accessorKey: 'prodId',
      header: 'ID Товара',
      size: 218,
      Cell: ({ cell }) => <Text className={classes.textSize}>{cell.getValue<number>()}</Text>,
    },
    {
      accessorKey: 'prodName',
      header: 'Наименование товара',
      size: 230,
      Cell: ({ cell, column }) => (
        <Text
          className={classes.cellTextOverflow}
          sx={{
            cursor: cell.getValue<string>().length > 21 ? 'pointer' : '',
          }}
          onClick={() =>
            cell.getValue<string>().length > 21
              ? openInfoModal(column.columnDef.header, cell.getValue<string>())
              : null
          }
        >
          {cell.getValue<string>()}
        </Text>
      ),
    },
    {
      accessorKey: 'pricePlanName',
      header: 'Ценовой анализ',
      size: 218,
      Cell: ({ cell, row }) => (
        <Link to={`/pricing-plans/${row.original.pricePlanId}`} className={classes.link}>
          <Text className={classes.pricePlanText}>{cell.getValue<string>()}</Text>
        </Link>
      ),
    },
    {
      accessorKey: 'price',
      header: 'Цена (\u20BD)',
      size: 218,
      Cell: ({ cell }) => <Text className={classes.textSize}>{cell.getValue<number>()}</Text>,
    },
    {
      accessorKey: 'dateCreate',
      header: 'Дата выгрузки в очередь',
      size: 250,
      Cell: ({ cell }) => <Text className={classes.textSize}>{cell.getValue<string>()}</Text>,
    },
    {
      accessorKey: 'dateLoad',
      header: 'Дата отправки',
      size: 218,
      Cell: ({ cell }) => <Text className={classes.textSize}>{cell.getValue<string>()}</Text>,
    },
  ];

  const editPlanColumns: MRT_ColumnDef<IPlanEditItem>[] = [
    {
      accessorKey: 'prodId',
      header: 'Артикул товара из 1С',
      Header: <Text>ID Товара</Text>,
      size: 218,
      Cell: ({ cell, row }) => (
        <Text
          className={classes.pricePlanText}
          onClick={() => handleDetailDrawer(row.original)}
          sx={{ cursor: 'pointer' }}
        >
          {cell.getValue<string>()}
        </Text>
      ),
    },
    {
      accessorKey: 'status',
      header: 'Статус согласования',
      size: 230,
      Cell: ({ cell }) => {
        const cellValue = cell.getValue<EPlanChangeItemValues>();
        if (!cellValue.length) return <Text>{'\u2014'}</Text>;
        return (
          <TableBadge
            key={cell.id}
            color={setDetailPlanStatus(cellValue)}
            text={getDetailPlanStatusName(cellValue)}
          />
        );
      },
    },
    {
      accessorKey: 'prodName',
      header: 'Наименование товара',
      size: 230,
      Cell: ({ cell, column }) => (
        <Text
          className={classes.cellTextOverflow}
          sx={{
            cursor: cell.getValue<string>().length > 21 ? 'pointer' : '',
          }}
          onClick={() =>
            cell.getValue<string>().length > 21
              ? openInfoModal(column.columnDef.header, cell.getValue<string>())
              : null
          }
        >
          {cell.getValue<string>()}
        </Text>
      ),
    },
    {
      accessorKey: 'profitability',
      header: '1 - (Сумма в ЗЦ с бонусом / Сумма текущая )',
      Header: <Text>Рентабельность</Text>,
      size: 218,
      Cell: ({ cell }) => (
        <Text className={classes.textSize}>
          {getPossiblyEmptyItemValue(cell.getValue<number | null>())}
        </Text>
      ),
    },
    {
      accessorKey: 'geoId',
      header: 'Код региона из 1С',
      Header: <Text>ID региона</Text>,
      size: 218,
      Cell: ({ cell }) => <Text className={classes.textSize}>{cell.getValue<string>()}</Text>,
    },
    {
      accessorKey: 'geoName',
      header: 'Наименование региона',
      size: 240,
      Cell: ({ cell }) => (
        <Text className={classes.cellTextOverflow}>{cell.getValue<string>()}</Text>
      ),
    },
    {
      accessorKey: 'cost',
      header: 'Закупочная цена из 1С',
      Header: <Text>ЗЦ (₽)</Text>,
      size: 218,
      Cell: ({ cell }) => (
        <Flex className={classes.textSize} ml="auto" justify="flex-start" w="100%">
          {getPossiblyEmptyItemValue(cell.getValue<number | null>())}
        </Flex>
      ),
    },
    {
      accessorKey: 'costWithBonus',
      header: 'Закупочная цена с бонусом из 1С',
      Header: <Text>ЗЦ с бон. (₽)</Text>,
      size: 218,
      Cell: ({ cell }) => (
        <Flex className={classes.textSize} ml="auto" justify="flex-start" w="100%">
          {getPossiblyEmptyItemValue(cell.getValue<number | null>())}
        </Flex>
      ),
    },
    {
      accessorKey: 'currentPrice',
      header: 'Актуальная цена из 1С',
      Header: <Text>Тек. цена (₽)</Text>,
      size: 218,
      Cell: ({ cell }) => (
        <Flex className={classes.textSize} ml="auto" justify="flex-start" w="100%">
          {getPossiblyEmptyItemValue(cell.getValue<number | null>())}
        </Flex>
      ),
    },
    {
      accessorKey: 'newPrice',
      header: 'Новая рассчитанная цена',
      Header: <Text>Нов. цена (₽)</Text>,
      size: 218,
      Cell: ({ cell }) => (
        <Flex className={classes.textSize} ml="auto" justify="flex-start" w="100%">
          {getPossiblyEmptyItemValue(cell.getValue<number | null>())}
        </Flex>
      ),
    },
    {
      accessorKey: 'lockPrice',
      header: 'Введите фиксированную цену',
      Header: <Text>Lock цена (₽)</Text>,
      size: 218,
      Cell: ({ cell, row }) => (
        <Flex justify="flex-start" w="100%">
          <Text className={classes.editPrice} onClick={() => handlePlanChangeLockPriceModal(row)}>
            {getPossiblyEmptyItemValue(cell.getValue<number | null>())}
          </Text>
        </Flex>
      ),
    },
    {
      accessorKey: 'newPriceFrom',
      header: 'Минимальное значение диапазона новой рассчитанной цены',
      Header: <Text>Диапазон от (₽)</Text>,
      size: 218,
      Cell: ({ cell }) => (
        <Flex className={classes.textSize} ml="auto" justify="flex-start" w="100%">
          {getPossiblyEmptyItemValue(cell.getValue<number | null>())}
        </Flex>
      ),
    },
    {
      accessorKey: 'newPriceTo',
      header: 'Максимальное значение диапазона новой рассчитанной цены',
      Header: <Text>Диапазон до (₽)</Text>,
      size: 218,
      Cell: ({ cell }) => (
        <Flex className={classes.textSize} ml="auto" justify="flex-start" w="100%">
          {getPossiblyEmptyItemValue(cell.getValue<number | null>())}
        </Flex>
      ),
    },
    {
      accessorKey: 'errorsCount',
      header: 'Количество непримененных правил при расчете',
      Header: <Text>Кол-во наруш.</Text>,
      size: 218,
      Cell: ({ cell }) => (
        <Flex className={classes.textSize} ml="auto" justify="flex-start" w="100%">
          {getPossiblyEmptyItemValue(cell.getValue<number | null>())}
        </Flex>
      ),
    },
    {
      accessorKey: 'priceChangeAbs',
      header: 'Изменение цены в рублях +- (Новая цена - Текущая цена)',
      Header: <Text>Изм. цены (₽)</Text>,
      size: 218,
      Cell: ({ cell }) => (
        <Flex className={classes.textSize} ml="auto" justify="flex-start" w="100%">
          {getPossiblyEmptyItemValue(cell.getValue<number | null>())}
        </Flex>
      ),
    },
    {
      accessorKey: 'priceChangeAbsMod',
      header: 'Изменение цены в рублях (Новая цена - Текущая цена)',
      Header: <Text>Изм. цены по модулю (₽)</Text>,
      size: 250,
      Cell: ({ cell }) => (
        <Flex className={classes.textSize} ml="auto" justify="flex-start" w="100%">
          {getPossiblyEmptyItemValue(cell.getValue<number | null>())}
        </Flex>
      ),
    },
    {
      accessorKey: 'priceChangePercent',
      header: 'Изменение цены в процентах +- (((Новая цена - Текущая цена) /Текущая цена) * 100)',
      Header: <Text>% изм. цены</Text>,
      size: 218,
      Cell: ({ cell }) => (
        <Flex className={classes.textSize} ml="auto" justify="flex-start" w="100%">
          {getPossiblyEmptyItemValue(cell.getValue<number | null>())}
        </Flex>
      ),
    },
    {
      accessorKey: 'priceChangePercentMod',
      header: 'Изменение цены в процентах (((Новая цена - Текущая цена) /Текущая цена) * 100)',
      Header: <Text>% изм. цены по модулю</Text>,
      size: 240,
      Cell: ({ cell }) => (
        <Flex className={classes.textSize} ml="auto" justify="flex-start" w="100%">
          {getPossiblyEmptyItemValue(cell.getValue<number | null>())}
        </Flex>
      ),
    },
    {
      accessorKey: 'currentMarkup',
      header:
        'Текущая наценка в процентах (((Текущая цена - Закупочная цена) / Закупочная цена) *100)',
      Header: <Text>Тек. наценка (%)</Text>,
      size: 218,
      Cell: ({ cell }) => (
        <Flex className={classes.textSize} ml="auto" justify="flex-start" w="100%">
          {getPossiblyEmptyItemValue(cell.getValue<number | null>())}
        </Flex>
      ),
    },
    {
      accessorKey: 'currentMarkupWithBonus',
      header:
        'Текущая наценка с бонусом в процентах (((Текущая цена - Закупочная цена с бонусом) / Закупочная цена с бонусом) * 100)',
      Header: <Text>Тек. наценка с бон. (%)</Text>,
      size: 230,
      Cell: ({ cell }) => (
        <Flex className={classes.textSize} ml="auto" justify="flex-start" w="100%">
          {getPossiblyEmptyItemValue(cell.getValue<number | null>())}
        </Flex>
      ),
    },
    {
      accessorKey: 'newMarkup',
      header:
        'Новая наценка в процентах (((Новая цена - Закупочная цена)  / Закупочная цена) * 100)',
      Header: <Text>Нов. наценка (%)</Text>,
      size: 218,
      Cell: ({ cell }) => (
        <Flex className={classes.textSize} ml="auto" justify="flex-start" w="100%">
          {getPossiblyEmptyItemValue(cell.getValue<number | null>())}
        </Flex>
      ),
    },
    {
      accessorKey: 'newMarkupWithBonus',
      header:
        'Новая наценка с бонусом в процентах (((Новая цена - Закупочная цена с бонусом)  / Закупочная цена с бонусом) * 100)',
      Header: <Text>Нов. наценка с бон. (%)</Text>,
      size: 230,
      Cell: ({ cell }) => (
        <Flex className={classes.textSize} ml="auto" justify="flex-start" w="100%">
          {getPossiblyEmptyItemValue(cell.getValue<number | null>())}
        </Flex>
      ),
    },
    {
      accessorKey: 'changeMarkup',
      header: 'Изменения наценки в процентах +- (Новая наценка - Текущая наценка)',
      Header: <Text>Изм. наценки (%)</Text>,
      size: 218,
      Cell: ({ cell }) => (
        <Flex className={classes.textSize} ml="auto" justify="flex-start" w="100%">
          {getPossiblyEmptyItemValue(cell.getValue<number | null>())}
        </Flex>
      ),
    },
    {
      accessorKey: 'changeMarkupAbs',
      header: 'Изменения наценки по модулю в процентах (Новая наценка - Текущая наценка)',
      Header: <Text>Изм. наценки по модулю (%)</Text>,
      size: 270,
      Cell: ({ cell }) => (
        <Flex className={classes.textSize} ml="auto" justify="flex-start" w="100%">
          {getPossiblyEmptyItemValue(cell.getValue<number | null>())}
        </Flex>
      ),
    },
    {
      accessorKey: 'changeMarkupWithBonus',
      header:
        'Изменение наценки с бонусом в процентах +- (Новая наценка с бонусом - Текущая наценка с бонусом)',
      Header: <Text>Изм. наценки с бон. (%)</Text>,
      size: 240,
      Cell: ({ cell }) => (
        <Flex className={classes.textSize} ml="auto" justify="flex-start" w="100%">
          {getPossiblyEmptyItemValue(cell.getValue<number | null>())}
        </Flex>
      ),
    },
    {
      accessorKey: 'changeMarkupWithBonusAbs',
      header:
        'Изменения наценки с бонусом по модулю в процентах (Новая наценка с бонусом - Текущая наценка с бонусом)',
      Header: <Text>Изм. нац. с бон. по мод. (%)</Text>,
      size: 260,
      Cell: ({ cell }) => (
        <Flex className={classes.textSize} ml="auto" justify="flex-start" w="100%">
          {getPossiblyEmptyItemValue(cell.getValue<number | null>())}
        </Flex>
      ),
    },
    {
      accessorKey: 'sales',
      header: 'Продажи с сайта А+ за последние 30 дней',
      Header: <Text>Продажи</Text>,
      size: 218,
      Cell: ({ cell }) => (
        <Flex className={classes.textSize} ml="auto" justify="flex-start" w="100%">
          {getPossiblyEmptyItemValue(cell.getValue<number | null>())}
        </Flex>
      ),
    },
    {
      accessorKey: 'turnover',
      header: 'Оборот в рублях (Продажи * Текущая цена)',
      Header: <Text>Оборот (₽)</Text>,
      size: 218,
      Cell: ({ cell }) => (
        <Flex className={classes.textSize} ml="auto" justify="flex-start" w="100%">
          {getPossiblyEmptyItemValue(cell.getValue<number | null>())}
        </Flex>
      ),
    },
    {
      accessorKey: 'estimatedTurnover',
      header: 'Расчетный оборот в рублях (Продажи * Новая цена)',
      Header: <Text>Расч. оборот (₽)</Text>,
      size: 218,
      Cell: ({ cell }) => (
        <Flex className={classes.textSize} ml="auto" justify="flex-start" w="100%">
          {getPossiblyEmptyItemValue(cell.getValue<number | null>())}
        </Flex>
      ),
    },
    {
      accessorKey: 'estimatedTurnoverGrowth',
      header: 'Расчетный прирост оборота в рублях (Расчетный оборот - Оборот)',
      Header: <Text>Расч. прир. оборота (₽)</Text>,
      size: 240,
      Cell: ({ cell }) => (
        <Flex className={classes.textSize} ml="auto" justify="flex-start" w="100%">
          {getPossiblyEmptyItemValue(cell.getValue<number | null>())}
        </Flex>
      ),
    },
    {
      accessorKey: 'comment',
      header: 'Комментарий',
      size: 218,
      Cell: ({ cell, column }) => (
        <Text
          className={classes.cellTextOverflow}
          sx={{
            cursor: cell.getValue<string>().length > 21 ? 'pointer' : '',
          }}
          onClick={() =>
            cell.getValue<string>().length > 21
              ? openInfoModal(column.columnDef.header, cell.getValue<string>())
              : null
          }
        >
          {cell.getValue<string>()}
        </Text>
      ),
    },
  ];

  return {
    openInfoModal,
    plansColumns,
    rulesColumns,
    managePlanColumns,
    monitoringColumns,
    pricesColumns,
    editPlanColumns,
  };
};
