import { ChangeEvent, FC, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Button, Checkbox, Flex, Loader, Text } from '@mantine/core';
import { modals } from '@mantine/modals';
import {
  IconFileExport,
  IconFileImport,
  IconLock,
  IconMessage,
  IconTrash,
} from '@tabler/icons-react';

import { modifiedTreeFilter } from '@/utils/filters';
import notify from '@/utils/notify';
import { getOrder, getSort } from '@/utils/rules';

import { titles } from '@/constants/paths';

import PageFooter from '@/ui/organisms/PageFooter/PageFooter';
import PageHeader from '@/ui/organisms/PageHeader/PageHeader';
import PriceRulesTable from '@/ui/pages/PriceRulesPage/organisms/PriceRulesTable/PriceRulesTable';
import PageLayoutBody from '@/ui/templates/PageLayout/organisms/PageLayoutBody/PageLayoutBody';
import PageLayoutFooter from '@/ui/templates/PageLayout/organisms/PageLayoutFooter/PageLayoutFooter';
import PageLayoutHeader from '@/ui/templates/PageLayout/organisms/PageLayoutHeader/PageLayoutHeader';
import PageLayout from '@/ui/templates/PageLayout/PageLayout';

import PriceRulesTableFilters from './organisms/PriceRulesTableFilters/PriceRulesTableFilters';

import { AppDispatch } from '@/store';
import { selectActiveFilters } from '@/store/slices/filters/filters';
import {
  fetchDeactivateRuleAction,
  fetchDeactivateRulesAction,
  fetchDeleteRuleAction,
  fetchDeleteRulesAction,
  fetchExportRulesAction,
  fetchImportRulesAction,
  selectActiveFilters as selectTreeFilter,
  selectCheckedRules,
  selectFetchingRulesExport,
  selectFetchingRulesImport,
  selectRulesRefetch,
  selectRulesSorting,
  selectTotalRulesCount,
  setCommentModalOpened,
  setRulesRefetch,
} from '@/store/slices/rules/rules';

const PriceRulesPage: FC = () => {
  const dispatch: AppDispatch = useDispatch();

  const fileInputRef = useRef<HTMLInputElement>(null);

  const fetchingRulesImport = useSelector(selectFetchingRulesImport);
  const fetchingRulesExport = useSelector(selectFetchingRulesExport);
  const totalRulesCount = useSelector(selectTotalRulesCount);
  const rulesRefetch = useSelector(selectRulesRefetch);
  const checkedRules = useSelector(selectCheckedRules);
  const activeFilters = useSelector(selectActiveFilters).rules;
  const activeTreeFilter = useSelector(selectTreeFilter);
  const rulesSorting = useSelector(selectRulesSorting);

  const isCheckedRulesMultiple = checkedRules.length > 1;
  const isCheckedRulesAllDeactive = checkedRules.every((item) => !item.active);
  const checkedRulesIds = checkedRules.map((item) => item.id);

  const deactiveRules = async () => {
    if (isCheckedRulesMultiple) {
      await dispatch(fetchDeactivateRuleAction(checkedRulesIds));
    } else {
      await dispatch(fetchDeactivateRulesAction(checkedRulesIds));
    }

    dispatch(setRulesRefetch(!rulesRefetch));
  };

  const deleteRules = async () => {
    if (isCheckedRulesMultiple) {
      await dispatch(fetchDeleteRulesAction(checkedRulesIds));
    } else {
      await dispatch(fetchDeleteRuleAction(checkedRulesIds));
    }

    dispatch(setRulesRefetch(!rulesRefetch));
  };

  const openDeactiveModal = () =>
    modals.openConfirmModal({
      title: (
        <Text fz={22} weight="bold">
          Подтверждение действия
        </Text>
      ),
      children: (
        <>
          <Text size="md" color="dark.2">
            Вы выбрали как активные, так и неактивные пункты. Вы действительно хотите деактивировать
            только активные пункты?
          </Text>
        </>
      ),
      labels: { confirm: 'Да', cancel: 'Отменить' },
      confirmProps: { color: 'dark.9', sx: { width: '195px' } },
      cancelProps: { variant: 'filled', color: 'gray.2', sx: { width: '195px' } },
      onConfirm: () => {
        deactiveRules();
      },
    });

  const openDeleteModal = () =>
    modals.openConfirmModal({
      title: (
        <Text fz={22} weight="bold">
          Удаление {isCheckedRulesMultiple ? 'правил' : 'правила'}
        </Text>
      ),
      children: (
        <>
          <Text size="md" color="dark.2">
            Вы действительно хотите удалить {isCheckedRulesMultiple ? 'правила' : 'правило'}{' '}
            без возможности восстановления?
          </Text>
        </>
      ),
      labels: { confirm: 'Да', cancel: 'Отменить' },
      confirmProps: { color: 'dark.9', sx: { width: '195px' } },
      cancelProps: { variant: 'filled', color: 'gray.2', sx: { width: '195px' } },
      onConfirm: () => {
        deleteRules();
      },
    });

  const handleDeactiveRules = () => {
    const checkedRulesStatus = checkedRules.map((item) => item.active);

    if (checkedRulesStatus.includes(false)) {
      openDeactiveModal();
    } else {
      deactiveRules();
    }
  };

  const handleCommentRules = () => {
    dispatch(setCommentModalOpened(true));
  };

  const saveFile = async (file: File, folderId?: string) => {
    const data = new FormData();
    data.append('file', file);
    if (folderId) data.append('folderId', folderId);

    await dispatch(fetchImportRulesAction(data));
    dispatch(setRulesRefetch(!rulesRefetch));
  };

  const handleAddFromDevice = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;
    const newFiles = [...e.target.files];
    const file = newFiles[0];
    const fileToSend = new File([file], `${file.name}`);
    const maxFileSize = 20971520;

    if (file.size < maxFileSize && file.type === 'text/csv') {
      saveFile(fileToSend);
    } else
      notify({ message: 'Формат файла должен быть csv, размер файла до 20 МБ', type: 'error' });
  };

  const downloadFile = (file: Blob) => {
    const url = window.URL.createObjectURL(new Blob([file]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'rules.csv');
    document.body.appendChild(link);
    link.click();
  };

  const handleExportFile = async () => {
    const result = await dispatch(
      fetchExportRulesAction({
        sort: getSort(rulesSorting),
        order: getOrder(rulesSorting),
        filter: [...activeFilters, ...modifiedTreeFilter(activeTreeFilter)],
      }),
    );
    downloadFile(result.payload);
  };

  return (
    <PageLayout>
      <PageLayoutHeader>
        <PageHeader title={titles.TITLE_PRICE_RULES}>
          <NavLink to="/price-rules/create-price-rules">
            <Button color="dark.9">Создать правило</Button>
          </NavLink>
          <Button
            color="gray.2"
            leftIcon={fetchingRulesImport ? <Loader size="xs" /> : <IconFileImport size="1.1rem" />}
            onClick={() => fileInputRef.current?.click()}
            disabled={fetchingRulesExport}
          >
            Импортировать
          </Button>
          <input type="file" hidden ref={fileInputRef} onChange={handleAddFromDevice} />
          <Button
            color="gray.2"
            leftIcon={fetchingRulesExport ? <Loader size="xs" /> : <IconFileExport size="1.1rem" />}
            onClick={handleExportFile}
            disabled={fetchingRulesImport}
          >
            Экспортировать
          </Button>
        </PageHeader>
      </PageLayoutHeader>

      <PageLayoutBody>
        <PriceRulesTableFilters />
        <PriceRulesTable />
      </PageLayoutBody>

      <PageLayoutFooter>
        {checkedRules.length !== 0 && (
          <PageFooter>
            <Flex justify="space-between" align="center">
              <Flex align="center">
                <Checkbox m={8} color="dark" radius="sm" size="sm" readOnly checked></Checkbox>
                <Text size="sm">
                  Выбрано {checkedRules.length} из {totalRulesCount}
                </Text>
              </Flex>
              <Flex align="center" gap={16}>
                <Button leftIcon={<IconMessage />} color="gray.2" onClick={handleCommentRules}>
                  Комментировать
                </Button>
                {!isCheckedRulesAllDeactive && (
                  <Button leftIcon={<IconLock />} color="gray.2" onClick={handleDeactiveRules}>
                    Деактивировать правила
                  </Button>
                )}
                <Button leftIcon={<IconTrash />} color="orange.8" onClick={openDeleteModal}>
                  Удалить правила
                </Button>
              </Flex>
            </Flex>
          </PageFooter>
        )}
      </PageLayoutFooter>
    </PageLayout>
  );
};

export default PriceRulesPage;
