import { IPlanPairs } from '@/types/plans/plans';

import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Flex, Paper, Title } from '@mantine/core';
import { IconTrash } from '@tabler/icons-react';
import {
  MRT_BottomToolbar,
  MRT_TableContainer,
  MRT_TableInstance,
  useMantineReactTable,
} from 'mantine-react-table';
import { MRT_Localization_RU } from 'mantine-react-table/locales/ru';

import { apiGetPairs } from '@/api/plans/plans';

import { useTableState } from '@/hooks/useTableState';

import TableEmptyData from '@/ui/organisms/TableEmptyData/TableEmptyData';

import { AppDispatch } from '@/store';
import {
  fetchDeletePlanPairsAction,
  fetchPairsAction,
  selectExpandedRows,
  selectIsLoadingPairs,
  selectPlanID,
  selectPlanPairs,
  setExpandedRows,
  setIsLoadingPairs,
  setPlanPairs,
} from '@/store/slices/plans/plans';

export const PricePlansSteppersTable: FC = () => {
  const { managePlanColumns } = useTableState();

  const dispatch: AppDispatch = useDispatch();

  const planPairs = useSelector(selectPlanPairs);
  const planID = useSelector(selectPlanID);
  const expandedRows = useSelector(selectExpandedRows);
  const isLoadingPairs = useSelector(selectIsLoadingPairs);

  const getChildRows = async (row: IPlanPairs) => {
    const rowId = row.prodItem.id;
    const rowGeo = row.geoItem.id;
    if (planID)
      try {
        const getPairChilds: IPlanPairs[] = await apiGetPairs({
          id: planID,
          parentProduct: rowId,
          parentGeo: rowGeo,
        }).then((data) => data.data);
        const planPairsChildData = planPairs.map((pair) => {
          if (pair.prodItem.id === rowId && pair.geoItem.id === rowGeo) {
            return {
              ...pair,
              child: getPairChilds,
            };
          } else return pair;
        });
        dispatch(setPlanPairs(planPairsChildData));
      } catch (error) {
        console.error(error);
      }
  };

  const handleDeletePairs = async (items: IPlanPairs[]) => {
    const deleteData = items.map((item) => {
      return {
        prodItem: { id: item.prodItem.id, level: item.prodItem.level },
        geoItem: item.geoItem.id,
      };
    });
    if (planID) {
      await dispatch(
        fetchDeletePlanPairsAction({
          id: planID,
          data: deleteData,
        }),
      );
      dispatch(fetchPairsAction({ id: planID }));
      dispatch(setExpandedRows([]));
    }
  };

  const handleDelete = async (table: MRT_TableInstance<IPlanPairs>) => {
    dispatch(setIsLoadingPairs(true));
    table.resetExpanded();
    await handleDeletePairs(table.getSelectedRowModel().flatRows.map((item) => item.original));
    dispatch(setIsLoadingPairs(false));
    return table.resetRowSelection();
  };

  const editPairsTable = useMantineReactTable({
    columns: managePlanColumns,
    data: planPairs,
    enableSorting: false,
    enableBottomToolbar: true,
    enableColumnDragging: false,
    enableStickyHeader: true,
    enableRowSelection: true,
    enableExpanding: true,
    enableExpandAll: false,
    enableGrouping: true,
    enableColumnOrdering: true,
    initialState: {
      density: 'xs',
      showColumnFilters: false,
      showGlobalFilter: false,
    },
    enableColumnFilters: false,
    enablePagination: false,
    enableColumnActions: false,
    enableRowActions: false,
    getRowCanExpand: (row) => row.original.prodItem.isParent,
    getSubRows: (originalRow) => originalRow.child,
    renderBottomToolbarCustomActions: ({ table }) => (
      <Button
        loading={!!table.getSelectedRowModel().flatRows.length && isLoadingPairs}
        leftIcon={<IconTrash />}
        color="orange.8"
        disabled={!table.getSelectedRowModel().flatRows.length}
        onClick={() => handleDelete(table)}
      >
        Удалить пары
      </Button>
    ),
    mantineTableProps: {
      withColumnBorders: true,
      highlightOnHover: false,
      sx: {
        tableLayout: 'fixed',
      },
    },
    mantineTableBodyProps: {
      sx: {
        minHeight: 'auto',
      },
    },
    mantineTableHeadRowProps: {
      sx: {
        backgroundColor: '#E9ECEF',
        boxShadow: 'none',
        withBorder: true,
      },
    },
    mantineTableHeadProps: {
      sx: {
        opacity: 1,
      },
    },
    mantineTableHeadCellProps: {
      sx: {
        ['div[class~="mantine-TableHeadCell-Content-Labels"]']: {
          gap: '5px',
          color: 'black',
        },
      },
    },
    mantineSelectCheckboxProps: {
      color: 'dark',
      radius: 'sm',
    },
    mantineSelectAllCheckboxProps: {
      color: 'dark',
      radius: 'sm',
    },
    mantineExpandButtonProps: ({ row }) => {
      const isExpanded = row.getIsExpanded();
      return {
        onClick: () => {
          if (!isExpanded && !expandedRows.includes(row.id)) {
            dispatch(setExpandedRows([...expandedRows, row.id]));
            return getChildRows(row.original);
          }
          return undefined;
        },
      };
    },
    displayColumnDefOptions: {
      'mrt-row-expand': {
        header: '',
      },
      'mrt-row-select': {
        size: 48,
      },
    },
    mantineBottomToolbarProps: { display: 'flex', bg: '#F5F5F5' },
    renderEmptyRowsFallback: () => <TableEmptyData variant="planDetail" withClearButton={false} />,
    enableRowVirtualization: true,
    mantineTableContainerProps: {
      sx: { maxHeight: 'calc(100vh - 300px)', minHeight: '200px' },
    },
    state: {
      columnOrder: ['mrt-row-select', 'mrt-row-expand', 'prodItem', 'geoItem'],
    },
    localization: MRT_Localization_RU,
  });

  return (
    <Paper w={1300} withBorder>
      <Flex direction="column" align="start" gap={24} px={24}>
        <Title order={3} pt={24}>
          Список добавленных пар
        </Title>
        <Paper withBorder mb={24} radius={0}>
          <MRT_TableContainer table={editPairsTable} />
          <MRT_BottomToolbar table={editPairsTable} />
        </Paper>
      </Flex>
    </Paper>
  );
};
