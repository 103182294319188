import {
  EPriceType,
  ERuleType,
  IFixMarginRule,
  IFixPriceRuleFormValues,
} from '@/types/rules/rules';

import { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ActionIcon,
  Badge,
  Button,
  Flex,
  Select,
  Switch,
  TextInput,
  Title,
  Tooltip,
} from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { useForm } from '@mantine/form';
import { IconCalendar, IconChevronDown, IconPlus, IconTrash, IconX } from '@tabler/icons-react';
import moment from 'moment';

import {
  emptyValuesValidation,
  getFixMarginRuleInitialValues,
  modifiedFixMarginRule,
} from '@/utils/forms';
import { dateParse, modifiedNodeParams } from '@/utils/rules';

import { rulesInitialValues } from '@/constants/rules';

import 'dayjs/locale/ru';

import { openDeleteModal, useStyles } from '../FixPriceRule/FixPriceRule';
import PriceRuleBlock from '../PriceRuleBlock/PriceRuleBlock';

import { AppDispatch } from '@/store';
import { selectNodesParams } from '@/store/slices/nodes/nodes';
import {
  fetchDeleteRuleAction,
  fetchRuleEditAction,
  fetchRulesListAction,
  selectRuleBlocks,
  setCurrentRule,
  setFixMarginRule,
  setFormsCommentModalOpened,
  setRuleBlocks,
} from '@/store/slices/rules/rules';

interface FixMarginRuleProps {
  fixMarginRule: IFixMarginRule;
  alsoHasInheritedRule?: boolean;
}

const FixMarginRule: FC<FixMarginRuleProps> = ({ alsoHasInheritedRule, fixMarginRule }) => {
  const { classes } = useStyles();

  const dispatch: AppDispatch = useDispatch();

  const ruleBlocks = useSelector(selectRuleBlocks);
  const nodesParams = useSelector(selectNodesParams);

  const [disabled, setDisabled] = useState<boolean>(fixMarginRule?.inherited ?? false);

  const form = useForm({
    initialValues: {
      fields: getFixMarginRuleInitialValues(fixMarginRule?.values ?? []),
      priceType: fixMarginRule?.priceType ?? EPriceType.cost,
    },
    validate: {
      fields: {
        dateStart: (value) => emptyValuesValidation(value),
        dateEnd: (value) => emptyValuesValidation(value),
        value: (value) => emptyValuesValidation(value),
      },
      priceType: (value) => emptyValuesValidation(value),
    },
    validateInputOnBlur: true,
    validateInputOnChange: true,
  });

  const handleSubmit = (formData: {
    priceType: EPriceType | undefined;
    fields: IFixPriceRuleFormValues[];
  }) => {
    if (fixMarginRule) {
      const newValues = formData.fields.map((item) => ({
        dateStart: moment(item.dateStart).format('DD.MM.YYYY'),
        dateEnd: moment(item.dateEnd).format('DD.MM.YYYY'),
        value: Number(item.value),
      }));

      const updatedFixMarginRule = {
        ...fixMarginRule,
        priceType: formData.priceType,
        values: newValues,
      };

      dispatch(setFixMarginRule(updatedFixMarginRule));
      dispatch(setCurrentRule(updatedFixMarginRule));
      dispatch(setFormsCommentModalOpened(true));
    }
  };

  const changeActiveStatus = async (status: boolean) => {
    if (fixMarginRule) {
      const updatedFixMarginRule = {
        ...fixMarginRule,
        active: status,
      };

      dispatch(setCurrentRule(updatedFixMarginRule));
      dispatch(setFixMarginRule(updatedFixMarginRule));

      await dispatch(
        fetchRuleEditAction({
          nodes: modifiedNodeParams(nodesParams),
          rule: modifiedFixMarginRule(status, fixMarginRule),
        }),
      );

      dispatch(fetchRulesListAction(modifiedNodeParams(nodesParams)));
    }
  };

  const addValue = () => {
    if (fixMarginRule)
      dispatch(
        setFixMarginRule({
          ...fixMarginRule,
          values: [...fixMarginRule?.values, { ...rulesInitialValues.fixPriceRule }],
        }),
      );

    form.insertListItem('fields', { ...rulesInitialValues.fixPriceRuleFormValue });
  };

  const removeValue = (valueIndex: number) => {
    if (fixMarginRule) {
      if (fixMarginRule?.values.length !== 1) {
        const newValues = fixMarginRule?.values.filter((item, index) => index !== valueIndex);

        dispatch(
          setFixMarginRule({
            ...fixMarginRule,
            values: newValues,
          }),
        );

        form.removeListItem('fields', valueIndex);
      } else {
        form.setFieldValue('fields.0.dateEnd', dateParse(moment().format('DD.MM.YYYY')));
        form.setFieldValue('fields.0.dateStart', dateParse(moment().format('DD.MM.YYYY')));
        form.setFieldValue('fields.0.value', '');
      }
    }
  };

  const deleteRule = async () => {
    if (fixMarginRule?.id) {
      await dispatch(fetchDeleteRuleAction([fixMarginRule.id]));
      dispatch(fetchRulesListAction(modifiedNodeParams(nodesParams)));
    }

    dispatch(setFixMarginRule(null));
    const updatedRuleBlocks = ruleBlocks.map((item) => ({
      ...item,
      checked: item.type === ERuleType.fixMargin ? false : item.checked,
      shown: item.type === ERuleType.fixMargin ? false : item.shown,
    }));

    dispatch(setRuleBlocks(updatedRuleBlocks));
  };

  return (
    <PriceRuleBlock>
      <Flex direction="row" justify="space-between">
        {fixMarginRule?.inherited || alsoHasInheritedRule ? (
          <Badge color="green" variant="filled">
            Наследуется
          </Badge>
        ) : (
          <Switch
            classNames={{ track: classes.track }}
            checked={fixMarginRule?.active ?? false}
            color="dark"
            size="md"
            onChange={(event) => {
              changeActiveStatus(event.currentTarget.checked);
            }}
          />
        )}
        {!(alsoHasInheritedRule || fixMarginRule?.inherited) && (
          <ActionIcon
            color="dark"
            size="lg"
            variant="transparent"
            className={classes.actionIcon}
            onClick={() => openDeleteModal(deleteRule)}
          >
            <IconX />
          </ActionIcon>
        )}
      </Flex>
      <Title mt={12} mb={24} order={3}>
        {fixMarginRule?.name}
      </Title>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        {fixMarginRule?.values.map((item, index) => (
          <Flex direction="row" gap={24} align="flex-end" key={index} mb={24}>
            <DatePickerInput
              classNames={{ label: classes.label }}
              w={182}
              locale="ru"
              valueFormat="DD.MM.YYYY"
              label="Дата начала"
              description="Включительно"
              disabled={!fixMarginRule.active || disabled}
              icon={<IconCalendar size="1.1rem" stroke={1.5} />}
              maxDate={form.getInputProps(`fields.${index}.dateEnd`).value}
              clearable
              required
              {...form.getInputProps(`fields.${index}.dateStart`)}
            />
            <DatePickerInput
              classNames={{ label: classes.label }}
              w={182}
              locale="ru"
              valueFormat="DD.MM.YYYY"
              label="Дата завершения"
              description="Не включительно"
              disabled={!fixMarginRule.active || disabled}
              icon={<IconCalendar size="1.1rem" stroke={1.5} />}
              minDate={form.getInputProps(`fields.${index}.dateStart`).value}
              clearable
              required
              {...form.getInputProps(`fields.${index}.dateEnd`)}
            />
            <TextInput
              classNames={{ label: classes.label, rightSection: classes.rightSection }}
              label="Значение"
              rightSection="%"
              w={182}
              disabled={!fixMarginRule.active || disabled}
              required
              {...form.getInputProps(`fields.${index}.value`)}
            />
            <ActionIcon
              color="dark"
              size="lg"
              variant="transparent"
              className={classes.actionIcon}
              disabled={!fixMarginRule.active || disabled}
              onClick={() => removeValue(index)}
              title="Очистить"
            >
              <IconTrash />
            </ActionIcon>
          </Flex>
        ))}
        <Select
          w={380}
          mb={24}
          label="Тип ЗЦ"
          rightSection={<IconChevronDown size="1rem" />}
          data={[
            { value: EPriceType.cost, label: 'ЗЦ' },
            { value: EPriceType.cost_bonus, label: 'ЗЦ с бонусом' },
          ]}
          disabled={!fixMarginRule?.active || disabled}
          required
          {...form.getInputProps('priceType')}
        />
        <Flex direction="row" justify="space-between" mt={24}>
          <Button
            sx={(theme) => ({
              color: fixMarginRule?.active || !disabled ? theme.colors.blue[8] : 'gray',
              paddingLeft: '0px',
              ['&[data-disabled]']: {
                backgroundColor: 'white',
              },
            })}
            variant="subtle"
            leftIcon={<IconPlus size="1.3rem" />}
            disabled={!fixMarginRule?.active || disabled}
            onClick={addValue}
          >
            Добавить ещё
          </Button>
          {alsoHasInheritedRule && (
            <Tooltip
              label="Вы не можете редактировать правило, так как уже создано деактивированное правило. Перейдите во вкладку деактивированных правил."
              withArrow
              multiline
              w={350}
              openDelay={500}
              position="left"
            >
              <Flex>
                <Button color="dark.9" disabled={alsoHasInheritedRule}>
                  Редактировать
                </Button>
              </Flex>
            </Tooltip>
          )}
          {fixMarginRule?.active && disabled && !alsoHasInheritedRule && (
            <Button color="dark.9" ml={40} onClick={() => setDisabled(false)}>
              Редактировать
            </Button>
          )}
          {fixMarginRule?.active && !disabled && !alsoHasInheritedRule && (
            <Button type="submit" color="dark.9" ml={40} disabled={!form.isValid()}>
              Сохранить
            </Button>
          )}
        </Flex>
      </form>
    </PriceRuleBlock>
  );
};

export default FixMarginRule;
