import { MRT_SortingState } from 'mantine-react-table';

export const getMonitoringSort = (sorting: MRT_SortingState) => {
  if (sorting.length > 0 && sorting[0].id !== 'createdAt') {
    return sorting[0].id;
  } else return 'date';
};

export const pluralize = (number: number, titles: string[]) => {
  const cases = [2, 0, 1, 1, 1, 2];

  if (number % 1 !== 0) return titles[1];

  return titles[
    number % 100 > 4 && number % 100 < 20 ? 2 : cases[number % 10 < 5 ? number % 10 : 5]
  ];
};

export const getPositionsDescription = (length: number) => {
  return `${length} ${pluralize(length, ['позиция', 'позиции', 'позиций'])}`;
};

export const getPricesDescription = (length: number) => {
  return `${length} ${pluralize(length, ['цена', 'цены', 'цен'])}`;
};
