import { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStyles, Drawer, Paper, Tabs, Text } from '@mantine/core';

import EditPlanPageTableDrawerBarsTab from '@/ui/pages/EditPlanPage/organisms/EditPlanPageTable/organisms/EditPlanPageTableDrawer/organisms/EditPlanPageTableDrawerBarsTab/EditPlanPageTableDrawerBarsTab';
import EditPlanPageTableDrawerCommentTab from '@/ui/pages/EditPlanPage/organisms/EditPlanPageTable/organisms/EditPlanPageTableDrawer/organisms/EditPlanPageTableDrawerCommentTab/EditPlanPageTableDrawerCommentTab';
import EditPlanPageTableDrawerInfoTab from '@/ui/pages/EditPlanPage/organisms/EditPlanPageTable/organisms/EditPlanPageTableDrawer/organisms/EditPlanPageTableDrawerInfoTab/EditPlanPageTableDrawerInfoTab';
import EditPlanPageTableDrawerPriceTab from '@/ui/pages/EditPlanPage/organisms/EditPlanPageTable/organisms/EditPlanPageTableDrawer/organisms/EditPlanPageTableDrawerPriceTab/EditPlanPageTableDrawerPriceTab';

import { AppDispatch } from '@/store';
import {
  selectDetailDrawerOpened,
  selectDetailPlanInfo,
  setDetailDrawerOpened,
} from '@/store/slices/plans/plans';

const useStyles = createStyles((theme) => ({
  tabText: {
    fontSize: '16px',
    color: theme.colors.dark[1],
  },
}));

const EditPlanPageTableDrawer: FC = () => {
  const dispatch: AppDispatch = useDispatch();

  const { classes } = useStyles();

  const planDetailDrawerOpened = useSelector(selectDetailDrawerOpened);
  const editPlanInfo = useSelector(selectDetailPlanInfo);

  const [activeTab, setActiveTab] = useState<string | null>('info');

  return (
    <Drawer
      size="832px"
      position="right"
      opened={planDetailDrawerOpened}
      title={
        <Text weight={600} size={24} ml={16} mt={16}>
          {editPlanInfo && editPlanInfo.prodId ? editPlanInfo.prodId : ''}
        </Text>
      }
      onClose={() => {
        dispatch(setDetailDrawerOpened(false));
        setActiveTab('info');
      }}
    >
      <Paper px={16}>
        <Tabs value={activeTab} onTabChange={setActiveTab} color="dark">
          <Tabs.List>
            <Tabs.Tab value="info" className={classes.tabText}>
              <Text weight={500}>Информация</Text>
            </Tabs.Tab>
            <Tabs.Tab value="comment" className={classes.tabText}>
              <Text weight={500}>Комментарий</Text>
            </Tabs.Tab>
            <Tabs.Tab value="sausages" className={classes.tabText}>
              <Text weight={500}>Графики</Text>
            </Tabs.Tab>
            <Tabs.Tab value="dynamics" className={classes.tabText}>
              <Text weight={500}>Динамика цен</Text>
            </Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel value="info" pt="38px">
            <EditPlanPageTableDrawerInfoTab />
          </Tabs.Panel>

          <Tabs.Panel value="comment" pt="xs">
            <EditPlanPageTableDrawerCommentTab />
          </Tabs.Panel>

          <Tabs.Panel value="sausages" pt="xs">
            <EditPlanPageTableDrawerBarsTab />
          </Tabs.Panel>

          <Tabs.Panel value="dynamics" pt="xs">
            <EditPlanPageTableDrawerPriceTab />
          </Tabs.Panel>
        </Tabs>
      </Paper>
    </Drawer>
  );
};

export default EditPlanPageTableDrawer;
