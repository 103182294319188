import { IFilterItem } from '@/types/filters/filters';

import { IGetNodeParams, INodeParams } from '../nodes/nodes';

export interface IRule {
  id: string;
  active: boolean;
  product: string;
  prodId: string;
  region: string;
  geoId: string;
  type: string;
  typeId: string;
  createdAt: string;
  comment: string;
  attributesNodes: INodeParams[];
  tradeMark: string;
  class: string;
  prodGroup: string;
}

export interface IGetRulesParams {
  sort: string;
  order: string;
  offset?: number;
  limit?: number;
  filter: IFilterItem[];
}

export interface IGetFilteredRulesParams {
  paramName: string;
  paramValues: string[];
}
export interface ICommentRulesParams {
  id: string[];
  comment: string;
  isMultiple?: boolean;
}
export interface IRuleEditParams {
  nodes: IGetNodeParams[];
  rule:
    | IFixPriceRuleEditParams
    | IFixMarginRuleEditParams
    | IMinPriceChangeRuleEditParams
    | IMinMaxPriceRuleEditParams
    | IMinMaxMarginRuleEditParams
    | ICompetitorRuleEditParams
    | ISamePriceRuleEditParams;
}

export interface IFixPriceRuleEditParams {
  type: string;
  active: boolean;
  comment?: string;
  value: {
    values: IFixPriceRuleValues[];
  };
}
export interface IFixMarginRuleEditParams {
  type: string;
  active: boolean;
  comment?: string;
  value: {
    values: IFixPriceRuleValues[];
    priceType?: EPriceType;
  };
}
export interface IMinPriceChangeRuleEditParams {
  type: string;
  active: boolean;
  comment?: string;
  value: {
    values: IMinPriceChangeRuleValues[];
  };
}
export interface IMinMaxPriceRuleEditParams {
  type: string;
  active: boolean;
  comment?: string;
  value: {
    from?: number | string;
    to?: number | string;
  };
}
export interface IMinMaxMarginRuleEditParams {
  type: string;
  active: boolean;
  comment?: string;
  value: {
    aggregationType?: EAggregationType;
    values: IMinMaxMarginRuleValues[];
  };
}
export interface ISamePriceRuleEditParams {
  type: string;
  active: boolean;
  comment?: string;
  value: {
    productId?: number;
  };
}
export interface ICompetitorRuleEditParams {
  type: string;
  active: boolean;
  comment?: string;
  value: {
    values: ICompetitorRuleValues[];
  };
}
export interface IRuleBlock {
  type: ERuleType;
  name: string;
  active: boolean;
  inherited: boolean;
  checked: boolean;
  shown: boolean;
}

export enum ERuleType {
  fixPrice = 'fixPrice',
  fixMargin = 'fixMargin',
  minPriceChange = 'minPriceChange',
  maxPriceChange = 'maxPriceChange',
  minMaxPrice = 'minMaxPrice',
  minMaxMarginSoft = 'minMaxMarginSoft',
  minMaxMarginHard = 'minMaxMarginHard',
  competitor = 'competitor',
  samePrice = 'samePrice',
}
export interface IRuleListItem {
  id?: string;
  active: boolean;
  inherited: boolean;
  type: ERuleType;
  typeId?: string;
  name: string;
  comment?: string;
  date?: string;
}

export interface IFixPriceRule extends IRuleListItem {
  values: IFixPriceRuleValues[];
}
export interface IFixMarginRule extends IRuleListItem {
  priceType?: EPriceType;
  values: IFixPriceRuleValues[];
}
export interface IMinPriceChangeRule extends IRuleListItem {
  values: IMinPriceChangeRuleValues[];
}
export interface IMinMaxPriceRule extends IRuleListItem {
  from?: number | string;
  to?: number | string;
}
export interface IMinMaxMarginRule extends IRuleListItem {
  aggregationType?: EAggregationType;
  values: IMinMaxMarginRuleValues[];
}
export interface ISamePriceRule extends IRuleListItem {
  productId?: number;
  productName?: string;
  productParents?: number[];
}
export interface ICompetitorRule extends IRuleListItem {
  values: ICompetitorRuleValues[];
}

export interface IFixPriceRuleFormValues {
  dateStart: Date;
  dateEnd: Date;
  value: number | string;
}

export interface IFixPriceRuleValues {
  dateStart: string;
  dateEnd: string;
  value: number | string;
}

export enum EPriceType {
  cost = 'cost',
  cost_bonus = 'cost_bonus',
}
export interface IMinPriceChangeRuleValues {
  priceFrom: number | string;
  priceTo: number | string;
  decreaseValue: number | string | null;
  decreasePercent: number | string | null;
  increaseValue: number | string | null;
  increasePercent: number | string | null;
}

export enum EAggregationType {
  intersection = 'intersection',
  union = 'union',
}
export interface IMinMaxMarginRuleValues {
  priceFrom: number;
  priceTo: number;
  marginFrom: number | string | null;
  marginTo: number | string | null;
  marginWithBonusFrom: number | string | null;
  marginWithBonusTo: number | string | null;
}
export interface ICompetitorRuleValues {
  competitorBrand?: string;
  values: ICompetitorRuleValue[];
}

export interface ICompetitorRuleValue {
  priceFrom: number;
  priceTo: number;
  fromPercent: number | string | null;
  fromValue: number | string | null;
  toPercent: number | string | null;
  toValue: number | string | null;
}

export interface ICompetitorAgregation {
  name: string;
  type: string;
  id: string;
}
