import { FC, PropsWithChildren } from 'react';
import { Flex } from '@mantine/core';

const PageLayout: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Flex direction="column" h="100%">
      {children}
    </Flex>
  );
};

export default PageLayout;
