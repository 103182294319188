import { FC, PropsWithChildren } from 'react';
import { createStyles, Flex } from '@mantine/core';

const useStyles = createStyles((theme) => ({
  wrapper: {
    padding: '24px',
    border: `1px solid ${theme.colors.gray[2]}`,
    borderRadius: '8px',
  },
  label: {
    fontWeight: 'bold',
  },
  actionIcon: {
    backgroundColor: theme.colors.gray[2],
    height: '36px',
  },
}));

const PriceRuleBlock: FC<PropsWithChildren> = ({ children }) => {
  const { classes } = useStyles();

  return (
    <Flex className={classes.wrapper} direction="column">
      <Flex direction="column">{children}</Flex>
    </Flex>
  );
};

export default PriceRuleBlock;
