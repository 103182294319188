import { EFilterKey } from '@/types/filters/filters';
import { EFilterType } from '@/types/filters/filters';

import { FC } from 'react';
import { useSelector } from 'react-redux';

import FilterBoolean from '@/ui/organisms/Filters/organisms/FilterBoolean/FilterBoolean';
import FilterCheckbox from '@/ui/organisms/Filters/organisms/FilterCheckbox/FilterCheckbox';
import FilterDatesRange from '@/ui/organisms/Filters/organisms/FilterDatesRange/FilterDatesRange';
import FilterRange from '@/ui/organisms/Filters/organisms/FilterRange/FilterRange';

import { selectFilters } from '@/store/slices/filters/filters';

interface IProps {
  filterKey: EFilterKey;
}

const Filters: FC<IProps> = ({ filterKey }) => {
  const filters = useSelector(selectFilters)[filterKey]?.filters;

  return filters?.map((filter, index) => {
    switch (filter.type) {
      case EFilterType.checkbox:
        return (
          <FilterCheckbox
            key={index}
            filterKey={filterKey}
            name={filter.name}
            type={filter.type}
            code={filter.code}
            values={filter.values}
          />
        );
      case EFilterType.range:
        return (
          <FilterRange
            key={index}
            filterKey={filterKey}
            name={filter.name}
            type={filter.type}
            code={filter.code}
          />
        );
      case EFilterType.dateRange:
        return (
          <FilterDatesRange
            key={index}
            filterKey={filterKey}
            name={filter.name}
            type={filter.type}
            code={filter.code}
          />
        );
      case EFilterType.boolean:
        return (
          <FilterBoolean
            key={index}
            filterKey={filterKey}
            name={filter.name}
            type={filter.type}
            code={filter.code}
          />
        );
      default:
        return null;
    }
  });
};

export default Filters;
