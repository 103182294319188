import { ERuleType } from '@/types/rules/rules';

import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Badge, Flex, ScrollArea, Tabs } from '@mantine/core';

import { rulesInitialValues } from '@/constants/rules';

import CompetitorRule from '../../../CompetitorRule/CompetitorRule';
import FixMarginRule from '../../../FixMarginRule/FixMarginRule';
import FixPriceRule from '../../../FixPriceRule/FixPriceRule';
import MaxPriceChangeRule from '../../../MaxPriceChangeRule/MaxPriceChangeRule';
import MinMaxMarginHardRule from '../../../MinMaxMarginHardRule/MinMaxMarginHardRule';
import MinMaxMarginSoftRule from '../../../MinMaxMarginSoftRule/MinMaxMarginSoftRule';
import MinMaxPriceRule from '../../../MinMaxPriceRule/MinMaxPriceRule';
import MinPriceChangeRule from '../../../MinPriceChangeRule/MinPriceChangeRule';
import SamePriceRule from '../../../SamePriceRule/SamePriceRule';
import PriceRulesSteppersCommentModal from '../PriceRulesSteppersCommentModal/PriceRulesSteppersCommentModal';
import PriceRulesSteppersRulesPageDrawer from '../PriceRulesSteppersRulesPageDrawer/PriceRulesSteppersRulesPageDrawer';

import { useStyles } from '@/assets/styles/priceRulesSteppers';
import { AppDispatch } from '@/store';
import {
  selectCompetitorRule,
  selectCompetitorRuleInherited,
  selectFixMarginRule,
  selectFixMarginRuleInherited,
  selectFixPriceRule,
  selectFixPriceRuleInherited,
  selectMaxPriceChangeRule,
  selectMaxPriceChangeRuleInherited,
  selectMinMaxMarginHardRule,
  selectMinMaxMarginHardRuleInherited,
  selectMinMaxMarginSoftRule,
  selectMinMaxMarginSoftRuleInherited,
  selectMinMaxPriceRule,
  selectMinMaxPriceRuleInherited,
  selectMinPriceChangeRule,
  selectMinPriceChangeRuleInherited,
  selectRuleBlocks,
  selectRulesList,
  selectSamePriceRule,
  selectSamePriceRuleInherited,
  setCompetitorRule,
  setFixMarginRule,
  setFixPriceRule,
  setMaxPriceChangeRule,
  setMinMaxMarginHardRule,
  setMinMaxMarginSoftRule,
  setMinMaxPriceRule,
  setMinPriceChangeRule,
  setSamePriceRule,
} from '@/store/slices/rules/rules';

const PriceRulesSteppersRulesForms: FC = () => {
  const { classes } = useStyles();

  const dispatch: AppDispatch = useDispatch();

  const [activeTab, setActiveTab] = useState<string | null>('active');

  const ruleBlocks = useSelector(selectRuleBlocks);
  const rulesList = useSelector(selectRulesList);

  const fixPriceRule = useSelector(selectFixPriceRule);
  const fixPriceRuleInherited = useSelector(selectFixPriceRuleInherited);

  const fixMarginRule = useSelector(selectFixMarginRule);
  const fixMarginRuleInherited = useSelector(selectFixMarginRuleInherited);

  const minPriceChangeRule = useSelector(selectMinPriceChangeRule);
  const minPriceChangeRuleInherited = useSelector(selectMinPriceChangeRuleInherited);

  const maxPriceChangeRule = useSelector(selectMaxPriceChangeRule);
  const maxPriceChangeRuleInherited = useSelector(selectMaxPriceChangeRuleInherited);

  const minMaxPriceRule = useSelector(selectMinMaxPriceRule);
  const minMaxPriceRuleInherited = useSelector(selectMinMaxPriceRuleInherited);

  const minMaxMarginSoftRule = useSelector(selectMinMaxMarginSoftRule);
  const minMaxMarginSoftRuleInherited = useSelector(selectMinMaxMarginSoftRuleInherited);

  const minMaxMarginHardRule = useSelector(selectMinMaxMarginHardRule);
  const minMaxMarginHardRuleInherited = useSelector(selectMinMaxMarginHardRuleInherited);

  const competitorRule = useSelector(selectCompetitorRule);
  const competitorRuleInherited = useSelector(selectCompetitorRuleInherited);

  const samePriceRule = useSelector(selectSamePriceRule);
  const samePriceRuleInherited = useSelector(selectSamePriceRuleInherited);

  const duplicateRulesCount = rulesList
    ? rulesList.map((item) => item.type).length -
      [...new Set(rulesList.map((item) => item.type))].length
    : 0;

  const activeRuleBlocksCount =
    ruleBlocks.filter((item) => item.active && item.shown).length + duplicateRulesCount;
  const inactiveRuleBlocksCount = ruleBlocks.filter((item) => !item.active && item.shown).length;

  const ruleItem = (ruleType: ERuleType) =>
    ruleBlocks.find((item) => item.type === ruleType && item.shown);

  const alsoHasInheritedRule = (ruleType: ERuleType) =>
    rulesList?.filter((item) => item.type === ruleType).length === 2;

  useEffect(() => {
    if (!fixPriceRule && ruleItem(ERuleType.fixPrice)) {
      dispatch(
        setFixPriceRule({
          active: true,
          inherited: false,
          type: ERuleType.fixPrice,
          name: 'Фиксированная цена',
          values: [{ ...rulesInitialValues.fixPriceRule }],
        }),
      );
    }

    if (!fixMarginRule && ruleItem(ERuleType.fixMargin)) {
      dispatch(
        setFixMarginRule({
          active: true,
          inherited: false,
          type: ERuleType.fixMargin,
          name: 'Фиксированная наценка',
          priceType: undefined,
          values: [{ ...rulesInitialValues.fixPriceRule }],
        }),
      );
    }

    if (!minPriceChangeRule && ruleItem(ERuleType.minPriceChange)) {
      dispatch(
        setMinPriceChangeRule({
          active: true,
          inherited: false,
          type: ERuleType.minPriceChange,
          name: 'Минимальное изменение цены',
          values: [{ ...rulesInitialValues.minPriceChangeRule }],
        }),
      );
    }

    if (!maxPriceChangeRule && ruleItem(ERuleType.maxPriceChange)) {
      dispatch(
        setMaxPriceChangeRule({
          active: true,
          inherited: false,
          type: ERuleType.maxPriceChange,
          name: 'Максимальное изменение цены',
          values: [{ ...rulesInitialValues.minPriceChangeRule }],
        }),
      );
    }

    if (!minMaxPriceRule && ruleItem(ERuleType.minMaxPrice)) {
      dispatch(
        setMinMaxPriceRule({
          active: true,
          inherited: false,
          type: ERuleType.minMaxPrice,
          name: 'Контроль РРЦ',
          from: undefined,
          to: undefined,
        }),
      );
    }

    if (!minMaxMarginSoftRule && ruleItem(ERuleType.minMaxMarginSoft)) {
      dispatch(
        setMinMaxMarginSoftRule({
          active: true,
          inherited: false,
          type: ERuleType.minMaxMarginSoft,
          name: 'Диапазон наценки',
          values: [{ ...rulesInitialValues.minMaxMarginRule }],
        }),
      );
    }

    if (!minMaxMarginHardRule && ruleItem(ERuleType.minMaxMarginHard)) {
      dispatch(
        setMinMaxMarginHardRule({
          active: true,
          inherited: false,
          type: ERuleType.minMaxMarginHard,
          name: 'Диапазон наценки (приоритетный)',
          values: [{ ...rulesInitialValues.minMaxMarginRule }],
        }),
      );
    }

    if (!competitorRule && ruleItem(ERuleType.competitor)) {
      dispatch(
        setCompetitorRule({
          active: true,
          inherited: false,
          type: ERuleType.competitor,
          name: 'Цена конкурентов',
          values: [
            {
              competitorBrand: '',
              values: [{ ...rulesInitialValues.competitorRule }],
            },
          ],
        }),
      );
    }

    if (!samePriceRule && ruleItem(ERuleType.samePrice)) {
      dispatch(
        setSamePriceRule({
          active: true,
          inherited: false,
          type: ERuleType.samePrice,
          name: 'Одинаковая цена',
        }),
      );
    }
  }, [ruleBlocks]);

  return (
    <Tabs
      value={activeTab}
      onTabChange={setActiveTab}
      color="gray"
      mt={24}
      classNames={{
        tabsList: classes.tabsList,
        tab: classes.tab,
      }}
    >
      <Tabs.List mb={24}>
        <Tabs.Tab
          rightSection={
            <Badge
              w={24}
              h={24}
              sx={(theme) => ({
                pointerEvents: 'none',
                backgroundColor: activeTab === 'active' ? 'black' : theme.colors.gray[2],
                color: activeTab === 'active' ? 'white' : theme.colors.gray[6],
              })}
              variant="filled"
              size="lg"
              p={0}
            >
              {activeRuleBlocksCount}
            </Badge>
          }
          value="active"
        >
          Активные правила
        </Tabs.Tab>
        <Tabs.Tab
          rightSection={
            <Badge
              w={24}
              h={24}
              sx={(theme) => ({
                pointerEvents: 'none',
                backgroundColor: activeTab === 'disactive' ? 'black' : theme.colors.gray[2],
                color: activeTab === 'disactive' ? 'white' : theme.colors.gray[6],
              })}
              variant="filled"
              size="lg"
              p={0}
            >
              {inactiveRuleBlocksCount}
            </Badge>
          }
          value="disactive"
        >
          Неактивные правила
        </Tabs.Tab>
      </Tabs.List>

      <Tabs.Panel value="active" pt="xs">
        <ScrollArea h={'calc(100vh - 380px)'}>
          <Flex direction="column" gap={24}>
            {fixPriceRule?.active && <FixPriceRule fixPriceRule={fixPriceRule} />}
            {fixPriceRuleInherited && alsoHasInheritedRule(ERuleType.fixPrice) && (
              <FixPriceRule alsoHasInheritedRule={true} fixPriceRule={fixPriceRuleInherited} />
            )}

            {fixMarginRule?.active && <FixMarginRule fixMarginRule={fixMarginRule} />}
            {fixMarginRuleInherited && alsoHasInheritedRule(ERuleType.fixMargin) && (
              <FixMarginRule alsoHasInheritedRule={true} fixMarginRule={fixMarginRuleInherited} />
            )}

            {samePriceRule?.active && <SamePriceRule samePriceRule={samePriceRule} />}
            {samePriceRuleInherited && alsoHasInheritedRule(ERuleType.samePrice) && (
              <SamePriceRule alsoHasInheritedRule={true} samePriceRule={samePriceRuleInherited} />
            )}

            {maxPriceChangeRule?.active && (
              <MaxPriceChangeRule maxPriceChangeRule={maxPriceChangeRule} />
            )}
            {maxPriceChangeRuleInherited && alsoHasInheritedRule(ERuleType.maxPriceChange) && (
              <MaxPriceChangeRule
                alsoHasInheritedRule={true}
                maxPriceChangeRule={maxPriceChangeRuleInherited}
              />
            )}

            {minPriceChangeRule?.active && (
              <MinPriceChangeRule minPriceChangeRule={minPriceChangeRule} />
            )}
            {minPriceChangeRuleInherited && alsoHasInheritedRule(ERuleType.minPriceChange) && (
              <MinPriceChangeRule
                alsoHasInheritedRule={true}
                minPriceChangeRule={minPriceChangeRuleInherited}
              />
            )}

            {minMaxPriceRule?.active && <MinMaxPriceRule minMaxPriceRule={minMaxPriceRule} />}
            {minMaxPriceRuleInherited && alsoHasInheritedRule(ERuleType.minMaxPrice) && (
              <MinMaxPriceRule
                alsoHasInheritedRule={true}
                minMaxPriceRule={minMaxPriceRuleInherited}
              />
            )}

            {minMaxMarginHardRule?.active && (
              <MinMaxMarginHardRule minMaxMarginHardRule={minMaxMarginHardRule} />
            )}
            {minMaxMarginHardRuleInherited && alsoHasInheritedRule(ERuleType.minMaxMarginHard) && (
              <MinMaxMarginHardRule
                alsoHasInheritedRule={true}
                minMaxMarginHardRule={minMaxMarginHardRuleInherited}
              />
            )}

            {competitorRule?.active && <CompetitorRule competitorRule={competitorRule} />}
            {competitorRuleInherited && alsoHasInheritedRule(ERuleType.competitor) && (
              <CompetitorRule
                alsoHasInheritedRule={true}
                competitorRule={competitorRuleInherited}
              />
            )}

            {minMaxMarginSoftRule?.active && (
              <MinMaxMarginSoftRule minMaxMarginSoftRule={minMaxMarginSoftRule} />
            )}
            {minMaxMarginSoftRuleInherited && alsoHasInheritedRule(ERuleType.minMaxMarginSoft) && (
              <MinMaxMarginSoftRule
                alsoHasInheritedRule={true}
                minMaxMarginSoftRule={minMaxMarginSoftRuleInherited}
              />
            )}
          </Flex>
          {!(
            fixPriceRule &&
            fixMarginRule &&
            minPriceChangeRule &&
            maxPriceChangeRule &&
            minMaxPriceRule &&
            minMaxMarginSoftRule &&
            minMaxMarginHardRule &&
            competitorRule &&
            samePriceRule
          ) && <PriceRulesSteppersRulesPageDrawer />}
        </ScrollArea>
      </Tabs.Panel>

      <Tabs.Panel value="disactive" pt="xs">
        <ScrollArea h={550}>
          <Flex direction="column" gap={24}>
            {fixPriceRule && !fixPriceRule?.active && <FixPriceRule fixPriceRule={fixPriceRule} />}
            {fixMarginRule && !fixMarginRule?.active && (
              <FixMarginRule fixMarginRule={fixMarginRule} />
            )}
            {minPriceChangeRule && !minPriceChangeRule?.active && (
              <MinPriceChangeRule minPriceChangeRule={minPriceChangeRule} />
            )}
            {maxPriceChangeRule && !maxPriceChangeRule?.active && (
              <MaxPriceChangeRule maxPriceChangeRule={maxPriceChangeRule} />
            )}
            {minMaxPriceRule && !minMaxPriceRule?.active && (
              <MinMaxPriceRule minMaxPriceRule={minMaxPriceRule} />
            )}
            {minMaxMarginSoftRule && !minMaxMarginSoftRule?.active && (
              <MinMaxMarginSoftRule minMaxMarginSoftRule={minMaxMarginSoftRule} />
            )}
            {minMaxMarginHardRule && !minMaxMarginHardRule?.active && (
              <MinMaxMarginHardRule minMaxMarginHardRule={minMaxMarginHardRule} />
            )}
            {competitorRule && !competitorRule?.active && (
              <CompetitorRule competitorRule={competitorRule} />
            )}
            {samePriceRule && !samePriceRule?.active && (
              <SamePriceRule samePriceRule={samePriceRule} />
            )}
          </Flex>
        </ScrollArea>
      </Tabs.Panel>

      <PriceRulesSteppersCommentModal />
    </Tabs>
  );
};

export default PriceRulesSteppersRulesForms;
