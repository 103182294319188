import { paths, titles } from '@/constants/paths';

export const menuItems = [
  {
    title: titles.TITLE_PRICE_PLANS,
    description:
      'В данном разделе выполняется работа c ценовыми анализами. Можно создать, посмотреть, согласовать и удалить ценовые анализы.',
    path: paths.PATH_PRICE_PLANS,
    fileName: 'plans.pdf',
    instructionName: 'Инструкция ценовые анализы.pdf',
  },
  {
    title: titles.TITLE_MONITORING,
    description:
      'В данном разделе выполняется отслеживание аномалий по конкурентам. Можно посмотреть информацию по аномалиям и снять аномалии с блокировки.',
    path: paths.PATH_MONITORING,
    fileName: 'monitorings.pdf',
    instructionName: 'Инструкция мониторинги.pdf',
  },
  {
    title: titles.TITLE_PRICE_RULES,
    description:
      'В данном разделе выполняется работа с ценовыми правилами. Можно создать, посмотреть, редактировать и удалить ценовые правила.',
    path: paths.PATH_PRICE_RULES,
    fileName: 'rules.pdf',
    instructionName: 'Инструкция ценовые правила.pdf',
  },
  {
    title: titles.TITLE_PRICE_QUEUE,
    description: 'В данном разделе выполняется мониторинг очереди обновления регулярных цен.',
    path: paths.PATH_PRICE_QUEUE,
    fileName: 'prices.pdf',
    instructionName: 'Инструкция очередь цен.pdf',
  },
];
