import {
  IPlanDetailPairInfo,
  IPlanDetailPriceInfo,
  IPlanEditItem,
  IPlanInfoTitle,
  IPlanItem,
  IPlanPairs,
} from '@/types/plans/plans';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MRT_SortingState } from 'mantine-react-table';

import {
  apiDeletePairs,
  apiGetPairs,
  apiGetPlanDetails,
  apiGetPlanPairProcessInfo,
  apiGetPlanPriceInfo,
  apiPostAddPairs,
  apiPostChangePlansStatus,
  apiPostClonePlan,
  apiPostCommentPlan,
  apiPostCreatePlan,
  apiPostExportPlanDetail,
  apiPostExportPlans,
  apiPostPlanDetailComment,
  apiPostPlanDetailData,
  apiPostPlanDetailProcessPairs,
  apiPostPlanDetailStatusChange,
  apiPostPlans,
  apiPostProcessPlan,
  apiPutChangePlanStatus,
  apiPutEditPlan,
  apiPutPlanItemLockPrice,
  apiPutUndraftPlan,
} from '@/api/plans/plans';

import { createAxiosThunk } from '@/utils/asyncRequest';
import notify from '@/utils/notify';

interface IPlansState {
  plansFilters: {
    query: string;
    inputQuery: string;
  };
  plans: IPlanItem[] | null;
  plansFetching: boolean;
  totalPlansCount: number;
  totalPlanDetailCount: number;
  plansCommentModalOpened: boolean;
  checkedPlan: IPlanItem | null;
  plansRefetch: boolean;
  checkedPlans: IPlanItem[];
  plansStep: number;
  planId: string | null;
  planPairs: IPlanPairs[];
  expandedRows: string[];
  planDetails: IPlanInfoTitle | null;
  planDetailRefetch: boolean;
  planDetailCheckedItem: IPlanEditItem | null;
  planDetailCheckedItems: IPlanEditItem[];
  editPlanCommentModalOpened: boolean;
  detailDrawerOpened: boolean;
  detailPlanInfo: IPlanEditItem | null;
  isLoadingPairs: boolean;
  planDetailPairProcessInfo: IPlanDetailPairInfo | null;
  planDetailPriceInfo: IPlanDetailPriceInfo;
  planChangeLockPriceModalOpened: boolean;
  fetchingPlansExport: boolean;
  fetchingPlanDetailExport: boolean;
  plansSorting: MRT_SortingState;
  planDetailSorting: MRT_SortingState;
  fetchingClonePlan: boolean;
}

const initialState: IPlansState = {
  plansFilters: { query: '', inputQuery: '' },
  plans: null,
  plansFetching: false,
  totalPlansCount: 0,
  totalPlanDetailCount: 0,
  plansCommentModalOpened: false,
  checkedPlan: null,
  plansRefetch: false,
  checkedPlans: [],
  plansStep: 0,
  planId: null,
  planPairs: [],
  expandedRows: [],
  planDetails: null,
  planDetailRefetch: false,
  planDetailCheckedItem: null,
  planDetailCheckedItems: [],
  editPlanCommentModalOpened: false,
  detailDrawerOpened: false,
  detailPlanInfo: null,
  isLoadingPairs: false,
  planDetailPairProcessInfo: null,
  planDetailPriceInfo: { points: [] },
  planChangeLockPriceModalOpened: false,
  fetchingPlansExport: false,
  fetchingPlanDetailExport: false,
  plansSorting: [],
  planDetailSorting: [],
  fetchingClonePlan: false,
};

export const fetchPlansAction = createAxiosThunk('/getPlans', apiPostPlans);
export const fetchProcessPlansStatusAction = createAxiosThunk('/processPlans', apiPostProcessPlan);
export const fetchClonePlanAction = createAxiosThunk('/clonePlan', apiPostClonePlan);
export const fetchCommentPlanAction = createAxiosThunk('/commentPlan', apiPostCommentPlan);
export const fetchChangePlanStatusAction = createAxiosThunk(
  '/changePlanStatus',
  apiPutChangePlanStatus,
);
export const fetchChangePlansStatusAction = createAxiosThunk(
  '/changePlansStatus',
  apiPostChangePlansStatus,
);
export const fetchCreateNewPlanAction = createAxiosThunk('/createNewPlan', apiPostCreatePlan);
export const fetchEditPlanAction = createAxiosThunk('/editPlan', apiPutEditPlan);
export const fetchAddPairsAction = createAxiosThunk('/planAddPairs', apiPostAddPairs);
export const fetchPairsAction = createAxiosThunk('/planGetPairs', apiGetPairs);
export const fetchDeletePlanPairsAction = createAxiosThunk('/planDeletePairs', apiDeletePairs);
export const fetchUndraftPlanAction = createAxiosThunk('/undraftPlan', apiPutUndraftPlan);
export const fetchPlanDetailAction = createAxiosThunk('/getPlanDetails', apiGetPlanDetails);
export const fetchPlanDetailDataAction = createAxiosThunk(
  '/getPlanDetailData',
  apiPostPlanDetailData,
);
export const fetchPlanDetailCommentAction = createAxiosThunk(
  '/detailCommentPlan',
  apiPostPlanDetailComment,
);
export const fetchPlanDetailPairProcessInfoAction = createAxiosThunk(
  '/getPlanDetailPairProcessInfo',
  apiGetPlanPairProcessInfo,
);
export const fetchPlanDetailPriceInfoAction = createAxiosThunk(
  '/getPlanDetailPriceInfo',
  apiGetPlanPriceInfo,
);
export const fetchPlanItemLockPriceAction = createAxiosThunk(
  '/putPlanItemLockPrice',
  apiPutPlanItemLockPrice,
);
export const fetchPlanDetailChangeStatusAction = createAxiosThunk(
  '/postPlanDetailChangeStatus',
  apiPostPlanDetailStatusChange,
);
export const fetchPostPlanDetailProcessPairsAction = createAxiosThunk(
  'postPlanDetailProcessPairs',
  apiPostPlanDetailProcessPairs,
);
export const fetchExportPlansAction = createAxiosThunk('/exportPlans', apiPostExportPlans);

export const fetchExportPlanDetailAction = createAxiosThunk(
  '/exportPlanDetail',
  apiPostExportPlanDetail,
);

export const plansSlice = createSlice({
  name: 'plans',
  initialState: initialState,
  reducers: {
    setActivePlansQuery: (state, action: PayloadAction<string>) => {
      state.plansFilters.query = action.payload;
    },
    setInputQuery: (state, action: PayloadAction<string>) => {
      state.plansFilters.inputQuery = action.payload;
    },
    setPlansCommentModalOpened: (state, action: PayloadAction<boolean>) => {
      state.plansCommentModalOpened = action.payload;
    },
    setCheckedPlan: (state, action: PayloadAction<IPlanItem | null>) => {
      state.checkedPlan = action.payload;
    },
    setCheckedPlans: (state, action: PayloadAction<IPlanItem[]>) => {
      state.checkedPlans = action.payload;
    },
    setPlansItems: (state, action: PayloadAction<IPlanItem[]>) => {
      state.plans = action.payload;
    },
    setPlansRefetch: (state, action: PayloadAction<boolean>) => {
      state.plansRefetch = action.payload;
    },
    setPlansStep: (state, action: PayloadAction<number>) => {
      state.plansStep = action.payload;
    },
    setPlanPairs: (state, action: PayloadAction<IPlanPairs[]>) => {
      state.planPairs = action.payload;
    },
    setExpandedRows: (state, action: PayloadAction<string[]>) => {
      state.expandedRows = action.payload;
    },
    setPlanDetailRefetch: (state, action: PayloadAction<boolean>) => {
      state.planDetailRefetch = action.payload;
    },
    setDetailCheckedPlan: (state, action: PayloadAction<IPlanEditItem | null>) => {
      state.planDetailCheckedItem = action.payload;
    },
    setDetailCheckedPlans: (state, action: PayloadAction<IPlanEditItem[]>) => {
      state.planDetailCheckedItems = action.payload;
    },
    setEditPlanCommentModalOpened: (state, action: PayloadAction<boolean>) => {
      state.editPlanCommentModalOpened = action.payload;
    },
    setDetailDrawerOpened: (state, action: PayloadAction<boolean>) => {
      state.detailDrawerOpened = action.payload;
    },
    setDetailPlanInfo: (state, action: PayloadAction<IPlanEditItem | null>) => {
      state.detailPlanInfo = action.payload;
    },
    setIsLoadingPairs: (state, action: PayloadAction<boolean>) => {
      state.isLoadingPairs = action.payload;
    },
    setPlanId: (state, action: PayloadAction<string | null>) => {
      state.planId = action.payload;
    },
    setPlanDetail: (state, action: PayloadAction<IPlanInfoTitle | null>) => {
      state.planDetails = action.payload;
    },
    setPlanChangeLockPriceModalOpened: (state, action: PayloadAction<boolean>) => {
      state.planChangeLockPriceModalOpened = action.payload;
    },
    setPlansSorting: (state, action: PayloadAction<MRT_SortingState>) => {
      state.plansSorting = action.payload;
    },
    setPlanDetailSorting: (state, action: PayloadAction<MRT_SortingState>) => {
      state.planDetailSorting = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPlansAction.pending, (state) => {
      state.plansFetching = true;
    });
    builder.addCase(fetchPlansAction.fulfilled, (state, action) => {
      state.plansFetching = false;
      state.plans = action.payload.items;
      state.totalPlansCount = action.payload.total;
    });
    builder.addCase(fetchPlansAction.rejected, (state) => {
      state.plansFetching = false;
    });
    builder.addCase(fetchPlanDetailDataAction.fulfilled, (state, action) => {
      state.totalPlanDetailCount = action.payload.total;
    });
    builder.addCase(fetchClonePlanAction.fulfilled, (state, action) => {
      notify({ message: `Ценовой анализ ${action.payload.name} создан`, type: 'success' });
      state.fetchingClonePlan = false;
    });
    builder.addCase(fetchClonePlanAction.pending, (state) => {
      state.fetchingClonePlan = true;
    });
    builder.addCase(fetchClonePlanAction.rejected, (state) => {
      notify({ message: 'Не удалось скопировать анализ. Повторите попытку позже', type: 'error' });
      state.fetchingClonePlan = false;
    });
    builder.addCase(fetchCommentPlanAction.fulfilled, () => {
      notify({ message: 'Сохранено', type: 'success' });
    });
    builder.addCase(fetchChangePlansStatusAction.fulfilled, () => {
      notify({ message: 'Статус успешно изменен', type: 'success' });
    });
    builder.addCase(fetchPlanDetailCommentAction.fulfilled, () => {
      notify({ message: 'Сохранено', type: 'success' });
    });
    builder.addCase(fetchPostPlanDetailProcessPairsAction.fulfilled, () => {
      notify({ message: 'Успешно', type: 'success' });
    });
    builder.addCase(fetchCreateNewPlanAction.fulfilled, (state, action) => {
      state.planId = action.payload.planId;
    });
    builder.addCase(fetchPairsAction.fulfilled, (state, action) => {
      state.planPairs = action.payload ?? [];
    });
    builder.addCase(fetchPlanDetailAction.fulfilled, (state, action) => {
      state.planDetails = action.payload;
    });
    builder.addCase(fetchPlanDetailPairProcessInfoAction.fulfilled, (state, action) => {
      state.planDetailPairProcessInfo = action.payload;
    });
    builder.addCase(fetchPlanDetailPriceInfoAction.fulfilled, (state, action) => {
      state.planDetailPriceInfo = action.payload;
    });
    builder.addCase(fetchPlanItemLockPriceAction.fulfilled, () => {
      notify({ message: 'Сохранено', type: 'success' });
    });
    builder.addCase(fetchExportPlansAction.pending, (state) => {
      state.fetchingPlansExport = true;
    });
    builder.addCase(fetchExportPlansAction.fulfilled, (state) => {
      state.fetchingPlansExport = false;
    });
    builder.addCase(fetchExportPlansAction.rejected, (state) => {
      state.fetchingPlansExport = false;
    });
    builder.addCase(fetchExportPlanDetailAction.pending, (state) => {
      state.fetchingPlanDetailExport = true;
    });
    builder.addCase(fetchExportPlanDetailAction.fulfilled, (state) => {
      state.fetchingPlanDetailExport = false;
    });
    builder.addCase(fetchExportPlanDetailAction.rejected, (state) => {
      state.fetchingPlanDetailExport = false;
    });
  },
});

type TSelectorState = {
  plans: IPlansState;
};

export const selectPlansCommentModalOpened = (state: TSelectorState) =>
  state.plans.plansCommentModalOpened;
export const selectPlans = (state: TSelectorState) => state.plans.plans;
export const selectPlansFetching = (state: TSelectorState) => state.plans.plansFetching;
export const selectTotalPlansCount = (state: TSelectorState) => state.plans.totalPlansCount;
export const selectCheckedPlan = (state: TSelectorState) => state.plans.checkedPlan;
export const selectPlansQuery = (state: TSelectorState) => state.plans.plansFilters.query;
export const selectInputQuery = (state: TSelectorState) => state.plans.plansFilters.inputQuery;
export const selectPlansRefetch = (state: TSelectorState) => state.plans.plansRefetch;
export const selectCheckedPlans = (state: TSelectorState) => state.plans.checkedPlans;
export const selectPlansStep = (state: TSelectorState) => state.plans.plansStep;
export const selectPlanID = (state: TSelectorState) => state.plans.planId;
export const selectPlanPairs = (state: TSelectorState) => state.plans.planPairs;
export const selectExpandedRows = (state: TSelectorState) => state.plans.expandedRows;
export const selectPlanDetails = (state: TSelectorState) => state.plans.planDetails;
export const selectPlanDetailRefetch = (state: TSelectorState) => state.plans.planDetailRefetch;
export const selectPlanDetailCheckedItem = (state: TSelectorState) =>
  state.plans.planDetailCheckedItem;
export const selectPlanDetailCheckedItems = (state: TSelectorState) =>
  state.plans.planDetailCheckedItems;
export const selectEditPlanCommentModalOpened = (state: TSelectorState) =>
  state.plans.editPlanCommentModalOpened;
export const selectDetailDrawerOpened = (state: TSelectorState) => state.plans.detailDrawerOpened;
export const selectDetailPlanInfo = (state: TSelectorState) => state.plans.detailPlanInfo;
export const selectIsLoadingPairs = (state: TSelectorState) => state.plans.isLoadingPairs;
export const selectPlanChangeLockPriceModalOpened = (state: TSelectorState) =>
  state.plans.planChangeLockPriceModalOpened;
export const selectDetailPriceInfo = (state: TSelectorState) => state.plans.planDetailPriceInfo;
export const selectDetailPairProcessInfo = (state: TSelectorState) =>
  state.plans.planDetailPairProcessInfo;
export const selectFetchingPlansExport = (state: TSelectorState) => state.plans.fetchingPlansExport;
export const selectFetchingPlanDetailExport = (state: TSelectorState) =>
  state.plans.fetchingPlanDetailExport;
export const selectPlansSorting = (state: TSelectorState) => state.plans.plansSorting;
export const selectPlanDetailSorting = (state: TSelectorState) => state.plans.planDetailSorting;
export const selectTotalPlanDetailCount = (state: TSelectorState) =>
  state.plans.totalPlanDetailCount;
export const selectFetchingClonePlan = (state: TSelectorState) => state.plans.fetchingClonePlan;

export const {
  setActivePlansQuery,
  setPlansCommentModalOpened,
  setCheckedPlan,
  setInputQuery,
  setPlansItems,
  setPlansRefetch,
  setCheckedPlans,
  setPlansStep,
  setPlanPairs,
  setExpandedRows,
  setPlanDetailRefetch,
  setDetailCheckedPlan,
  setDetailCheckedPlans,
  setEditPlanCommentModalOpened,
  setDetailDrawerOpened,
  setDetailPlanInfo,
  setIsLoadingPairs,
  setPlanId,
  setPlanDetail,
  setPlanChangeLockPriceModalOpened,
  setPlansSorting,
  setPlanDetailSorting,
} = plansSlice.actions;

export default plansSlice.reducer;
