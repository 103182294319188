import { FC } from 'react';

import { titles } from '@/constants/paths';

import PageHeader from '@/ui/organisms/PageHeader/PageHeader';
import { PricePageTable } from '@/ui/pages/PricesPage/organisms/PricePageTable/PricePageTable';
import { PricesPageFilters } from '@/ui/pages/PricesPage/organisms/PricesPageFilters/PricesPageFilters';
import { PricesPageInfo } from '@/ui/pages/PricesPage/organisms/PricesPageInfo/PricesPageInfo';
import PageLayoutBody from '@/ui/templates/PageLayout/organisms/PageLayoutBody/PageLayoutBody';
import PageLayoutHeader from '@/ui/templates/PageLayout/organisms/PageLayoutHeader/PageLayoutHeader';
import PageLayout from '@/ui/templates/PageLayout/PageLayout';

export const PricesPage: FC = () => {
  return (
    <PageLayout>
      <PageLayoutHeader>
        <PageHeader title={titles.TITLE_PRICE_QUEUE} />
      </PageLayoutHeader>

      <PageLayoutBody>
        <PricesPageInfo />
        <PricesPageFilters />
        <PricePageTable />
      </PageLayoutBody>
    </PageLayout>
  );
};
