import { IItems } from '@/types/common/common';
import { IRule } from '@/types/rules/rules';

import { FC, type UIEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Flex, Loader } from '@mantine/core';
import { useInfiniteQuery } from '@tanstack/react-query';
import {
  type MRT_SortingState,
  MRT_TableContainer,
  type MRT_Virtualizer,
  useMantineReactTable,
} from 'mantine-react-table';
import { MRT_Localization_RU } from 'mantine-react-table/locales/ru';

import { modifiedTreeFilter } from '@/utils/filters';
import { getOrder, getSort } from '@/utils/rules';

import { fetchOptions } from '@/constants/fetch';
import { useTableState } from '@/hooks/useTableState';

import TableEmptyData from '@/ui/organisms/TableEmptyData/TableEmptyData';

import PriceRulesTableCommentModal from './organisms/PriceRulesTableCommentModal/PriceRulesTableCommentModal';
import PriceRulesTableRowAction from './organisms/PriceRulesTableRowAction/PriceRulesTableRowAction';

import { AppDispatch } from '@/store';
import { selectActiveFilters } from '@/store/slices/filters/filters';
import {
  fetchRulesAction,
  selectActiveFilters as selectTreeFilter,
  selectCommentModalOpened,
  selectRulesRefetch,
  setActiveRulesFilters,
  setCheckedRule,
  setCheckedRules,
  setRulesSorting,
} from '@/store/slices/rules/rules';

const PriceRulesTable: FC = () => {
  const { rulesColumns } = useTableState();

  const dispatch: AppDispatch = useDispatch();

  const tableContainerRef = useRef<HTMLDivElement>(null);
  const rowVirtualizerInstanceRef =
    useRef<MRT_Virtualizer<HTMLDivElement, HTMLTableRowElement>>(null);

  const [sorting, setSorting] = useState<MRT_SortingState>([]);

  const rulesRefetch = useSelector(selectRulesRefetch);
  const activeFilters = useSelector(selectActiveFilters).rules;
  const activeTreeFilter = useSelector(selectTreeFilter);
  const commentModalOpened = useSelector(selectCommentModalOpened);

  const getInfiniteData = async ({ pageParam = 0 }) => {
    const result = await dispatch(
      fetchRulesAction({
        sort: getSort(sorting),
        order: getOrder(sorting),
        offset: pageParam * fetchOptions.FETCH_RULES_LIMIT,
        limit: fetchOptions.FETCH_RULES_LIMIT,
        filter: [...activeFilters, ...modifiedTreeFilter(activeTreeFilter)],
      }),
    );
    return result.payload;
  };

  const { data, fetchNextPage, isFetching, isLoading } = useInfiniteQuery<IItems<IRule>>({
    queryKey: [
      'table-data',
      sorting,
      activeFilters,
      rulesRefetch,
      modifiedTreeFilter(activeTreeFilter),
    ],
    queryFn: getInfiniteData,
    getNextPageParam: (_lastGroup, groups) => groups.length,
  });

  const flatData = useMemo(
    () => (data?.pages[0]?.items ? data?.pages.flatMap((page) => page?.items) : []),
    [data],
  );

  const totalRowsCount = data?.pages?.[0]?.total ?? 0;
  const totalFetched = flatData.length;

  const fetchMoreOnBottomReached = useCallback(
    (containerRefElement?: HTMLDivElement | null) => {
      if (containerRefElement) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
        if (
          scrollHeight - scrollTop - clientHeight < 646 &&
          !isFetching &&
          totalFetched < totalRowsCount
        ) {
          fetchNextPage();
        }
      }
    },
    [fetchNextPage, isFetching, totalFetched],
  );

  const rulesTable = useMantineReactTable({
    columns: rulesColumns,
    data: flatData,
    enableColumnResizing: true,
    enableStickyHeader: true,
    enableRowSelection: true,
    enableGrouping: true,
    enableColumnOrdering: true,
    initialState: {
      density: 'xs',
      showColumnFilters: false,
      showGlobalFilter: false,
      columnPinning: {
        right: ['mrt-row-actions'],
      },
    },
    enableColumnFilters: false,
    enablePagination: false,
    enableColumnActions: false,
    enableRowActions: true,
    positionActionsColumn: 'last',
    displayColumnDefOptions: {
      'mrt-row-actions': {
        header: '',
        size: 43,
        mantineTableBodyCellProps: ({ row }) =>
          row.getIsSelected() ? { bg: 'gray.2' } : { bg: 'white' },
      },
    },
    mantineTableProps: {
      withColumnBorders: true,
      withBorder: true,
      highlightOnHover: false,
      sx: {
        tableLayout: 'fixed',
      },
    },
    mantineTableBodyProps: {
      sx: {
        minHeight: 'auto',
      },
    },
    mantineTableHeadRowProps: {
      sx: {
        backgroundColor: '#E9ECEF',
        boxShadow: 'none',
        withBorder: true,
      },
    },
    mantineTableHeadProps: {
      sx: {
        opacity: 1,
      },
    },
    mantineTableHeadCellProps: {
      sx: {
        ['div[class~="mantine-TableHeadCell-Content-Labels"]']: {
          gap: '5px',
          color: 'black',
        },
      },
    },
    mantineSelectCheckboxProps: {
      color: 'dark',
      radius: 'sm',
    },
    mantineSelectAllCheckboxProps: {
      color: 'dark',
      radius: 'sm',
    },
    renderRowActionMenuItems: ({ row, table: ruleTable }) => (
      <PriceRulesTableRowAction
        checkedRule={row.original}
        isActive={row.original.active}
        table={ruleTable}
      />
    ),
    renderEmptyRowsFallback: () => <TableEmptyData />,
    enableRowVirtualization: true,
    manualSorting: true,
    mantineTableContainerProps: {
      ref: tableContainerRef,
      sx: { height: 'calc(100vh - 300px)' },
      onScroll: (event: UIEvent<HTMLDivElement>) =>
        fetchMoreOnBottomReached(event.target as HTMLDivElement),
    },
    onSortingChange: setSorting,
    state: {
      isLoading,
      showProgressBars: isFetching,
      sorting,
    },
    rowVirtualizerInstanceRef,
    rowVirtualizerProps: { overscan: 10 },
    localization: MRT_Localization_RU,
  });

  const checkedRows = rulesTable.getSelectedRowModel().rows;

  useEffect(() => {
    if (rowVirtualizerInstanceRef.current) {
      try {
        rowVirtualizerInstanceRef.current.scrollToIndex(0);
      } catch (e) {
        console.error(e);
      }
    }
  }, [sorting, activeFilters, rulesRefetch]);

  useEffect(() => {
    dispatch(setRulesSorting(sorting));
  }, [sorting]);

  useEffect(() => {
    fetchMoreOnBottomReached(tableContainerRef.current);
  }, [fetchMoreOnBottomReached]);

  useEffect(() => {
    dispatch(setCheckedRules(checkedRows.map((item) => item.original)));
  }, [activeFilters, checkedRows]);

  useEffect(() => {
    rulesTable.resetRowSelection();
  }, [activeFilters, rulesRefetch]);

  useEffect(() => {
    if (checkedRows.length) dispatch(setCheckedRule(null));
  }, [checkedRows]);

  useEffect(() => {
    if (!commentModalOpened) rulesTable.resetRowSelection();
  }, [commentModalOpened]);

  useEffect(() => {
    return () => {
      dispatch(setActiveRulesFilters([]));
    };
  }, []);

  return (
    <Flex direction="column">
      <MRT_TableContainer table={rulesTable} />
      {isFetching && !isLoading && (
        <Flex justify="center">
          <Loader mt={10} color="black" size="lg" />
        </Flex>
      )}
      <PriceRulesTableCommentModal />
    </Flex>
  );
};

export default PriceRulesTable;
