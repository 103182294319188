import { IUserInfo } from '@/types/user/user';

import { createSlice } from '@reduxjs/toolkit';

import { apiGetUserInfo } from '@/api/user/user';

import { createAxiosThunk } from '@/utils/asyncRequest';

interface IUserState {
  userInfo: IUserInfo | null;
}

const initialState: IUserState = {
  userInfo: null,
};

export const fetchUserInfoAction = createAxiosThunk('/getUserInfo', apiGetUserInfo);

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchUserInfoAction.fulfilled, (state, action) => {
      state.userInfo = action.payload;
    });
  },
});

type TSelectorState = { user: IUserState };

export const selectUserInfo = (state: TSelectorState) => state.user.userInfo;

export default userSlice.reducer;
