import { IItems } from '@/types/common/common';
import { EFilterKey } from '@/types/filters/filters';
import { IMonitoringItem } from '@/types/monitoring/monitoring';

import { FC, UIEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Flex, Loader } from '@mantine/core';
import { useInfiniteQuery } from '@tanstack/react-query';
import {
  MRT_SortingState,
  MRT_TableContainer,
  MRT_Virtualizer,
  useMantineReactTable,
} from 'mantine-react-table';
import { MRT_Localization_RU } from 'mantine-react-table/locales/ru';

import { getMonitoringSort } from '@/utils/monitorings';
import { getOrder } from '@/utils/rules';

import { fetchOptions } from '@/constants/fetch';
import { useTableState } from '@/hooks/useTableState';

import { AppDispatch } from '@/store';
import { resetFilters, selectActiveFilters } from '@/store/slices/filters/filters';
import {
  fetchMonitoringListAction,
  selectMonitoringsRefetch,
  setCheckedMonitorings,
} from '@/store/slices/monitoring/monitoring';

export const MonitoringsPageTable: FC = () => {
  const dispatch: AppDispatch = useDispatch();

  const { monitoringColumns } = useTableState();

  const [sorting, setSorting] = useState<MRT_SortingState>([]);

  const activeFilters = useSelector(selectActiveFilters).monitorings;

  const monitoringsRefetch = useSelector(selectMonitoringsRefetch);

  const tableContainerRef = useRef<HTMLDivElement>(null);
  const rowVirtualizerInstanceRef =
    useRef<MRT_Virtualizer<HTMLDivElement, HTMLTableRowElement>>(null);

  const getInfiniteData = async ({ pageParam = 0 }) => {
    const result = await dispatch(
      fetchMonitoringListAction({
        sort: getMonitoringSort(sorting),
        order: getOrder(sorting),
        offset: pageParam * fetchOptions.FETCH_PLANS_LIMIT,
        limit: fetchOptions.FETCH_PLANS_LIMIT,
        filter: activeFilters,
      }),
    );
    return result.payload;
  };

  const { data, fetchNextPage, isLoading, isFetching } = useInfiniteQuery<IItems<IMonitoringItem>>({
    queryKey: ['getMonitoring', sorting, activeFilters, monitoringsRefetch],
    queryFn: getInfiniteData,
    getNextPageParam: (_lastGroup, groups) => groups.length,
  });

  const flatData = useMemo(
    () => (data?.pages[0]?.items ? data?.pages.flatMap((page) => page?.items) : []),
    [data],
  );

  const totalRowsCount = data?.pages?.[0]?.total ?? 0;
  const totalFetched = flatData.length;

  const fetchMoreOnBottomReached = useCallback(
    (containerRefElement?: HTMLDivElement | null) => {
      if (containerRefElement) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
        if (
          scrollHeight - scrollTop - clientHeight < 646 &&
          !isFetching &&
          totalFetched < totalRowsCount
        ) {
          fetchNextPage();
        }
      }
    },
    [fetchNextPage, isFetching, totalFetched],
  );

  const monitoringTable = useMantineReactTable({
    columns: monitoringColumns,
    data: flatData,
    enableColumnResizing: true,
    enableStickyHeader: true,
    enableRowSelection: true,
    enableGrouping: true,
    enableColumnOrdering: true,
    initialState: {
      density: 'xs',
      showColumnFilters: false,
      showGlobalFilter: false,
    },
    enableColumnFilters: false,
    enablePagination: false,
    enableColumnActions: false,
    enableRowActions: false,
    mantineTableProps: {
      withColumnBorders: true,
      withBorder: true,
      highlightOnHover: false,
      sx: {
        tableLayout: 'fixed',
      },
    },
    mantineTableBodyProps: {
      sx: {
        minHeight: 'auto',
      },
    },
    mantineTableHeadRowProps: {
      sx: {
        backgroundColor: '#E9ECEF',
        boxShadow: 'none',
        withBorder: true,
      },
    },
    mantineTableHeadProps: {
      sx: {
        opacity: 1,
      },
    },
    mantineTableHeadCellProps: {
      sx: {
        ['div[class~="mantine-TableHeadCell-Content-Labels"]']: {
          gap: '5px',
          color: 'black',
        },
      },
    },
    mantineSelectCheckboxProps: {
      color: 'dark',
      radius: 'sm',
    },
    mantineSelectAllCheckboxProps: {
      color: 'dark',
      radius: 'sm',
    },
    enableRowVirtualization: true,
    manualSorting: true,
    mantineTableContainerProps: {
      ref: tableContainerRef,
      sx: { height: 'calc(100vh - 350px)' },
      onScroll: (event: UIEvent<HTMLDivElement>) =>
        fetchMoreOnBottomReached(event.target as HTMLDivElement),
    },
    onSortingChange: setSorting,
    state: {
      isLoading,
      showProgressBars: isFetching,
      sorting,
    },
    rowVirtualizerInstanceRef,
    rowVirtualizerProps: { overscan: 10 },
    localization: MRT_Localization_RU,
  });

  const checkedRows = monitoringTable.getSelectedRowModel().rows;

  useEffect(() => {
    if (rowVirtualizerInstanceRef.current) {
      try {
        rowVirtualizerInstanceRef.current.scrollToIndex(0);
      } catch (e) {
        console.error(e);
      }
    }
  }, [sorting, activeFilters]);

  useEffect(() => {
    fetchMoreOnBottomReached(tableContainerRef.current);
  }, [fetchMoreOnBottomReached]);

  useEffect(() => {
    dispatch(setCheckedMonitorings(checkedRows.map((item) => item.original)));
  }, [activeFilters, checkedRows]);

  useEffect(() => {
    monitoringTable.resetRowSelection();
  }, [activeFilters, monitoringsRefetch]);

  useEffect(() => {
    return () => {
      dispatch(resetFilters(EFilterKey.monitorings));
    };
  }, []);

  return (
    <Flex direction="column">
      <MRT_TableContainer table={monitoringTable} />
      {isFetching && !isLoading && (
        <Flex justify="center">
          <Loader mt={10} color="black" size="lg" />
        </Flex>
      )}
    </Flex>
  );
};
