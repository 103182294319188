import { TAuthParams } from '@/types/auth/auth';

import axios from 'axios';
import qs from 'qs';

import { URLS } from '@/api/urls';

import { headers } from '../instance';
import { tryAction } from '../tryAction';

const api = axios.create({
  baseURL: process.env.PUBLIC_API_URL,
  paramsSerializer: (params) => {
    return qs.stringify(params, { indices: false });
  },
});

export const apiPostAuth = ({ data }: { data: TAuthParams }) =>
  api.post(`${process.env.PUBLIC_API_URL}${URLS.auth.postAuth}`, data, {
    headers: headers,
  });

export const apiPostAuthByRefresh = (data: any) => {
  return api.post<{
    token: string;
    refreshExpiresAt: number;
    expiresAt: number;
  }>(`${process.env.PUBLIC_API_URL}${URLS.auth.postAuthByRefresh}`, data, {
    headers: headers,
  });
};

export const tryPostAuth = (data: TAuthParams) => tryAction(apiPostAuth({ data }));
