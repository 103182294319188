import { FC, ReactNode } from 'react';
import { Drawer, DrawerProps, Group, Text } from '@mantine/core';

interface DrawerExtProps extends DrawerProps {
  title: string;
  description?: string;
  size: number;
  trigger: ReactNode;
  opened: boolean;
  onClose: VoidFunction;
}

const DrawerExt: FC<DrawerExtProps> = ({
  title,
  description,
  children,
  trigger,
  size,
  opened,
  onClose,
  ...props
}) => {
  return (
    <>
      <Drawer
        opened={opened}
        onClose={onClose}
        position="right"
        title={
          <Text weight={600} size={24}>
            {title}
          </Text>
        }
        size={size}
        {...props}
      >
        {description && (
          <Text color="gray" mb={24}>
            {description}
          </Text>
        )}
        {children}
      </Drawer>

      <Group position="left">{trigger}</Group>
    </>
  );
};

export default DrawerExt;
