import { ERuleType, IMinMaxPriceRule } from '@/types/rules/rules';

import { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ActionIcon,
  Badge,
  Button,
  Flex,
  Switch,
  Text,
  TextInput,
  Title,
  Tooltip,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconTrash, IconX } from '@tabler/icons-react';

import { modifiedMinMaxPriceRule, positiveNumbersValidation } from '@/utils/forms';
import { modifiedNodeParams } from '@/utils/rules';

import { openDeleteModal, useStyles } from '../FixPriceRule/FixPriceRule';
import PriceRuleBlock from '../PriceRuleBlock/PriceRuleBlock';

import { AppDispatch } from '@/store';
import { selectNodesParams } from '@/store/slices/nodes/nodes';
import {
  fetchDeleteRuleAction,
  fetchRuleEditAction,
  fetchRulesListAction,
  selectRuleBlocks,
  setCurrentRule,
  setFormsCommentModalOpened,
  setMinMaxPriceRule,
  setRuleBlocks,
} from '@/store/slices/rules/rules';

interface MinMaxPriceRuleProps {
  minMaxPriceRule: IMinMaxPriceRule;
  alsoHasInheritedRule?: boolean;
}

const MinMaxPriceRule: FC<MinMaxPriceRuleProps> = ({ alsoHasInheritedRule, minMaxPriceRule }) => {
  const { classes } = useStyles();

  const dispatch: AppDispatch = useDispatch();

  const ruleBlocks = useSelector(selectRuleBlocks);
  const nodesParams = useSelector(selectNodesParams);

  const [disabled, setDisabled] = useState<boolean>(minMaxPriceRule?.inherited ?? false);

  const form = useForm({
    initialValues: {
      from: minMaxPriceRule?.from ?? 0,
      to: minMaxPriceRule?.to ?? 999999,
    },
    validate: {
      from: (value) => positiveNumbersValidation(value, true),
      to: (value) => positiveNumbersValidation(value, true),
    },
    validateInputOnBlur: true,
    validateInputOnChange: true,
  });

  const handleSubmit = (formData: {
    from: number | string | undefined;
    to: number | string | undefined;
  }) => {
    if (minMaxPriceRule) {
      const updatedMinMaxPriceRule = {
        ...minMaxPriceRule,
        from: Number(formData.from),
        to: Number(formData.to),
      };

      dispatch(setMinMaxPriceRule(updatedMinMaxPriceRule));
      dispatch(setCurrentRule(updatedMinMaxPriceRule));
      dispatch(setFormsCommentModalOpened(true));
    }
  };

  const changeActiveStatus = async (status: boolean) => {
    if (minMaxPriceRule) {
      const updatedMinMaxPriceRule = {
        ...minMaxPriceRule,
        active: status,
      };

      dispatch(setCurrentRule(updatedMinMaxPriceRule));
      dispatch(setMinMaxPriceRule(updatedMinMaxPriceRule));

      await dispatch(
        fetchRuleEditAction({
          nodes: modifiedNodeParams(nodesParams),
          rule: modifiedMinMaxPriceRule(status, minMaxPriceRule),
        }),
      );
      dispatch(fetchRulesListAction(modifiedNodeParams(nodesParams)));
    }
  };

  const resetValues = () => {
    form.setFieldValue('from', 0);
    form.setFieldValue('to', 999999);
  };

  const deleteRule = async () => {
    if (minMaxPriceRule?.id) {
      await dispatch(fetchDeleteRuleAction([minMaxPriceRule.id]));
      dispatch(fetchRulesListAction(modifiedNodeParams(nodesParams)));
    }

    dispatch(setMinMaxPriceRule(null));
    const updatedRuleBlocks = ruleBlocks.map((item) => ({
      ...item,
      checked: item.type === ERuleType.minMaxPrice ? false : item.checked,
      shown: item.type === ERuleType.minMaxPrice ? false : item.shown,
    }));

    dispatch(setRuleBlocks(updatedRuleBlocks));
  };

  return (
    <PriceRuleBlock>
      <Flex direction="row" justify="space-between">
        {minMaxPriceRule?.inherited || alsoHasInheritedRule ? (
          <Badge color="green" variant="filled">
            Наследуется
          </Badge>
        ) : (
          <Switch
            classNames={{ track: classes.track }}
            checked={minMaxPriceRule?.active ?? false}
            color="dark"
            size="md"
            onChange={(event) => {
              changeActiveStatus(event.currentTarget.checked);
            }}
          />
        )}
        {!(alsoHasInheritedRule || minMaxPriceRule?.inherited) && (
          <ActionIcon
            color="dark"
            size="lg"
            variant="transparent"
            className={classes.actionIcon}
            onClick={() => openDeleteModal(deleteRule)}
          >
            <IconX />
          </ActionIcon>
        )}
      </Flex>
      <Title mt={12} mb={24} order={3}>
        {minMaxPriceRule?.name}
      </Title>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Flex direction="row" gap={24} align="flex-end">
          <TextInput
            classNames={{ label: classes.label, rightSection: classes.rightSection }}
            label="Цена"
            rightSection="₽"
            w={182}
            icon={<Text>от</Text>}
            disabled={!minMaxPriceRule?.active || disabled}
            required
            {...form.getInputProps('from')}
          />
          <TextInput
            classNames={{ label: classes.label, rightSection: classes.rightSection }}
            rightSection="₽"
            w={182}
            icon={<Text>до</Text>}
            disabled={!minMaxPriceRule?.active || disabled}
            required
            {...form.getInputProps('to')}
          />
          <ActionIcon
            color="dark"
            size="lg"
            variant="transparent"
            className={classes.actionIcon}
            disabled={!minMaxPriceRule?.active || disabled}
            onClick={resetValues}
          >
            <IconTrash />
          </ActionIcon>
        </Flex>

        <Flex justify="flex-end" mt={24}>
          {alsoHasInheritedRule && (
            <Tooltip
              label="Вы не можете редактировать правило, так как уже создано деактивированное правило. Перейдите во вкладку деактивированных правил."
              withArrow
              multiline
              w={350}
              openDelay={500}
              position="left"
            >
              <Flex>
                <Button color="dark.9" disabled={alsoHasInheritedRule}>
                  Редактировать
                </Button>
              </Flex>
            </Tooltip>
          )}
          {minMaxPriceRule?.active && disabled && !alsoHasInheritedRule && (
            <Button color="dark.9" ml={40} onClick={() => setDisabled(false)}>
              Редактировать
            </Button>
          )}
          {minMaxPriceRule?.active && !disabled && !alsoHasInheritedRule && (
            <Button color="dark.9" ml={40} type="submit" disabled={!form.isValid()}>
              Сохранить
            </Button>
          )}
        </Flex>
      </form>
    </PriceRuleBlock>
  );
};

export default MinMaxPriceRule;
