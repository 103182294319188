import { IAdditionalNodeModified } from '@/types/nodes/nodes';
import {
  ERuleType,
  ICompetitorRule,
  ICompetitorRuleEditParams,
  ICompetitorRuleValue,
  ICompetitorRuleValues,
  IFixMarginRule,
  IFixMarginRuleEditParams,
  IFixPriceRule,
  IFixPriceRuleEditParams,
  IFixPriceRuleFormValues,
  IFixPriceRuleValues,
  IMinMaxMarginRule,
  IMinMaxMarginRuleEditParams,
  IMinMaxMarginRuleValues,
  IMinMaxPriceRule,
  IMinMaxPriceRuleEditParams,
  IMinPriceChangeRule,
  IMinPriceChangeRuleEditParams,
  IMinPriceChangeRuleValues,
  ISamePriceRule,
  ISamePriceRuleEditParams,
} from '@/types/rules/rules';

import { dateParse } from './rules';

export const getFixPriceRuleInitialValues = (values: IFixPriceRuleValues[]) => {
  const fields: IFixPriceRuleFormValues[] = [];

  values.map((value) => {
    fields.push({
      dateStart: dateParse(value.dateStart),
      dateEnd: dateParse(value.dateEnd),
      value: value.value,
    });
  });

  return fields;
};

export const getMaxPriceChangeRuleInitialValues = (values: IMinPriceChangeRuleValues[]) => {
  const fields: IMinPriceChangeRuleValues[] = [];

  values.map((value) => {
    fields.push({
      priceFrom: value.priceFrom,
      priceTo: value.priceTo,
      decreaseValue: value.decreaseValue === null ? '' : value.decreaseValue,
      decreasePercent: value.decreasePercent === null ? '' : value.decreasePercent,
      increaseValue: value.increaseValue === null ? '' : value.increaseValue,
      increasePercent: value.increasePercent === null ? '' : value.increasePercent,
    });
  });

  return fields;
};

export const getMinPriceChangeRuleInitialValues = (values: IMinPriceChangeRuleValues[]) => {
  const fields: IMinPriceChangeRuleValues[] = [];

  values.map((value) => {
    fields.push({
      priceFrom: value.priceFrom,
      priceTo: value.priceTo,
      decreaseValue: value.decreaseValue === null ? '' : value.decreaseValue,
      decreasePercent: value.decreasePercent === null ? '' : value.decreasePercent,
      increaseValue: value.increaseValue === null ? '' : value.increaseValue,
      increasePercent: value.increasePercent === null ? '' : value.increasePercent,
    });
  });

  return fields;
};

export const getMinMaxMarginRuleInitialValues = (values: IMinMaxMarginRuleValues[]) => {
  const fields: IMinMaxMarginRuleValues[] = [];

  values.map((value) => {
    fields.push({
      priceFrom: value.priceFrom,
      priceTo: value.priceTo,
      marginFrom: value.marginFrom === null ? '' : value.marginFrom,
      marginTo: value.marginTo === null ? '' : value.marginTo,
      marginWithBonusFrom: value.marginWithBonusFrom === null ? '' : value.marginWithBonusFrom,
      marginWithBonusTo: value.marginWithBonusTo === null ? '' : value.marginWithBonusTo,
    });
  });

  return fields;
};

export const getFixMarginRuleInitialValues = (values: IFixPriceRuleValues[]) => {
  const fields: IFixPriceRuleFormValues[] = [];

  values.map((value) => {
    fields.push({
      dateStart: dateParse(value.dateStart),
      dateEnd: dateParse(value.dateEnd),
      value: value.value,
    });
  });

  return fields;
};

export const getCompetitorRuleValues = (values: ICompetitorRuleValue[]) => {
  const fields: ICompetitorRuleValue[] = [];

  values.map((value) => {
    fields.push({
      priceFrom: value.priceFrom,
      priceTo: value.priceTo,
      fromPercent: value.fromPercent === null ? '' : value.fromPercent,
      fromValue: value.fromValue === null ? '' : value.fromValue,
      toPercent: value.toPercent === null ? '' : value.toPercent,
      toValue: value.toValue === null ? '' : value.toValue,
    });
  });

  return fields;
};

export const getCompetitorRuleInitialValues = (values: ICompetitorRuleValues[]) => {
  const fields: ICompetitorRuleValues[] = [];

  values.map((value) => {
    fields.push({
      competitorBrand: value.competitorBrand,
      values: getCompetitorRuleValues(value.values),
    });
  });

  return fields;
};

export const modifiedFixPriceRule = (
  status: boolean,
  rule: IFixPriceRule,
  comment?: string,
): IFixPriceRuleEditParams => {
  return {
    type: ERuleType.fixPrice,
    active: status,
    comment: comment ?? rule.comment,
    value: {
      values: rule.values,
    },
  };
};

export const modifiedFixMarginRule = (
  status: boolean,
  rule: IFixMarginRule,
  comment?: string,
): IFixMarginRuleEditParams => {
  return {
    type: ERuleType.fixMargin,
    active: status,
    comment: comment ?? rule.comment,
    value: {
      values: rule.values,
      priceType: rule.priceType,
    },
  };
};

export const modifiedMinPriceChangeRule = (
  status: boolean,
  rule: IMinPriceChangeRule,
  comment?: string,
): IMinPriceChangeRuleEditParams => {
  return {
    type: ERuleType.minPriceChange,
    active: status,
    comment: comment ?? rule.comment,
    value: {
      values: rule.values,
    },
  };
};

export const modifiedMaxPriceChangeRule = (
  status: boolean,
  rule: IMinPriceChangeRule,
  comment?: string,
): IMinPriceChangeRuleEditParams => {
  return {
    type: ERuleType.maxPriceChange,
    active: status,
    comment: comment ?? rule.comment,
    value: {
      values: rule.values,
    },
  };
};

export const modifiedMinMaxPriceRule = (
  status: boolean,
  rule: IMinMaxPriceRule,
  comment?: string,
): IMinMaxPriceRuleEditParams => {
  return {
    type: ERuleType.minMaxPrice,
    active: status,
    comment: comment ?? rule.comment,
    value: {
      from: rule.from,
      to: rule.to,
    },
  };
};

export const modifiedMinMaxMarginSoftRule = (
  status: boolean,
  rule: IMinMaxMarginRule,
  comment?: string,
): IMinMaxMarginRuleEditParams => {
  return {
    type: ERuleType.minMaxMarginSoft,
    active: status,
    comment: comment ?? rule.comment,
    value: {
      aggregationType: rule.aggregationType,
      values: rule.values,
    },
  };
};

export const modifiedMinMaxMarginHardRule = (
  status: boolean,
  rule: IMinMaxMarginRule,
  comment?: string,
): IMinMaxMarginRuleEditParams => {
  return {
    type: ERuleType.minMaxMarginHard,
    active: status,
    comment: comment ?? rule.comment,
    value: {
      aggregationType: rule.aggregationType,
      values: rule.values,
    },
  };
};

export const modifiedSamePriceRule = (
  status: boolean,
  rule: ISamePriceRule,
  comment?: string,
): ISamePriceRuleEditParams => {
  return {
    type: ERuleType.samePrice,
    active: status,
    comment: comment ?? rule.comment,
    value: {
      productId: rule.productId,
    },
  };
};

export const modifiedCompetitorRule = (
  status: boolean,
  rule: ICompetitorRule,
  comment?: string,
): ICompetitorRuleEditParams => {
  return {
    type: ERuleType.competitor,
    active: status,
    comment: comment ?? rule.comment,
    value: {
      values: rule.values,
    },
  };
};

export const positiveNumbersValidation = (
  value: number | string | undefined | null,
  couldBeZero?: boolean,
) => {
  return (
    (Number.isNaN(Number(value)) && !Number.isInteger(Number(value))) ||
    value === undefined ||
    value === null ||
    (couldBeZero ? Number(value) < 0 : Number(value) <= 0) ||
    value?.toString().length === 0
  );
};

export const emptyValuesValidation = (value: Date | number | string | undefined | null) => {
  return value === undefined || value === null || value?.toString().length === 0;
};

export const validateAdditionalNode = (
  nodeCode: string,
  value: string,
  additionalNodes: IAdditionalNodeModified[] | null,
) => {
  if (additionalNodes?.find((item) => item.code === nodeCode)?.shown) {
    return value.length === 0;
  } else return false;
};
