import { FC } from 'react';
import { Flex, Paper, Text } from '@mantine/core';

interface ChartsEmptyDataProps {
  title: string;
}

const ChartsEmptyData: FC<ChartsEmptyDataProps> = ({ title }) => {
  return (
    <Paper withBorder mt={36}>
      <Flex align="center" direction="column" justify="center" h="calc(100vh - 240px)" px={32}>
        <Text weight={600}>{title}</Text>
        <Text align="center" color="dark.2">
          Сейчас здесь нет данных, потому что не было выполнено ни одного действия, соответствующего
          выбранному вами правилу.
        </Text>
      </Flex>
    </Paper>
  );
};

export default ChartsEmptyData;
