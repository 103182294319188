import { DefaultMantineColor, Tuple } from '@mantine/core';

export type ExtraColors =
  | 'chateau-green'
  | 'carrot-orange'
  | 'blue-bell'
  | 'mariner'
  | 'new-york-pink'
  | 'burnt-sienna';

export const extraColors: Record<
  ExtraColors,
  [string, string, string, string, string, string, string, string, string, string]
> = {
  'chateau-green': [
    '#f3faf4',
    '#e3f5e6',
    '#c8eace',
    '#9dd8a8',
    '#6abe7b',
    '#4bb05f',
    '#348544',
    '#2c6939',
    '#275430',
    '#22452a',
  ],
  'carrot-orange': [
    '#fef8ee',
    '#fdf0d7',
    '#faddae',
    '#f7c37a',
    '#f3a044',
    '#f08827',
    '#e16a15',
    '#ba5014',
    '#944018',
    '#783616',
  ],
  'blue-bell': [
    '#f3f5fa',
    '#eaebf5',
    '#d8dbed',
    '#c0c4e1',
    '#a6a7d3',
    '#9896c9',
    '#7e77b4',
    '#6c659d',
    '#595380',
    '#4b4867',
  ],
  mariner: [
    '#f2f8fd',
    '#e4eefa',
    '#c4ddf3',
    '#8fc2ea',
    '#54a1dc',
    '#2d86ca',
    '#2173ba',
    '#1a548a',
    '#194973',
    '#1a3d60',
  ],
  'new-york-pink': [
    '#fcf4f4',
    '#f8e9e8',
    '#f3d6d5',
    '#eab9b7',
    '#d77f7b',
    '#cc6b67',
    '#b7504b',
    '#99403c',
    '#7f3835',
    '#6b3431',
  ],
  'burnt-sienna': [
    '#fef4f2',
    '#fde7e3',
    '#fdd3cb',
    '#fab4a7',
    '#f68873',
    '#ec6248',
    '#d94529',
    '#b6371f',
    '#97301d',
    '#7d2e1f',
  ],
};

declare module '@mantine/core' {
  export interface MantineThemeColorsOverride {
    colors: Record<DefaultMantineColor | ExtraColors, Tuple<string, 10>>;
  }
}
