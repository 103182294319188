import { FC } from 'react';
import { Badge, createStyles, Text } from '@mantine/core';

type TProps = {
  color: string;
  text: string;
};

const useStyles = createStyles(() => ({
  plansBadge: {
    padding: '1px 8px',
    height: '24px',
  },
}));

const TableBadge: FC<TProps> = ({ color, text }) => {
  const { classes } = useStyles();
  return (
    <Badge
      size="lg"
      radius="md"
      className={classes.plansBadge}
      color={color}
      variant="filled"
      fw={500}
    >
      <Text>{text}</Text>
    </Badge>
  );
};

export default TableBadge;
