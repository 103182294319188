import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStyles, Flex, Text } from '@mantine/core';

import { getPricesDescription } from '@/utils/monitorings';

import { AppDispatch } from '@/store';
import { fetchPricesInfoAction, selectPricesInfo } from '@/store/slices/prices/prices';

const useStyles = createStyles((theme) => ({
  grayText: {
    fontSize: '14px',
    color: theme.colors.gray[7],
  },
  text: {
    fontSize: '14px',
  },
}));

export const PricesPageInfo: FC = () => {
  const dispatch: AppDispatch = useDispatch();

  const { classes } = useStyles();

  const pricesInfo = useSelector(selectPricesInfo);

  useEffect(() => {
    dispatch(fetchPricesInfoAction());
  }, []);

  if (!pricesInfo) return null;

  return (
    <Flex gap={24} mb={32}>
      <Flex gap={12} w={228}>
        <Text className={classes.grayText}>Сегодня выгружено</Text>
        <Text className={classes.text}>{getPricesDescription(pricesInfo.today)}</Text>
      </Flex>
      <Flex gap={12}>
        <Text className={classes.grayText}>В очереди на отправку</Text>
        <Text className={classes.text}>{getPricesDescription(pricesInfo.queued)}</Text>
      </Flex>
    </Flex>
  );
};
