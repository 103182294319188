import { IAdditionalNode, IAdditionalNodeModified, INodeParams } from '@/types/nodes/nodes';

export const modifiedNodesData = (
  dataFromBackend: IAdditionalNode[],
  nodesParams: INodeParams[],
): IAdditionalNodeModified[] => {
  return dataFromBackend.map((item: IAdditionalNode) => ({
    name: item.name,
    code: item.code,
    count: item.count,
    checked: nodesParams.some((node) => node.code === item.code),
    shown: nodesParams.some((node) => node.code === item.code),
  }));
};

export const getCheckedAdditionalNodes = (
  node: IAdditionalNodeModified,
  nodes: IAdditionalNodeModified[],
) => {
  if (nodes) {
    return nodes.map((item: IAdditionalNodeModified) => ({
      ...item,
      checked: item.code === node.code ? !item.checked : item.checked,
    }));
  }
  return null;
};

export const resetCheckedAdditionalNodes = (nodes: IAdditionalNodeModified[] | null) => {
  if (nodes) {
    return nodes.map((item: IAdditionalNodeModified) => ({
      ...item,
      checked: false,
    }));
  }
  return null;
};

export const getShownAdditionalNodes = (nodes: IAdditionalNodeModified[] | null) => {
  if (nodes) {
    return nodes.map((item: IAdditionalNodeModified) => ({
      ...item,
      shown: item.checked,
    }));
  }
  return null;
};
