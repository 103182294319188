import { IItems } from '@/types/common/common';
import { INotification } from '@/types/notifications/notifications';

import { FC, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Button,
  createStyles,
  Divider,
  Flex,
  Loader,
  ScrollArea,
  Text,
  Title,
} from '@mantine/core';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useVirtualizer } from '@tanstack/react-virtual';

import { fetchOptions } from '@/constants/fetch';
import { paths } from '@/constants/paths';
import { reportsData } from '@/constants/reports';

import { AppDispatch } from '@/store';
import { selectActiveFilters } from '@/store/slices/filters/filters';
import { fetchNotificationsAction } from '@/store/slices/notifications/notifications';

interface NotificationsBlockProps {
  title?: string;
  path?: string;
}

const useStyles = createStyles((theme) => ({
  wrapper: {
    padding: '24px 8px 24px 24px',
    border: `1px solid ${theme.colors.gray[2]}`,
    borderRadius: '8px',
  },
  container: {
    width: '100%',
    overflow: 'auto',
    position: 'relative',
    justifyContent: 'center',
  },
  link: {
    textDecoration: 'none',
    color: 'black',
    marginBottom: 8,

    '&:hover': {
      textDecoration: 'underline',
    },
  },
  rowContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    paddingRight: 24,
    height: 100,
    width: '100%',
  },
}));

const NotificationsBlock: FC<NotificationsBlockProps> = ({ title, path }) => {
  const { classes } = useStyles();

  const dispatch: AppDispatch = useDispatch();

  const containerRef = useRef<HTMLDivElement>(null);

  const activeNotificationsFilters = useSelector(selectActiveFilters).notifications;

  const getInfiniteData = async ({ pageParam = 0 }) => {
    const result = await dispatch(
      fetchNotificationsAction({
        offset: pageParam * fetchOptions.FETCH_NOTIFICATIONS_LIMIT,
        limit: fetchOptions.FETCH_NOTIFICATIONS_LIMIT,
        filter: activeNotificationsFilters,
      }),
    );
    return result.payload;
  };

  const { data, isFetching, isFetchingNextPage, fetchNextPage, hasNextPage } = useInfiniteQuery<
    IItems<INotification>
  >({
    queryKey: ['notifications-data', activeNotificationsFilters],
    queryFn: getInfiniteData,
    getNextPageParam: (_lastGroup, groups) => groups.length,
  });

  const totalRowsCount = data?.pages?.[0]?.total ?? 0;
  const fetchedRows = data ? data?.pages.flatMap((page) => page?.items) : [];

  const rowVirtualizer = useVirtualizer({
    count: hasNextPage ? fetchedRows.length + 1 : fetchedRows.length,
    getScrollElement: () => containerRef.current,
    estimateSize: () => 100,
    overscan: 3,
  });

  useEffect(() => {
    const [lastItem] = [...rowVirtualizer.getVirtualItems()].reverse();
    if (!lastItem) {
      return;
    }
    if (
      lastItem.index >= fetchedRows.length - 1 &&
      hasNextPage &&
      !isFetching &&
      !isFetchingNextPage &&
      fetchedRows.length < totalRowsCount
    ) {
      fetchNextPage();
    }
  }, [
    hasNextPage,
    fetchNextPage,
    fetchedRows.length,
    isFetchingNextPage,
    rowVirtualizer.getVirtualItems(),
  ]);

  return (
    <Flex className={classes.wrapper} direction="column">
      {title && path && (
        <Flex direction="row" justify="space-between" mb={16}>
          <Title order={5}>{title}</Title>
          <Link to={path} target={path === paths.PATH_REPORTS ? '_blank' : '_self'}>
            <Button variant="default" sx={{ color: 'gray' }}>
              Смотреть все
            </Button>
          </Link>
        </Flex>
      )}
      {path === paths.PATH_REPORTS ? (
        <Flex h={path ? 270 : 400} direction="column">
          <ScrollArea>
            {reportsData.map((report) => {
              return (
                <Flex pr={24} direction="column" key={report.title}>
                  <Link className={classes.link} target="_blank" to={report.url}>
                    <Text fz="sm" fw={500} mb={8}>
                      {report.title}
                    </Text>
                    <Text fz="sm" c="dimmed">
                      {report.description}
                    </Text>
                  </Link>

                  <Divider
                    sx={(theme) => ({
                      margin: '16px -16px',
                      borderTopColor: theme.colors.gray[2],
                    })}
                  />
                </Flex>
              );
            })}
          </ScrollArea>
        </Flex>
      ) : (
        <Flex ref={containerRef} className={classes.container} h={path ? 270 : 400}>
          {fetchedRows.length > 0 &&
            rowVirtualizer.getVirtualItems().map((virtualRow) => {
              const notification = fetchedRows[virtualRow.index];

              if (notification) {
                return (
                  <Flex
                    key={virtualRow.index}
                    direction="column"
                    className={classes.rowContainer}
                    sx={{ transform: `translateY(${virtualRow.start}px)` }}
                  >
                    <Flex direction="row" justify="space-between">
                      <Text fz="sm" fw={500} mb={8}>
                        {notification.title}
                      </Text>
                      <Text fz="xs" c="dimmed" sx={{ flexShrink: 0 }}>
                        {notification.date}
                      </Text>
                    </Flex>

                    <Text fz="sm" c="dimmed">
                      {notification.description}
                    </Text>

                    <Divider
                      sx={(theme) => ({
                        margin: '16px -16px',
                        borderTopColor: theme.colors.gray[2],
                      })}
                    />
                  </Flex>
                );
              } else return null;
            })}
          {!isFetching && fetchedRows.length === 0 && (
            <Title order={3}>Ой! К сожалению, по вашим параметрам ничего не найдено.</Title>
          )}
          {isFetching && <Loader mt={10} color="black" size="lg" />}
        </Flex>
      )}
    </Flex>
  );
};

export default NotificationsBlock;
