import { FC, PropsWithChildren } from 'react';
import { Flex } from '@mantine/core';

const PageLayoutBody: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Flex h="100%" direction={'column'}>
      {children}
    </Flex>
  );
};

export default PageLayoutBody;
