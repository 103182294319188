import { IRule } from '@/types/rules/rules';

import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Group, Menu, Text } from '@mantine/core';
import { modals } from '@mantine/modals';
import { IconLock, IconMessage, IconPencil, IconTrash } from '@tabler/icons-react';
import { MRT_TableInstance } from 'mantine-react-table';

import { paths } from '@/constants/paths';

import { AppDispatch } from '@/store';
import { setNodesParams } from '@/store/slices/nodes/nodes';
import {
  fetchDeactivateRuleAction,
  fetchDeleteRuleAction,
  selectRulesRefetch,
  setCheckedRule,
  setCommentModalOpened,
  setRulesRefetch,
  setStepper,
} from '@/store/slices/rules/rules';

interface PriceRulesTableRowActionProps {
  checkedRule: IRule;
  isActive: boolean;
  table: MRT_TableInstance<IRule>;
}

const PriceRulesTableRowAction: FC<PriceRulesTableRowActionProps> = ({
  checkedRule,
  isActive,
  table,
}) => {
  const dispatch: AppDispatch = useDispatch();

  const navigate = useNavigate();

  const rulesRefetch = useSelector(selectRulesRefetch);

  const deleteRule = async () => {
    await dispatch(fetchDeleteRuleAction([checkedRule.id]));
    dispatch(setRulesRefetch(!rulesRefetch));
  };

  const deactivateRule = async () => {
    await dispatch(fetchDeactivateRuleAction([checkedRule.id]));
    dispatch(setRulesRefetch(!rulesRefetch));
  };

  const commentRuleModal = () => {
    dispatch(setCommentModalOpened(true));
  };

  const deleteRuleModal = () =>
    modals.openConfirmModal({
      title: (
        <Text fz={22} weight="bold">
          Удаление правила
        </Text>
      ),
      children: (
        <>
          <Text size="md" color="dark.2">
            Вы действительно хотите удалить правило без возможности восстановления?
          </Text>
        </>
      ),
      labels: { confirm: 'Удалить', cancel: 'Отменить' },
      confirmProps: { color: 'orange.9', sx: { width: '195px' } },
      cancelProps: { variant: 'filled', color: 'gray.2', sx: { width: '195px' } },

      onConfirm: () => {
        deleteRule();
      },
    });

  const navigateToEditRulesPage = () => {
    dispatch(setNodesParams(checkedRule.attributesNodes));
    dispatch(setStepper(1));
    navigate(paths.PATH_CREATE_PRICE_RULES);
  };

  useEffect(() => {
    dispatch(setCheckedRule(checkedRule));
    table.resetRowSelection();
  }, [checkedRule]);

  return (
    <>
      <Menu.Item onClick={navigateToEditRulesPage}>
        <Group>
          <IconPencil />
          Редактировать правило
        </Group>
      </Menu.Item>
      {isActive && (
        <Menu.Item onClick={deactivateRule}>
          <Group>
            <IconLock />
            Деактивировать правило
          </Group>
        </Menu.Item>
      )}
      <Menu.Item onClick={commentRuleModal}>
        <Group>
          <IconMessage />
          Комментировать
        </Group>
      </Menu.Item>
      <Menu.Item onClick={deleteRuleModal}>
        <Group
          sx={(theme) => ({
            color: theme.colors.red[7],
          })}
        >
          <IconTrash />
          Удалить правило
        </Group>
      </Menu.Item>
    </>
  );
};

export default PriceRulesTableRowAction;
