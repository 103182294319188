import { EFilterKey } from '@/types/filters/filters';

import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch } from '@mantine/core';

import { AppDispatch } from '@/store';
import { clearFilter, selectActiveFilters, setActiveFilters } from '@/store/slices/filters/filters';

interface IProps {
  filterKey: EFilterKey;
  name: string;
  code: string;
  type: string;
}

const FilterBoolean: FC<IProps> = ({ name, code, type, filterKey }) => {
  const dispatch: AppDispatch = useDispatch();

  const activeFilters = useSelector(selectActiveFilters);

  const currentActiveFilter = activeFilters[filterKey].find((item) => item.code === code);
  const currentActiveFilterValue = currentActiveFilter?.value.isSelected;

  const [checked, setChecked] = useState<boolean>(!!currentActiveFilterValue);

  const handleChange = (value: boolean) => {
    const filterValue = {
      filterKey: filterKey,
      item: {
        code: code,
        type: type,
        value: {
          isSelected: value,
        },
      },
    };
    setChecked(value);

    if (!value) {
      dispatch(clearFilter(filterValue));
      return;
    }

    dispatch(setActiveFilters(filterValue));
  };

  useEffect(() => {
    if (!currentActiveFilter) {
      setChecked(false);
    }
  }, [currentActiveFilter]);

  return (
    <Switch
      color="dark"
      label={name}
      checked={checked}
      onChange={(event) => handleChange(event.currentTarget.checked)}
    />
  );
};

export default FilterBoolean;
