import { FC } from 'react';
import { Group, Header, Text } from '@mantine/core';

import { ReactComponent as Logo } from '../../../../../assets/icons/logo.svg';

const LayoutHeader: FC = () => {
  return (
    <Header height={60} p="xs">
      <Group sx={{ height: '100%' }} px={10} spacing="xs">
        <Logo height={30} />
        <Text weight={600}>АЦО</Text>
      </Group>
    </Header>
  );
};

export default LayoutHeader;
