import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Flex, Modal, Text, Textarea } from '@mantine/core';
import { useForm } from '@mantine/form';

import { AppDispatch } from '@/store';
import {
  fetchCommentRuleAction,
  selectCheckedRule,
  selectCheckedRules,
  selectCommentModalOpened,
  selectRulesRefetch,
  setCheckedRule,
  setCheckedRules,
  setCommentModalOpened,
  setRulesRefetch,
} from '@/store/slices/rules/rules';

const PriceRulesTableCommentModal: FC = () => {
  const dispatch: AppDispatch = useDispatch();

  const commentModalOpened = useSelector(selectCommentModalOpened);
  const checkedRule = useSelector(selectCheckedRule);
  const checkedRules = useSelector(selectCheckedRules);
  const rulesRefetch = useSelector(selectRulesRefetch);

  const checkedRulesIds = checkedRules.map((item) => item.id);

  const form = useForm({
    initialValues: {
      comment: checkedRule?.comment || '',
    },
  });

  const resetCheckedRules = () => {
    dispatch(setCommentModalOpened(false));
    dispatch(setCheckedRule(null));
    dispatch(setCheckedRules([]));
    form.setFieldValue('comment', '');
  };

  const handleSubmit = async (formData: { comment: string }) => {
    if (checkedRule) {
      await dispatch(fetchCommentRuleAction({ id: [checkedRule.id], comment: formData.comment }));

      dispatch(setRulesRefetch(!rulesRefetch));
    } else if (checkedRules) {
      await dispatch(
        fetchCommentRuleAction({
          id: checkedRulesIds,
          comment: formData.comment,
          isMultiple: checkedRules.length > 1,
        }),
      );

      dispatch(setRulesRefetch(!rulesRefetch));
    }
    resetCheckedRules();
  };

  useEffect(() => {
    if (checkedRule) {
      form.setFieldValue('comment', checkedRule.comment as string);
    } else if (checkedRules.length === 1) {
      form.setFieldValue('comment', checkedRules[0].comment as string);
    } else form.setFieldValue('comment', '');

    form.resetDirty();
  }, [checkedRule, checkedRules]);

  return (
    <Modal
      size={'md'}
      opened={commentModalOpened}
      onClose={resetCheckedRules}
      title={
        <Text fz={22} weight="bold">
          Комментировать
        </Text>
      }
    >
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Textarea
          size="sm"
          color="dark.2"
          placeholder="Введите комментарий для правила"
          minRows={4}
          {...form.getInputProps('comment')}
        />
        <Flex direction="row" justify="space-between" gap={16} mt={24}>
          <Button fullWidth onClick={resetCheckedRules} variant="filled" color="gray.2">
            Отменить
          </Button>
          <Button fullWidth type={'submit'} color="dark.9">
            Комментировать
          </Button>
        </Flex>
      </form>
    </Modal>
  );
};

export default PriceRulesTableCommentModal;
