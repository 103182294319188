import { IFilterTreeValue, IModifiedFilterTreeItem } from '@/types/filters/filters';
import { ERuleType, ISamePriceRule } from '@/types/rules/rules';

import { FC, Key, StrictMode, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ActionIcon,
  Badge,
  Button,
  Divider,
  Flex,
  Input,
  Switch,
  Text,
  Title,
  Tooltip,
} from '@mantine/core';
import { IconChevronDown, IconChevronRight, IconSearch, IconX } from '@tabler/icons-react';
import { Provider } from 'rc-motion';
import Tree from 'rc-tree';
import { EventDataNode } from 'rc-tree/lib/interface';

import { generateTreeNodes, getNewTreeData, motion } from '@/utils/filters';
import { modifiedSamePriceRule } from '@/utils/forms';
import { modifiedNodeParams } from '@/utils/rules';

import FilterTreeMultiSelectTitle from '@/ui/organisms/FilterTreeMultiSelect/organisms/FilterTreeMultiSelectTitle/FilterTreeMultiSelectTitle';

import { openDeleteModal } from '../FixPriceRule/FixPriceRule';
import PriceRuleBlock from '../PriceRuleBlock/PriceRuleBlock';

import { useStyles } from '@/assets/styles/priceRulesSteppers';
import { childStyle } from '@/assets/styles/treeMultiSelect';
import { AppDispatch } from '@/store';
import { selectNodesParams } from '@/store/slices/nodes/nodes';
import {
  fetchDeleteRuleAction,
  fetchFiltersTreeNodeAction,
  fetchRuleEditAction,
  fetchRulesListAction,
  fetchSamePriceTreeAction,
  fetchSamePriceTreeFilteredAction,
  selectRuleBlocks,
  selectSamePriceRuleFilter,
  selectSamePriceTree,
  selectSamePriceTreeFiltered,
  setCurrentRule,
  setFormsCommentModalOpened,
  setRuleBlocks,
  setSamePriceRule,
  setSamePriceRuleFilter,
} from '@/store/slices/rules/rules';

interface SamePriceRuleProps {
  samePriceRule: ISamePriceRule;
  alsoHasInheritedRule?: boolean;
}

const SamePriceRule: FC<SamePriceRuleProps> = ({ alsoHasInheritedRule, samePriceRule }) => {
  const { classes } = useStyles();

  const dispatch: AppDispatch = useDispatch();

  const treeRef = useRef<Tree<IModifiedFilterTreeItem>>(null);

  const [selectedProduct, setSelectedProduct] = useState<IFilterTreeValue | null>(null);
  const [treeDataExample, setTreeDataExample] = useState<IModifiedFilterTreeItem[]>([]);
  const [treeDataExampleFiltered, setTreeDataExampleFiltered] = useState<IModifiedFilterTreeItem[]>(
    [],
  );

  const samePriceRuleFilter = useSelector(selectSamePriceRuleFilter);
  const samePriceTree = useSelector(selectSamePriceTree);
  const samePriceTreeFiltered = useSelector(selectSamePriceTreeFiltered);
  const ruleBlocks = useSelector(selectRuleBlocks);
  const nodesParams = useSelector(selectNodesParams);

  const [disabled, setDisabled] = useState<boolean>(samePriceRule?.inherited ?? false);

  const modifiedTreeData = (dataFromBackend: IFilterTreeValue[]): IModifiedFilterTreeItem[] => {
    return dataFromBackend.map((item: IFilterTreeValue, index: number) => ({
      title: <FilterTreeMultiSelectTitle item={item} isProductsFilter />,
      key: `0-${index}`,
      id: item.id,
      isLeaf: false,
    }));
  };

  const onExpand = async (
    keys: Key[],
    treeNode: {
      node: EventDataNode<IModifiedFilterTreeItem>;
      expanded: boolean;
      nativeEvent: MouseEvent;
    },
  ) => {
    if (treeNode.expanded) {
      let newTreeData = [...treeDataExample];

      const result = await dispatch(
        fetchFiltersTreeNodeAction({ parentId: treeNode.node.id, query: samePriceRuleFilter }),
      );

      const modifiedFiltersTreeData = result.payload.items
        ? result.payload.items.map((item: IFilterTreeValue) => ({
            title: <FilterTreeMultiSelectTitle item={item} isProductsFilter />,
            id: item.id,
          }))
        : [];

      getNewTreeData(
        newTreeData,
        treeNode.node.key,
        generateTreeNodes(treeNode, modifiedFiltersTreeData),
      );
      setTreeDataExample(newTreeData);
    }
  };

  const onExpandFiltered = async (
    keys: Key[],
    treeNode: {
      node: EventDataNode<IModifiedFilterTreeItem>;
      expanded: boolean;
      nativeEvent: MouseEvent;
    },
  ) => {
    if (treeNode.expanded) {
      let newTreeDataFiltered = [...treeDataExampleFiltered];

      const result = await dispatch(
        fetchFiltersTreeNodeAction({ parentId: treeNode.node.id, query: samePriceRuleFilter }),
      );

      const modifiedFiltersTreeData = result.payload.items
        ? result.payload.items.map((item: IFilterTreeValue) => ({
            title: <FilterTreeMultiSelectTitle item={item} isProductsFilter />,
            id: item.id,
          }))
        : [];

      getNewTreeData(
        newTreeDataFiltered,
        treeNode.node.key,
        generateTreeNodes(treeNode, modifiedFiltersTreeData),
      );
      setTreeDataExampleFiltered(newTreeDataFiltered);
    }
  };

  const onSelect = (
    selectedKeys: Key[],
    info: {
      node: EventDataNode<IModifiedFilterTreeItem>;
    },
  ) => {
    if (samePriceRule?.active && !disabled) {
      if (info.node.isLeaf) {
        const selectedItem = info.node.title.props.item;
        if (selectedProduct === selectedItem) {
          setSelectedProduct(null); // Удаляем продукт, если он уже выбран
        } else {
          setSelectedProduct(selectedItem); // Выбираем продукт, если он не выбран
        }
      }
    }
  };

  const handleSubmit = () => {
    if (samePriceRule) {
      const updatedSamePriceRule = {
        ...samePriceRule,
        productId: selectedProduct?.id,
      };

      dispatch(setSamePriceRule(updatedSamePriceRule));
      dispatch(setCurrentRule(updatedSamePriceRule));
      dispatch(setFormsCommentModalOpened(true));
    }
  };

  const changeActiveStatus = async (status: boolean) => {
    if (samePriceRule) {
      const updatedSamePriceRule = {
        ...samePriceRule,
        active: status,
      };

      dispatch(setCurrentRule(updatedSamePriceRule));
      dispatch(setSamePriceRule(updatedSamePriceRule));

      await dispatch(
        fetchRuleEditAction({
          nodes: modifiedNodeParams(nodesParams),
          rule: modifiedSamePriceRule(status, samePriceRule),
        }),
      );

      dispatch(fetchRulesListAction(modifiedNodeParams(nodesParams)));
    }
  };

  const deleteRule = async () => {
    if (samePriceRule?.id) {
      await dispatch(fetchDeleteRuleAction([samePriceRule.id]));
      dispatch(fetchRulesListAction(modifiedNodeParams(nodesParams)));
    }

    dispatch(setSamePriceRule(null));
    const updatedRuleBlocks = ruleBlocks.map((item) => ({
      ...item,
      checked: item.type === ERuleType.samePrice ? false : item.checked,
      shown: item.type === ERuleType.samePrice ? false : item.shown,
    }));

    dispatch(setRuleBlocks(updatedRuleBlocks));
  };

  useEffect(() => {
    if (samePriceRuleFilter.length > 0) {
      dispatch(fetchSamePriceTreeFilteredAction(samePriceRuleFilter));
    } else {
      dispatch(fetchSamePriceTreeAction());
    }
  }, [samePriceRuleFilter]);

  useEffect(() => {
    if (samePriceRuleFilter.length === 0 && samePriceTree) {
      setTreeDataExample(modifiedTreeData(samePriceTree.items.slice(1)));
    }
  }, [samePriceTree, samePriceRuleFilter]);

  useEffect(() => {
    if (samePriceRuleFilter.length > 0 && samePriceTreeFiltered) {
      setTreeDataExampleFiltered(modifiedTreeData(samePriceTreeFiltered.items.slice(1)));
    }
  }, [samePriceTreeFiltered, samePriceRuleFilter]);

  useEffect(() => {
    return () => {
      dispatch(setSamePriceRuleFilter(''));
    };
  }, []);

  return (
    <PriceRuleBlock>
      <Flex direction="row" justify="space-between">
        {samePriceRule?.inherited || alsoHasInheritedRule ? (
          <Badge color="green" variant="filled">
            Наследуется
          </Badge>
        ) : (
          <Switch
            classNames={{ track: classes.track }}
            checked={samePriceRule?.active ?? false}
            color="dark"
            size="md"
            onChange={(event) => {
              changeActiveStatus(event.currentTarget.checked);
            }}
          />
        )}
        {!(alsoHasInheritedRule || samePriceRule?.inherited) && (
          <ActionIcon
            color="dark"
            size="lg"
            variant="transparent"
            className={classes.actionIcon}
            onClick={() => openDeleteModal(deleteRule)}
          >
            <IconX />
          </ActionIcon>
        )}
      </Flex>
      <Title mt={12} mb={24} order={3}>
        {samePriceRule?.name}
      </Title>
      <Flex gap={32} mb={24} align="center">
        <Input
          radius={8}
          w={250}
          icon={<IconSearch />}
          placeholder="Поиск по названию и ID"
          disabled={!samePriceRule?.active || disabled}
          onChange={(e) => dispatch(setSamePriceRuleFilter(e.target.value))}
        />
        <Text>Выбранный товар: {selectedProduct?.name ?? samePriceRule?.productName}</Text>
      </Flex>
      <Text mb={8}>Все категории</Text>
      <Provider motion={true}>
        <StrictMode>
          <style dangerouslySetInnerHTML={{ __html: childStyle }} />
          {samePriceRuleFilter.length === 0 && (
            <Tree
              ref={treeRef}
              motion={motion}
              height={200}
              itemHeight={40}
              className={classes.tree}
              showLine
              selectable
              treeData={treeDataExample}
              onSelect={onSelect}
              onExpand={onExpand}
              showIcon={false}
              switcherIcon={(obj) => {
                if (!obj.isLeaf) {
                  return obj.expanded ? <IconChevronDown /> : <IconChevronRight />;
                } else return null;
              }}
            />
          )}
          {samePriceRuleFilter.length > 0 && (
            <Tree
              ref={treeRef}
              motion={motion}
              height={200}
              itemHeight={40}
              className={classes.tree}
              showLine
              selectable
              treeData={treeDataExampleFiltered}
              onSelect={onSelect}
              onExpand={onExpandFiltered}
              showIcon={false}
              switcherIcon={(obj) => {
                if (!obj.isLeaf) {
                  return obj.expanded ? <IconChevronDown /> : <IconChevronRight />;
                } else return null;
              }}
            />
          )}
        </StrictMode>
      </Provider>

      <Divider sx={(theme) => ({ margin: '24px 0px', borderTopColor: theme.colors.gray[2] })} />
      <Flex justify="flex-end" mt={24}>
        {alsoHasInheritedRule && (
          <Tooltip
            label="Вы не можете редактировать правило, так как уже создано деактивированное правило. Перейдите во вкладку деактивированных правил."
            withArrow
            multiline
            w={350}
            openDelay={500}
            position="left"
          >
            <Flex>
              <Button color="dark.9" disabled={alsoHasInheritedRule}>
                Редактировать
              </Button>
            </Flex>
          </Tooltip>
        )}
        {samePriceRule?.active && disabled && !alsoHasInheritedRule && (
          <Button color="dark.9" ml={40} onClick={() => setDisabled(false)}>
            Редактировать
          </Button>
        )}
        {samePriceRule?.active && !disabled && !alsoHasInheritedRule && (
          <Button color="dark.9" ml={40} onClick={handleSubmit} disabled={!selectedProduct}>
            Сохранить
          </Button>
        )}
      </Flex>
    </PriceRuleBlock>
  );
};

export default SamePriceRule;
