import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  checkboxBody: {
    display: 'flex',
    alignItems: 'center',
  },
  label: {
    fontSize: 12,
    whiteSpace: 'nowrap',
  },
  checkboxFlex: {
    flexWrap: 'wrap',
  },
  tooltip: {
    backgroundColor: theme.colors.dark[9],
    borderRadius: 8,
    color: 'white',
    padding: '16px',
    fontSize: '12px',
  },
  list: {
    color: 'white',
    fontSize: '12px',
  },
  titleText: {
    fontSize: '14px',
    color: theme.colors.dark[2],
  },
  infoText: {
    fontSize: '14px',
  },
  spoilerControl: {
    ':hover': { textDecoration: 'none' },
    paddingTop: '16px',
  },
  select: {
    rightSection: {
      pointerEvents: 'none',
    },
    input: {
      ':hover': { backgroundColor: theme.colors.gray[3] },
      '::placeholder': {
        color: 'black',
      },
      backgroundColor: theme.colors.gray[2],
      ':focus': {
        border: 'none',
      },
      border: 'none',
    },
  },
  icon: {
    color: theme.colors.mariner[6],
  },
}));
