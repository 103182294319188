import { EFilterKey } from '@/types/filters/filters';

import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Flex, Group, Text, Title } from '@mantine/core';
import { IconTrash } from '@tabler/icons-react';

import { AppDispatch } from '@/store';
import { resetFilters } from '@/store/slices/filters/filters';
import {
  selectInputQuery,
  selectPlansFetching,
  setActivePlansQuery,
  setInputQuery,
} from '@/store/slices/plans/plans';
import { setActiveRulesFilters } from '@/store/slices/rules/rules';

type TTableEmptyDataProps = {
  variant?: 'rules' | 'plans' | 'planDetail';
  withClearButton?: boolean;
};

const TableEmptyData: FC<TTableEmptyDataProps> = ({
  variant = 'rules',
  withClearButton = true,
}) => {
  const dispatch: AppDispatch = useDispatch();

  const plansInputValue = useSelector(selectInputQuery);
  const plansFetching = useSelector(selectPlansFetching);

  const isPlansVariant = variant === 'plans';

  const clearAllFilters = () => {
    if (isPlansVariant) {
      dispatch(setActivePlansQuery(''));
      dispatch(setInputQuery(''));
      dispatch(resetFilters(EFilterKey.plans));
      return;
    }
    dispatch(setActiveRulesFilters([]));
    dispatch(resetFilters(EFilterKey.rules));
  };

  const easterText = 'поисковая строка три тысячи';

  const tableText = {
    rules:
      'Начните сейчас — просто нажмите кнопку «создать правило». Это быстрый и простой процесс, который позволит вам максимально использовать возможности вашего товарного узла.',
    plan: 'Начните сейчас — просто нажмите кнопку «создать анализ». Это быстрый и простой процесс, который позволит вам максимально использовать возможности вашего товарного узла.',
    planEaster: 'Зато можно вспомнить, что ты классный и улыбнуться!',
    planDetail: 'Добавьте товары или категории и регион для создания ценового анализа',
  };
  const titleText = {
    rules: 'Ой! К сожалению, по вашим параметрам ничего не найдено.',
    planDetail: 'Ой! Ваш список пока пуст.',
  };

  return (
    <Group m="32px" maw="882px">
      <Title order={3} w="100%">
        {(variant === 'rules' || variant === 'plans') && titleText.rules}
        {variant === 'planDetail' && titleText.planDetail}{' '}
      </Title>
      <Text size="md" color="dark.2" w="100%">
        {variant === 'rules' && tableText.rules}
        {variant === 'plans' && plansInputValue !== easterText && tableText.plan}
        {variant === 'plans' && plansInputValue === easterText && !plansFetching && (
          <Flex direction="column" gap={16}>
            <Flex direction="row" align="center" gap={8}>
              {tableText.planEaster}{' '}
              <img
                src="https://emoji.slack-edge.com/T044QQS8U/hot_heart/725fedf6e09d3446.gif"
                alt="serdechko"
                width="30"
                height="30"
              />
            </Flex>
            <img
              src="https://www.gifki.org/data/media/198/lyagushka-animatsionnaya-kartinka-0466.gif"
              alt="lyagushka"
              width="200"
              height="200"
            />
          </Flex>
        )}
        {variant === 'planDetail' && tableText.planDetail}
      </Text>
      {withClearButton && (
        <Button onClick={clearAllFilters} leftIcon={<IconTrash />} color="gray.2" size="md">
          Сбросить фильтры
        </Button>
      )}
    </Group>
  );
};

export default TableEmptyData;
