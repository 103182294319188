import {
  IPlanDetailPriceInfoPoints,
  IPlanDetailPriceInfoPointsValues,
  IPlanDetailValues,
  IPlanPriceDataValues,
  IPlanPriceDotsValues,
} from '@/types/plans/plans';

import { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Checkbox, Flex, List, RangeSlider, Text, useMantineTheme } from '@mantine/core';
import {
  CartesianGrid,
  Line,
  LineChart,
  ReferenceDot,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';

import { getBarColor } from '@/utils/plans';

import { chartsEmptyDataTitles } from '@/constants/plans';

import ChartsEmptyData from '@/ui/organisms/ChartsEmptyData/ChartsEmptyData';

import { useStyles } from '@/assets/styles/editPlanPage';
import { AppDispatch } from '@/store';
import {
  fetchPlanDetailPriceInfoAction,
  selectDetailPlanInfo,
  selectDetailPriceInfo,
} from '@/store/slices/plans/plans';

const EditPlanPageTableDrawerPriceTab: FC = () => {
  const { classes } = useStyles();

  const dispatch: AppDispatch = useDispatch();

  const detailPlanItem = useSelector(selectDetailPlanInfo);
  const detailPriceInfo = useSelector(selectDetailPriceInfo);

  const theme = useMantineTheme();

  const [todayDate] = useState(new Date());
  const [rangeValues, setRangeValues] = useState<[number, number]>();
  const [minMaxRangeValues, setMinMaxRangeValues] = useState<[number, number]>([0, 0]);

  const oneDayInSeconds = 86400;

  const linesNames: string[] = [];
  detailPriceInfo.points?.forEach((item) => {
    item.values.forEach((value) => {
      if (!linesNames.includes(value.name)) linesNames.push(value.name);
    });
  });

  const pricesValues = detailPriceInfo?.points
    ?.map((item) => item.values[0])
    .map((item) => item.value);
  const maxPricesValue = pricesValues && Math.max.apply(null, pricesValues);
  const maxYValue =
    detailPlanItem?.newPrice && maxPricesValue
      ? Math.max(maxPricesValue, detailPlanItem?.newPrice)
      : maxPricesValue;

  // эти значения нам нужны, чтобы правильно задать значения оси Х, получить точки для построения графика
  // и получить значения для слайдера масштабирования
  const calculatedValues = useMemo(() => {
    const todayDateInSeconds = todayDate.getTime() / 1000;
    // массив дат, с наименьшей полученной в ответе до сегодняшней
    const dates: number[] = [];
    // точки для графика по датам генерим сами, так как в ответе от бека получаем только дату с которой устанавливается
    // определенное значение
    const newPoints: IPlanDetailPriceInfoPoints[] = [];

    if (detailPriceInfo.points?.length) {
      // сотируем точки по датам для правильного обхода
      const points = [...detailPriceInfo.points].sort((a, b) => a.date - b.date);
      // начальная дата равная наименьшей дате в ответе
      let startDate = points[0].date;

      // выполняем цикл пока начальная дата меньше сегодняшней
      while (startDate < todayDateInSeconds + oneDayInSeconds) {
        // массив значений для добавления к конктетной дате
        let newPointsValues: IPlanDetailPriceInfoPointsValues[] = [];
        for (let i = 0; i < points.length; i++) {
          // обязательно поверяем дату элемента чтобы добавить элементы в правильной последовательности
          if (points[i].date <= startDate) {
            // так как values это массив обходим его чтобы добавить нужные значения
            for (let j = 0; j < points[i].values.length; j++) {
              // фильтруем массив на случай, если на текущую дату у нас уже есть значения с таким именем
              // чтобы не было дублей
              const filteredNewPointsValues = newPointsValues.filter(
                (item) => item.name !== points[i].values[j].name,
              );
              // переназначаем значение без дублей
              newPointsValues = [...filteredNewPointsValues, points[i].values[j]];
            }
          }
        }
        // после того как мы собрали все values, добавляем в массив поинт с датой и значениями
        newPoints.push({
          date: startDate,
          values: newPointsValues,
        });
        // добавляем в массив дат новую дату отличающуюся от предыдущей на 1 день
        dates.push(startDate);
        // прибавляем 1 день и повторяем цикл while
        startDate += oneDayInSeconds;
      }
    }

    return { dates, newPoints };
  }, [detailPriceInfo]);

  const referenceDots: IPlanPriceDotsValues[] = [];
  detailPriceInfo.points?.forEach((point) => {
    point.values.forEach((item) => {
      if (item.isAnomaly) {
        referenceDots.push({
          date: point.date,
          value: item.value,
          isAnomaly: item.isAnomaly,
        });
      }
    });
  });

  const colorNames: string[] = [];
  const { colors } = useMantineTheme();
  for (const key in colors) {
    if (key !== 'dark' && key !== 'gray') colorNames.push(key);
  }

  const getValue = (data: IPlanPriceDataValues, name: string) => {
    const index = data.values.findIndex((item) => item.name === name);
    if (index === -1) return null;
    return data.values[index].value;
  };

  const customTooltip = ({ active, payload }: TooltipProps<ValueType, NameType>) => {
    if (active && payload && payload.length) {
      const date = new Date(payload[0].payload.date * 1000).toLocaleDateString();
      return (
        <Box className={classes.tooltip}>
          <Box>
            <Text>{`Дата: ${date}`}</Text>
            <Text>Динамика</Text>
            <List className={classes.list}>
              {payload[0].payload.values.map((item: IPlanDetailValues) => {
                return (
                  <List.Item
                    key={item.name + item.value}
                  >{`${item.name}: ${item.value} \u20BD`}</List.Item>
                );
              })}
            </List>
          </Box>
        </Box>
      );
    }

    return null;
  };

  // payload: any потому что не типизировано библиотекой
  const customXAsisTick = (payload: any) => {
    const date = new Date(payload.payload.value * 1000).toLocaleDateString();

    return (
      <g transform={`translate(${payload.x},${payload.y})`}>
        <text x={0} y={0} dy={16} textAnchor="middle" fill={theme.colors.dark[3]}>
          {date}
        </text>
      </g>
    );
  };

  useEffect(() => {
    if (detailPlanItem?.id) dispatch(fetchPlanDetailPriceInfoAction(detailPlanItem.id));
  }, [detailPlanItem]);

  useEffect(() => {
    if (detailPriceInfo.points?.length) {
      const todayDateInSeconds = todayDate.getTime() / 1000;
      const points = [...detailPriceInfo.points].sort((a, b) => a.date - b.date);

      setMinMaxRangeValues([points[0].date, todayDateInSeconds]);
      setRangeValues([points[0].date, todayDateInSeconds]);
    }
  }, [detailPriceInfo]);

  if (!detailPriceInfo?.points?.length)
    return <ChartsEmptyData title={chartsEmptyDataTitles.PRICES} />;
  return (
    <Flex direction="column">
      <LineChart
        width={720}
        height={384}
        data={calculatedValues.newPoints}
        margin={{ top: 10, right: 10, left: 0, bottom: 0 }}
      >
        <CartesianGrid />
        <XAxis
          dataKey="date"
          type="number"
          fontSize={14}
          tick={customXAsisTick}
          domain={rangeValues}
          allowDataOverflow
          tickCount={8}
        />
        <YAxis fontSize={14} domain={maxYValue ? [0, maxYValue] : [0, 0]} />
        <Tooltip content={customTooltip} cursor={{ fill: 'transparent' }} />
        {linesNames.map((name, index) => (
          <Line
            key={index}
            dataKey={(data) => getValue(data, name)}
            stroke={getBarColor(colors, colorNames, index)}
            dot={false}
            strokeWidth={2}
          />
        ))}
        {detailPlanItem?.newPrice && (
          <ReferenceDot
            x={todayDate.setHours(0, 0, 0, 0) / 1000}
            y={detailPlanItem?.newPrice}
            r={6}
            fill={theme.colors.blue[6]}
            stroke="none"
            key={detailPlanItem?.newPrice}
          />
        )}
        {!!referenceDots.length &&
          referenceDots.map((dot) => (
            <ReferenceDot
              x={dot.date}
              y={dot.value}
              r={6}
              fill={theme.colors.red[8]}
              stroke="none"
              key={dot.date + dot.value}
            />
          ))}
      </LineChart>
      <RangeSlider
        value={rangeValues}
        color="dark"
        py={24}
        size="lg"
        onChange={(value) => setRangeValues(value)}
        min={minMaxRangeValues[0]}
        max={minMaxRangeValues[1]}
        step={oneDayInSeconds}
        label={(value) => new Date(value * 1000).toLocaleDateString()}
      />
      <Flex wrap="wrap" gap={16}>
        {linesNames.map((item, index) => {
          return (
            <Checkbox
              checked={false}
              key={index}
              sx={{
                input: {
                  backgroundColor: getBarColor(colors, colorNames, index),
                  border: 'none',
                  ':checked': {
                    backgroundColor: getBarColor(colors, colorNames, index),
                  },
                },
              }}
              radius="sm"
              size="sm"
              label={item}
              classNames={{ label: classes.label, body: classes.checkboxBody }}
              onChange={() => {}}
            />
          );
        })}
        {!!detailPlanItem?.newPrice && (
          <Checkbox
            classNames={{ label: classes.label, body: classes.checkboxBody }}
            radius="xl"
            label="Новая цена"
            size="sm"
            styles={{ input: { backgroundColor: theme.colors.blue[6], border: 'none' } }}
            checked={false}
            onChange={() => {}}
          />
        )}
        {!!referenceDots.length && (
          <Checkbox
            classNames={{ label: classes.label, body: classes.checkboxBody }}
            radius="xl"
            label="Аномалия"
            size="sm"
            styles={{ input: { backgroundColor: theme.colors.red[8], border: 'none' } }}
            checked={false}
            onChange={() => {}}
          />
        )}
      </Flex>
    </Flex>
  );
};

export default EditPlanPageTableDrawerPriceTab;
