import { FC, HTMLAttributes } from 'react';
import { Group, Title } from '@mantine/core';

import BreadcrumbsExt, { BreadcrumbsExtItem } from '../BreadcrumbsExt/BreadcrumbsExt';

interface PageHeaderProps extends HTMLAttributes<HTMLElement> {
  title: string;
  items?: BreadcrumbsExtItem[];
}

const PageHeader: FC<PageHeaderProps> = ({ title, items, children }) => {
  return (
    <>
      {items && <BreadcrumbsExt items={items} />}
      <Group mb="24px" position="apart">
        <Title order={1}>{title}</Title>

        <Group position="center">{children}</Group>
      </Group>
    </>
  );
};

export default PageHeader;
