import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Flex, Modal, Text, Textarea } from '@mantine/core';
import { useForm } from '@mantine/form';

import { AppDispatch } from '@/store';
import {
  fetchCommentPlanAction,
  selectCheckedPlan,
  selectCheckedPlans,
  selectPlansCommentModalOpened,
  selectPlansRefetch,
  setCheckedPlan,
  setCheckedPlans,
  setPlansCommentModalOpened,
  setPlansRefetch,
} from '@/store/slices/plans/plans';

export const PricePlansTableCommentModal: FC = () => {
  const dispatch: AppDispatch = useDispatch();

  const plansCommentModalOpened = useSelector(selectPlansCommentModalOpened);
  const checkedPlan = useSelector(selectCheckedPlan);
  const checkedPlans = useSelector(selectCheckedPlans);
  const refetchPlans = useSelector(selectPlansRefetch);

  const checkedPlansIDs = checkedPlans.map((item) => item.id);

  const form = useForm({
    initialValues: {
      comment: checkedPlan?.comment || '',
    },
  });

  const resetCheckedPlans = () => {
    dispatch(setPlansCommentModalOpened(false));
    dispatch(setCheckedPlan(null));
    dispatch(setCheckedPlans([]));
    form.setFieldValue('comment', '');
  };

  const handleSubmit = async (formData: { comment: string }) => {
    if (checkedPlan) {
      await dispatch(fetchCommentPlanAction({ id: [checkedPlan?.id], comment: formData.comment }));

      dispatch(setPlansRefetch(!refetchPlans));
    } else if (checkedPlans) {
      await dispatch(
        fetchCommentPlanAction({
          id: checkedPlansIDs,
          comment: formData.comment,
          isMultiple: checkedPlans.length > 1,
        }),
      );

      dispatch(setPlansRefetch(!refetchPlans));
    }
    resetCheckedPlans();
  };

  useEffect(() => {
    if (checkedPlan) {
      form.setFieldValue('comment', checkedPlan.comment as string);
    } else if (checkedPlans.length === 1) {
      form.setFieldValue('comment', checkedPlans[0].comment as string);
    } else form.setFieldValue('comment', '');

    form.resetDirty();
  }, [checkedPlan, checkedPlans]);

  return (
    <Modal
      size={'md'}
      opened={plansCommentModalOpened}
      onClose={resetCheckedPlans}
      title={
        <Text fz={22} weight="bold">
          Комментировать
        </Text>
      }
    >
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Textarea
          size="sm"
          color="dark.2"
          placeholder="Введите комментарий для анализа"
          minRows={4}
          {...form.getInputProps('comment')}
        />
        <Flex direction="row" justify="space-between" gap={16} mt={24}>
          <Button fullWidth onClick={resetCheckedPlans} variant="filled" color="gray.2">
            Отменить
          </Button>
          <Button fullWidth type={'submit'} color="dark.9">
            Комментировать
          </Button>
        </Flex>
      </form>
    </Modal>
  );
};
