import { EFilterKey } from '@/types/filters/filters';

import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Flex, Group, Input, Skeleton } from '@mantine/core';
import { IconSearch, IconTrash } from '@tabler/icons-react';

import { useDebounce } from '@/hooks/useDebounce';
import { useLocation } from 'react-router-dom';

import Filters from '@/ui/organisms/Filters/Filters';

import { AppDispatch } from '@/store';
import {
  fetchPlansFiltersAction,
  resetFilters,
  selectFetchingFilters,
} from '@/store/slices/filters/filters';
import { selectInputQuery, setActivePlansQuery, setInputQuery } from '@/store/slices/plans/plans';

const PricePlansTableFilters: FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const location = useLocation();

  const queryValue = useSelector(selectInputQuery);
  const fetchingFilters = useSelector(selectFetchingFilters);

  const debouncedValue = useDebounce<string>(queryValue);

  const clearAllFilters = () => {
    dispatch(resetFilters(EFilterKey.plans));
    dispatch(setInputQuery(''));
  };

  useEffect(() => {
    dispatch(fetchPlansFiltersAction());
    return () => {
      dispatch(setInputQuery(''));
    };
  }, []);

  useEffect(() => {
    dispatch(setActivePlansQuery(debouncedValue));
  }, [debouncedValue]);

  useEffect(() => {
    clearAllFilters();
  }, [location]);

  return (
    <Flex justify="space-between" mb={24}>
      {fetchingFilters.plans ? (
        <Skeleton height={36} radius="8px" width="50%" />
      ) : (
        <Group spacing="xs">
          <Filters filterKey={EFilterKey.plans} />
          <Button
            variant="subtle"
            sx={{ color: 'black' }}
            ml={8}
            leftIcon={<IconTrash size="1.3rem" />}
            onClick={clearAllFilters}
          >
            Сбросить все
          </Button>
        </Group>
      )}
      <Group spacing="xs">
        <Input
          sx={{ width: 260 }}
          icon={<IconSearch />}
          placeholder="Поиск по имени и описанию"
          onChange={(event) => dispatch(setInputQuery(event.target.value))}
          value={queryValue}
        />
      </Group>
    </Flex>
  );
};

export default PricePlansTableFilters;
