import { PriceAlgTitle } from '@/types/plans/plans';

import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Checkbox,
  Divider,
  Flex,
  List,
  RangeSlider,
  Spoiler,
  Text,
  useMantineTheme,
} from '@mantine/core';
import { IconChevronDown, IconChevronUp } from '@tabler/icons-react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  LabelList,
  ReferenceLine,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';

import { getBarColor, getLabelListValue, getOpacity, getReferenceLineColor } from '@/utils/plans';

import { chartsEmptyDataTitles } from '@/constants/plans';

import ChartsEmptyData from '@/ui/organisms/ChartsEmptyData/ChartsEmptyData';

import { useStyles } from '@/assets/styles/editPlanPage';
import { AppDispatch } from '@/store';
import {
  fetchPlanDetailPairProcessInfoAction,
  selectDetailPairProcessInfo,
  selectDetailPlanInfo,
  selectPlanDetails,
} from '@/store/slices/plans/plans';

const EditPlanPageTableDrawerBarsTab: FC = () => {
  const dispatch: AppDispatch = useDispatch();

  const { classes } = useStyles();

  const detailPlanItem = useSelector(selectDetailPlanInfo);
  const planDetails = useSelector(selectPlanDetails);

  const detailProcessInfo = useSelector(selectDetailPairProcessInfo);

  const editPlanInfo = useSelector(selectDetailPlanInfo);

  let minMaxValues: [number, number] = [0, 0];
  const [isClicked, setIsClicked] = useState<boolean>(false);
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<string>('');
  const [rangeValues, setRangeValues] = useState<[number, number]>();
  const [defaultRangeValues, setDefaultRangeValues] = useState<[number, number]>(minMaxValues);

  const colorNames: string[] = [];
  const { colors } = useMantineTheme();
  for (const key in colors) {
    if (key !== 'dark' && key !== 'gray') colorNames.push(key);
  }

  const customTooltip = ({ active, payload, label }: TooltipProps<ValueType, NameType>) => {
    if (active && payload && payload.length && isHovered) {
      return (
        <Box className={classes.tooltip}>
          {!isClicked && (
            <Box>
              <Text>{label}</Text>
              {payload[0].payload.prodNode && (
                <Text>Товарный узел: {payload[0].payload.prodNode}</Text>
              )}
              <List className={classes.list}>
                <List.Item>{`Левая граница: ${payload[0].payload.range[0]} \u20BD`}</List.Item>
                <List.Item>{`Правая граница: ${payload[0].payload.range[1]} \u20BD`}</List.Item>
              </List>
            </Box>
          )}
          {isClicked && (
            <Text dangerouslySetInnerHTML={{ __html: payload[0].payload.description }} />
          )}
        </Box>
      );
    }

    return null;
  };

  // библиотека не предоставляет типы
  const renderCustomizedLabel = (props: any) => {
    const { x, y, width, value } = props;
    const radius = 16;

    return (
      <text
        x={x + width / 2}
        y={y + radius}
        fill="white"
        fontSize={width > 300 ? 14 : 10}
        style={{ cursor: 'pointer' }}
        textAnchor="middle"
        dominantBaseline="middle"
        onMouseEnter={() => setIsHovered(true)}
      >
        {getLabelListValue(value, width)}
      </text>
    );
  };

  useEffect(() => {
    if (detailPlanItem?.id) dispatch(fetchPlanDetailPairProcessInfoAction(detailPlanItem.id));
  }, [detailPlanItem]);

  useEffect(() => {
    const linesValues = detailProcessInfo?.lines?.map((item) => item.value);
    const maxLinesValue = linesValues && Math.max.apply(null, linesValues);

    if (detailProcessInfo?.rules) {
      const rulesMinMaxValues: [number, number] = detailProcessInfo?.rules.reduce(
        (acc, item) => {
          const minValue = acc[0];
          const maxValue = acc[1];

          if (minValue > item.range[0]) {
            acc = [item.range[0], maxValue];
          }
          if (maxValue < item.range[1] && maxLinesValue && maxLinesValue <= item.range[1]) {
            acc = [minValue, item.range[1]];
          }
          if (maxValue < item.range[1] && maxLinesValue && item.range[1] < maxLinesValue) {
            acc = [minValue, maxLinesValue];
          }
          return acc;
        },
        [0, 0],
      );
      setRangeValues(rulesMinMaxValues);
      setDefaultRangeValues(rulesMinMaxValues);
    }
  }, [detailProcessInfo]);

  if (!detailProcessInfo?.rules) return <ChartsEmptyData title={chartsEmptyDataTitles.BARS} />;

  const emptyRuleBars =
    detailProcessInfo.rules &&
    detailProcessInfo.rules.filter((item) => item.range[1] - item.range[0] === 0);

  return (
    <Flex direction="column">
      <BarChart
        width={768}
        height={440}
        data={detailProcessInfo?.rules}
        margin={{ left: -54, bottom: 20, top: 20 }}
        layout="vertical"
      >
        <XAxis
          dataKey="range"
          type="number"
          tickCount={11}
          tickLine={false}
          tickMargin={16}
          allowDataOverflow
          domain={rangeValues}
        />
        <YAxis type="category" dataKey="name" tick={false} />
        <CartesianGrid />
        <Bar
          dataKey="range"
          onClick={(data) => {
            setIsClicked(true);
            setSelectedItem(selectedItem === data.name ? '' : data.name);
          }}
          onMouseLeave={() => {
            setIsClicked(false);
            setIsHovered(false);
          }}
          onMouseEnter={() => setIsHovered(true)}
          radius={8}
          maxBarSize={32}
          style={{ cursor: 'pointer' }}
        >
          <LabelList
            content={renderCustomizedLabel}
            dataKey="name"
            position="inside"
            fill="white"
            fontSize={14}
            pointerEvents="none"
          />
          {detailProcessInfo?.rules.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={getBarColor(colors, colorNames, index)}
              opacity={getOpacity(selectedItem, entry.name)}
            />
          ))}
        </Bar>
        <Tooltip content={customTooltip} cursor={{ fill: 'transparent' }} />
        {detailProcessInfo?.lines?.map((line, index) => (
          <ReferenceLine
            key={index}
            x={line.value}
            stroke={getReferenceLineColor(line.name)}
            strokeDasharray="3 3"
          />
        ))}
      </BarChart>
      {!!emptyRuleBars.length && (
        <Flex direction="column" mt={16}>
          <Text color="gray" size="sm" mb={8}>
            Данные диапазоны цен по правилам не показаны на графике, так как их границы совпадают:
          </Text>
          {emptyRuleBars.map((item, index) => {
            return (
              <Text color="gray" key={index}>
                {item?.name}: {item?.range[0]}
              </Text>
            );
          })}
        </Flex>
      )}
      <Spoiler
        classNames={{ control: classes.spoilerControl }}
        py={16}
        maxHeight={0}
        showLabel={
          <Flex gap={4} align="center">
            <Text color="blue.5" size={14}>
              Показать все
            </Text>
            <IconChevronDown className={classes.icon} />
          </Flex>
        }
        hideLabel={
          <Flex gap={4} align="center">
            <Text color="blue.5" size={14}>
              Скрыть
            </Text>
            <IconChevronUp className={classes.icon} />
          </Flex>
        }
      >
        {editPlanInfo && (
          <Flex direction="column" gap={16}>
            <Flex gap={12}>
              <Text className={classes.titleText} w={250}>
                Название товара
              </Text>
              <Text className={classes.infoText}>{editPlanInfo.prodName}</Text>
            </Flex>
            {editPlanInfo.priceBeforePostProcessing && (
              <Flex gap={12}>
                <Text className={classes.titleText} w={250}>
                  Цена до постобработки
                </Text>
                <Text className={classes.infoText}>{editPlanInfo.priceBeforePostProcessing}</Text>
              </Flex>
            )}
            {editPlanInfo.minPriceRuleValueFrom && (
              <Flex gap={12}>
                <Text className={classes.titleText} w={250}>
                  {'Минимальное значение цены: от (\u20BD)'}
                </Text>
                <Text className={classes.infoText}>{editPlanInfo.minPriceRuleValueFrom}</Text>
              </Flex>
            )}
            {editPlanInfo.minPriceRuleValueTo && (
              <Flex gap={12}>
                <Text className={classes.titleText} w={250}>
                  {'Минимальное значение цены: до (\u20BD)'}
                </Text>
                <Text className={classes.infoText}>{editPlanInfo.minPriceRuleValueTo}</Text>
              </Flex>
            )}
            {editPlanInfo.maxRetailPriceJnvls && (
              <Flex gap={12}>
                <Text className={classes.titleText} w={250}>
                  {'Ограничение по ЖНВЛП (\u20BD)'}
                </Text>
                <Text className={classes.infoText}>{editPlanInfo.maxRetailPriceJnvls}</Text>
              </Flex>
            )}
            <Flex gap={12}>
              <Text className={classes.titleText} w={250}></Text>
              <Text className={classes.infoText}></Text>
            </Flex>
            <Flex gap={12}>
              <Text className={classes.titleText} w={250}>
                {'Новая цена: от (\u20BD)'}
              </Text>
              <Text className={classes.infoText}>{editPlanInfo.newPriceFrom}</Text>
            </Flex>
            <Flex gap={12}>
              <Text className={classes.titleText} w={250}>
                {'Новая цена: до (\u20BD)'}
              </Text>
              <Text className={classes.infoText}>{editPlanInfo.newPriceTo}</Text>
            </Flex>
            <Flex gap={12}>
              <Text className={classes.titleText} w={250}>
                {'Новая цена (\u20BD)'}
              </Text>
              <Text className={classes.infoText}>{editPlanInfo.newPrice}</Text>
            </Flex>
            <Flex gap={12}>
              <Text className={classes.titleText} w={250}>
                ЖНВЛП
              </Text>
              <Text className={classes.infoText}>{editPlanInfo.jnvls ? 'Да' : 'Нет'}</Text>
            </Flex>
            <Flex gap={12}>
              <Text className={classes.titleText} w={250}>
                Алгоритм определения цены
              </Text>
              <Text className={classes.infoText}>
                {planDetails?.priceAlg && PriceAlgTitle[planDetails?.priceAlg]}
              </Text>
            </Flex>
          </Flex>
        )}
      </Spoiler>

      <RangeSlider
        color="dark"
        pb={24}
        onChange={(value) => setRangeValues(value)}
        min={defaultRangeValues[0]}
        max={defaultRangeValues[1]}
        value={rangeValues}
        size="lg"
      />

      <Flex wrap="wrap" gap={16}>
        {detailProcessInfo?.rules.map((item, index) => {
          return (
            <Checkbox
              key={index}
              sx={{
                opacity: getOpacity(selectedItem, item.name),
                input: {
                  backgroundColor: getBarColor(colors, colorNames, index),
                  border: 'none',
                  ':checked': {
                    backgroundColor: getBarColor(colors, colorNames, index),
                  },
                },
              }}
              radius="sm"
              size="sm"
              label={item.name}
              classNames={{ label: classes.label, body: classes.checkboxBody }}
              onChange={(event) =>
                event.target.checked ? setSelectedItem(item.name) : setSelectedItem('')
              }
              checked={selectedItem === item.name}
            />
          );
        })}
      </Flex>

      {detailProcessInfo.notAppliedRules && (
        <Flex direction="column" mt={16}>
          <Text>Непримененные правила:</Text>
          {detailProcessInfo?.notAppliedRules?.map((rule, index) => {
            return (
              <Flex direction="column" mt={8} key={index}>
                <Text size={16}>{rule.name}:</Text>
                <Text
                  key={index}
                  size={14}
                  dangerouslySetInnerHTML={{ __html: rule.description }}
                ></Text>
              </Flex>
            );
          })}
        </Flex>
      )}

      <Flex mt={32} direction="column">
        {detailProcessInfo?.lines?.map((line, index) => {
          return (
            <Box key={index} w={200}>
              <Divider
                variant="dashed"
                labelPosition="left"
                size="sm"
                color={getReferenceLineColor(line.name)}
                label={
                  <>
                    <Text color="black">
                      {line.name}: {line.value}
                    </Text>
                  </>
                }
              />
            </Box>
          );
        })}
      </Flex>
    </Flex>
  );
};

export default EditPlanPageTableDrawerBarsTab;
