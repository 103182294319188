import {
  IAdditionalNode,
  IAdditionalNodeValue,
  IGetAdditionalNodeValue,
} from '@/types/nodes/nodes';

import { URLS } from '@/api/urls';

import api from '../instance';

export const apiGetAdditionalNodes = () =>
  api.get<IAdditionalNode[]>(URLS.nodes.getAdditionalNodes);

export const apiGetAdditionalNodeValues = ({ field, query }: IGetAdditionalNodeValue) =>
  api.get<IAdditionalNodeValue[]>(URLS.nodes.getAdditionalNodeValues, { params: { field, query } });
