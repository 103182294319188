import {
  EPlanChangeItemValues,
  EPlansChangeValues,
  EPlanStatus,
  EPlanValidationStatus,
} from '@/types/plans/plans';

import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavigateFunction, useNavigate, useParams } from 'react-router-dom';
import { Button, Flex, Loader } from '@mantine/core';
import { IconDownload, IconPencil } from '@tabler/icons-react';

import notify from '@/utils/notify';

import { notifyMessages } from '@/constants/notify';
import { paths } from '@/constants/paths';

import SelectPlanItemsStatus from '@/ui/organisms/SelectPlanItemsStatus/SelectPlanItemsStatus';
import SelectPlanStatus from '@/ui/organisms/SelectPlanStatus/SelectPlanStatus';

import { AppDispatch } from '@/store';
import { selectActiveFilters } from '@/store/slices/filters/filters';
import {
  fetchChangePlansStatusAction,
  fetchExportPlanDetailAction,
  fetchPlanDetailAction,
  fetchPlanDetailChangeStatusAction,
  fetchProcessPlansStatusAction,
  selectFetchingPlanDetailExport,
  selectPlanDetailRefetch,
  selectPlanDetails,
  selectPlanDetailSorting,
  selectTotalPlanDetailCount,
  setPlanDetailRefetch,
  setPlanId,
  setPlansStep,
} from '@/store/slices/plans/plans';

export const EditPlanPageHeaderButtons: FC = () => {
  const dispatch: AppDispatch = useDispatch();

  const { id } = useParams();

  const navigate: NavigateFunction = useNavigate();

  const planDetail = useSelector(selectPlanDetails);
  const planDetailRefetch = useSelector(selectPlanDetailRefetch);
  const planDetailTotal = useSelector(selectTotalPlanDetailCount);
  const activeFilters = useSelector(selectActiveFilters).planDetail;
  const planDetailSorting = useSelector(selectPlanDetailSorting);
  const fetchingPlanDetailExport = useSelector(selectFetchingPlanDetailExport);

  const isProcessingPlan = planDetail?.status === EPlanStatus.ready;
  const isProcessedPlan =
    planDetail?.validationStatus === EPlanValidationStatus.readyPlan ||
    planDetail?.validationStatus === EPlanValidationStatus.canceled;

  const isReadyPlan = planDetail?.validationStatus === EPlanValidationStatus.readyPlan;
  const canSetReadyPlan =
    planDetail?.validationStatus === EPlanValidationStatus.processed ||
    planDetail?.validationStatus === EPlanValidationStatus.canceled;
  const canSetCancelPlan =
    planDetail?.validationStatus === EPlanValidationStatus.processed ||
    planDetail?.validationStatus === EPlanValidationStatus.canceled;
  const isCanceledPlan = planDetail?.validationStatus === EPlanValidationStatus.canceled;
  const canChangeStatus =
    planDetail?.status === EPlanStatus.ready &&
    planDetail.validationStatus === EPlanValidationStatus.processed;

  const isCalculatePlan = planDetail?.status === EPlanStatus.calculate;

  const calculateAll = async () => {
    if (id) await dispatch(fetchProcessPlansStatusAction([id]));
    notify({
      message: notifyMessages.CALCULATE,
      type: 'success',
    });
  };

  const handlePlanStatus = async (value: EPlansChangeValues) => {
    if (!isProcessingPlan) {
      notify({
        message: notifyMessages.NOT_PROCESSED_PLAN,
        type: 'warning',
      });
      return;
    }
    if (isReadyPlan) {
      notify({
        message: notifyMessages.READY_PLAN,
        type: 'warning',
      });
      return;
    }
    if (!canSetReadyPlan && value === EPlansChangeValues.published) {
      notify({
        message: notifyMessages.ERROR_READY_PLAN,
        type: 'warning',
      });
      return;
    }
    if (!canSetCancelPlan && value === EPlansChangeValues.declined) {
      notify({
        message: notifyMessages.ERROR_CANCEL_PLAN,
        type: 'warning',
      });
      return;
    }
    if (isCanceledPlan && value !== EPlansChangeValues.processed) {
      notify({
        message: notifyMessages.ERROR_PROCESS_PLAN,
        type: 'warning',
      });
      return;
    }
    if (id) {
      await dispatch(fetchChangePlansStatusAction({ id: [id], status: value }));
      dispatch(fetchPlanDetailAction(id));
    }
  };

  const handlePlanItemStatus = async (value: EPlanChangeItemValues) => {
    if (!canChangeStatus) {
      notify({
        message: notifyMessages.NOT_PROCESSED_PLAN_DETAIL,
        type: 'warning',
      });
      return;
    }

    if (id)
      await dispatch(
        fetchPlanDetailChangeStatusAction({
          id: id,
          values: [],
          status: value,
        }),
      );
    dispatch(setPlanDetailRefetch(!planDetailRefetch));
  };

  const downloadFile = (file: Blob) => {
    const url = window.URL.createObjectURL(new Blob([file]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'plan.csv');
    document.body.appendChild(link);
    link.click();
  };

  const handleExportFile = async () => {
    if (id) {
      const result = await dispatch(
        fetchExportPlanDetailAction({
          id: id,
          ...(planDetailSorting[0]?.id && { sort: planDetailSorting[0].id }),
          ...(typeof planDetailSorting[0]?.desc === 'boolean' && {
            order: planDetailSorting[0].desc ? 'desc' : 'asc',
          }),
          filter: [...activeFilters],
        }),
      );
      downloadFile(result.payload);
    }
  };

  const editPlanData = () => {
    if (id) {
      dispatch(setPlanId(id));
      dispatch(setPlansStep(1));
      navigate(`${paths.PATH_CREATE_PRICE_PLAN}`);
    }
  };

  return (
    <Flex gap={8}>
      <Button color="dark" onClick={calculateAll} disabled={isProcessedPlan || isCalculatePlan}>
        Рассчитать все
      </Button>
      <SelectPlanStatus onChange={handlePlanStatus} />
      <SelectPlanItemsStatus
        onChange={handlePlanItemStatus}
        placeholder="Изменить статус всех элементов"
        width="280px"
      />
      <Button
        color="gray.2"
        leftIcon={fetchingPlanDetailExport ? <Loader size="xs" /> : <IconDownload size="1.1rem" />}
        onClick={handleExportFile}
        disabled={!planDetailTotal}
      >
        Выгрузить
      </Button>
      <Button
        color="gray.2"
        leftIcon={<IconPencil size="1.1rem" />}
        onClick={editPlanData}
        disabled={isProcessedPlan || isCalculatePlan}
      >
        Редактировать
      </Button>
    </Flex>
  );
};
