import { EFilterKey } from '@/types/filters/filters';

import { FC, SyntheticEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Divider, Flex, Popover, Text } from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { IconX } from '@tabler/icons-react';

import { getIcon } from '@/ui/organisms/Filters/organisms/FilterCheckbox/FilterCheckbox';

import { AppDispatch } from '@/store';
import { clearFilter, selectActiveFilters, setActiveFilters } from '@/store/slices/filters/filters';

interface IProps {
  filterKey: EFilterKey;
  name: string;
  code: string;
  type: string;
}

const FilterDatesRange: FC<IProps> = ({ code, type, name, filterKey }) => {
  const dispatch: AppDispatch = useDispatch();

  const activeFilters = useSelector(selectActiveFilters);

  const [opened, setOpened] = useState<boolean>(false);

  const currentActiveFilter = activeFilters[filterKey].find((item) => item.code === code);

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const todayDate = new Date();

  const isActiveFilter = !!currentActiveFilter && !!activeFilters[filterKey].length;

  const resetFilter = (event: SyntheticEvent) => {
    if (!opened) event.stopPropagation();

    const filterItem = {
      filterKey: filterKey,
      item: {
        type: type,
        code: code,
        value: {},
      },
    };
    setStartDate(null);
    setEndDate(null);
    dispatch(clearFilter(filterItem));
  };

  const setFilter = () => {
    const filterItem = {
      filterKey: filterKey,
      item: {
        code: code,
        type: type,
        value: {
          from: startDate?.toLocaleDateString('ru-Ru'),
          to: endDate?.toLocaleDateString('ru-Ru'),
        },
      },
    };
    dispatch(setActiveFilters(filterItem));
    setOpened(false);
  };

  useEffect(() => {
    if (!currentActiveFilter) {
      setStartDate(null);
      setEndDate(null);
    }
  }, [currentActiveFilter]);

  return (
    <Popover
      opened={opened}
      onChange={setOpened}
      width={300}
      trapFocus
      position="bottom"
      shadow="md"
    >
      <Popover.Target>
        <Button
          variant={isActiveFilter ? 'filled' : 'default'}
          sx={{ color: isActiveFilter ? 'white' : 'black' }}
          color="dark"
          onClick={() => setOpened((state) => !state)}
        >
          <Flex gap={8} align="center">
            <Text>{name}</Text>
            {isActiveFilter ? (
              <Flex align="center" onClick={resetFilter}>
                <IconX size="1.1rem" />
              </Flex>
            ) : (
              getIcon(opened)
            )}
          </Flex>
        </Button>
      </Popover.Target>
      <Popover.Dropdown>
        <Flex direction="column" gap="8px">
          <DatePickerInput
            valueFormat="D MMMM YYYY"
            value={startDate}
            onChange={setStartDate}
            clearable
            placeholder="Выберите начальную дату"
            locale="ru"
            maxDate={endDate ?? todayDate}
            maxLevel="month"
            aria-label="minDate"
          />
          <DatePickerInput
            valueFormat="D MMMM YYYY"
            value={endDate}
            onChange={setEndDate}
            clearable
            placeholder="Выберите конечную дату"
            locale="ru"
            minDate={startDate ?? undefined}
            maxDate={todayDate}
            maxLevel="month"
            aria-label="maxDate"
          />
        </Flex>
        <Box>
          <Divider
            sx={(theme) => ({ margin: '16px -16px', borderTopColor: theme.colors.gray[2] })}
          />
          <Flex direction="row" gap="8px">
            <Button fullWidth color="gray.2" onClick={resetFilter}>
              Очистить
            </Button>
            <Button fullWidth color="dark.9" onClick={setFilter} disabled={!startDate && !endDate}>
              Применить
            </Button>
          </Flex>
        </Box>
      </Popover.Dropdown>
    </Popover>
  );
};

export default FilterDatesRange;
