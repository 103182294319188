import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Flex } from '@mantine/core';

import { modifiedData } from '@/utils/filters';

import FilterMultiSelect from '@/ui/organisms/FilterMultiSelect/FilterMultiSelect';

import { AppDispatch } from '@/store';
import {
  fetchProductsFiltersAction,
  fetchProductsFilterValues,
  selectProductsFilterCode,
  selectProductsFilterFilter,
  selectProductsFilters,
  selectProductsFilterValues,
  setProductsFilterValues,
} from '@/store/slices/products/products';

const SteppersProductNodeFilters: FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const productsFilters = useSelector(selectProductsFilters);
  const productsFilterFilter = useSelector(selectProductsFilterFilter);
  const productsFilterCode = useSelector(selectProductsFilterCode);
  const productsFilterValues = useSelector(selectProductsFilterValues);

  const productsFilterHasValues = (code: string) => {
    return productsFilters?.items.find((item) => item.code === code)?.values;
  };

  useEffect(() => {
    dispatch(fetchProductsFiltersAction());
  }, []);

  useEffect(() => {
    if (
      productsFilterFilter &&
      productsFilterCode &&
      productsFilterFilter.length > 2 &&
      !productsFilterHasValues(productsFilterCode)
    ) {
      dispatch(
        fetchProductsFilterValues({
          field: productsFilterCode,
          query: productsFilterFilter,
        }),
      );
    }
  }, [productsFilterFilter, productsFilterCode]);

  useEffect(() => {
    dispatch(setProductsFilterValues(null));
  }, [productsFilterCode]);

  useEffect(() => {
    if (productsFilterFilter.length < 3) dispatch(setProductsFilterValues(null));
  }, [productsFilterFilter]);

  return (
    <Flex gap={8} mb={24} w={500} wrap="wrap">
      {productsFilters?.items.map((item) => (
        <FilterMultiSelect
          key={item.code}
          title={item.name}
          name={item.code}
          placeholder={item.name}
          isProductsFilter
          data={item.values ? modifiedData(item.values) : modifiedData(productsFilterValues)}
        />
      ))}
    </Flex>
  );
};

export default SteppersProductNodeFilters;
