import {
  IFilter,
  IFilterFilters,
  IFilterTree,
  IFilterTreeValue,
  IFilterValue,
  IGetFilteredTreeValue,
} from '@/types/filters/filters';
import { IGetNodeParams } from '@/types/nodes/nodes';
import { IRuleEditParams, IRuleListItem } from '@/types/rules/rules';

import { URLS } from '@/api/urls';

import api from '../instance';

export const apiGetRulesFilters = () =>
  api.get<IFilterFilters<IFilter<IFilterValue>>>(URLS.rules.getRulesFilters);

export const apiPostFiltersTree = () =>
  api.post<IFilterTree<IFilterTreeValue>>(URLS.filters.getFiltersTree, {});

export const apiPostFiltersTreeNode = ({ parentId, query, filter }: IGetFilteredTreeValue) =>
  api.post(URLS.filters.getFiltersTree, { parentId, query, filter });

export const apiPostFiltersTreeFiltered = (query: string) =>
  api.post<IFilterTree<IFilterTreeValue>>(URLS.filters.getFiltersTree, { query });

export const apiPostRulesList = (nodeParams: IGetNodeParams[]) =>
  api.post<IRuleListItem[]>(URLS.rules.rulesList, nodeParams);

export const apiPostRuleEdit = (ruleEditParams: IRuleEditParams) =>
  api.post(URLS.rules.ruleEdit, ruleEditParams);
