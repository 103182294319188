import { FC } from 'react';
import { Link } from 'react-router-dom';
import { BoxProps, Breadcrumbs, createStyles } from '@mantine/core';
import { IconChevronRight } from '@tabler/icons-react';

export interface BreadcrumbsExtItem {
  title: string;
  path: string;
}

interface BreadcrumbsExtProps extends BoxProps {
  items: BreadcrumbsExtItem[];
}

const useStyles = createStyles(() => ({
  link: {
    color: 'gray',
    textDecoration: 'none',

    '&:last-child': {
      color: 'black',
    },
  },
}));

const BreadcrumbsExt: FC<BreadcrumbsExtProps> = ({ items }) => {
  const { classes } = useStyles();

  const breadcrumbsItems = items.map((item: BreadcrumbsExtItem) => (
    <Link to={item.path} key={item.title} className={classes.link}>
      {item.title}
    </Link>
  ));

  return (
    <Breadcrumbs separator={<IconChevronRight size={12} />} mt={20} mb={15}>
      {breadcrumbsItems}
    </Breadcrumbs>
  );
};

export default BreadcrumbsExt;
