import { INodeParams } from '@/types/nodes/nodes';
import {
  ICompetitorRule,
  IFixMarginRule,
  IFixPriceRule,
  IMinMaxMarginRule,
  IMinMaxPriceRule,
  IMinPriceChangeRule,
  IRuleBlock,
  IRuleListItem,
  ISamePriceRule,
} from '@/types/rules/rules';

import { MRT_SortingState } from 'mantine-react-table';

export const getSort = (sorting: MRT_SortingState) => {
  if (sorting.length > 0 && sorting[0].id !== 'createdAt') {
    return sorting[0].id;
  } else return 'created_at';
};

export const getOrder = (sorting: MRT_SortingState) => {
  if (sorting.length > 0) {
    return sorting[0].desc ? 'desc' : 'asc';
  } else return 'desc';
};

export const sliceString = (str: string) => {
  if (!str?.length) return '';
  if (str.length > 21) {
    return str.slice(0, 21) + '...';
  } else return str;
};

export const getCheckedRuleBlocks = (ruleBlock: IRuleBlock, ruleBlocks: IRuleBlock[]) => {
  return ruleBlocks.map((item: IRuleBlock) => ({
    ...item,
    checked: item === ruleBlock ? !item.checked : item.checked,
  }));
};

export const getShownRuleBlocks = (ruleBlocks: IRuleBlock[]) => {
  return ruleBlocks.map((item: IRuleBlock) => ({
    ...item,
    shown: item.checked,
  }));
};

export const getRuleBlocksState = (
  ruleBlocks: IRuleBlock[],
  rulesList:
    | IRuleListItem[]
    | Array<
        | IFixPriceRule
        | IFixMarginRule
        | IMinPriceChangeRule
        | IMinMaxPriceRule
        | IMinMaxMarginRule
        | ICompetitorRule
        | ISamePriceRule
      >,
) => {
  return ruleBlocks.map((item: IRuleBlock) => ({
    type: item.type,
    name: item.name,
    active: rulesList?.find((rule) => rule.type === item.type)?.active ?? item.active,
    inherited: rulesList?.find((rule) => rule.type === item.type)?.inherited ?? item.inherited,
    checked: rulesList?.some((rule) => rule.type === item.type),
    shown: rulesList?.some((rule) => rule.type === item.type),
  }));
};

export const dateParse = (dateString: string) => {
  const [day, month, year] = dateString.split('.');
  return new Date(+year, +month - 1, +day);
};

export const modifiedNodeParams = (nodesParams: INodeParams[]) => {
  return nodesParams.map((item) => ({
    code: item.code,
    value: item.value,
    level: item.level,
  }));
};
