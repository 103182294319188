import { EPlanStatus, EPlanValidationStatus, IPlanItem } from '@/types/plans/plans';

import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Group, Menu, Text } from '@mantine/core';
import { modals } from '@mantine/modals';
import { IconCalculator, IconCopy, IconLock, IconMessage } from '@tabler/icons-react';
import { MRT_TableInstance } from 'mantine-react-table';

import { AppDispatch } from '@/store';
import {
  fetchChangePlanStatusAction,
  fetchClonePlanAction,
  fetchProcessPlansStatusAction,
  selectPlansRefetch,
  setCheckedPlan,
  setPlansCommentModalOpened,
  setPlansRefetch,
} from '@/store/slices/plans/plans';

interface IPricePlansTableRowsActions {
  planData: IPlanItem;
  table: MRT_TableInstance<IPlanItem>;
}

export const PricePlansTableRowsActions: FC<IPricePlansTableRowsActions> = ({
  planData,
  table,
}) => {
  const dispatch: AppDispatch = useDispatch();

  const plansRefetch = useSelector(selectPlansRefetch);

  const isProcessingPlan = planData.status === EPlanStatus.calculate;
  const isProcessedPlan =
    planData.validationStatus === EPlanValidationStatus.readyPlan ||
    planData.validationStatus === EPlanValidationStatus.canceled;

  const processPlan = async () => {
    await dispatch(fetchProcessPlansStatusAction([planData.id]));
    dispatch(setPlansRefetch(!plansRefetch));
  };

  const commentPlan = () => {
    dispatch(setPlansCommentModalOpened(true));
  };

  const duplicatePlan = async () => {
    await dispatch(fetchClonePlanAction(planData.id));
    dispatch(setPlansRefetch(!plansRefetch));
  };

  const changePlanStatus = async () => {
    await dispatch(fetchChangePlanStatusAction({ id: planData.id, value: !planData.active }));
    dispatch(setPlansRefetch(!plansRefetch));
  };

  const openChangePlanStatus = () =>
    modals.openConfirmModal({
      title: (
        <Text fz={22} weight="bold">
          {planData.active ? 'Деактивация' : 'Активация'} анализа
        </Text>
      ),
      children: (
        <>
          <Text size="md" color="dark.2">
            Вы уверены что хотите {planData.active ? 'деактивировать' : 'активировать'} анализ?
          </Text>
        </>
      ),
      labels: { confirm: 'Да', cancel: 'Отменить' },
      confirmProps: { color: 'dark.9', sx: { width: '195px' } },
      cancelProps: { variant: 'filled', color: 'gray.2', sx: { width: '195px' } },
      onConfirm: () => {
        changePlanStatus();
      },
    });

  useEffect(() => {
    dispatch(setCheckedPlan(planData));
    table.resetRowSelection();
  }, [planData]);

  return (
    <>
      <Menu.Item onClick={processPlan} disabled={isProcessedPlan || isProcessingPlan}>
        <Group>
          <IconCalculator />
          Рассчитать
        </Group>
      </Menu.Item>
      <Menu.Item onClick={commentPlan}>
        <Group>
          <IconMessage />
          Комментировать
        </Group>
      </Menu.Item>
      <Menu.Item onClick={duplicatePlan}>
        <Group>
          <IconCopy />
          Дублировать
        </Group>
      </Menu.Item>
      <Menu.Item onClick={openChangePlanStatus} disabled={isProcessingPlan}>
        <Group>
          <IconLock />
          {planData.active ? 'Деактивировать анализ' : 'Активировать анализ'}
        </Group>
      </Menu.Item>
    </>
  );
};
