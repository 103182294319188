import { MantineTheme } from '@mantine/core';

export const extraButton = {
  components: {
    Button: {
      styles: (theme: MantineTheme, { color }: any, { variant }: any) => ({
        root: {
          color: color === 'gray.2' || variant === 'outline' ? theme.colors.dark[9] : 'white',
          fontWeight: 400,
        },
      }),
    },
  },
};
