import {
  IFilter,
  IFilterTree,
  IFilterTreeValue,
  IFilterValue,
  IItemsFilters,
} from '@/types/filters/filters';
import {
  ERuleType,
  ICompetitorAgregation,
  ICompetitorRule,
  IFixMarginRule,
  IFixPriceRule,
  IGetFilteredRulesParams,
  IMinMaxMarginRule,
  IMinMaxPriceRule,
  IMinPriceChangeRule,
  IRule,
  IRuleBlock,
  IRuleListItem,
  ISamePriceRule,
} from '@/types/rules/rules';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MRT_SortingState } from 'mantine-react-table';

import {
  apiPostFiltersTree,
  apiPostFiltersTreeFiltered,
  apiPostFiltersTreeNode,
  apiPostRuleEdit,
  apiPostRulesList,
} from '@/api/filters/filters';
import {
  apiDeleteRules,
  apiGetCompetitorAggregations,
  apiPostCommentRules,
  apiPostDeactivateRules,
  apiPostExportRules,
  apiPostImportRules,
  apiPostRules,
  apiPostSamePriceTree,
  apiPostSamePriceTreeFiltered,
} from '@/api/rules/rules';

import { createAxiosThunk } from '@/utils/asyncRequest';
import notify from '@/utils/notify';
import { getRuleBlocksState } from '@/utils/rules';

import { ruleBlocksInitialState } from '@/constants/rules';

interface IRulesState {
  checkedRules: IRule[];
  checkedRule: IRule | null;
  rules: IRule[] | null;
  totalRulesCount: number;
  rulesRefetch: boolean;
  commentModalOpened: boolean;
  formsCommentModalOpened: boolean;
  rulesFilters: IRulesFiltersState;
  stepper: number;
  rulesList: null | Array<
    | IFixPriceRule
    | IFixMarginRule
    | IMinPriceChangeRule
    | IMinMaxPriceRule
    | IMinMaxMarginRule
    | ICompetitorRule
    | ISamePriceRule
  >;
  fetchingRulesList: boolean;
  ruleBlocks: IRuleBlock[];
  rulesItems: {
    fixPriceRule: IFixPriceRule | null;
    fixMarginRule: IFixMarginRule | null;
    samePriceRule: ISamePriceRule | null;
    maxPriceChangeRule: IMinPriceChangeRule | null;
    minPriceChangeRule: IMinPriceChangeRule | null;
    minMaxPriceRule: IMinMaxPriceRule | null;
    minMaxMarginHardRule: IMinMaxMarginRule | null;
    competitorRule: ICompetitorRule | null;
    minMaxMarginSoftRule: IMinMaxMarginRule | null;
  };
  rulesItemsInherited: {
    fixPriceRuleInherited: IFixPriceRule | null;
    fixMarginRuleInherited: IFixMarginRule | null;
    samePriceRuleInherited: ISamePriceRule | null;
    maxPriceChangeRuleInherited: IMinPriceChangeRule | null;
    minPriceChangeRuleInherited: IMinPriceChangeRule | null;
    minMaxPriceRuleInherited: IMinMaxPriceRule | null;
    minMaxMarginHardRuleInherited: IMinMaxMarginRule | null;
    competitorRuleInherited: ICompetitorRule | null;
    minMaxMarginSoftRuleInherited: IMinMaxMarginRule | null;
  };
  currentRule:
    | IFixPriceRule
    | IFixMarginRule
    | IMinPriceChangeRule
    | IMinMaxPriceRule
    | IMinMaxMarginRule
    | ICompetitorRule
    | ISamePriceRule
    | null;
  samePriceRuleFilter: string;
  samePriceTree: IFilterTree<IFilterTreeValue> | null;
  samePriceTreeFiltered: IFilterTree<IFilterTreeValue> | null;
  rulesPageDrawerOpened: boolean;
  competitorAggregations: ICompetitorAgregation[];
  fetchingRulesImport: boolean;
  fetchingRulesExport: boolean;
  rulesSorting: MRT_SortingState;
}

interface IRulesFiltersState {
  filters: IItemsFilters<IFilter<IFilterValue>> | null;
  treeFilters: IFilterTree<IFilterTreeValue> | null;
  treeFiltersFiltered: IFilterTree<IFilterTreeValue> | null;
  treeFiltersNode: IFilterTreeValue[];
  activeFilters: IGetFilteredRulesParams[];
  treeFilter: string;
  checkedTreeFilters: IFilterTreeValue[];
}

const initialState: IRulesState = {
  checkedRules: [],
  checkedRule: null,
  rules: null,
  totalRulesCount: 0,
  rulesRefetch: false,
  commentModalOpened: false,
  formsCommentModalOpened: false,
  rulesFilters: {
    filters: null,
    treeFilters: null,
    treeFiltersFiltered: null,
    treeFiltersNode: [],
    activeFilters: [],
    treeFilter: '',
    checkedTreeFilters: [],
  },
  stepper: 0,
  rulesList: null,
  fetchingRulesList: false,
  ruleBlocks: ruleBlocksInitialState,
  rulesPageDrawerOpened: false,
  rulesItems: {
    fixPriceRule: null,
    fixMarginRule: null,
    minPriceChangeRule: null,
    maxPriceChangeRule: null,
    minMaxPriceRule: null,
    minMaxMarginSoftRule: null,
    minMaxMarginHardRule: null,
    competitorRule: null,
    samePriceRule: null,
  },
  rulesItemsInherited: {
    fixPriceRuleInherited: null,
    fixMarginRuleInherited: null,
    minPriceChangeRuleInherited: null,
    maxPriceChangeRuleInherited: null,
    minMaxPriceRuleInherited: null,
    minMaxMarginSoftRuleInherited: null,
    minMaxMarginHardRuleInherited: null,
    competitorRuleInherited: null,
    samePriceRuleInherited: null,
  },
  currentRule: null,
  samePriceRuleFilter: '',
  samePriceTree: null,
  samePriceTreeFiltered: null,
  competitorAggregations: [],
  fetchingRulesImport: false,
  fetchingRulesExport: false,
  rulesSorting: [],
};

export const fetchRulesAction = createAxiosThunk('/getRules', apiPostRules);
export const fetchDeleteRulesAction = createAxiosThunk('/deleteRules', apiDeleteRules);
export const fetchDeleteRuleAction = createAxiosThunk('/deleteRule', apiDeleteRules);
export const fetchDeactivateRuleAction = createAxiosThunk(
  '/deactivateRule',
  apiPostDeactivateRules,
);
export const fetchDeactivateRulesAction = createAxiosThunk(
  '/deactivateRules',
  apiPostDeactivateRules,
);
export const fetchCommentRuleAction = createAxiosThunk('/commentRule', apiPostCommentRules);
export const fetchFiltersTreeAction = createAxiosThunk('/getFiltersTree', apiPostFiltersTree);
export const fetchFiltersTreeFilteredAction = createAxiosThunk(
  '/getFiltersTreeFiltered',
  apiPostFiltersTreeFiltered,
);
export const fetchFiltersTreeNodeAction = createAxiosThunk(
  '/getFiltersTreeNode',
  apiPostFiltersTreeNode,
);
export const fetchRulesListAction = createAxiosThunk('/getGetRulesList', apiPostRulesList);
export const fetchRuleEditAction = createAxiosThunk('/apiRuleEdit', apiPostRuleEdit);
export const fetchSamePriceTreeAction = createAxiosThunk('/getSamePriceTree', apiPostSamePriceTree);
export const fetchSamePriceTreeFilteredAction = createAxiosThunk(
  '/getSamePriceTreeFiltered',
  apiPostSamePriceTreeFiltered,
);
export const fetchCompetitorAggregationsAction = createAxiosThunk(
  '/getGetCompetitorAggregations',
  apiGetCompetitorAggregations,
);
export const fetchImportRulesAction = createAxiosThunk('/importFile', apiPostImportRules);
export const fetchExportRulesAction = createAxiosThunk('/exportFile', apiPostExportRules);

export const rulesSlice = createSlice({
  name: 'rules',
  initialState,
  reducers: {
    setCheckedRules: (state, action: PayloadAction<IRule[]>) => {
      state.checkedRules = action.payload;
    },
    setCheckedRule: (state, action: PayloadAction<IRule | null>) => {
      state.checkedRule = action.payload;
    },
    setRules: (state, action: PayloadAction<IRule[]>) => {
      state.rules = action.payload;
    },
    setRulesRefetch: (state, action: PayloadAction<boolean>) => {
      state.rulesRefetch = action.payload;
    },
    setCommentModalOpened: (state, action: PayloadAction<boolean>) => {
      state.commentModalOpened = action.payload;
    },
    setFormsCommentModalOpened: (state, action: PayloadAction<boolean>) => {
      state.formsCommentModalOpened = action.payload;
    },
    setActiveRulesFilters: (state, action: PayloadAction<IGetFilteredRulesParams[]>) => {
      state.rulesFilters.activeFilters = action.payload;
    },
    setTreeFilter: (state, action: PayloadAction<string>) => {
      state.rulesFilters.treeFilter = action.payload;
    },
    setCheckedTreeFilters: (state, action: PayloadAction<IFilterTreeValue[]>) => {
      state.rulesFilters.checkedTreeFilters = action.payload;
    },
    setStepper: (state, action: PayloadAction<number>) => {
      state.stepper = action.payload;
    },

    setRulesPageDrawerOpened: (state, action: PayloadAction<boolean>) => {
      state.rulesPageDrawerOpened = action.payload;
    },
    setRuleBlocks: (state, action: PayloadAction<IRuleBlock[]>) => {
      state.ruleBlocks = action.payload;
    },
    setCurrentRule: (
      state,
      action: PayloadAction<
        | IFixPriceRule
        | IFixMarginRule
        | IMinPriceChangeRule
        | IMinMaxPriceRule
        | IMinMaxMarginRule
        | ICompetitorRule
        | ISamePriceRule
        | null
      >,
    ) => {
      state.currentRule = action.payload;
    },
    setRulesSorting: (state, action: PayloadAction<MRT_SortingState>) => {
      state.rulesSorting = action.payload;
    },

    setFixPriceRule: (state, action: PayloadAction<IFixPriceRule | null>) => {
      state.rulesItems.fixPriceRule = action.payload;
    },
    setFixMarginRule: (state, action: PayloadAction<IFixMarginRule | null>) => {
      state.rulesItems.fixMarginRule = action.payload;
    },
    setMinPriceChangeRule: (state, action: PayloadAction<IMinPriceChangeRule | null>) => {
      state.rulesItems.minPriceChangeRule = action.payload;
    },
    setMaxPriceChangeRule: (state, action: PayloadAction<IMinPriceChangeRule | null>) => {
      state.rulesItems.maxPriceChangeRule = action.payload;
    },
    setMinMaxPriceRule: (state, action: PayloadAction<IMinMaxPriceRule | null>) => {
      state.rulesItems.minMaxPriceRule = action.payload;
    },
    setMinMaxMarginSoftRule: (state, action: PayloadAction<IMinMaxMarginRule | null>) => {
      state.rulesItems.minMaxMarginSoftRule = action.payload;
    },
    setMinMaxMarginHardRule: (state, action: PayloadAction<IMinMaxMarginRule | null>) => {
      state.rulesItems.minMaxMarginHardRule = action.payload;
    },
    setCompetitorRule: (state, action: PayloadAction<ICompetitorRule | null>) => {
      state.rulesItems.competitorRule = action.payload;
    },
    setSamePriceRule: (state, action: PayloadAction<ISamePriceRule | null>) => {
      state.rulesItems.samePriceRule = action.payload;
    },
    setSamePriceRuleFilter: (state, action: PayloadAction<string>) => {
      state.samePriceRuleFilter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchRulesAction.fulfilled, (state, action) => {
      state.rules = action.payload.items;
      state.totalRulesCount = action.payload.total;
    });
    builder.addCase(fetchDeleteRulesAction.fulfilled, () => {
      notify({ message: 'Правила удалены', type: 'success' });
    });
    builder.addCase(fetchDeleteRuleAction.fulfilled, () => {
      notify({ message: 'Правило удалено', type: 'success' });
    });
    builder.addCase(fetchCommentRuleAction.fulfilled, () => {
      notify({ message: 'Сохранено', type: 'success' });
    });
    builder.addCase(fetchFiltersTreeAction.fulfilled, (state, action) => {
      state.rulesFilters.treeFilters = action.payload;
    });
    builder.addCase(fetchFiltersTreeFilteredAction.fulfilled, (state, action) => {
      state.rulesFilters.treeFiltersFiltered = action.payload;
    });
    builder.addCase(fetchFiltersTreeNodeAction.fulfilled, (state, action) => {
      state.rulesFilters.treeFiltersNode = action.payload.items;
    });
    builder.addCase(fetchRuleEditAction.fulfilled, (state) => {
      notify({ message: `Правило "${state.currentRule?.name}" сохранено`, type: 'success' });
    });

    builder.addCase(fetchImportRulesAction.pending, (state) => {
      state.fetchingRulesImport = true;
    });
    builder.addCase(fetchImportRulesAction.fulfilled, (state, action) => {
      state.fetchingRulesImport = false;
      notify({
        title: 'Ваш файл успешно загружен',
        message: `${action.payload.message}`,
        type: 'success',
      });
    });
    builder.addCase(fetchImportRulesAction.rejected, (state) => {
      state.fetchingRulesImport = false;
    });

    builder.addCase(fetchExportRulesAction.pending, (state) => {
      state.fetchingRulesExport = true;
    });
    builder.addCase(fetchExportRulesAction.fulfilled, (state) => {
      state.fetchingRulesExport = false;
    });
    builder.addCase(fetchExportRulesAction.rejected, (state) => {
      state.fetchingRulesExport = false;
    });

    builder.addCase(fetchRulesListAction.pending, (state) => {
      state.fetchingRulesList = true;
    });
    builder.addCase(fetchRulesListAction.fulfilled, (state, action) => {
      state.rulesList = action.payload;
      state.ruleBlocks = getRuleBlocksState(ruleBlocksInitialState, action.payload);
      state.fetchingRulesList = false;

      state.rulesItems.fixPriceRule = action.payload?.find(
        (item: IRuleListItem) => item.type === ERuleType.fixPrice,
      ) as IFixPriceRule;
      state.rulesItemsInherited.fixPriceRuleInherited = action.payload?.find(
        (item: IRuleListItem) => item.type === ERuleType.fixPrice && item.inherited,
      ) as IFixPriceRule;

      state.rulesItems.fixMarginRule = action.payload?.find(
        (item: IRuleListItem) => item.type === ERuleType.fixMargin,
      ) as IFixMarginRule;
      state.rulesItemsInherited.fixMarginRuleInherited = action.payload?.find(
        (item: IRuleListItem) => item.type === ERuleType.fixMargin && item.inherited,
      ) as IFixMarginRule;

      state.rulesItems.minPriceChangeRule = action.payload?.find(
        (item: IRuleListItem) => item.type === ERuleType.minPriceChange,
      ) as IMinPriceChangeRule;
      state.rulesItemsInherited.minPriceChangeRuleInherited = action.payload?.find(
        (item: IRuleListItem) => item.type === ERuleType.minPriceChange && item.inherited,
      ) as IMinPriceChangeRule;

      state.rulesItems.maxPriceChangeRule = action.payload?.find(
        (item: IRuleListItem) => item.type === ERuleType.maxPriceChange,
      ) as IMinPriceChangeRule;
      state.rulesItemsInherited.maxPriceChangeRuleInherited = action.payload?.find(
        (item: IRuleListItem) => item.type === ERuleType.maxPriceChange && item.inherited,
      ) as IMinPriceChangeRule;

      state.rulesItems.minMaxPriceRule = action.payload?.find(
        (item: IRuleListItem) => item.type === ERuleType.minMaxPrice,
      ) as IMinMaxPriceRule;
      state.rulesItemsInherited.minMaxPriceRuleInherited = action.payload?.find(
        (item: IRuleListItem) => item.type === ERuleType.minMaxPrice && item.inherited,
      ) as IMinMaxPriceRule;

      state.rulesItems.minMaxMarginSoftRule = action.payload?.find(
        (item: IRuleListItem) => item.type === ERuleType.minMaxMarginSoft,
      ) as IMinMaxMarginRule;
      state.rulesItemsInherited.minMaxMarginSoftRuleInherited = action.payload?.find(
        (item: IRuleListItem) => item.type === ERuleType.minMaxMarginSoft && item.inherited,
      ) as IMinMaxMarginRule;

      state.rulesItems.minMaxMarginHardRule = action.payload?.find(
        (item: IRuleListItem) => item.type === ERuleType.minMaxMarginHard,
      ) as IMinMaxMarginRule;
      state.rulesItemsInherited.minMaxMarginHardRuleInherited = action.payload?.find(
        (item: IRuleListItem) => item.type === ERuleType.minMaxMarginHard && item.inherited,
      ) as IMinMaxMarginRule;

      state.rulesItems.competitorRule = action.payload?.find(
        (item: IRuleListItem) => item.type === ERuleType.competitor,
      ) as ICompetitorRule;
      state.rulesItemsInherited.competitorRuleInherited = action.payload?.find(
        (item: IRuleListItem) => item.type === ERuleType.competitor && item.inherited,
      ) as ICompetitorRule;

      state.rulesItems.samePriceRule = action.payload?.find(
        (item: IRuleListItem) => item.type === ERuleType.samePrice,
      ) as ISamePriceRule;
      state.rulesItemsInherited.samePriceRuleInherited = action.payload?.find(
        (item: IRuleListItem) => item.type === ERuleType.samePrice && item.inherited,
      ) as ISamePriceRule;
    });

    builder.addCase(fetchSamePriceTreeAction.fulfilled, (state, action) => {
      state.samePriceTree = action.payload;
    });
    builder.addCase(fetchSamePriceTreeFilteredAction.fulfilled, (state, action) => {
      state.samePriceTreeFiltered = action.payload;
    });

    builder.addCase(fetchCompetitorAggregationsAction.fulfilled, (state, action) => {
      state.competitorAggregations = action.payload;
    });
  },
});

type TSelectorState = { rules: IRulesState };

export const selectRules = (state: TSelectorState) => state.rules.rules;
export const selectTotalRulesCount = (state: TSelectorState) => state.rules.totalRulesCount;
export const selectRulesRefetch = (state: TSelectorState) => state.rules.rulesRefetch;
export const selectRulesList = (state: TSelectorState) => state.rules.rulesList;
export const selectFetchingRulesList = (state: TSelectorState) => state.rules.fetchingRulesList;
export const selectFetchingRulesImport = (state: TSelectorState) => state.rules.fetchingRulesImport;
export const selectFetchingRulesExport = (state: TSelectorState) => state.rules.fetchingRulesExport;
export const selectCheckedRules = (state: TSelectorState) => state.rules.checkedRules;
export const selectCommentModalOpened = (state: TSelectorState) => state.rules.commentModalOpened;
export const selectCheckedRule = (state: TSelectorState) => state.rules.checkedRule;
export const selectFiltersTree = (state: TSelectorState) => state.rules.rulesFilters.treeFilters;
export const selectFiltersTreeFiltered = (state: TSelectorState) =>
  state.rules.rulesFilters.treeFiltersFiltered;
export const selectFiltersTreeNode = (state: TSelectorState) =>
  state.rules.rulesFilters.treeFiltersNode;
export const selectActiveFilters = (state: TSelectorState) =>
  state.rules.rulesFilters.activeFilters;
export const selectTreeFilter = (state: TSelectorState) => state.rules.rulesFilters.treeFilter;
export const selectCheckedTreeFilter = (state: TSelectorState) =>
  state.rules.rulesFilters.checkedTreeFilters;

export const selectStepper = (state: TSelectorState) => state.rules.stepper;
export const selectRuleBlocks = (state: TSelectorState) => state.rules.ruleBlocks;
export const selectRulesPageDrawerOpened = (state: TSelectorState) =>
  state.rules.rulesPageDrawerOpened;
export const selectFormsCommentModalOpened = (state: TSelectorState) =>
  state.rules.formsCommentModalOpened;
export const selectCurrentRule = (state: TSelectorState) => state.rules.currentRule;
export const selectRulesSorting = (state: TSelectorState) => state.rules.rulesSorting;

export const selectFixPriceRule = (state: TSelectorState) => state.rules.rulesItems.fixPriceRule;
export const selectFixPriceRuleInherited = (state: TSelectorState) =>
  state.rules.rulesItemsInherited.fixPriceRuleInherited;

export const selectFixMarginRule = (state: TSelectorState) => state.rules.rulesItems.fixMarginRule;
export const selectFixMarginRuleInherited = (state: TSelectorState) =>
  state.rules.rulesItemsInherited.fixMarginRuleInherited;

export const selectMinPriceChangeRule = (state: TSelectorState) =>
  state.rules.rulesItems.minPriceChangeRule;
export const selectMinPriceChangeRuleInherited = (state: TSelectorState) =>
  state.rules.rulesItemsInherited.minPriceChangeRuleInherited;

export const selectMaxPriceChangeRule = (state: TSelectorState) =>
  state.rules.rulesItems.maxPriceChangeRule;
export const selectMaxPriceChangeRuleInherited = (state: TSelectorState) =>
  state.rules.rulesItemsInherited.maxPriceChangeRuleInherited;

export const selectMinMaxPriceRule = (state: TSelectorState) =>
  state.rules.rulesItems.minMaxPriceRule;
export const selectMinMaxPriceRuleInherited = (state: TSelectorState) =>
  state.rules.rulesItemsInherited.minMaxPriceRuleInherited;

export const selectMinMaxMarginSoftRule = (state: TSelectorState) =>
  state.rules.rulesItems.minMaxMarginSoftRule;
export const selectMinMaxMarginSoftRuleInherited = (state: TSelectorState) =>
  state.rules.rulesItemsInherited.minMaxMarginSoftRuleInherited;

export const selectMinMaxMarginHardRule = (state: TSelectorState) =>
  state.rules.rulesItems.minMaxMarginHardRule;
export const selectMinMaxMarginHardRuleInherited = (state: TSelectorState) =>
  state.rules.rulesItemsInherited.minMaxMarginHardRuleInherited;

export const selectCompetitorRule = (state: TSelectorState) =>
  state.rules.rulesItems.competitorRule;
export const selectCompetitorRuleInherited = (state: TSelectorState) =>
  state.rules.rulesItemsInherited.competitorRuleInherited;

export const selectSamePriceRule = (state: TSelectorState) => state.rules.rulesItems.samePriceRule;
export const selectSamePriceRuleInherited = (state: TSelectorState) =>
  state.rules.rulesItemsInherited.samePriceRuleInherited;

export const selectSamePriceRuleFilter = (state: TSelectorState) => state.rules.samePriceRuleFilter;
export const selectSamePriceTree = (state: TSelectorState) => state.rules.samePriceTree;
export const selectSamePriceTreeFiltered = (state: TSelectorState) =>
  state.rules.samePriceTreeFiltered;

export const selectCompetitorAggregations = (state: TSelectorState) =>
  state.rules.competitorAggregations;

export const {
  setCheckedRules,
  setRules,
  setRulesRefetch,
  setCommentModalOpened,
  setFormsCommentModalOpened,
  setCurrentRule,
  setCheckedRule,
  setActiveRulesFilters,
  setTreeFilter,
  setCheckedTreeFilters,
  setStepper,
  setRulesSorting,
  setRulesPageDrawerOpened,
  setRuleBlocks,
  setFixPriceRule,
  setFixMarginRule,
  setMinPriceChangeRule,
  setMaxPriceChangeRule,
  setMinMaxPriceRule,
  setMinMaxMarginSoftRule,
  setMinMaxMarginHardRule,
  setCompetitorRule,
  setSamePriceRule,
  setSamePriceRuleFilter,
} = rulesSlice.actions;

export default rulesSlice.reducer;
