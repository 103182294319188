import { FC } from 'react';

import { paths, titles } from '@/constants/paths';

import PageHeader from '@/ui/organisms/PageHeader/PageHeader';
import PageLayoutBody from '@/ui/templates/PageLayout/organisms/PageLayoutBody/PageLayoutBody';
import PageLayoutHeader from '@/ui/templates/PageLayout/organisms/PageLayoutHeader/PageLayoutHeader';
import PageLayout from '@/ui/templates/PageLayout/PageLayout';

import CreatePriceRulesSteppers from './organisms/PriceRulesSteppers/PriceRulesSteppers';

const items = [
  { title: titles.TITLE_PRICE_RULES, path: paths.PATH_PRICE_RULES },
  { title: titles.TITLE_CREATE_PRICE_RULES, path: paths.PATH_CREATE_PRICE_RULES },
];

const CreatePriceRulesPage: FC = () => {
  return (
    <PageLayout>
      <PageLayoutHeader>
        <PageHeader title={titles.TITLE_CREATE_PRICE_RULES} items={items} />
      </PageLayoutHeader>

      <PageLayoutBody>
        <CreatePriceRulesSteppers />
      </PageLayoutBody>
    </PageLayout>
  );
};

export default CreatePriceRulesPage;
