import {
  IFilter,
  IFilterTree,
  IFilterTreeValue,
  IFilterValue,
  IItemsFilters,
} from '@/types/filters/filters';
import {
  IGetFilteredTreeParams,
  IGetFilterTreeParams,
  IGetProductsFilterValues,
  IProductsFilterValue,
} from '@/types/products/products';

import { URLS } from '@/api/urls';

import api from '../instance';

export const apiGetProductsFilters = () =>
  api.get<IItemsFilters<IFilter<IFilterValue>>>(URLS.products.getProductsFilters);

export const apiGetProductsFilterValues = ({ field, query }: IGetProductsFilterValues) =>
  api.get<IProductsFilterValue[]>(URLS.products.getProductsFilterValues, {
    params: { field, query },
  });

export const apiPostTreeProduct = (filter: IGetFilterTreeParams[]) =>
  api.post<IFilterTree<IFilterTreeValue>>(URLS.filters.getFiltersTree, { filter });

export const apiPostTreeProductFiltered = ({ query, filter }: IGetFilteredTreeParams) =>
  api.post<IFilterTree<IFilterTreeValue>>(URLS.filters.getFiltersTree, { query, filter });
