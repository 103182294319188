import {
  EPlanChangeItemValues,
  EPlanStatus,
  EPlanValidationStatus,
  IPlanEditItem,
} from '@/types/plans/plans';

import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Group, Menu } from '@mantine/core';
import { IconCalculator, IconMessage } from '@tabler/icons-react';
import { MRT_TableInstance } from 'mantine-react-table';

import { AppDispatch } from '@/store';
import {
  fetchPostPlanDetailProcessPairsAction,
  selectPlanDetailCheckedItem,
  selectPlanDetailRefetch,
  selectPlanDetails,
  setDetailCheckedPlan,
  setEditPlanCommentModalOpened,
  setPlanDetailRefetch,
} from '@/store/slices/plans/plans';

interface IProps {
  planData: IPlanEditItem;
  table: MRT_TableInstance<IPlanEditItem>;
}

const EditPlanPageTableRowActions: FC<IProps> = ({ planData, table }) => {
  const dispatch: AppDispatch = useDispatch();

  const { id } = useParams();

  const planDetailsRefetch = useSelector(selectPlanDetailRefetch);
  const planDetails = useSelector(selectPlanDetails);
  const detailCheckedPlan = useSelector(selectPlanDetailCheckedItem);

  const allowProcessPlanItem =
    planDetails?.status === EPlanStatus.ready &&
    planDetails.validationStatus === EPlanValidationStatus.processed &&
    detailCheckedPlan?.status === EPlanChangeItemValues.waiting;

  const allowProcessPlanItemWithError =
    planDetails?.status === EPlanStatus.error &&
    planDetails.validationStatus === EPlanValidationStatus.error &&
    detailCheckedPlan?.status === EPlanChangeItemValues.error;

  const processItem = async () => {
    if (id && detailCheckedPlan?.id) {
      await dispatch(
        fetchPostPlanDetailProcessPairsAction({
          id: id,
          values: [detailCheckedPlan.id],
        }),
      );
      dispatch(setPlanDetailRefetch(!planDetailsRefetch));
    }
  };

  const commentItem = () => {
    dispatch(setEditPlanCommentModalOpened(true));
  };

  useEffect(() => {
    dispatch(setDetailCheckedPlan(planData));
    table.resetRowSelection();
  }, [planData]);

  return (
    <>
      <Menu.Item
        onClick={processItem}
        disabled={!allowProcessPlanItem && !allowProcessPlanItemWithError}
      >
        <Group>
          <IconCalculator />
          Рассчитать
        </Group>
      </Menu.Item>
      <Menu.Item onClick={commentItem}>
        <Group>
          <IconMessage />
          Комментировать
        </Group>
      </Menu.Item>
    </>
  );
};

export default EditPlanPageTableRowActions;
