export const notifyMessages = {
  CALCULATE: 'Происходит расчет. Обновите страницу, чтобы узнать актуальный статус расчета.',
  NOT_PROCESSED_PLAN: 'Изменить статус анализа можно только со статусом расчета = Рассчитан',
  NOT_PROCESSED_PLAN_DETAIL:
    'Изменить статус элементов можно только у анализа со статусом согласования "Рассчитан"',
  NO_STATUS_PLAN_DETAIL: 'У элементов отсутствует статус согласования',
  READY_PLAN: 'Нельзя изменить статус анализа со статусом согласования "Готов"',
  ERROR_READY_PLAN:
    'Перевести в статус "Готов" можно только анализы со статусами согласования "Рассчитан" и "Отклонен"',
  ERROR_CANCEL_PLAN:
    'Перевести в статус "Отклонен" можно только анализы со статусом согласования "Рассчитан"',
  ERROR_PROCESS_PLAN: 'Изменить статус "Отклонен" можно только на статус "Рассчитан"',
  LOCK_PRICE_ERROR: 'Изменить Lock цену можно только элементу со статусом "Ожидает согласования"',
  LOCK_PRICE_STATUS_ERROR:
    'Изменить Lock цену можно только анализу со статусом "Рассчитан" или "Новый"',
};
