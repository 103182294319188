import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Button, createStyles, Flex, Grid, Text, Title } from '@mantine/core';
import { IconListDetails } from '@tabler/icons-react';

import { menuItems } from './menuItems';

const useStyles = createStyles((theme) => ({
  wrapper: {
    padding: '24px',
    border: `1px solid ${theme.colors.gray[2]}`,
    borderRadius: '8px',
    minHeight: '184px',
  },
  link: {
    color: 'black',
    textDecoration: 'none',

    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

interface MenuItem {
  title: string;
  description: string;
  path: string;
  fileName: string;
  instructionName: string;
}

const MainPageMenuBlock: FC = () => {
  const { classes } = useStyles();

  const downloadFile = (fileName: string, instructionName: string) => {
    const pdfUrl = `../files/${fileName}`;
    const link = document.createElement('a');
    link.href = pdfUrl;
    link.download = `${instructionName}`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Grid gutter={24} w="100%">
      {menuItems.map((item: MenuItem) => (
        <Grid.Col span={6} key={item.title}>
          <Flex className={classes.wrapper} direction="column">
            <Flex direction="column">
              <Flex direction="row" justify="space-between">
                <IconListDetails />
                <Button
                  variant="default"
                  sx={{ color: 'gray' }}
                  onClick={() => downloadFile(item.fileName, item.instructionName)}
                >
                  Инструкция
                </Button>
              </Flex>

              <Link to={item.path} key={item.title} className={classes.link}>
                <Title mb={8} order={5}>
                  {item.title}
                </Title>

                <Text fz="sm" c="dimmed">
                  {item.description}
                </Text>
              </Link>
            </Flex>
          </Flex>
        </Grid.Col>
      ))}
    </Grid>
  );
};

export default MainPageMenuBlock;
