import { IItems } from '@/types/common/common';
import { IFilterTree, IFilterTreeValue } from '@/types/filters/filters';
import { ICommentRulesParams, ICompetitorAgregation, IGetRulesParams } from '@/types/rules/rules';

import api from '../instance';
import { URLS } from '../urls';

export const apiPostRules = ({ sort, order, offset, limit, filter }: IGetRulesParams) =>
  api.post(URLS.rules.getRules, { filter }, { params: { sort, order, offset, limit } });

export const apiDeleteRules = (id: string[]) =>
  api.delete(URLS.rules.getRules, {
    data: {
      id,
    },
  });

export const apiPostDeactivateRules = (id: string[]) =>
  api.post<IItems<IFilterTree<IFilterTreeValue>>>(URLS.rules.deactivateRules, {
    id,
  });

export const apiPostCommentRules = ({ id, comment, isMultiple }: ICommentRulesParams) =>
  api.post<IItems<IFilterTree<IFilterTreeValue>>>(URLS.rules.commentRules, {
    id,
    comment,
    isMultiple,
  });

export const apiPostSamePriceTree = () =>
  api.post<IFilterTree<IFilterTreeValue>>(URLS.filters.getFiltersTree, {});

export const apiPostSamePriceTreeFiltered = (query: string) =>
  api.post<IFilterTree<IFilterTreeValue>>(URLS.filters.getFiltersTree, { query });

export const apiGetCompetitorAggregations = () =>
  api.get<ICompetitorAgregation[]>(URLS.rules.competitorAggregations);

const controller = new AbortController();

export const apiPostImportRules = (data: FormData) =>
  api.post(URLS.rules.import, data, {
    signal: controller.signal,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

export const apiPostExportRules = ({ sort, order, filter }: IGetRulesParams) =>
  api.post(URLS.rules.export, { filter }, { params: { sort, order } });
