import { EFilterKey } from '@/types/filters/filters';

import { FC, SyntheticEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Divider, Flex, Input, Popover, Text } from '@mantine/core';
import { IconCurrencyRubel, IconX } from '@tabler/icons-react';

import { getIcon } from '@/ui/organisms/Filters/organisms/FilterCheckbox/FilterCheckbox';

import { AppDispatch } from '@/store';
import { clearFilter, selectActiveFilters, setActiveFilters } from '@/store/slices/filters/filters';

interface IProps {
  filterKey: EFilterKey;
  name: string;
  code: string;
  type: string;
}

const FilterRange: FC<IProps> = ({ code, type, name, filterKey }) => {
  const dispatch: AppDispatch = useDispatch();

  const activeFilters = useSelector(selectActiveFilters);

  const [opened, setOpened] = useState<boolean>(false);

  const currentActiveFilter = activeFilters[filterKey].find((item) => item.code === code);
  const currentActiveFilterMinValue = currentActiveFilter?.value.from;
  const currentActiveFilterMaxValue = currentActiveFilter?.value.to;

  const [minValue, setMinValue] = useState<string>(currentActiveFilterMinValue?.toString() ?? '');
  const [maxValue, setMaxValue] = useState<string>(currentActiveFilterMaxValue?.toString() ?? '');

  const isActiveFilter = !!currentActiveFilter && !!activeFilters[filterKey].length;

  const resetFilter = (event: SyntheticEvent) => {
    if (!opened) event.stopPropagation();

    const filterItem = {
      filterKey: filterKey,
      item: {
        type: type,
        code: code,
        value: {},
      },
    };
    setMinValue('');
    setMaxValue('');
    dispatch(clearFilter(filterItem));
  };

  const setFilter = () => {
    const valueFrom = Number(minValue);
    const valueTo = Number(maxValue);
    const filterValues = {
      ...(!!valueFrom && { from: valueFrom }),
      ...(!!valueTo && { to: valueTo }),
    };
    const filterItem = {
      filterKey: filterKey,
      item: {
        code: code,
        type: type,
        value: filterValues,
      },
    };
    dispatch(setActiveFilters(filterItem));
    setOpened(false);
  };

  useEffect(() => {
    if (!currentActiveFilter) {
      setMinValue('');
      setMaxValue('');
    }
  }, [currentActiveFilter]);

  return (
    <Popover
      opened={opened}
      onChange={setOpened}
      width={300}
      trapFocus
      position="bottom"
      shadow="md"
    >
      <Popover.Target>
        <Button
          variant={isActiveFilter ? 'filled' : 'default'}
          sx={{ color: isActiveFilter ? 'white' : 'black' }}
          color="dark"
          onClick={() => setOpened((state) => !state)}
        >
          <Flex gap={8} align="center">
            <Text>
              {name}
              {!!currentActiveFilterMinValue && ` от ${currentActiveFilterMinValue}`}
              {!!currentActiveFilterMaxValue && ` до ${currentActiveFilterMaxValue}`}
            </Text>
            {isActiveFilter ? (
              <Flex align="center" onClick={resetFilter}>
                <IconX size="1.1rem" />
              </Flex>
            ) : (
              getIcon(opened)
            )}
          </Flex>
        </Button>
      </Popover.Target>
      <Popover.Dropdown>
        <Flex direction="row" gap="8px">
          <Input
            type="number"
            icon={<Text>от</Text>}
            rightSection={<IconCurrencyRubel size="1rem" color="#A3A3A3" />}
            value={minValue}
            onChange={(event) => setMinValue(event.target.value)}
            max={maxValue}
            aria-label="inputMin"
          />
          <Input
            type="number"
            icon={<Text>до</Text>}
            rightSection={<IconCurrencyRubel size="1rem" color="#A3A3A3" />}
            value={maxValue}
            onChange={(event) => setMaxValue(event.target.value)}
            min={minValue}
            aria-label="inputMax"
          />
        </Flex>
        <Box>
          <Divider
            sx={(theme) => ({ margin: '16px -16px', borderTopColor: theme.colors.gray[2] })}
          />
          <Flex direction="row" gap="8px">
            <Button fullWidth color="gray.2" onClick={resetFilter}>
              Очистить
            </Button>
            <Button
              fullWidth
              color="dark.9"
              onClick={setFilter}
              disabled={!minValue.length && !maxValue.length}
            >
              Применить
            </Button>
          </Flex>
        </Box>
      </Popover.Dropdown>
    </Popover>
  );
};

export default FilterRange;
