import { FC } from 'react';
import { MantineProvider, Text } from '@mantine/core';
import { ContextModalProps, ModalsProvider } from '@mantine/modals';
import { Notifications } from '@mantine/notifications';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { CustomFonts } from './assets/styles/CustomFonts';
import { extraButton } from './assets/styles/extraButton';
import { extraColors } from './assets/styles/extraColors';
import { extraFonts } from './assets/styles/extraFonts';
import { useAuthToken } from './hooks/useAuthToken';
import Layout from './ui/templates/Layout/Layout';

const DemonstrationModal = ({ innerProps }: ContextModalProps<{ modalBody: string }>) => (
  <Text size="sm">{innerProps.modalBody}</Text>
);

const App: FC = () => {
  useAuthToken();
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        keepPreviousData: true,
        refetchOnWindowFocus: false,
        refetchOnMount: true,
      },
    },
  });

  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <MantineProvider
        withNormalizeCSS
        withGlobalStyles
        theme={{
          primaryColor: 'gray',
          defaultRadius: 8,
          colors: { ...extraColors },
          ...extraFonts,
          ...extraButton,
        }}
      >
        <Notifications position="top-right" limit={5} />
        <ModalsProvider modals={{ demonstration: DemonstrationModal }}>
          <CustomFonts />
          <Layout />
        </ModalsProvider>
      </MantineProvider>
    </QueryClientProvider>
  );
};

export default App;
