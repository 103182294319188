import {
  IFilter,
  IFilterTree,
  IFilterTreeValue,
  IFilterValue,
  IItemsFilters,
} from '@/types/filters/filters';
import { IGetFilterTreeParams } from '@/types/products/products';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  apiGetProductsFilters,
  apiGetProductsFilterValues,
  apiPostTreeProduct,
  apiPostTreeProductFiltered,
} from '@/api/products/products';

import { createAxiosThunk } from '@/utils/asyncRequest';

interface IProductsFiltersState {
  productsFilters: IItemsFilters<IFilter<IFilterValue>> | null;
  productsFilterCode: string | null;
  productsFilterFilter: string;
  productsFilterValues: IFilterValue[] | null;
  activeProductsFilters: IGetFilterTreeParams[];

  treeProductFilter: string;
  treeProduct: IFilterTree<IFilterTreeValue> | null;
  treeProductFiltered: IFilterTree<IFilterTreeValue> | null;
}

const initialState: IProductsFiltersState = {
  productsFilters: null,
  productsFilterCode: null,
  productsFilterFilter: '',
  productsFilterValues: null,
  activeProductsFilters: [],

  treeProductFilter: '',
  treeProduct: null,
  treeProductFiltered: null,
};

export const fetchProductsFiltersAction = createAxiosThunk(
  '/getProductsFilters',
  apiGetProductsFilters,
);
export const fetchProductsFilterValues = createAxiosThunk(
  '/getProductsFilterValues',
  apiGetProductsFilterValues,
);
export const fetchTreeProductAction = createAxiosThunk('/getFiltersTree', apiPostTreeProduct);
export const fetchTreeProductFilteredAction = createAxiosThunk(
  '/getFiltersTreeFiltered',
  apiPostTreeProductFiltered,
);

export const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    setActiveProductsFilters: (state, action: PayloadAction<IGetFilterTreeParams[]>) => {
      state.activeProductsFilters = action.payload;
    },
    setProductsFilterValues: (state, action: PayloadAction<IFilterValue[] | null>) => {
      state.productsFilterValues = action.payload;
    },
    setProductsFilterFilter: (state, action: PayloadAction<string>) => {
      state.productsFilterFilter = action.payload;
    },
    setProductsFilterCode: (state, action: PayloadAction<string | null>) => {
      state.productsFilterCode = action.payload;
    },
    setTreeProductFilter: (state, action: PayloadAction<string>) => {
      state.treeProductFilter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProductsFiltersAction.fulfilled, (state, action) => {
      state.productsFilters = action.payload;
    });

    builder.addCase(fetchProductsFilterValues.fulfilled, (state, action) => {
      state.productsFilterValues = action.payload;
    });

    builder.addCase(fetchTreeProductAction.fulfilled, (state, action) => {
      state.treeProduct = action.payload;
    });

    builder.addCase(fetchTreeProductFilteredAction.fulfilled, (state, action) => {
      state.treeProductFiltered = action.payload;
    });
  },
});

type TSelectorState = { products: IProductsFiltersState };

export const selectProductsFilters = (state: TSelectorState) => state.products.productsFilters;
export const selectProductsFilterFilter = (state: TSelectorState) =>
  state.products.productsFilterFilter;
export const selectProductsFilterCode = (state: TSelectorState) =>
  state.products.productsFilterCode;
export const selectProductsFilterValues = (state: TSelectorState) =>
  state.products.productsFilterValues;
export const selectActiveProductsFilters = (state: TSelectorState) =>
  state.products.activeProductsFilters;

export const selectTreeProductFilter = (state: TSelectorState) => state.products.treeProductFilter;
export const selectTreeProduct = (state: TSelectorState) => state.products.treeProduct;
export const selectTreeProductFiltered = (state: TSelectorState) =>
  state.products.treeProductFiltered;

export const {
  setActiveProductsFilters,
  setProductsFilterFilter,
  setProductsFilterCode,
  setProductsFilterValues,
  setTreeProductFilter,
} = productsSlice.actions;

export default productsSlice.reducer;
