import { FC } from 'react';
import { useSelector } from 'react-redux';
import { createStyles, Flex, Spoiler, Text } from '@mantine/core';
import { IconChevronDown, IconChevronUp } from '@tabler/icons-react';

import { selectAdditionalNodes, selectNodesParams } from '@/store/slices/nodes/nodes';

const useStyles = createStyles((theme) => ({
  icon: {
    color: theme.colors.mariner[6],
  },
  badge: {
    padding: '1px 8px',
    height: '24px',
  },
}));

const PriceRulesSteppersHeader: FC = () => {
  const { classes } = useStyles();

  const additionalNodes = useSelector(selectAdditionalNodes);
  const nodesParams = useSelector(selectNodesParams);

  const getNodeValue = (code: string) => {
    return nodesParams.find((item) => item.code === code)?.name ?? 'Все товары';
  };

  return (
    <Spoiler
      styles={{
        control: {
          ':hover': { textDecoration: 'none' },
          paddingBottom: '16px',
        },
      }}
      maxHeight={0}
      showLabel={
        <Flex gap={4} align="center">
          <Text color="mariner.6" size={14}>
            Показать информацию об узлах
          </Text>
          <IconChevronDown className={classes.icon} />
        </Flex>
      }
      hideLabel={
        <Flex gap={4} align="center">
          <Text color="mariner.6" size={14}>
            Скрыть
          </Text>
          <IconChevronUp className={classes.icon} />
        </Flex>
      }
    >
      <Flex direction="row" justify="space-between" mb={32}>
        <Flex direction="column" gap={4} sx={{ maxWidth: '300px' }} mr={8}>
          <Text color="gray">Товарный узел</Text>
          <Text>{getNodeValue('product')}</Text>
        </Flex>
        <Flex direction="column" gap={4} mr={8}>
          <Text color="gray">Регион</Text>
          <Text>{getNodeValue('region')}</Text>
        </Flex>

        {additionalNodes &&
          additionalNodes.map((node) => (
            <Flex direction="column" gap={4} key={node.code} mr={8}>
              <Text color="gray">{node.name}</Text>
              <Text>{getNodeValue(node.code)}</Text>
            </Flex>
          ))}
      </Flex>
    </Spoiler>
  );
};

export default PriceRulesSteppersHeader;
