export const URLS = {
  auth: {
    postAuth: '/users/auth',
    postAuthByRefresh: '/users/auth-by-refresh',
  },
  filters: {
    getFiltersTree: '/core/products',
  },
  plans: {
    getPlansFilters: '/core/plans/filter',
    getPlans: '/core/plans',
    processPlans: '/core/plans/process',
    clonePlan: (planId: string): string => `/core/plans/clone/${planId}`,
    commentPlan: 'core/plans/comment',
    changePlanActive: (planId: string) => `/core/plans/change-active/${planId}`,
    changePlansStatus: '/core/plans/change-status',
    createPlan: '/core/plans/create',
    editPlan: (planId: string) => `/core/plans/edit/${planId}`,
    getPlanPairs: (planId: string) => `/core/plans/get-pairs/${planId}`,
    addPlanPairs: (planId: string) => `core/plans/add-pairs/${planId}`,
    deletePlanPairs: (planId: string) => `/core/plans/delete-pairs/${planId}`,
    getPlanDetail: (planId: string) => `/core/plans/detail/${planId}`,
    undraftPlan: (planId: string) => `/core/plans/undraft/${planId}`,
    getPlanDetailFilters: '/core/plans/pairs-filter',
    getPlanDetailFilterSearch: '/core/plans/pairs-filter/search',
    getPlanDetailData: (planId: string) => `/core/plans/pairs/${planId}`,
    planDetailComment: '/core/plans/pairs/comment',
    planDetailProcessInfo: (id: string) => `core/plans/pairs/process-info/${id}`,
    planDetailPriceInfo: (id: string) => `core/plans/pairs/price-info/${id}`,
    planDetailLockPrice: (id: string) => `core/plans/pairs/set-price/${id}`,
    planDetailStatusChange: (id: string) => `core/plans/pairs/status-change/${id}`,
    planDetailProcessPairs: (id: string) => `core/plans/pairs/process/${id}`,
    export: '/core/plans/export',
    planDetailExport: (id: string) => `/core/plans/pairs/export/${id}`,
  },
  user: {
    getUserInfo: '/users/current',
  },
  rules: {
    getRulesFilters: '/core/rules/filter',
    getRules: '/core/rules',
    deactivateRules: '/core/rules/deactivate',
    commentRules: '/core/rules/comment',
    rulesList: '/core/rules/list',
    ruleEdit: '/core/rules/edit',
    competitorAggregations: '/core/rules/competitor/aggregations',
    import: '/core/rules/import',
    export: '/core/rules/export',
  },
  nodes: {
    getAdditionalNodes: '/core/rules/additional-nodes',
    getAdditionalNodeValues: '/core/rules/node/values',
  },
  products: {
    getProductsFilters: '/core/products/filters',
    getProductsFilterValues: '/core/products/filters/search',
    getProductsTree: '/core/products',
  },
  monitoring: {
    filters: '/core/monitoring/filters',
    filtersSearch: '/core/monitoring/filters/search',
    list: '/core/monitoring',
    removeAnomaly: '/core/monitoring/unset-anomaly',
  },
  prices: {
    info: '/core/prices/info',
    filters: '/core/prices/filters',
    filtersSearch: '/core/prices/filters/search',
    pricesList: '/core/prices',
  },
  notifications: {
    filters: '/core/notifications/filters',
    notifications: '/core/notifications',
  },
};
