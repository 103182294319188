import { IItems } from '@/types/common/common';
import { IPlanItem } from '@/types/plans/plans';

import { FC, type UIEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Flex, Loader } from '@mantine/core';
import { useInfiniteQuery } from '@tanstack/react-query';
import {
  MRT_SortingState,
  MRT_TableContainer,
  MRT_Virtualizer,
  useMantineReactTable,
} from 'mantine-react-table';
import { MRT_Localization_RU } from 'mantine-react-table/locales/ru';

import { getPlansSort } from '@/utils/plans';
import { getOrder } from '@/utils/rules';

import { fetchOptions } from '@/constants/fetch';
import { useTableState } from '@/hooks/useTableState';

import TableEmptyData from '@/ui/organisms/TableEmptyData/TableEmptyData';
import { PricePlansTableCommentModal } from '@/ui/pages/PricePlansPage/organisms/PricePlansTable/organisms/PricePlansTableCommentModal/PricePlansTableCommentModal';
import { PricePlansTableRowsActions } from '@/ui/pages/PricePlansPage/organisms/PricePlansTable/organisms/PricePlansTableRowActions/PricePlansTableRowsActions';

import { AppDispatch } from '@/store';
import { selectActiveFilters } from '@/store/slices/filters/filters';
import {
  fetchPlansAction,
  selectFetchingClonePlan,
  selectPlansCommentModalOpened,
  selectPlansQuery,
  selectPlansRefetch,
  setCheckedPlan,
  setCheckedPlans,
  setPlansSorting,
} from '@/store/slices/plans/plans';

export const PricePlansTable: FC = () => {
  const dispatch: AppDispatch = useDispatch();

  const { plansColumns } = useTableState();

  const activePlansFilters = useSelector(selectActiveFilters).plans;
  const plansQuery = useSelector(selectPlansQuery);
  const plansRefetch = useSelector(selectPlansRefetch);
  const commentModalOpened = useSelector(selectPlansCommentModalOpened);
  const fetchingClonePlan = useSelector(selectFetchingClonePlan);

  const tableContainerRef = useRef<HTMLDivElement>(null);
  const rowVirtualizerInstanceRef =
    useRef<MRT_Virtualizer<HTMLDivElement, HTMLTableRowElement>>(null);

  const [sorting, setSorting] = useState<MRT_SortingState>([]);

  const getInfiniteData = async ({ pageParam = 0 }) => {
    const result = await dispatch(
      fetchPlansAction({
        sort: getPlansSort(sorting),
        order: getOrder(sorting),
        offset: pageParam * fetchOptions.FETCH_PLANS_LIMIT,
        limit: fetchOptions.FETCH_PLANS_LIMIT,
        filter: activePlansFilters,
        ...(plansQuery.length && { query: plansQuery }),
      }),
    );
    return result.payload;
  };

  const { data, fetchNextPage, isLoading, isFetching } = useInfiniteQuery<IItems<IPlanItem>>({
    queryKey: ['getPlans', sorting, activePlansFilters, plansQuery, plansRefetch],
    queryFn: getInfiniteData,
    getNextPageParam: (_lastGroup, groups) => groups.length,
  });

  const flatData = useMemo(
    () => (data?.pages[0]?.items ? data?.pages.flatMap((page) => page?.items) : []),
    [data],
  );

  const totalRowsCount = data?.pages?.[0]?.total ?? 0;
  const totalFetched = flatData.length;

  const fetchMoreOnBottomReached = useCallback(
    (containerRefElement?: HTMLDivElement | null) => {
      if (containerRefElement) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
        if (
          scrollHeight - scrollTop - clientHeight < 646 &&
          !isFetching &&
          totalFetched < totalRowsCount
        ) {
          fetchNextPage();
        }
      }
    },
    [fetchNextPage, isFetching, totalFetched],
  );

  const plansTable = useMantineReactTable({
    columns: plansColumns,
    data: flatData,
    enableColumnResizing: true,
    enablePinning: true,
    enableStickyHeader: true,
    enableRowSelection: true,
    enableGrouping: true,
    enableColumnOrdering: true,
    initialState: {
      density: 'xs',
      showColumnFilters: false,
      showGlobalFilter: false,
      columnPinning: {
        right: ['mrt-row-actions'],
      },
    },
    enableColumnFilters: false,
    enablePagination: false,
    enableColumnActions: false,
    enableRowActions: true,
    positionActionsColumn: 'last',
    displayColumnDefOptions: {
      'mrt-row-actions': {
        header: '',
        size: 43,
        mantineTableBodyCellProps: ({ row }) =>
          row.getIsSelected() ? { bg: 'gray.2' } : { bg: 'white' },
      },
      'mrt-row-select': {
        size: 40,
      },
    },
    mantineTableProps: {
      withColumnBorders: true,
      withBorder: true,
      highlightOnHover: false,
      sx: {
        tableLayout: 'fixed',
      },
    },
    mantineTableBodyProps: {
      sx: {
        minHeight: 'auto',
      },
    },
    mantineTableHeadRowProps: {
      sx: (theme) => ({
        backgroundColor: theme.colors.gray[2],
        boxShadow: 'none',
        withBorder: true,
      }),
    },
    mantineTableHeadProps: {
      sx: {
        opacity: 1,
      },
    },
    mantineTableHeadCellProps: {
      sx: {
        ['div[class~="mantine-TableHeadCell-Content-Labels"]']: {
          gap: '5px',
          color: 'black',
        },
        ['div[class~="mantine-TableHeadCell-Content-Actions"]']: {
          marginLeft: 'auto',
          transform: 'rotate(90deg)',
        },
      },
    },
    mantineSelectCheckboxProps: {
      color: 'dark',
      radius: 'sm',
    },
    mantineSelectAllCheckboxProps: {
      color: 'dark',
      radius: 'sm',
    },
    renderRowActionMenuItems: ({ row, table }) => (
      <PricePlansTableRowsActions planData={row.original} table={table} />
    ),
    renderEmptyRowsFallback: () => <TableEmptyData variant="plans" />,
    enableRowVirtualization: true,
    manualSorting: true,
    mantineTableContainerProps: {
      ref: tableContainerRef,
      sx: { height: 'calc(100vh - 350px)' },
      onScroll: (event: UIEvent<HTMLDivElement>) =>
        fetchMoreOnBottomReached(event.target as HTMLDivElement),
    },
    onSortingChange: setSorting,
    state: {
      isLoading,
      showProgressBars: isFetching,
      sorting,
    },
    rowVirtualizerInstanceRef,
    rowVirtualizerProps: { overscan: 10 },
    localization: MRT_Localization_RU,
  });

  const checkedRows = plansTable.getSelectedRowModel().rows;

  useEffect(() => {
    if (rowVirtualizerInstanceRef.current) {
      try {
        rowVirtualizerInstanceRef.current.scrollToIndex(0);
      } catch (e) {
        console.error(e);
      }
    }
  }, [sorting, activePlansFilters, plansRefetch]);

  useEffect(() => {
    dispatch(setPlansSorting(sorting));
  }, [sorting]);

  useEffect(() => {
    fetchMoreOnBottomReached(tableContainerRef.current);
  }, [fetchMoreOnBottomReached]);

  useEffect(() => {
    dispatch(setCheckedPlans(checkedRows.map((item) => item.original)));
  }, [activePlansFilters, checkedRows]);

  useEffect(() => {
    plansTable.resetRowSelection();
  }, [activePlansFilters, plansRefetch]);

  useEffect(() => {
    if (checkedRows.length) dispatch(setCheckedPlan(null));
  }, [checkedRows]);

  useEffect(() => {
    if (!commentModalOpened) plansTable.resetRowSelection();
  }, [commentModalOpened]);

  return (
    <Flex direction="column">
      <MRT_TableContainer table={plansTable} />
      {isFetching && !isLoading && (
        <Flex justify="center">
          <Loader mt={10} color="black" size="lg" />
        </Flex>
      )}
      {fetchingClonePlan && !isFetching && (
        <Flex justify="center">
          <Loader mt={10} color="black" size="lg" />
        </Flex>
      )}
      <PricePlansTableCommentModal />
    </Flex>
  );
};
