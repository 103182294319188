import { FC, HTMLAttributes } from 'react';
import { Divider } from '@mantine/core';

const PageFooter: FC<HTMLAttributes<HTMLElement>> = ({ children }) => {
  return (
    <>
      <Divider sx={(theme) => ({ margin: '16px -16px', borderTopColor: theme.colors.gray[2] })} />
      {children}
    </>
  );
};

export default PageFooter;
