import { combineReducers } from 'redux';

import authReducer from '@/store/slices/auth/auth';
import filtersReducer from '@/store/slices/filters/filters';
import monitoringReducer from '@/store/slices/monitoring/monitoring';
import nodesReducer from '@/store/slices/nodes/nodes';
import notificationsReducer from '@/store/slices/notifications/notifications';
import plansReducer from '@/store/slices/plans/plans';
import pricesReducer from '@/store/slices/prices/prices';
import productsReducer from '@/store/slices/products/products';
import rulesReducer from '@/store/slices/rules/rules';
import userReducer from '@/store/slices/user/user';

const rootReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  rules: rulesReducer,
  nodes: nodesReducer,
  products: productsReducer,
  plans: plansReducer,
  monitoring: monitoringReducer,
  prices: pricesReducer,
  notifications: notificationsReducer,
  filters: filtersReducer,
});

export default rootReducer;
export type IRootReducer = ReturnType<typeof rootReducer>;
